import React, { useState } from "react";
import MultiSelectDropdown from "../../dropdown/MultiSelectDropdown";
import RadioButton from "../../inputs/RadioButtonInput";
import ButtonLoader from "../../common/buttonLoader/ButtonLoader";
import ValidationText from "../../../utils/validation/ValidationText";
import TopupRegistrationHeadModal from "../../../pages/membership/slotsMembers/TopupRegistrationHeadModal";
import TopupRegistrationHeadModalAdditional from "../../../pages/membership/slotsMembers/TopupRegistrationHeadModalAdditional";

const SLOTSApplication = (props) => {
  const [isTopupModal, setTopupModal] = useState(false);
  const [isTopupModalAdditional, setTopupModalAdditional] = useState(false);

  return (
    <div className="w-full">
      <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="xl:col-span-12 lg:col-span-6 col-span-12 input-sec-texts">
            <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
              Company Financial Strength
            </h2>

            <div class="flex pt-4 flex items-center">
              <div class="flex items-center w-auto h-6">
                <img
                  src={
                    props &&
                      props.SLOTSApplicationDetails.bankAccountStatementList
                        ?.length > 0
                      ? "/check-orange.png"
                      : "/checkNot.png"
                  }
                  className="w-[25px] mr-3"
                  alt=""
                />
                <span className="text-[#000]">
                  Company bank account statements for past 6 months
                </span>
              </div>
              <div className="ml-5">
                <ValidationText
                  error={
                    props.validStateSLOTSMember.error
                      .companyBankStatementsOf6Month
                  }
                />
              </div>
              <div class="flex-initial w-auto">
                {props && props.isBankAccStatLoader ? (
                  <ButtonLoader className="ml-10 btn btn-orange" />
                ) : (
                  <form className="custom-uploader custom-file-upload-btn flex cursor-pointer ml-10 md:mt-0 mt-2">
                    <div className={`border border-[#f3f3f3] btn btn-orange ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                      <label
                        htmlFor="Bank_Account_Statement"
                        className={`text-xl text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                      >
                        Upload File
                      </label>
                      <input
                        className="sr-only"
                        id="Bank_Account_Statement"
                        type="file"
                        onChange={(event) =>
                          props.onFileChange(event, "Bank_Account_Statement")
                        }
                        disabled={props.isDisabledFlag ? true : false}
                      />
                    </div>
                  </form>
                )}
              </div>
              {props &&
                props.SLOTSApplicationDetails.companyBankStatementsOf6Month ? (
                <div class="w-auto">
                  <button
                    className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c]"
                    onClick={() =>
                      props.DownloadMultipleFilesZip("BankAccountStatements")
                    }
                  >
                    <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                      View Files
                    </span>
                  </button>
                </div>
              ) : null}
            </div>
            <div>
              <div>
                {props.SLOTSApplicationDetails.bankAccountStatementList
                  ?.length > 0
                  ? props.SLOTSApplicationDetails.bankAccountStatementList.map(
                    (data, key) => {
                      return (
                        <div
                          className="mt-5 ml-9 file-uploaded-txt flex items-center"
                          key={key}
                        >
                          {/* <img src='/check-orange.png' className='w-[25px] mr-3' alt=''></img> */}
                          <span className="text-[#000]">{data}</span>
                          <button
                            className={`underline text-[#e74c3c] font-[500] ml-3 ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                            onClick={() =>
                              props.deleteFile(
                                data,
                                "Bank_Account_Statement_6Months"
                              )
                            }
                            disabled={props.isDisabledFlag ? true : false}
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </button>
                        </div>
                      );
                    }
                  )
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 mt-5">
          <div className="form-info-title">
            <h4 className="text-[#000000] text-base form-info-title-txt">
              Company Activities
            </h4>
          </div>
          <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-2">
            <div className="lg:col-span-6 col-span-12">
              <h2 className=" mb-3">
                Are you registered with Building and Construction Authority
                (BCA)?
                <span className="text-[#C00000]">*</span>
              </h2>
              <div className="flex items-center w-full">
                <div
                  className={`flex items-center ${props.isDisabledFlag ? "cursor-not-allowed" : null
                    }`}
                >
                  <RadioButton
                    id="isCompanyActivities"
                    name="isCompanyActivities"
                    className={`form-radio-orange ${props.isDisabledFlag ? "cursor-not-allowed" : null
                      }`}
                    value={props.SLOTSApplicationDetails.isCompanyActivities}
                    checked={
                      props.SLOTSApplicationDetails.isCompanyActivities === true
                    }
                    handleRadioButtons={(e) =>
                      props.handleChange(e, "Radio_Company_Activities")
                    }
                    disabled={props.isDisabledFlag ? true : false}
                  />
                  <span className="ml-3">Yes</span>
                </div>
                <div
                  className={`flex items-center ml-10 ${props.isDisabledFlag ? "cursor-not-allowed" : null
                    }`}
                >
                  <RadioButton
                    id="isCompanyActivities"
                    name="isCompanyActivities"
                    className={`form-radio-orange ${props.isDisabledFlag ? "cursor-not-allowed" : null
                      }`}
                    value={props.SLOTSApplicationDetails.isCompanyActivities}
                    checked={
                      props.SLOTSApplicationDetails.isCompanyActivities ===
                      false
                    }
                    handleRadioButtons={(e) =>
                      props.handleChange(e, "Radio_Company_Activities")
                    }
                    disabled={props.isDisabledFlag ? true : false}
                  />
                  <span className="ml-3">No</span>
                </div>
              </div>
            </div>

            {/* Table Section Start */}
            <div className="col-span-12">
              <div className="table-section">
                <div className="overflow-x-auto">
                  <table className="w-full">
                    <thead className="bg-[#e78e3c] text-white">
                      <tr>
                        <th className="whitespace-nowrap text-left py-3 px-5">
                          Selected Scope
                        </th>
                        <th className="whitespace-nowrap text-left py-3 px-5">
                          Grade
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.SLOTSApplicationDetails &&
                        props.SLOTSApplicationDetails.registeredBCAScopeList?.map(
                          (scope, key) => {
                            return (
                              <tr
                                className="bg-[#eeeeee] text-black border-t-4 border-[#ffffff]"
                                key={key}
                              >
                                <td className="whitespace-nowrap text-left py-2 px-5">
                                  {`${scope.mainGroupName} - ${scope.workheadName}`}
                                </td>
                                <td className="whitespace-nowrap text-left py-2 px-5">
                                  {scope.gradeName}
                                </td>
                                {/* <td className='whitespace-nowrap text-center py-2 px-5'>
                                                            <button
                                                                className='action-btn'
                                                                onClick={(event) => props.handleChange(event, "Remove_BCA_Scope", key)}
                                                            >
                                                                <img className='h-[30px] ' src='../delete-icon.svg' alt=''>
                                                                </img>
                                                            </button>
                                                        </td> */}
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* Table Section End */}
          </div>

          <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-10">
            <div className="md:col-span-4 col-span-12">
              <h2 className="input-title mb-3">
                Main Group
                <span className="text-[#C00000]">*</span>
              </h2>
              <div className="dropdown">
                <div className="select-dropdown w-full">
                  <MultiSelectDropdown
                    drpIdentity="Main_Group_Scope1"
                    options={props.mainGroupList}
                    // isMulti
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    onChange={props.handleChangeInDropdown}
                    allowSelectAll={false}
                    value={props.mainGroupList.filter(
                      (mainGroup) =>
                        mainGroup.value ===
                        props.selectedBuildingConScope.selectedMainGroupId
                    )}
                    disabled={props.isDisabledFlag ? true : false}
                  />
                  <ValidationText
                    error={props.validStateBCAScope.error.selectedMainGroupId}
                  />
                </div>
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <h2 className="input-title mb-3">
                Workhead
                <span className="text-[#C00000]">*</span>
              </h2>
              <div className="dropdown">
                <div className="select-dropdown w-full">
                  <MultiSelectDropdown
                    drpIdentity="Workhead_Scope1"
                    options={props.workheadList}
                    // isMulti
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    onChange={props.handleChangeInDropdown}
                    allowSelectAll={false}
                    value={props.workheadList.filter(
                      (workhead) =>
                        workhead.value ===
                        props.selectedBuildingConScope.selectedWorkheadId
                    )}
                    disabled={props.isDisabledFlag ? true : false}
                  />
                  <ValidationText
                    error={props.validStateBCAScope.error.selectedWorkheadId}
                  />
                </div>
              </div>
            </div>
            <div className="md:col-span-4 col-span-12">
              <h2 className="input-title mb-3">
                Grade
                <span className="text-[#C00000]">*</span>
              </h2>
              <div className="dropdown">
                <div className="select-dropdown w-full">
                  <MultiSelectDropdown
                    drpIdentity="Grade_Scope1"
                    options={props.gradeList}
                    // isMulti
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    onChange={props.handleChangeInDropdown}
                    allowSelectAll={false}
                    value={props.gradeList.filter(
                      (grade) =>
                        grade.value ===
                        props.selectedBuildingConScope.selectedGradeId
                    )}
                    disabled={props.isDisabledFlag ? true : false}
                  />
                  <ValidationText
                    error={props.validStateBCAScope.error.selectedGradeId}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-12">
              <div className="flex justify-end">
                <button
                  className={`btn btn-orange-border ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                  onClick={(event) =>
                    props.handleChange(event, "Add_BCA_Scope")
                  }
                  disabled={props.isDisabledFlag ? true : false}
                >
                  <span className="fa fa-plus"></span>
                  <span className="ml-2">Update Scope</span>
                </button>
              </div>
              {/* {
                //props.SLOTSApplicationDetails.statusId === 4 || props.SLOTSApplicationDetails.statusId === 15 || props.SLOTSApplicationDetails.statusId === 16 || props.SLOTSApplicationDetails.statusId === 17 || props.SLOTSApplicationDetails.statusId === 18 || props.SLOTSApplicationDetails.statusId === 22 ?
                props.SLOTSApplicationDetails.statusId === 4 || props.SLOTSApplicationDetails.statusId === 15 || props.SLOTSApplicationDetails.statusId === 16 || props.SLOTSApplicationDetails.statusId === 17 || props.SLOTSApplicationDetails.statusId === 18 || props.SLOTSApplicationDetails.statusId === 22 ?
                  <>
                    <div className="flex justify-end mt-[15px]">
                      <button
                        className={`btn btn-orange-border ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                        onClick={() => { setTopupModal(true); props.pricingCalculation() }}
                        disabled={props.isDisabledFlag ? true : false}
                      >
                        <span className="fa fa-plus"></span>
                        <span className="ml-2">Update Registration Head(s)</span>
                      </button>
                    </div>
                  </> : <></>
              } */}
              {/* {
                props.SLOTSApplicationDetails.statusId === 4 || props.SLOTSApplicationDetails.statusId === 15 || props.SLOTSApplicationDetails.statusId === 16 || props.SLOTSApplicationDetails.statusId === 17 || props.SLOTSApplicationDetails.statusId === 18 || props.SLOTSApplicationDetails.statusId === 22 ?
                  <>
                    <div className="flex justify-end mt-[15px]">
                      <button
                        className={`btn btn-orange-border ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                        onClick={() => { setTopupModalAdditional(true); props.pricingCalculationAdditional() }}
                        disabled={props.isDisabledFlag ? true : false}
                      >
                        <span className="fa fa-plus"></span>
                        <span className="ml-2">TopUp-Registration Head(s)</span>
                      </button>
                    </div>
                  </> : <></>
              } */}


            </div>

          </div>

          <div className="col-span-12 mt-5">
            {/* Table Section Start */}
            <div className="grid grid-cols-12 gap-4 w-full justify-between mt-8">
              <div className="col-span-12">
                <div className="flex justify-end lg:gap-6 gap-3">
                <div className="lg:col-span-4 col-span-12 flex justify-end mt-[15px]">
                  <button
                    className={`btn btn-orange-border ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                    onClick={() => { setTopupModal(true); props.pricingCalculation() }}
                    disabled={props.isDisabledFlag ? true : false}
                  >
                    <span className="fa fa-plus"></span>
                    <span className="ml-2">Update Registration Head(s)</span>
                  </button>

                </div>
                {
                  props.SLOTSApplicationDetails.statusId === 4 ?
                    <>
                      <div className=" lg:col-span-4  col-span-12 flex justify-end mt-[15px]">
                        <button
                          className={`btn btn-orange-border ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                          onClick={() => { setTopupModalAdditional(true); props.pricingCalculationAdditional() }}
                          disabled={props.isDisabledFlag ? true : false}
                        >
                          <span className="fa fa-plus"></span>
                          <span className="ml-2">TopUp-Registration Head(s)</span>
                        </button>
                      </div>
                    </> : <></>
                }
                </div>
             
              </div>
              <div className="lg:col-span-8 col-span-12">
                <h2 className="input-title mt-[15px]">
                  Please select the SLOTs registration head(s) you would like to
                  apply
                  <span className="text-[#C00000]">*</span>

                </h2>

              </div>
             

              <div className="col-span-12">
                <div className="table-section">
                  <div className="overflow-x-auto">
                    <table className="w-full">
                      <thead className="bg-[#e78e3c] text-white">
                        <tr>
                          <th className="whitespace-nowrap text-left py-3 px-5">
                            Selected Scope
                          </th>
                          <th className="whitespace-nowrap text-left py-3 px-5">
                            Workhead
                          </th>
                          {/* <th className='whitespace-nowrap text-center py-3 px-5'>

                                                    </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {props &&
                          props.SLOTSApplicationDetails.slotsRegistrationHeadScope?.map(
                            (scope, key) => {
                              return (
                                <tr
                                  className="bg-[#eeeeee] text-black border-t-4 border-[#ffffff]"
                                  key={key}
                                >
                                  <td className="whitespace-nowrap text-left py-2 px-5">
                                    {scope.mainGroupName}
                                  </td>
                                  <td className="whitespace-nowrap text-left py-2 px-5">
                                    {scope.workheadName}
                                  </td>
                                  {/* <td className='whitespace-nowrap text-center py-2 px-5'>
                                                                <button
                                                                    className='action-btn'
                                                                    onClick={(event) => props.handleChange(event, "Remove_SLOTS_Registration_Scope", key)}
                                                                >
                                                                    <img className='h-[30px] ' src='../delete-icon.svg' alt=''>
                                                                    </img>
                                                                </button>
                                                            </td> */}
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* Table Section End */}

            {/* Table Section Start */}
            {/* Table Section End */}

            <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-10">
              {/* <div className="md:col-span-4 col-span-12">
                <h2 className="input-title mb-3">
                  Main Group
                  <span className="text-[#C00000]">*</span>
                </h2>
                <div className="dropdown">
                  <div className="select-dropdown w-full">
                    <MultiSelectDropdown
                      drpIdentity="Main_Group_Scope2"
                      options={props.mainGroupListPricing}
                      // isMulti
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={props.handleChangeInDropdown}
                      allowSelectAll={false}
                      value={props.mainGroupListPricing.filter(
                        (mainGroup) =>
                          mainGroup.value ===
                          props.selectedSLOTSRegistrationScope
                            .selectedMainGroupId
                      )}
                    />
                    <ValidationText
                      error={
                        props.validStateregistrationScope.error
                          .selectedMainGroupId
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="md:col-span-4 col-span-12">
                <h2 className="input-title mb-3">
                  Workhead
                  <span className="text-[#C00000]">*</span>
                </h2>
                <div className="dropdown">
                  <div className="select-dropdown w-full">
                    <MultiSelectDropdown
                      drpIdentity="Workhead_Scope2"
                      options={props.workheadListPricing}
                      // isMulti
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={props.handleChangeInDropdown}
                      allowSelectAll={false}
                      value={props.workheadListPricing.filter(
                        (workhead) =>
                          workhead.value ===
                          props.selectedSLOTSRegistrationScope
                            .selectedWorkheadId
                      )}
                    />
                    <ValidationText
                      error={
                        props.validStateregistrationScope.error
                          .selectedWorkheadId
                      }
                    />
                  </div>
                </div>
              </div> */}



            </div>

            <div className="col-span-12 mt-5">
              {/* Table Section Start */}
              <div className="grid grid-cols-12 gap-4 w-full justify-between">
                <div className="lg:col-span-6 col-span-12">
                  <h2 className="input-title">
                    Price Calculator
                    <span className="text-[#C00000]">*</span>
                  </h2>
                </div>
                <div className="col-span-12">
                  <div className="table-section">
                    <div className="overflow-x-auto">
                      <table className="w-full">
                        <thead className="bg-[#e78e3c] text-white">
                          <tr>
                            <th className="whitespace-nowrap text-left py-3 px-5">
                              Description
                            </th>
                            <th className="whitespace-nowrap text-left py-3 px-5">
                              Quantity
                            </th>
                            <th className="whitespace-nowrap text-center py-3 px-5">
                              Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.SLOTSApplicationDetails.priceCalculator.map(
                            (price, key) => {
                              return (
                                <tr
                                  className="bg-[#eeeeee] text-black border-t-4 border-[#ffffff]"
                                  key={key}
                                >
                                  <td className="whitespace-nowrap text-left py-2 px-5">
                                    {price.description}
                                  </td>
                                  <td className="whitespace-nowrap text-left py-2 px-5">
                                    {price.quantity}
                                  </td>
                                  <td className="whitespace-nowrap text-center py-2 px-5">
                                    ${price.amount}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          {/* <tr className='bg-[#eeeeee] text-black border-t-4 border-[#ffffff]'>
                                                    <td className='whitespace-nowrap text-left py-2 px-5' colSpan="2">
                                                        Subtotal (before GST)
                                                    </td>
                                                    <td className='whitespace-nowrap text-center py-2 px-5'>
                                                        $700
                                                    </td>
                                                </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div> {`Calculated Length : ${props.SLOTSApplicationDetails.renewalSLOTSRegistrationHeadScope?.length}`}</div> */}
              {/* Table Section End */}
              {/* {props && props.renewalDataLength > 0 ? */}
              {/* added in comments renewal Please select the SLOTs registration head(s) you would like to apply*  */}
              {/* <div className="mt-8">
                {props.SLOTSApplicationDetails.renewalSLOTSRegistrationHeadScope
                  ?.length > 0 ? (
                  <>
                    <div className="grid grid-cols-12 gap-4 w-full justify-between">
                      <div className="lg:col-span-8 col-span-12">
                        <h2 className="input-title mb-3">
                          Renewal Please select the SLOTs registration head(s)
                          you would like to apply
                          <span className="text-[#C00000]">*</span>
                        </h2>
                      </div>
                      <div className="col-span-12">
                        <div className="table-section">
                          <div className="overflow-x-auto">
                            <table className="w-full">
                              <thead className="bg-[#e78e3c] text-white">
                                <tr>
                                  <th className="whitespace-nowrap text-left py-3 px-5">
                                    Selected Scope
                                  </th>
                                  <th className="whitespace-nowrap text-left py-3 px-5">
                                    Workhead
                                  </th>
                                 
                                </tr>
                              </thead>
                              <tbody>
                                {props &&
                                  props.SLOTSApplicationDetails.renewalSLOTSRegistrationHeadScope?.map(
                                    (scope, key) => {
                                      return (
                                        <tr
                                          className="bg-[#eeeeee] text-black border-t-4 border-[#ffffff]"
                                          key={key}
                                        >
                                          <td className="whitespace-nowrap text-left py-2 px-5">
                                            {scope.mainGroupName}
                                          </td>
                                          <td className="whitespace-nowrap text-left py-2 px-5">
                                            {scope.workheadName}
                                          </td>
                                       
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div> */}

              {/* <div>{`Rernewal Price Calculator Lenght : ${props.SLOTSApplicationDetails.renewalPriceCalculator?.length}`}</div> */}
              {/* added in comments   Renewal Price Calculator*/}
              {/* <div className="mt-8">
                {props.SLOTSApplicationDetails.renewalPriceCalculator?.length >
                  0 ? (
                  <>
                    <div className="grid grid-cols-12 gap-4 w-full justify-between">
                      <div className="lg:col-span-6 col-span-12">
                        <h2 className="input-title">
                          Renewal Price Calculator
                          <span className="text-[#C00000]">*</span>
                        </h2>
                      </div>
                      <div className="col-span-12">
                        <div className="table-section">
                          <div className="overflow-x-auto">
                            <table className="w-full">
                              <thead className="bg-[#e78e3c] text-white">
                                <tr>
                                  <th className="whitespace-nowrap text-left py-3 px-5">
                                    Description
                                  </th>
                                  <th className="whitespace-nowrap text-left py-3 px-5">
                                    Quantity
                                  </th>
                                  <th className="whitespace-nowrap text-center py-3 px-5">
                                    Amount
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {props.SLOTSApplicationDetails.renewalPriceCalculator.map(
                                  (price, key) => {
                                    return (
                                      <tr
                                        className="bg-[#eeeeee] text-black border-t-4 border-[#ffffff]"
                                        key={key}
                                      >
                                        <td className="whitespace-nowrap text-left py-2 px-5">
                                          {price.description}
                                        </td>
                                        <td className="whitespace-nowrap text-left py-2 px-5">
                                          {price.quantity}
                                        </td>
                                        <td className="whitespace-nowrap text-center py-2 px-5">
                                          ${price.amount}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                                
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div> */}

              <div className="col-span-12 mt-5">
                <div className="form-info-title">
                  <h4 className="input-title mt-4">Project Track Record</h4>
                </div>

                {/* Form A (on-going project) */}
                <div class="flex pt-4 flex items-center">
                  <div class="flex items-center w-auto h-6">
                    <img
                      src={
                        props &&
                          props.SLOTSApplicationDetails
                            .projectTrackRecordsOfOnGoingProject
                          ? "/check-orange.png"
                          : "/checkNot.png"
                      }
                      className="w-[25px] mr-3"
                      alt=""
                    />
                    <span className="text-[#000]">
                      Form A (on-going project)
                    </span>
                  </div>
                  <div class="flex-initial w-auto">
                    {props && props.isOnGoingProLoader ? (
                      <ButtonLoader className="ml-10 btn btn-orange" />
                    ) : (
                      <form className="custom-uploader custom-file-upload-btn flex cursor-pointer ml-10 md:mt-0 mt-2">
                        <div className={`border border-[#f3f3f3] btn btn-orange ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                          <label
                            htmlFor="Upload_from_A"
                            className={`text-xl text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                          >
                            Upload File
                          </label>
                          <input
                            className="sr-only"
                            id="Upload_from_A"
                            type="file"
                            onChange={(event) =>
                              props.onFileChange(event, "Upload_from_A")
                            }
                            disabled={props.isDisabledFlag ? true : false}
                          />
                        </div>
                      </form>
                    )}
                  </div>
                  <div class="w-auto">
                    <button
                      className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c]"
                      onClick={() =>
                        props.GetSlotsMemberFiles(
                          "ProjectTrackRecordsOfOnGoingProject",
                          props.SLOTSApplicationDetails
                            .projectTrackRecordsOfOnGoingProject
                        )
                      }
                    >
                      <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                        View Files
                      </span>
                    </button>
                  </div>
                </div>
                <div className="ml-9 mt-2 mb-2">
                  {
                    props.SLOTSApplicationDetails
                      .projectTrackRecordsOfOnGoingProject
                  }
                </div>

                {/* Form A (on-going project) */}
                {/* <div class="flex pt-4 flex items-center">
                  <div class="flex items-center w-auto h-6">
                    <img
                      src={
                        props &&
                        props.SLOTSApplicationDetails
                          .projectTrackRecordsOfOnGoingProject
                          ? "/check-orange.png"
                          : "/checkNot.png"
                      }
                      className="w-[25px] mr-3"
                      alt=""
                    />
                    <span className="text-[#000]">
                      Form A (on-going project)
                    </span>
                  </div>
                  <div className="ml-5">
                    <ValidationText
                      error={
                        props.validStateSLOTSMember.error
                          .projectTrackRecordsOfOnGoingProject
                      }
                    />
                  </div>
                  <div class="flex-initial w-auto">
                    {props && props.isOnGoingProLoader ? (
                      <ButtonLoader className="ml-10 btn btn-orange" />
                    ) : (
                      <form className="custom-uploader custom-file-upload-btn flex cursor-pointer ml-10 md:mt-0 mt-2">
                        <div className="border border-[#f3f3f3] btn btn-orange">
                          <label
                            htmlFor="Upload_from_A"
                            className="text-xl text-white"
                          >
                            Upload File
                          </label>
                          <input
                            className="sr-only"
                            id="Upload_from_A"
                            type="file"
                            onChange={(event) =>
                              props.onFileChange(event, "Upload_from_A")
                            }
                          />
                        </div>
                      </form>
                    )}
                  </div>
                  <div class="w-auto">
                    <button
                      className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c]"
                      onClick={() =>
                        props.GetSlotsMemberFiles(
                          "ProjectTrackRecordsOfOnGoingProject",
                          props.SLOTSApplicationDetails
                            .projectTrackRecordsOfOnGoingProject
                        )
                      }
                    >
                      <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                        View Files
                      </span>
                    </button>
                  </div>
                </div>
                <div className="ml-9 mt-2 mb-2">
                  {
                    props.SLOTSApplicationDetails
                      .projectTrackRecordsOfOnGoingProject
                  }
                </div> */}

                {/* Form A (on-going project Supporting document) */}
                <div class="flex pt-4 flex items-center">
                  <div class="flex items-center w-auto h-6">
                    <img
                      src={
                        props &&
                          props.SLOTSApplicationDetails.formAOnGoingList?.length >
                          0
                          ? "/check-orange.png"
                          : "/checkNot.png"
                      }
                      className="w-[25px] mr-3"
                      alt=""
                    />
                    <span className="text-[#000]">
                      Form A (on-going project Supporting document)
                    </span>
                  </div>
                  <div className="ml-5">
                    <ValidationText
                      error={
                        props.validStateSLOTSMember.error
                          .supportingDocFormAOngoing
                      }
                    />
                  </div>
                  <div class="flex-initial w-auto">
                    {props && props.isOnGoingProLoader2 ? (
                      <ButtonLoader className="ml-10 btn btn-orange" />
                    ) : (
                      <form className="custom-uploader custom-file-upload-btn flex cursor-pointer ml-10 md:mt-0 mt-2">
                        <div className={`border border-[#f3f3f3] btn btn-orange ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                          <label
                            htmlFor="supportingDocFormAOngoing"
                            className={`text-xl text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                          >
                            Upload File
                          </label>
                          <input
                            className="sr-only"
                            id="supportingDocFormAOngoing"
                            type="file"
                            onChange={(event) =>
                              props.onFileChange(
                                event,
                                "supportingDocFormAOngoing"
                              )
                            }
                            disabled={props.isDisabledFlag ? true : false}
                          />
                        </div>
                      </form>
                    )}
                  </div>
                  {props &&
                    props.SLOTSApplicationDetails.supportingDocFormAOngoing ? (
                    <div class="w-auto">
                      <button
                        className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c]"
                        onClick={() =>
                          props.DownloadMultipleFilesZip(
                            "supportingDocFormAOngoing"
                          )
                        }
                      >
                        <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                          View Files
                        </span>
                      </button>
                    </div>
                  ) : null}
                </div>
                <div>
                  {props.SLOTSApplicationDetails.formAOnGoingList?.length > 0
                    ? props.SLOTSApplicationDetails.formAOnGoingList.map(
                      (data, key) => {
                        return (
                          <div
                            className="mt-5 ml-9 file-uploaded-txt flex items-center"
                            key={key}
                          >
                            {/* <img src='/check-orange.png' className='w-[25px] mr-3' alt=''></img> */}
                            <span className="text-[#000]">{data}</span>
                            {/* <button className='underline text-[#e74c3c] font-[500] ml-3'
                                                    // onClick={() => props.GetSlotsMemberFiles('WPOnlineRecord', data)}
                                                    >
                                                        Download File
                                                    </button> */}
                            <button
                              className={`underline text-[#e74c3c] font-[500] ml-3 ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                              onClick={() =>
                                props.deleteFile(
                                  data,
                                  "Supporting_Doc_Form_Ongoing"
                                )
                              }
                              disabled={props.isDisabledFlag ? true : false}
                            >
                              <i
                                className="fa fa-trash"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        );
                      }
                    )
                    : null}
                </div>

                {/* Form A (completed project) */}
                <div class="flex pt-4 flex items-center">
                  <div class="flex items-center w-auto h-6">
                    <img
                      src={
                        props &&
                          props.SLOTSApplicationDetails
                            .projectTrackRecordsOfCompletedProject
                          ? "/check-orange.png"
                          : "/checkNot.png"
                      }
                      className="w-[25px] mr-3"
                      alt=""
                    />
                    <span className="text-[#000]">
                      Form A (completed project)
                    </span>
                  </div>
                  <div class="flex-initial w-auto">
                    {props && props.isCompProLoader ? (
                      <ButtonLoader className="ml-10 btn btn-orange" />
                    ) : (
                      <form className="custom-uploader custom-file-upload-btn flex cursor-pointer ml-10 md:mt-0 mt-2">
                        <div className={`border border-[#f3f3f3] btn btn-orange ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                          <label
                            htmlFor="Upload_from_B"
                            className={`text-xl text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                          >
                            Upload File
                          </label>
                          <input
                            className="sr-only"
                            id="Upload_from_B"
                            type="file"
                            onChange={(event) =>
                              props.onFileChange(event, "Upload_from_B")
                            }
                            disabled={props.isDisabledFlag ? true : false}
                          />
                        </div>
                      </form>
                    )}
                  </div>
                  <div class="w-auto">
                    <button
                      className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c]"
                      onClick={() =>
                        props.GetSlotsMemberFiles(
                          "ProjectTrackRecordsOfOnCompletedProject",
                          props.SLOTSApplicationDetails
                            .projectTrackRecordsOfCompletedProject
                        )
                      }
                    >
                      <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                        View Files
                      </span>
                    </button>
                  </div>
                </div>
                <div className="ml-9 mt-2 mb-2">
                  {
                    props.SLOTSApplicationDetails
                      .projectTrackRecordsOfCompletedProject
                  }
                </div>

                {/* Form A (completed project) */}
                {/* <div class="flex pt-4 flex items-center">
                  <div class="flex items-center w-auto h-6">
                    <img
                      src={
                        props &&
                        props.SLOTSApplicationDetails
                          .projectTrackRecordsOfCompletedProject
                          ? "/check-orange.png"
                          : "/checkNot.png"
                      }
                      className="w-[25px] mr-3"
                      alt=""
                    />
                    <span className="text-[#000]">
                      Form A (completed project)
                    </span>
                  </div>
                  <div className="ml-5">
                    <ValidationText
                      error={
                        props.validStateSLOTSMember.error
                          .projectTrackRecordsOfCompletedProject
                      }
                    />
                  </div>
                  <div class="flex-initial w-auto">
                    {props && props.isCompProLoader ? (
                      <ButtonLoader className="ml-10 btn btn-orange" />
                    ) : (
                      <form className="custom-uploader custom-file-upload-btn flex cursor-pointer ml-10 md:mt-0 mt-2">
                        <div className="border border-[#f3f3f3] btn btn-orange">
                          <label
                            htmlFor="Upload_from_B"
                            className="text-xl text-white"
                          >
                            Upload File
                          </label>
                          <input
                            className="sr-only"
                            id="Upload_from_B"
                            type="file"
                            onChange={(event) =>
                              props.onFileChange(event, "Upload_from_B")
                            }
                          />
                        </div>
                      </form>
                    )}
                  </div>
                  <div class="w-auto">
                    <button
                      className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c]"
                      onClick={() =>
                        props.GetSlotsMemberFiles(
                          "ProjectTrackRecordsOfOnCompletedProject",
                          props.SLOTSApplicationDetails
                            .projectTrackRecordsOfCompletedProject
                        )
                      }
                    >
                      <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                        View Files
                      </span>
                    </button>
                  </div>
                </div>
                <div className="ml-9 mt-2 mb-2">
                  {
                    props.SLOTSApplicationDetails
                      .projectTrackRecordsOfCompletedProject
                  }
                </div> */}

                {/*  Form A (completed project Supporting document) */}
                <div class="flex pt-4 flex items-center">
                  <div class="flex items-center w-auto h-6">
                    <img
                      src={
                        props &&
                          props.SLOTSApplicationDetails.formACompletedList
                            ?.length > 0
                          ? "/check-orange.png"
                          : "/checkNot.png"
                      }
                      className="w-[25px] mr-3"
                      alt=""
                    />
                    <span className="text-[#000]">
                      Form A (completed project Supporting document)
                    </span>
                  </div>
                  <div className="ml-5">
                    <ValidationText
                      error={
                        props.validStateSLOTSMember.error
                          .supportingDocFormAOnComplete
                      }
                    />
                  </div>
                  <div class="flex-initial w-auto">
                    {props && props.isCompProLoader2 ? (
                      <ButtonLoader className="ml-10 btn btn-orange" />
                    ) : (
                      <form className="custom-uploader custom-file-upload-btn flex cursor-pointer ml-10 md:mt-0 mt-2">
                        <div className={`border border-[#f3f3f3] btn btn-orange ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                          <label
                            htmlFor="supportingDocFormAOnComplete"
                            className={`text-xl text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                          >
                            Upload File
                          </label>
                          <input
                            className="sr-only"
                            id="supportingDocFormAOnComplete"
                            type="file"
                            onChange={(event) =>
                              props.onFileChange(
                                event,
                                "supportingDocFormAOnComplete"
                              )
                            }
                            disabled={props.isDisabledFlag ? true : false}
                          />
                        </div>
                      </form>
                    )}
                  </div>
                  {props &&
                    props.SLOTSApplicationDetails.supportingDocFormAOnComplete ? (
                    <div class="w-auto">
                      <button
                        className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c]"
                        onClick={() =>
                          props.DownloadMultipleFilesZip(
                            "supportingDocFormAOnComplete"
                          )
                        }
                      >
                        <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                          View Files
                        </span>
                      </button>
                    </div>
                  ) : null}
                </div>
                <div>
                  {props.SLOTSApplicationDetails.formACompletedList?.length > 0
                    ? props.SLOTSApplicationDetails.formACompletedList.map(
                      (data, key) => {
                        return (
                          <div
                            className="mt-5 ml-9 file-uploaded-txt flex items-center"
                            key={key}
                          >
                            {/* <img src='/check-orange.png' className='w-[25px] mr-3' alt=''></img> */}
                            <span className="text-[#000]">{data}</span>
                            {/* <button className='underline text-[#e74c3c] font-[500] ml-3'
                                                    // onClick={() => props.GetSlotsMemberFiles('WPOnlineRecord', data)}
                                                    >
                                                        Download File
                                                    </button> */}
                            <button
                              className={`underline text-[#e74c3c] font-[500] ml-3 ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                              onClick={() =>
                                props.deleteFile(
                                  data,
                                  "Supporting_Doc_Form_Complete"
                                )
                              }
                              disabled={props.isDisabledFlag ? true : false}
                            >
                              <i
                                className="fa fa-trash"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        );
                      }
                    )
                    : null}
                </div>

                {/* <div class="flex pt-4 flex items-center">
                                    <div class="flex items-center w-auto h-6">
                                        <img
                                            src={props && props.SLOTSApplicationDetails.uploadPaymentProof ? '/check-orange.png' : '/checkNot.png'}
                                            className='w-[25px] mr-3'
                                            alt='' />
                                        <span className='text-[#000]'>
                                            Upload payment proof
                                        </span>
                                    </div>
                                    <div class="flex-initial w-auto">
                                        {
                                            props && props.isPaymentProofLoader ?
                                                <ButtonLoader
                                                    className='ml-10 btn btn-orange'
                                                />
                                                :
                                                (
                                                    <form className="custom-uploader custom-file-upload-btn flex cursor-pointer ml-10 md:mt-0 mt-2">
                                                        <div className="border border-[#f3f3f3] btn btn-orange">
                                                            <label htmlFor="Upload_Payment_Proof" className="text-xl text-white">Upload File</label>
                                                            <input className="sr-only" id="Upload_Payment_Proof" type="file"
                                                                onChange={(event) => props.onFileChange(event, "Upload_Payment_Proof")}
                                                            />
                                                        </div>
                                                    </form>
                                                )
                                        }
                                    </div>
                                    <div class="w-auto">
                                        <button className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c]"
                                        // onClick={() => props.DownloadMultipleFilesZip('BankAccountStatements')}
                                        >
                                            <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                                                View Files
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="ml-9 mt-2 mb-2">
                                    {props.SLOTSApplicationDetails.uploadPaymentProof}
                                </div> */}

                <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-3 mb-3">
                  {/* <div className="col-span-6">
                                        <div className='flex items-center mb-3'>
                                            <h2 className="input-title mr-5">
                                                Form A (on-going project)
                                                <span className="text-[#C00000]">*</span>
                                            </h2>
                                            <a className='underline text-[#e74c3c] font-[500]' href=''>
                                                Download File
                                            </a>
                                        </div>
                                        <div className="flex item-center flex-col">
                                            <button className="2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c] mb-10"
                                                onClick={() => props.GetSlotsMemberFiles('ProjectTrackRecordsOfOnGoingProject', props.SLOTSApplicationDetails.projectTrackRecordsOfOnGoingproject)}
                                            >
                                                <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                                                    View Form A (on-going project)
                                                </span>
                                            </button>
                                        </div>
                                    </div> */}
                  {/* <div className="col-span-6">
                                        {props.SLOTSApplicationDetails.supportingDocFormAOngoing ?
                                            <div className="flex item-center flex-col">
                                                <button className="2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c] mb-10"
                                                    // onClick={() => props.GetSlotsMemberFiles('supportingDocFormAOngoing', props.SLOTSApplicationDetails.supportingDocFormAOngoing)}
                                                    onClick={() => props.DownloadMultipleFilesZip('supportingDocFormAOngoing')}
                                                >
                                                    <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                                                        View Form A (Supporting On-going project)
                                                    </span>
                                                </button>
                                            </div>
                                            : null}
                                    </div> */}
                  {/* <div className="col-span-6">
                                        <div className='flex items-center mb-3'>
                                            <h2 className="input-title mr-5">
                                                Form A (completed project)
                                                <span className="text-[#C00000]">*</span>
                                            </h2>
                                            <a className='underline text-[#e74c3c] font-[500]' href=''>
                                                Download File
                                            </a>
                                        </div>
                                        <div className="flex item-center flex-col">
                                            <button className="2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c] mb-10"
                                                onClick={() => props.GetSlotsMemberFiles('ProjectTrackRecordsOfOnCompletedProject', props.SLOTSApplicationDetails.projectTrackRecordsOfCompletpdProject)}
                                            >
                                                <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                                                    View Form A (completed project)
                                                </span>
                                            </button>
                                        </div>
                                    </div> */}
                  {/* <div className="col-span-6">
                                        {props.SLOTSApplicationDetails.supportingDocFormAOnComplete ?
                                            <div className="flex item-center flex-col">
                                                <button className="2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c] mb-10"
                                                    // onClick={() => props.GetSlotsMemberFiles('supportingDocFormAOnComplete', props.SLOTSApplicationDetails.supportingDocFormAOnComplete)}
                                                    onClick={() => props.DownloadMultipleFilesZip('supportingDocFormAOnComplete')}
                                                >
                                                    <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                                                        View Form A (Supporting Completed project)
                                                    </span>
                                                </button>
                                            </div>
                                            : null}
                                    </div> */}
                  <div className="col-span-12">
                    <div className="flex item-center flex-col">
                      {props.SLOTSApplicationDetails
                        .isSgMAworkshopCertificate ? (
                        props.SLOTSApplicationDetails.workshopProofPath ? (
                          <div className="col-span-6">
                            <button
                              className="2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c] mb-10"
                              onClick={() =>
                                props.GetSlotsMemberFiles(
                                  "UploadWorkshopProof",
                                  props.SLOTSApplicationDetails
                                    .workshopProofPath
                                )
                              }
                            >
                              <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                                View workshop File
                              </span>
                            </button>
                            <button
                              className=" btn text-xl border text-[#e78e3c] mb-10"
                            // onClick={() =>
                            //   props.GetSlotsMemberFiles(
                            //     "UploadWorkshopProof",
                            //     props.SLOTSApplicationDetails
                            //       .workshopProofPath
                            //   )
                            // }
                            >
                              {/* <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                                Update workshop File
                              </span> */}
                              <form className="custom-uploader custom-file-upload-btn flex cursor-pointer ml-10 md:mt-0 mt-2">
                                <div className={`border border-[#f3f3f3] btn btn-orange ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                                  <label
                                    htmlFor="WorkshopProofPath"
                                    className={`text-xl text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                                  >
                                    Update Workshop File
                                  </label>
                                  <input
                                    className="sr-only"
                                    id="WorkshopProofPath"
                                    type="file"
                                    onChange={(event) =>
                                      props.onFileChange(
                                        event,
                                        "WorkshopProofPath"
                                      )
                                    }
                                    disabled={props.isDisabledFlag ? true : false}
                                  />
                                </div>
                              </form>
                            </button>
                          </div>
                        ) : null
                      ) : (
                        <div className="col-span-12">
                          <div className="flex items-center justify-between flex-wrap">
                            {props.SLOTSApplicationDetails.iaoOrbizSAFEStar ? (
                              <>
                                <button
                                  className="2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c] mb-10"
                                  onClick={() =>
                                    props.GetSlotsMemberFiles(
                                      "UploadWorkshopProof",
                                      props.SLOTSApplicationDetails
                                        .iaoOrbizSAFEStar
                                    )
                                  }
                                >
                                  <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                                    View ISO 45001(SAC accredited) OR bizSafe Star
                                  </span>
                                </button>
                                <button
                                  className="mb-10"
                                >
                                  <form className="custom-uploader custom-file-upload-btn flex cursor-pointer md:mt-0 mt-2">
                                    <div className={`border border-[#f3f3f3] btn btn-orange ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                                      <label
                                        htmlFor="IAOOrbizSAFEStar"
                                        className={`text-xl text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                                      >
                                        Update ISO 45001(SAC accredited) OR bizSafe Star
                                      </label>
                                      <input
                                        className="sr-only"
                                        id="IAOOrbizSAFEStar"
                                        type="file"
                                        onChange={(event) =>
                                          props.onFileChange(
                                            event,
                                            "IAOOrbizSAFEStar"
                                          )
                                        }
                                        disabled={props.isDisabledFlag ? true : false}
                                      />
                                    </div>
                                  </form>
                                </button>
                              </>
                            ) : null}
                            {props.SLOTSApplicationDetails.isoOrGreenPolicy ? (
                              <>
                                <button
                                  className="2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c] mb-10"
                                  onClick={() =>
                                    props.GetSlotsMemberFiles(
                                      "UploadWorkshopProof",
                                      props.SLOTSApplicationDetails
                                        .isoOrGreenPolicy
                                    )
                                  }
                                >
                                  <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                                    View ISO 14001 OR Green Policy
                                  </span>
                                </button>
                                <button
                                  className="mb-10"
                                >
                                  <form className="custom-uploader custom-file-upload-btn flex cursor-pointer md:mt-0 mt-2">
                                    <div className={`border border-[#f3f3f3] btn btn-orange ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                                      <label
                                        htmlFor="ISOOrGreenPolicy"
                                        className={`text-xl text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                                      >
                                        Update ISO 14001 OR Green Policy
                                      </label>
                                      <input
                                        className="sr-only"
                                        id="ISOOrGreenPolicy"
                                        type="file"
                                        onChange={(event) =>
                                          props.onFileChange(
                                            event,
                                            "ISOOrGreenPolicy"
                                          )
                                        }
                                        disabled={props.isDisabledFlag ? true : false}
                                      />
                                    </div>
                                  </form>
                                </button>
                              </>
                            ) : null}
                            {props.SLOTSApplicationDetails
                              .appointmentletterforSGSecure ? (
                              <>
                                <button
                                  className="2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c] mb-10"
                                  onClick={() =>
                                    props.GetSlotsMemberFiles(
                                      "UploadWorkshopProof",
                                      props.SLOTSApplicationDetails
                                        .appointmentletterforSGSecure
                                    )
                                  }
                                >
                                  <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                                    View Appointment Letter for SG Secure
                                    Representative
                                  </span>
                                </button>
                                <button
                                  className=" mb-10"
                                >
                                  <form className="custom-uploader custom-file-upload-btn flex cursor-pointer0">
                                    <div className={` btn btn-orange ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                                      <label
                                        htmlFor="AppointmentletterforSGSecure"
                                        className={`text-xl text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                                      >
                                        Update Appointment Letter for SG Secure Representative
                                      </label>
                                      <input
                                        className="sr-only"
                                        id="AppointmentletterforSGSecure"
                                        type="file"
                                        onChange={(event) =>
                                          props.onFileChange(
                                            event,
                                            "AppointmentletterforSGSecure"
                                          )
                                        }
                                        disabled={props.isDisabledFlag ? true : false}
                                      />
                                    </div>
                                  </form>
                                </button>
                              </>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-span-12">
                    {/* Form A */}
                    {props.SLOTSApplicationDetails.statusId === 22 || props.SLOTSApplicationDetails.statusId === 4 || props.SLOTSApplicationDetails.statusId === 12 ?
                      <>
                        {(props && props.SLOTSApplicationDetails.formA) || (props.SLOTSApplicationDetails.supportingDocFormAList?.length > 0) ?
                          <>
                            <span className="text-xl text-[#e78e3c] font-semibold tracking-normal">
                              Renewal Information
                            </span>
                            <div class="flex pt-4 flex items-center">
                              <div class="flex items-center w-auto h-6">
                                <img
                                  src={
                                    props &&
                                      props.SLOTSApplicationDetails
                                        .formA
                                      ? "/check-orange.png"
                                      : "/checkNot.png"
                                  }
                                  className="w-[25px] mr-3"
                                  alt=""
                                />
                                <span className="text-[#000]">
                                  Form A
                                </span>
                              </div>
                              <div class="flex-initial w-auto">
                                {props && props.isFormALoader ? (
                                  <ButtonLoader className="ml-10 btn btn-orange" />
                                ) : (
                                  <form className="custom-uploader custom-file-upload-btn flex cursor-pointer ml-10 md:mt-0 mt-2">
                                    <div className={`border border-[#f3f3f3] btn btn-orange ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                                      <label
                                        htmlFor="Upload_from_A_For_Renewal"
                                        className={`text-xl text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                                      >
                                        Upload File
                                      </label>
                                      <input
                                        className="sr-only"
                                        id="Upload_from_A_For_Renewal"
                                        type="file"
                                        onChange={(event) =>
                                          props.onFileChange(
                                            event,
                                            "Upload_from_A_For_Renewal"
                                          )
                                        }
                                        disabled={props.isDisabledFlag ? true : false}
                                      />
                                    </div>
                                  </form>
                                )}
                              </div>
                              <div class="w-auto">
                                <button
                                  className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c]"
                                  onClick={() =>
                                    props.GetSlotsMemberFiles(
                                      "FormA",
                                      props.SLOTSApplicationDetails
                                        .formA
                                    )
                                  }
                                >
                                  <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                                    View Files
                                  </span>
                                </button>
                              </div>

                            </div>
                            <div className="ml-9 mt-2 mb-2">
                              {
                                props.SLOTSApplicationDetails
                                  .formA
                              }
                            </div>
                            <div class="flex pt-4 flex items-center">
                              <div class="flex items-center w-auto h-6">
                                <img
                                  src={
                                    props &&
                                      props.SLOTSApplicationDetails.supportingDocFormAList
                                        ?.length > 0
                                      ? "/check-orange.png"
                                      : "/checkNot.png"
                                  }
                                  className="w-[25px] mr-3"
                                  alt=""
                                />
                                <span className="text-[#000]">
                                  Form A (Supporting document)
                                </span>
                              </div>
                              <div class="flex-initial w-auto">
                                {props && props.isFormALoader ? (
                                  <ButtonLoader className="ml-10 btn btn-orange" />
                                ) : (
                                  <form className="custom-uploader custom-file-upload-btn flex cursor-pointer ml-10 md:mt-0 mt-2">
                                    <div className={`border border-[#f3f3f3] btn btn-orange ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                                      <label
                                        htmlFor="supportingDocFormA"
                                        className={`text-xl text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                                      >
                                        Upload File
                                      </label>
                                      <input
                                        className="sr-only"
                                        id="supportingDocFormA"
                                        type="file"
                                        onChange={(event) =>
                                          props.onFileChange(
                                            event,
                                            "supportingDocFormA"
                                          )
                                        }
                                        disabled={props.isDisabledFlag ? true : false}
                                      />
                                    </div>
                                  </form>
                                )}
                              </div>
                              {props &&
                                props.SLOTSApplicationDetails.supportingDocFormAList.length > 0 ? (
                                <div class="w-auto">
                                  <button
                                    className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c]"
                                    onClick={() =>
                                      props.DownloadMultipleFilesZip(
                                        "SupportingDocFormA"
                                      )
                                    }
                                  >
                                    <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                                      View Files
                                    </span>
                                  </button>
                                </div>
                              ) : null}
                            </div>
                            <div>
                              {props.SLOTSApplicationDetails.supportingDocFormAList?.length > 0
                                ? props.SLOTSApplicationDetails.supportingDocFormAList.map(
                                  (data, key) => {
                                    return (
                                      <div
                                        className="mt-5 ml-9 file-uploaded-txt flex items-center"
                                        key={key}
                                      >
                                        {/* <img src='/check-orange.png' className='w-[25px] mr-3' alt=''></img> */}
                                        <span className="text-[#000]">{data}</span>
                                        {/* <button className='underline text-[#e74c3c] font-[500] ml-3'
                                                    // onClick={() => props.GetSlotsMemberFiles('WPOnlineRecord', data)}
                                                    >
                                                        Download File
                                                    </button> */}
                                        <button
                                          className="underline text-[#e74c3c] font-[500] ml-3"
                                          onClick={() =>
                                            props.deleteSlotsMembersFilesComman(
                                              data,
                                              "SupportingDocFormA"
                                            )
                                          }
                                        >
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </div>
                                    );
                                  }
                                )
                                : null}
                            </div>
                            <div className="col-span-12 mt-4">
                              <div className="flex item-center flex-col">
                                <div className="col-span-6">
                                  <button
                                    className="2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c] mb-10"
                                    onClick={() =>
                                      props.GetSlotsMemberFiles(
                                        "Certificate_of_Attendance",
                                        props.SLOTSApplicationDetails
                                          .certificateofAttendance
                                      )
                                    }
                                  >
                                    <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                                      View Certificate Of Attendance
                                    </span>
                                  </button>
                                  <button
                                    className=" btn text-xl border text-[#e78e3c] mb-10"
                                  >
                                    <form className="custom-uploader custom-file-upload-btn flex cursor-pointer ml-10 md:mt-0 mt-2">
                                      <div className={`border border-[#f3f3f3] btn btn-orange ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                                        <label
                                          htmlFor={`Certificate_of_Attendance`}
                                          className={`text-xl text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                                        >
                                          Update Certificate Of Attendance
                                        </label>
                                        <input
                                          className="sr-only"
                                          id={`Certificate_of_Attendance`}
                                          type="file"
                                          onChange={(event) =>
                                            props.onFileChange(
                                              event,
                                              "Certificate_of_Attendance"
                                            )
                                          }
                                          disabled={props.isDisabledFlag ? true : false}
                                        />
                                      </div>
                                    </form>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                          :
                          null
                        }

                        {/* {props && props.SLOTSApplicationDetails.formA ?
                          <>

                          </>
                          :
                          null} */}

                        {/* {props && props.SLOTSApplicationDetails.supportingDocFormAList?.length > 0 ?
                          <>

                          </>
                          :
                          null
                        } */}


                      </>
                      :
                      null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TopupRegistrationHeadModal
        TopupModal={isTopupModal}
        // individualImage={props.individualBasicInforamtion}
        setOpenModal={() => setTopupModal(false)}
        mainGroupList={props.mainGroupList}
        workheadList={props.workheadList}
        gradeList={props.gradeList}
        SLOTSApplicationDetails={props.SLOTSApplicationDetails}
        selectedBuildingConScope={props.selectedBuildingConScope}
        selectedSLOTSRegistrationScope={props.selectedSLOTSRegistrationScope}
        validStateBCAScope={props.validStateBCAScope}
        validStateregistrationScope={props.validStateregistrationScope}
        mainGroupListPricing={props.mainGroupListPricing}
        workheadListPricing={props.workheadListPricing}
        handleChange={props.handleChange.bind(props)}
        handleChangeInDropdown={props.handleChangeInDropdown.bind(props)}
        currentPriceCalculatorArray={props.priceCalculatorArray}
        removeRegistrationHead={props.removeRegistrationHead.bind(props)}
        onClose={(e) => {
          e.stopPropagation();
          setTopupModal(false);
          props.getSLOTSMemberDetailByMemberId(props.SLOTSApplicationDetails.slotsMemberId);
        }}
        getSLOTSMemberDetailByMemberId={props.getSLOTSMemberDetailByMemberId.bind(this)}
      //saveInformtion={props.saveInformtion.bind(this)}
      />
      <TopupRegistrationHeadModalAdditional
        TopupModalAdditional={isTopupModalAdditional}
        // individualImage={props.individualBasicInforamtion}
        setTopupModalAdditional={() => setTopupModalAdditional(false)}
        mainGroupList={props.mainGroupList}
        workheadList={props.workheadList}
        gradeList={props.gradeList}
        SLOTSApplicationDetails={props.SLOTSApplicationDetails}
        selectedBuildingConScope={props.selectedBuildingConScope}
        selectedSLOTSRegistrationScope={props.selectedSLOTSRegistrationScope}
        validStateBCAScope={props.validStateBCAScope}
        validStateregistrationScope={props.validStateregistrationScope}
        mainGroupListPricing={props.mainGroupListPricing}
        workheadListPricing={props.workheadListPricing}
        handleChange={props.handleChange.bind(props)}
        handleChangeInDropdown={props.handleChangeInDropdown.bind(props)}

        priceCalculatorArrayTopUpAdditional={props.priceCalculatorArrayTopUpAdditional}
        removeRegistrationHeadAdditional={props.removeRegistrationHeadAdditional.bind(props)}
        BuyAdditionalRegistrationHeadTopUp={props.BuyAdditionalRegistrationHeadTopUp.bind(props)}
        onClose={(e) => {
          e.stopPropagation();
          setTopupModalAdditional(false);
        }}
        getSLOTSMemberDetailByMemberId={props.getSLOTSMemberDetailByMemberId.bind(this)}
      />
    </div>
  );
};
export default SLOTSApplication;
