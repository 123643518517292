import React, { Component } from "react";
import SectionWithBorder from "../../../components/UIComponent/SectionBorder";
import Row3Input from "../../../components/membership/membershipPricing/Row3Input";
import MembershipPricingServices from "../../../services/axiosServices/apiServices/MembershipPricingServices";
import { NavLink } from "react-router-dom";
import SwalServices from "../../../services/swalServices/SwalServices";
import {
    isValidForm,
    validate,
} from "../../../utils/validation/CommonValidator";
import { CommonValidationMessages } from "../../../utils/Messages";
// import { getAuthProps } from "../../../utils/AuthenticationLibrary";

export default class SLOTSPricing extends Component {
    constructor(props) {
        super(props);
        this.membershipPricingServices = new MembershipPricingServices();
        this.swalServices = new SwalServices();

        this.state = {
            slotsPricingTable: {
                membershipSLOTSPricingId: 0,
                slotsAdminFee: 0,
                slotsAnnual1To2TradeSameCategoryFee: 0,
                slotsAnnualTradeExistingCategoryFee: 0,
                slotsAnnual1To2TradeDifferentCategoryFee: 0,

            },
            validationRule: {
                slotsAdminFee: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                slotsAnnual1To2TradeSameCategoryFee: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                slotsAnnualTradeExistingCategoryFee: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
                slotsAnnual1To2TradeDifferentCategoryFee: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
            },
            validState: {
                isValid: true,
                error: {},
            },
        };
    }
    handleChange(event) {
        const value = event.target.value;
        var num = parseFloat(value).toFixed(0);
        const name = event.target.name;
        let detail = this.state.slotsPricingTable;
        detail[name] = num;
        this.setState({ slotsPricingTable: { ...detail } });
    }

    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.slotsPricingTable,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        console.log("isValidateAllFields");
        const newValidState = isValidForm(
            this.state.slotsPricingTable,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };

    addMembershipPricing = (id) => {
        let isValid = this.isValidateAllFields();
        if (isValid) {
            let request = this.state.slotsPricingTable
            this.membershipPricingServices
                .addEditMembershipSlotSPricing(request)
                .then((response) => {
                    if (response.statusCode === 200 && response.responseItem != null) {
                        this.swalServices.Success(
                            "Membership pricing updated successfully."
                        );
                    } else {
                        this.swalServices.Error(response.message);
                    }
                });
        }
    };
    getMembershipPricing = () => {
        this.membershipPricingServices.getMembershipSlotSPricing().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                // this.swalServices.Success("Success");
                this.setState({
                    slotsPricingTable: response.responseItem.responseContent,
                });
            } else {
                this.swalServices.Error(response.message);
            }
        });
    };
    componentDidMount() {

        // For Set Created by based on admin login
        //let cookie = getAuthProps();
        //   const adminUserId = (cookie && cookie.adminUserId) ? Number(cookie.adminUserId) : 0;
        //   if (adminUserId > 0) {
        //     this.setState({ createdBy: adminUserId });
        //   }
        // END
        this.getMembershipPricing();
    }

    render() {
        return (
            <>
            <div className="main-body-section">
                <div className="bg-white">
                    <div className="heading-part pl-7 py-4 border-b">
                        <div className="flex items-center justify-between">
                            <h4 className="small-title font-bold theme-color">
                                SLOTS Pricing Table
                                </h4>
                        </div>
                    </div>
                    <div className="custom-card shadow-lg mb-10">
                        <div className="">
                            <SectionWithBorder
                                totalColumn={2}
                                column1="Fees"
                                column2="SLOTS Member"
                            >
                                <div className="sign-icon-input px-8">
                                    <div className="pr-24">
                                        <Row3Input
                                            totalColumn={2}
                                            className="text-base text-[#000000]"
                                            caption="Admin Fee (One-Time)"
                                            col1InputType="Money"
                                            col1FieldName="slotsAdminFee"
                                            col1value={this.state.slotsPricingTable.slotsAdminFee}
                                            handleChange={this.handleChange.bind(this)}
                                            validateField={this.validateField.bind(this)}
                                            validState={this.state.validState}
                                            error1={this.state.validState.error.slotsAdminFee}
                                        />
                                    </div>
                                </div>
                            </SectionWithBorder>

                            <div className="annual-member-fee">
                                <SectionWithBorder
                                    column1="Annual Membership Fee"
                                >
                                    <h2 className="text-base px-8 mt-10 text-zinc-500">
                                        Tier
                                        </h2>
                                    <div className="px-8">
                                        <div className="pr-24">
                                            <Row3Input
                                                totalColumn={2}
                                                className="text-base text-[#000000]"
                                                caption="Registration 1 to 2 Trade Same Category Inclusive"
                                                col1InputType="Money"
                                                col1FieldName="slotsAnnual1To2TradeSameCategoryFee"
                                                col1value={this.state.slotsPricingTable.slotsAnnual1To2TradeSameCategoryFee}
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={this.state.validState.error.slotsAnnual1To2TradeSameCategoryFee}
                                            />

                                            <Row3Input
                                                totalColumn={2}
                                                className="text-base text-[#000000]"
                                                caption="Registration Additional Trade within Existing Category"
                                                col1InputType="Money"
                                                col1FieldName="slotsAnnualTradeExistingCategoryFee"
                                                col1value={this.state.slotsPricingTable.slotsAnnualTradeExistingCategoryFee}
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={this.state.validState.error.slotsAnnualTradeExistingCategoryFee}
                                            />

                                            <Row3Input
                                                totalColumn={2}
                                                className="text-base text-[#000000]"
                                                caption="Registration Additional 1 to 2 Trade in Different Category"
                                                col1InputType="Money"
                                                col1FieldName="slotsAnnual1To2TradeDifferentCategoryFee"
                                                col1value={this.state.slotsPricingTable.slotsAnnual1To2TradeDifferentCategoryFee}
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={this.state.validState.error.slotsAnnual1To2TradeDifferentCategoryFee}
                                            />

                                            {/* <Row3Input
                                                    totalColumn={2}
                                                    className="text-base text-[#000000]"
                                                    caption="Revenue <$30M to $50M"
                                                    col1InputType="Money"
                                                    col1FieldName="annualOrdinaryFee50M"
                                                    col1value={this.state.slotsPricingTable.annualOrdinaryFee50M}
                                                    handleChange={this.handleChange.bind(this)}
                                                    validateField={this.validateField.bind(this)}
                                                    validState={this.state.validState}
                                                // error1={this.state.validState.error.annualOrdinaryFee50M}
                                                // error2={this.state.validSate.error.annualAssociateFee50M}
                                                />

                                                <Row3Input
                                                    totalColumn={2}
                                                    className="text-base text-[#000000]"
                                                    caption="Revenue >$50M"
                                                    col1InputType="Money"
                                                    col1FieldName="annualOrdinaryFeeMax"
                                                    col1value={this.state.slotsPricingTable.annualOrdinaryFeeMax}
                                                    handleChange={this.handleChange.bind(this)}
                                                    validateField={this.validateField.bind(this)}
                                                    // validState={this.state.validState}
                                                // error1={this.state.validState.error.annualOrdinaryFeeMax}
                                                // error2={this.state.validState.error.annualAssociateFeeMax}
                                                /> */}
                                        </div>
                                    </div>
                                </SectionWithBorder>
                            </div>
                            <div className="">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-between">
                    <NavLink to="/Dashboard" className="btn btn-gray text-xl text-white">
                        {" "}
                        Back
                        </NavLink>
                    <button
                        className="text-lg font-bold btn btn-blue text-white"
                        onClick={() => this.addMembershipPricing()}
                    >
                        Save
                        </button>
                </div>
            </div>
            </>
        );
    }
}