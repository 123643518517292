import React from 'react'
import ModalBasic from '../../../pages/component/ModalBasic'
import ValidationText from '../../../utils/validation/ValidationText'
import DropdownSelect from '../../dropdown/Dropdown'
import TextInput from '../../UIComponent/TextInput'
import ButtonLoader from '../../common/buttonLoader/ButtonLoader'

function AddEditProfile(props) {
  return (
    <ModalBasic
      id="show-Add-edit-profile-modal"
      modalOpen={props.addEditModel}
      setModalOpen={props.setOpenModal}
      title=""
    >
      <div className="bg-white mb-10">
        <div className="">
          <div className="grid grid-cols-12 gap-6 w-full px-10 pr-0">
            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
              <h1 className="text-4xl text-[#005B9C] font-bold leading-loose py-4">
                Add/Edit Profile
              </h1>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-11 lg:col-span-11 col-span-12">
                  <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                    First Name <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="flex items-center">
                    <div className="w-[130px] add-edite-f-name">
                      <DropdownSelect
                        drpIdentity={"mrMrsId"}
                        optionArray={props.mrMrs}
                        setFilterParameters={props.setFilterParameters}
                        value={props.employeeDetails.mrMrsId}
                      />
                    </div>
                    <div className="w-full">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="firstName"
                        value={props.employeeDetails.firstName}
                        identity="Employee"
                        handleChange={props.handleChange}
                        onBlur={(e) => props.validate("firstName", props.employeeDetails)}
                      />
                    </div>
                  </div>
                  <ValidationText error={props.validState.error.mrMrsId} />
                  <ValidationText error={props.validState.error.firstName} />
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 ">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-11 lg:col-span-11 col-span-12">
                  <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                    Last Name <span className="text-[#c00000]">*</span>
                  </h2>
                  <TextInput
                    placeholder=""
                    type="text"
                    name="lastName"
                    value={props.employeeDetails.lastName}
                    identity="Employee"
                    handleChange={props.handleChange}
                    onBlur={(e) => props.validate("lastName", props.employeeDetails)}
                  />
                  <ValidationText error={props.validState.error.lastName} />
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 ">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-11 lg:col-span-11 col-span-12">
                  <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                    Email{" "}
                    {/* Email (please enter company email){" "} */}
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full right-border-none">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="email"
                        value={props.employeeDetails.email}
                        identity="Employee"
                        handleChange={props.handleChange}
                        onBlur={(e) => props.validate("email", props.employeeDetails)}
                      />
                    </div>
                    {/* <div className="w-[55%] add-edit-profile-email">
                      <DropdownSelect
                        disabled={true}
                        drpIdentity={"EmailDomain"}
                        optionArray={props.domainArr}
                        setFilterParameters={props.setFilterParameters}
                        value={props.employeeDetails.emailDomainId}
                      />
                    </div> */}
                  </div>
                  <ValidationText error={props.validState.error.email} />
                  {/* <ValidationText error={props.validState.error.emailString} /> */}
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 ">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-11 lg:col-span-11 col-span-12">
                  <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                    Direct Office Number
                  </h2>
                  <TextInput
                    placeholder=""
                    type="text"
                    name="directOfficeNumber"
                    value={props.employeeDetails.directOfficeNumber}
                    identity="Employee"
                    handleChange={props.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 ">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-11 lg:col-span-11 col-span-12">
                  <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                    Mobile Number
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="dropdwn-input-email flex items-center">
                    <TextInput
                      placeholder=""
                      type="number"
                      name="contactNo"
                      value={props.employeeDetails.contactNo}
                      identity="Employee"
                      handleChange={props.handleChange}
                      onBlur={(e) => props.validate("contactNo", props.employeeDetails)}
                    />
                  </div>
                  {/* <Selector
                    drpIdentity="Designation_Employee"
                    options={props.designationList}
                    isMulti
                    //   options={props.countriesWithPresence}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    // components={{ Option }}
                    onChange={props.handleChangeMultiDropdown}
                    allowSelectAll={true}
                    value={
                      props.employeeDetails.selectedDesignation
                    }
                  /> */}
                  <ValidationText error={props.validState.error.title} />
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 ">
              <div className="grid grid-cols-12 gap-6">
                <div className="2xl:col-span-11 lg:col-span-11 col-span-12">
                  <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                    Designation
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="dropdwn-input-email flex items-center">
                    <TextInput
                      placeholder=""
                      type="text"
                      name="title"
                      value={props.employeeDetails.title}
                      identity="Employee"
                      handleChange={props.handleChange}
                      onBlur={(e) => props.validate("title", props.employeeDetails)}
                    />
                  </div>
                  {/* <Selector
                    drpIdentity="Designation_Employee"
                    options={props.designationList}
                    isMulti
                    //   options={props.countriesWithPresence}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    // components={{ Option }}
                    onChange={props.handleChangeMultiDropdown}
                    allowSelectAll={true}
                    value={
                      props.employeeDetails.selectedDesignation
                    }
                  /> */}
                  <ValidationText error={props.validState.error.title} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delete and Save Button */}
        <div className="grid grid-cols-12 gap-6 w-full pt-16 px-10">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="flex items-center justify-center">
              <div className="savebtn">
                {
                  props.isLoaderOn ?
                    (
                      <ButtonLoader type='btn btn-blue' />
                    )
                    :
                    (
                      <button className=" px-14 py-3 bg-[#005B9C] text-xl text-white font-semibold"
                        onClick={() => { props.saveEmployee() }}
                      >
                        Save
                      </button>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBasic>
  )
}
export default AddEditProfile