import React from "react";
import ValidationText from "../../utils/validation/ValidationText";

const NumberInput = (props) => {

    return (
        <div className="relative dollar-input mb-[10px]">
            <input
                className="w-full outline-none appearance-none text-[18px] text-[#000000]"
                id={props.id}
                placeholder={props.placeholder}
                type="number"
                name={props.name}
                value={props.value}
                onChange={(event) => props.handleChange(event, props.identity)}
                onBlur={() => props.onBlur(props.name)}
                onWheel={(e) => e.target.blur()}
                disabled={props.disabled ? true : false}
            />
            <div className="absolute top-[92%]">
                <ValidationText error={props.error} />
            </div>
        </div>
    );
}
export default NumberInput;