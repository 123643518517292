import React from 'react';
import ModalBasic from '../../../pages/component/ModalBasic';
import { APIURL, GetImage } from '../../../services/axiosServices/ApiEndPoints';

const ImageViewer = (props) => {
    return (

        <ModalBasic
            id="send-payment-link-modal"
            modalOpen={props.ImageViewerModal}
            setModalOpen={props.setOpenModal}
            title="Student Uploaded Image"
            noCloseIcon={true}
        >
            <div className="pb-6 px-7 mt-2">
                <div className='flex justify-center'>
                    {
                        props && props.StudentIdImage.uploadStudentIDCard ?
                            <img
                                src={
                                    APIURL +
                                    GetImage +
                                    "?type=" +
                                    "StudentMembers" +
                                    "&&fileName=" +
                                    props.StudentIdImage.uploadStudentIDCard
                                }
                                alt="Student iamge"
                            />
                            :
                            <img
                                src="../default-img.png"
                                className="border border-slate-300 w-full mb-7"
                                alt="../default-img.png"
                            />
                    }
                </div>
                <div className="text-center flex justify-center ">
                    <button
                        className="btn btn-blue text-base text-[#fff] font-bold mt-8"
                        onClick={(e) => props.onClose(e)}
                    >
                        OK
                    </button>
                </div>
            </div >
        </ModalBasic>
    )
}
export default ImageViewer;