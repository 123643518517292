import React from 'react';
import ModalBasic from '../../../pages/component/ModalBasic';
import { APIURL, GetImage } from '../../../services/axiosServices/ApiEndPoints';

const ImageViewer = (props) => {
    console.log("Image Props Geted Photo path :", props.individualImage.photo)
    return (
        <ModalBasic
            id="send-payment-link-modal"
            modalOpen={props.ImageViewerModal}
            setModalOpen={props.setOpenModal}
            title="Individual Uploaded Image"
            noCloseIcon={true}
        >
            <div className="pb-6 px-7 mt-2">
                <div>
                    {
                        props && props.individualImage.photo ?
                            <img
                                // id={generateIds.cropimg}
                                src={
                                    APIURL +
                                    GetImage +
                                    "?type=" +
                                    "IndividualMembers" +
                                    "&&fileName=" +
                                    props.individualImage.photo
                                }
                                alt="individual iamge"
                            />
                            :
                            <img
                                // id={"defaultimage_" + props.keyId}
                                src="../default-img.png"
                                className="border border-slate-300 w-full mb-7"
                                alt="../default-img.png"
                            />

                    }

                </div>
                <div className="text-center">
                    <button
                        className="btn btn-blue text-base text-[#fff] font-bold mt-8"
                        onClick={(e) => props.onClose(e)}
                    // onClick={() => props.getClarificationDetails()}
                    >
                        OK
                    </button>
                </div>
            </div >
        </ModalBasic>
    )
}
export default ImageViewer;