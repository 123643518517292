
import React, { Component } from "react";
import Table from "../../components/table/Table";
import moment from "moment";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import { CommonValidationMessages } from "../../utils/Messages";
import ValidationText from "../../utils/validation/ValidationText";
import PageLoader from "../../components/common/pageLoader/PageLoader";
import { saveAs } from "file-saver";
import SlotMemberService from "../../services/axiosServices/apiServices/SlotMemberService";


export default class SlotRenewalReport extends Component {
    constructor(props) {
        super(props);
        this.slotMemberService = new SlotMemberService();

        this.state = {
            statusList: [
                {
                    value: 1,
                    label: "Membership",
                },
                {
                    value: 2,
                    label: "External Invoice",
                },
            ],
            // transactionReportRequest: {
            //     currentDate: "",
            //     transactionReportFor: ""
            // },
            SlotRenewalRecordList: [],
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "CreatedAt",
                orderFlag: 0,
                searchText: "",
                startDate: "",
                endDate: "",

            },
            totalCount: 0,
            totalResultes: 0,
            previousReportFor: "",
            previousReportStartDate: "",
            previousReportEndDate: "",
            isLoading: false,
            outstandingInvoicesList: [],
            validationRule: {
                startDate: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "Start date for Slot Renewal report"),
                    },
                ],
                endDate: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "End date for Slot Renewal  report"),
                    },
                    {
                        type: "minEndDate",
                        minEndDate: "startDate",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "greater end date")
                    }
                ],

            },
            validState: {
                isValid: true,
                error: {},
            },
        };
    }

    // reset = () => {
    //     let resetDetails = this.state.transactionReportRequest
    //     resetDetails.currentDate = ""
    //     resetDetails.transactionReportFor = ""
    //     this.setState({ transactionReportRequest: resetDetails })
    // }

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getSlotRenewalRecordList();
    };

    // handleChangeInDropdown(selected, drpIdentity) {
    //     if (drpIdentity === "TransactionReportType") {
    //         var dataArr = this.state.pagination
    //         if (selected.value > 0) {
    //             dataArr.transactionReportFor = selected.label;
    //         } else {
    //             dataArr.transactionReportFor = "";
    //         }
    //         this.setState({ transactionRecordRequest: dataArr }, () => {
    //             this.validateField("transactionReportFor");
    //         })
    //     }
    // }


    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'startDate') {
            let date = this.state.pagination
            date.startDate = value
            this.setState({ startDate: date })
        }
        if (name === 'endDate') {
            let date = this.state.pagination
            date.endDate = value
            this.setState({ endDate: date })
        }
    }


    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.pagination,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.pagination,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };


    getSlotRenewalRecordList = () => {
        let isValidateAllFields = this.isValidateAllFields()
        if (isValidateAllFields === true) {
            if (this.state.pagination.startDate && this.state.pagination.endDate) {
                this.setState({ isLoading: true });
                let request = { ...this.state.pagination };
                request.startDate = moment(this.state.pagination.startDate).format("DD MMM YYYY");
                request.endDate = moment(this.state.pagination.endDate).format("DD MMM YYYY");
                console.log(this.state.pagination)
                this.slotMemberService.getSlotRenewalRecordList(request).then((response) => {
                    if (response.statusCode === 200 && response.responseItem !== null) {
                        if (response.responseItem.responseContent !== null) {
                            let details = response.responseItem.responseContent.itemList
                            if (details.length > 0) {
                                details.map((date) => {
                                    date.subcriptionEndDate = date.subcriptionEndDate ? moment(date.subcriptionEndDate).format("DD MMM YYYY") : "";
                                    let count = 0;
                                    count = date.sloTsRegistrationHeads.split("").length; //4
                                    if (count > 70) {
                                        date.sloTsRegistrationHeads = date.sloTsRegistrationHeads.split(/(.{70}[^\s]*)\s/)[1];
                                        date.sloTsRegistrationHeads = date.sloTsRegistrationHeads + "...";
                                    }
                                    return date;
                                })
                            }
                            this.setState({
                                SlotRenewalRecordList: details,
                                totalCount: response.responseItem.responseContent.totalCount,
                                totalResultes: response.responseItem.responseContent.totalCount,

                                previousReportStartDate: this.state.pagination.startDate,
                                previousReportEndDate: this.state.pagination.endDate
                            })
                        }
                    }
                    else if (response.statusCode === 409) {
                        this.swalServices.Error(response.message);
                    }
                    else {
                        this.swalServices.Error(response.message);
                    }
                    this.setState({ isLoading: false });
                });
            }
        }
    }

    downloadSheetForSlotRenewalRecord = () => {
        let isValidateAllFields = this.isValidateAllFields()
        if (isValidateAllFields) {
            if (this.state.pagination.startDate && this.state.pagination.endDate) {
                let request = { ...this.state.pagination };
                request.startDate = moment(this.state.pagination.startDate).format("DD MMM YYYY");
                request.endDate = moment(this.state.pagination.endDate).format("DD MMM YYYY");
                this.slotMemberService.downloadSheetForSlotRenewalRecord(request).then((response) => {
                    if (response.status === 200 && response.data != null) {
                        this.setState({

                            previousReportStartDate: this.state.pagination.startDate,
                            previousReportEndDate: this.state.pagination.endDate
                        })
                        var blob = new Blob([response.data]);
                        
                        // saveAs(blob, 'Slot Renewal Report' + ".xlsx");
                        saveAs(blob, 'Slot Renewal Report.xlsx');

                    }
                    else {
                        this.swalServices.Error(response.message);
                    }
                    this.setState({ isLoading: false });
                });
            }
        }
    }



    render() {
        return (
            <div className="main-body-section finance-body-sec">
                {/* Add/Edit GST Start */}
                <div className="bg-white">
                    <div className="grid grid-cols-12 gap-6 w-full items-center px-8 py-10">
                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                for="default"
                            >
                                Start Date<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="">
                                <input
                                    id="startDate"
                                    name="startDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(this.state.pagination.startDate).format("yyyy-MM-DD")}
                                    onChange={(event) => this.handleChange(event)}
                                    onBlur={() => this.validateField("startDate")}
                                //    disabled
                                />
                            </div>
                            <ValidationText error={this.state.validState.error.startDate} />
                        </div>

                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                for="default"
                            >
                                End Date<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="">
                                <input
                                    id="endDate"
                                    name="endDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(this.state.pagination.endDate).format("yyyy-MM-DD")}
                                    onChange={(event) => this.handleChange(event)}
                                    onBlur={() => this.validateField("endDate")}
                                //    disabled
                                />
                            </div>
                            <ValidationText error={this.state.validState.error.endDate} />
                        </div>


                        <div className="col-span-4">
                            <button className="btn btn-blue text-xl border text-white mt-8" onClick={() => this.getSlotRenewalRecordList()}>
                                Show Slot Renewal Report
                            </button>
                        </div>
                        {this.state.SlotRenewalRecordList &&
                            this.state.SlotRenewalRecordList.length > 0 &&
                            this.state.previousReportStartDate === this.state.pagination.startDate &&
                            this.state.previousReportEndDate === this.state.pagination.endDate ?
                            <div className="col-span-4">
                                <button className="btn btn-blue text-xl border text-white mt-8" onClick={() => this.downloadSheetForSlotRenewalRecord()}>
                                    Download Excel
                                </button>
                            </div>
                            : null}
                    </div>
                </div>
                {/* Add/Edit GST End */}
                {/* Section 3 Start */}
                <div className="grid grid-cols-12 gap-6 w-full items-center justify-center px-8 py-10">
                    <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                        {this.state.isLoading ?
                            <PageLoader /> :
                            <Table
                                columns={[
                                    { name: "CompanyName", title: "Company Name", },
                                    { name: "UENNumber", title: "UEN Number" },
                                    { name: "SubcriptionEndDate", title: "Certificate Expiry Date" },
                                    { name: "SLOTsRegistrationHeads", title: " Tradehead" },

                                ]}
                                pagination={this.state.pagination}
                                rows={this.state.SlotRenewalRecordList}
                                sortingColumns={[""]}
                                isLoading={this.state.isLoading}
                                totalResultes={this.state.totalResultes}
                                totalCount={this.state.totalCount}
                                setPagination={this.setPagination.bind(this)}
                            />

                        }
                    </div>
                </div>

            </div>
        );
    }
}