import React from "react"
const CheckboxInput = (props) => {
    return (
        <input
            className="cursor-pointer text-[#e1e1e1] focus:ring-[#e1e1e1] rounded check-box-custom"
            id={props.id}
            type="checkbox"
            name={props.name}
            value={props.value}
            checked={props.checked}
            onChange={(event) => props.handleChange(event, props.identity)}
            disabled={props.disabled === true ? true : false}
        />
    );
}
export default CheckboxInput;