import React, { Component } from 'react';
import ButtonLoader from '../../components/common/buttonLoader/ButtonLoader';
import LogoImage from "../../images/logo.png";
import {  Navigate } from 'react-router-dom';
import { isValidForm, validate } from '../../utils/validation/CommonValidator';
import { ErrorMessage } from '../../utils/Messages';
import ValidationText from '../../utils/validation/ValidationText';
import AuthenticationServices from '../../services/axiosServices/apiServices/AuthenticationServices';
import SwalServices from '../../services/swalServices/SwalServices';
export default class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.adminServices = new AuthenticationServices();
        this.swalServices = new SwalServices();
        this.state = {
            route: "",
            isLoading: false,
            adminId: 0,
            passwordDetails: {
                password: "",
                confirmPassword: "",
                adminID: 0,
            },
            validationRules: {
                password: [
                    {
                        type: "require",
                        message: ErrorMessage.FieldRequired.replace("{0}", "password"),
                    },
                    {
                        type: "password",
                        message: ErrorMessage.PasswordFormat,
                    },
                ],
                confirmPassword: [
                    {
                        type: "require",
                        message: ErrorMessage.FieldRequired.replace("{0}", "confirm password"),
                    },
                    {
                        type: "compare",
                        compareEle: "password",
                        message: ErrorMessage.ComparePassword,
                    },


                ],
            },
            validState: {
                isValid: true,
                error: {},
            },
        }
    }
    componentDidMount() {
        const currentAdminId = Number(localStorage.getItem('adminUserId'));
        this.setState({ adminId: currentAdminId });
    }

    resetPassword = () => {
        if (this.isValidateAllFields()) {
            let request = this.state.passwordDetails;
            request.adminID = this.state.adminId;
            this.adminServices.resetPassword(request).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    this.swalServices
                        .SuccessWithAction("Password has been reset successfully, Please login with new credentials.", "Ok")
                        .then((response) => {
                            this.setState({ route: "/Login" });
                        });
                }
                else {
                    this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
            })
        }
    }

    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.passwordDetails,
            this.state.validationRules,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.passwordDetails,
            this.state.validationRules,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        let details = this.state.passwordDetails;
        details[name] = value;
        this.setState({ passwordDetails: details });
    }

    render() {
        if (this.state.route !== "") {
            return <Navigate to={this.state.route} />;
        }
        return (
            <div>
                <div className="mt-4">
                    <div className="min-h-full flex items-center justify-center py-1 px-4 sm:px-6 lg:px-8 w-full">
                        <div className=" max-w-md w-full space-y-8 rounded-sm ">
                            <div className="">
                                <img
                                    className="mx-auto w-[220px]"
                                    src={LogoImage}
                                    alt="logo"
                                />
                                <h2 className="mt-9 text-center text-3xl font-extrabold text-[#000000]">
                                    New Password
                                </h2>
                            </div>
                            <input type="hidden" name="remember" value="true" />
                            <div className="rounded-md space-y-8 relative">
                                <div>
                                    <label for="email-address" className="text-base text-[#000000]">
                                        Enter New Password
                                    </label>
                                    <input
                                        id="email-address"
                                        name="password"
                                        type="password"
                                        autoComplete="email"
                                        required
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-[#005b9c] placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#005b9c] focus:border-[#004270] focus:z-10 sm:text-sm bg-white"
                                        placeholder="Password"
                                        value={this.state.passwordDetails.password}
                                        onChange={(event) => this.handleChange(event)}
                                        onBlur={() => this.validateField("password")}
                                    />
                                    <ValidationText
                                        className="absolute left-0 top-[100%]"
                                        error={this.state.validState.error.password}
                                    />
                                </div>

                                <div>
                                    <label for="email-address" className="text-base text-[#000000]">
                                        Confirm  New Password
                                    </label>
                                    <input
                                        id="email-address"
                                        name="confirmPassword"
                                        type="password"
                                        autoComplete="email"
                                        required
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-[#005b9c] placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#005b9c] focus:border-[#004270] focus:z-10 sm:text-sm bg-white"
                                        placeholder="Confirm Password"
                                        value={this.state.passwordDetails.confirmPassword}
                                        onChange={(event) => this.handleChange(event)}
                                        onBlur={() => this.validateField("confirmPassword")}
                                    />
                                    <ValidationText
                                        className="absolute left-0 top-[100%]"
                                        error={this.state.validState.error.confirmPassword}
                                    />
                                </div>


                            </div>

                            <div className="flex justify-center">
                                {this.state.isLoading ? (
                                    <ButtonLoader type='btn btn-blue' />
                                ) : (
                                    <button
                                        type="submit"
                                        className="group relative w-full flex items-center justify-center border border-transparent text-base font-medium text-white btn btn-blue focus:outline-none"
                                        onClick={() => this.resetPassword()}
                                    >
                                        Continue
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}