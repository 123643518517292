// Import React Libraries and Components
import React from "react";
import moment from "moment";
import DatePicker from "../../datePicker/DatePicker";
import DropdownSelect from "../../dropdown/Dropdown";
import TextInput from "../../UIComponent/TextInput";


function CONQUASAccrediation(props) {


  return (
    <div>
      <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Subscription Start Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input dropdwn-input-email flex items-center">
                  <DatePicker
                    isDisable={true}
                    name="subscriptionStartDate"
                    handleChange={props.handleChangeIndividualMember}
                    identity="MembershipSubscription"
                    value={moment(props.CONQUASAccrediation.subscriptionStartDate).format("yyyy-MM-DD")}
                  // onBlur={(e) => props.validate("corporateJoinedDate", props.membershipSubscription)}
                  />
                  {/* <Validation error={props.validState.error.subscriptionStartDate} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Subscription End Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input dropdwn-input-email flex items-center">
                  <DatePicker
                    isDisable={true}
                    name="subscriptionEndDate"
                    handleChange={props.handleChangeIndividualMember}
                    identity="MembershipSubscription"
                    value={moment(props.CONQUASAccrediation.subscriptionEndDate).format("yyyy-MM-DD")}
                  // onBlur={(e) => props.validate("corporateJoinedDate", props.membershipSubscription)}
                  />

                  {/* <Validation error={props.validState.error.subcriptionEndDate} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Updated By
                </h2>
                <DropdownSelect
                  disabled={true}
                  drpIdentity={"updatedById"}
                  optionArray={props._by}
                  setFilterParametersProfile={props.setFilterParametersProfile}
                  setFilterParametersEngagement={props.setFilterParametersEngagement}
                  setFilterSCALMembershipInformation={props.setFilterSCALMembershipInformation}
                  setFilterParametersIndividualAccountInfor={props.setFilterParametersIndividualAccountInfor}
                  value={props.CONQUASAccrediation.updatedBy}
                // onBlur={(e) => props.validate("updatedById", props.membershipSubscription)}
                />
                {/* <Validation error={props.validState.error.updatedById} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Last Updated Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input">
                  {props.CONQUASAccrediation.updatedAt === "" || props.CONQUASAccrediation.updatedAt === null ?
                    <TextInput
                      disabled={true}
                      placeholder=""
                      type="text"
                      name="linkedIn"
                      value="None"
                      identity="BasicInformation"
                    />
                    :
                    <DatePicker
                      isDisable={true}
                      name="updatedAt"
                      handleChange={props.handleChangeIndividualMember}
                      identity="updatedAt"
                      value={moment(props.CONQUASAccrediation.updatedAt).format("yyyy-MM-DD")}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Renew Button */}
      <div className="grid grid-cols-12 gap-6 pb-10">
        <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10 new-btn">
        <div className="left-btn">
            {
              props && props.isDisabledFlag === true ?
                (
                  <button
                    className="w-32 px-7 py-3 btn-sm btn-blue text-xl text-white font-semibold w-[250px] cursor-not-allowed"
                    disabled={true}
                  >
                    Send Renewal Mail


                  </button>
                )
                :
                (
                  <button
                    className="w-32 px-7 py-3 btn-sm btn-blue text-xl text-white font-semibold w-[250px]"
                    onClick={() => props.SendRenewalMail()}
                  >
                    Send Renewal Mail

                  </button>
                )
            }
          </div>
          <div className="left-btn">
            <a
              className="flex items-center justify-end"
            //href="/CreateCorporateMembers"
            >
              {
                props && props.isDisabledFlag === true ?
                  (
                    <button
                      className="w-32 px-7 py-3 btn-sm btn-blue text-xl text-white font-semibold w-[250px] cursor-not-allowed"
                      disabled={true}
                    >
                      Latest Certification

                    </button>
                  )
                  :
                  (
                    <button
                      className="w-32 px-7 py-3 btn-sm btn-blue text-xl text-white font-semibold w-[250px]"
                      onClick={() => props.getCONQUASMemberCertificate()}

                    >
                      Latest Certification

                    </button>
                  )
              }
            </a>
          </div>
          <div className="">
            <a
              className="flex items-center justify-end"
            //href="/CreateCorporateMembers"
            >
              {
                props && props.isLoading === true ?
                  (
                    <button
                      className="w-32 px-7 py-3 btn-sm btn-blue text-xl text-white font-semibold w-[250px] cursor-not-allowed"
                      disabled={true}
                    >
                      View/Update Details
                    </button>

                  )
                  :
                  (
                    <button
                      className="w-32 px-7 py-3 btn-sm btn-blue text-xl text-white font-semibold w-[250px] "
                      onClick={(value) => props.viewUpdateCONQUASMember(value)}

                    >
                      View/Update Details
                    </button>
                  )
              }
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CONQUASAccrediation;