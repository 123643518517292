import {
  
    //AcceptTotalPurchaseChapterPaidAmountInReconciliation,

    DeleteEventPaymentReconcilation,
  
} from '../ApiEndPoints';
import {  axiosPostAuthorize } from '../AxiosRequests';


export default class BookingServices {

    // async getbookingsList(request) {
    //     return axiosPostAuthorize(GetbookingsList, request)
    // }
    // async getbookingsCancellationList(request) {
    //     return axiosPostAuthorize(GetBookingCancellationList, request)
    // }
    // async getBookingDetailsById(request) {
    //     return axiosGetAuthorize(GetBookingDetailsById, request);
    // }
    // async getBookingCancellationDetailsById(request) {
    //     return axiosGetAuthorize(GetBookingCancellationDetailsById, request);
    // }
    // async getBookingDetailsByBookingId(request) {
    //     return axiosGetMultiParams(GetBookingDetailsByBookingId, request);
    // }
    // async getEventPaymentReconcilationList(request) {
    //     return axiosPostAuthorize(GetEventPaymentReconcilationList, request)
    // }
    // async savePaymentReconcilationDetailsByAdmin(request) {
    //     return axiosPostAuthorize(SavePaymentReconcilationDetailsByAdmin, request);
    // }
    // async acceptTotalPaidAmountInReconcilation(request) {
    //     return axiosGetAuthorize(AcceptTotalPaidAmountInReconcilation, request);
    // }
    // async acceptTotalMembershipPaidAmountInReconciliation(request) {
    //     return axiosPostAuthorize(AcceptTotalMembershipPaidAmountInReconciliation, request);
    // }
    // async acceptTotalExternalInvoicePaidAmountInReconciliation(request) {
    //     return axiosGetAuthorize(AcceptTotalExternalInvoicePaidAmountInReconciliation, request);
    // }
    // async acceptTotalPurchaseChapterPaidAmountInReconciliation(request) {
    //     return axiosGetAuthorize(AcceptTotalPurchaseChapterPaidAmountInReconciliation, request);
    // }
    async deleteEventPaymentReconcilation(request) {
        return axiosPostAuthorize(DeleteEventPaymentReconcilation, request)
    }
    // async getOutstandingInvoicesList(request) {
    //     return axiosPostAuthorize(GetOutstandingInvoicesList, request)
    // }
    // async addProofInPaymentProofMapping(request) {
    //     return axiosPostAuthorize(AddProofInPaymentProofMapping, request);
    // }
}