import React from "react";
import ActionDeleteButton from "../UIComponent/ActionTableComponent/ActionDeleteButton";

const ApprovalProcessTable = (props) => {
  console.log("first props :", props.creditNoteApprovalList)
  return (
    <div>
      <div className="mb-2 overflow-x-auto">
        <table className="stretegic-member-tbl w-full mx-auto my-5 fixed_header">
          <thead>
            <tr className="text-base font-bold flex">
              <th class="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[28%]">
                <div class="font-bold tble-th-txt text-left">Starting Amount</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[28%]">
                <div class="font-bold tble-th-txt text-center">Ending Amount</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[28%]">
                <div class="font-bold tble-th-txt text-center">Approval Email</div>
              </th>
              <th class="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[16%]">
                <div class="font-bold tble-th-txt text-center">Action</div>
              </th>
            </tr>
          </thead>

          <tbody className="scrollit text-sm custom-tbody globle-setting-tbody">
            {props.creditNoteApprovalList && props.creditNoteApprovalList.length > 0 ? (
              <>
                {props.creditNoteApprovalList.map((creditNote, creditNoteIndex) => {
                  return (
                    <tr className="custom-tbl-td flex items-center">
                      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[28%]">
                        {creditNote.financeStartingAmount}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[28%]">
                        {creditNote.financeEndingAmount}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[28%]">
                        {`${creditNote.financeApprovalEmail}`}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[16%] gst-edit-btn">
                        <span className="flex items-center">
                          <button
                            className="action-btn mr-4 flex items-center"
                            aria-controls="add-edit-modal"
                            onClick={() => {
                              props.getCreditNoteDataById(creditNoteIndex);
                            }}
                          >
                            <p className="ml-2 tool-tip-txt underline text-[#005b9c]">
                              Edit
                            </p>
                          </button>
                          <ActionDeleteButton
                            onActionClick={() => props.deleteCreditNote(creditNote.financeCreditNoteApprovalId)}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                <tr className="grid justify-items-center">
                  <td className="text-lg text-[#000000]	">No Records Found</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default ApprovalProcessTable;