import { axiosGetAuthorize, axiosGetFileAuthorizeblob,axiosGetMultiParams, axiosPostAuthorize, axiosGetAuthorizeMultiParams } from '../AxiosRequests';
import {
    //GetAllCorporateMemberList,
    // GetCorporateMembersById,
    // DeleteCorporateMembers,
   
    GetAllDropdownsForCorporateMember,
    AddEditCorporateSIISAccountSegment,
    //GetCorporateBasicSIISInformation,
    AddEditCorporateEngagementTracking,
    GetCorporateEngagementTrackingList,
    GetEmployeeInfomationForCorporateMemberAdmin,
    GetCorporateEngagementDetailsById,
    GetAllCorporateOrganisationRole,
    GetChapterCommitteeRoles,
    AddEditEmployeeInformation,
    GetEmployeeInformationById,
    AddEditCorporateOrganisationRole,
    DeleteCorporateOrganisationRole,
    GetCorporateOrganisationRoleById,
    AddEditFinancialDetail,
    AddEditOrganisationEmployees,
    AddEditOrganisationKeyRoles,
    AddEditOrganisationBussinessDetails,
    AddEditOrganisationProfile,
    AddEditCorporateMembershipSubscription,
    AddEditFinanceCardDetails,
    AddEditProspectInformation,
    AddEditEngagementTrackingList,
    AddEditSIISCorporateAccountInformation,
    AddEditOrganisationBasicInformation,
    GetAllCorporateSectionsById,
    GetSIISCorporateAccountInformationById,
    GetOrganisationBasicInformationById,
    GetChapterInformationById,
    GetOrganisationProfileById,
    GetOrganisationBussinessDetailsById,
    GetCorporateMembershipSubscriptionById,
    //AddEditCorporateProspect,
    GetProspectInformationById,
    AddEditCorporateCommunication,
    GetGovernmentProgrammeDetailByProjectId,
    GetCorporateFinancialInfo,
    GetCorporateMemberFiles,
    GetCorporateFinanceReceiptInvoice,
    AddEditOrganisationBasicInformationAdmin,
    GetDropdownDataOfWorkheadAndGrade,
    GetCompanyRegisteredWithBCAList,
    GetIndividualFinancialInfo,
    GetIndividualFinanceReceiptInvoice,
    SendRenewalReminderMailForCorporateMember,
    getReceiptAndCertificate,
    GetCorporateMemberFilesDownload,
    SendPaymentLinkForCorporate
} from '../ApiEndPoints'

export default class CorporateMemberServices {

    // async getAllCorporateMemberList(request) {
    //     return axiosPostAuthorize(GetAllCorporateMemberList, request)
    // }

    // async getCorporateMembersById(request) {
    //     return axiosGetAuthorize(GetCorporateMembersById, request)
    // }

    // async deleteCorporateMembers(request) {
    //     return axiosPostAuthorize(DeleteCorporateMembers, request)
    // }

    // async addEditCorporateMembers(request) {
    //     return axiosPostAuthorize(AddEditCorporateMembers, request)
    // }
    // async addEditOrganisationBasicInformation(request){
    //     return axiosPostAuthorize(AddEditOrganisationBasicInformation,request);
    // }
    async getReceiptAndCertificate(request) {
        return axiosGetAuthorize(getReceiptAndCertificate, request)
    }
    async getAllDropdownForCorporateMember(request) {
        return axiosGetAuthorize(GetAllDropdownsForCorporateMember, request);
    }
    async addEditCorporateSIISAccountSegment(request) {
        return axiosPostAuthorize(AddEditCorporateSIISAccountSegment, request)
    }
    // async getCorporateBasicSIISInformation(request) {
    //     return axiosGetAuthorize(GetCorporateBasicSIISInformation, request);
    // }
    async getEngagementTrackingList(request) {
        return axiosGetAuthorize(GetCorporateEngagementTrackingList, request);
    }
    async getEngagmentDetailsById(request) {
        return axiosGetAuthorize(GetCorporateEngagementDetailsById, request)
    }
    // async getBasicInformation(request) {
    //     return axiosGetAuthorize(GetCorporateBasicSIISInformation, request)
    // }
    async addEditEngagementTracking(request) {
        return axiosPostAuthorize(AddEditCorporateEngagementTracking, request)
    }
    async getEmployeeList(request) {
        return axiosGetAuthorizeMultiParams(GetEmployeeInfomationForCorporateMemberAdmin, request);
    }
    async getOrganisationRoleList(request) {
        return axiosGetAuthorize(GetAllCorporateOrganisationRole, request);
    }
    async getChapterCommitteeRoles(request) {
        return axiosGetAuthorize(GetChapterCommitteeRoles, request);
    }
    async addEditEmployeeInformation(request) {
        return axiosPostAuthorize(AddEditEmployeeInformation, request);
    }
    async getEmployeeInfoById(request) {
        return axiosGetAuthorize(GetEmployeeInformationById, request);
    }
    async addEditOrganisation(request) {
        return axiosPostAuthorize(AddEditCorporateOrganisationRole, request);
    }
    async deleteCorporateOrganisationRole(request) {
        return axiosPostAuthorize(DeleteCorporateOrganisationRole, request);
    }
    async getCorporateOrganisationRoleById(request) {
        return axiosGetAuthorize(GetCorporateOrganisationRoleById, request);
    }

    //New services for create corporate members
    async addEditSIISCorporateAccountInformation(request) {
        return axiosPostAuthorize(AddEditSIISCorporateAccountInformation, request)
    }
    async addEditEngagementTrackingList(request) {
        return axiosPostAuthorize(AddEditEngagementTrackingList, request)
    }
    async addEditProspectInformation(request) {
        return axiosPostAuthorize(AddEditProspectInformation, request)
    }
    // async addEditCorporateProspect(request) {
    //     return axiosPostAuthorize(AddEditCorporateProspect, request)
    // }
    async addEditFinanceCardDetails(request) {
        return axiosPostAuthorize(AddEditFinanceCardDetails, request)
    }
    async addEditCorporateMembershipSubscription(request) {
        return axiosPostAuthorize(AddEditCorporateMembershipSubscription, request)
    }
    async addEditOrganisationBasicInformation(request) {
        return axiosPostAuthorize(AddEditOrganisationBasicInformation, request)
    }
    async addEditOrganisationBasicInformationAdmin(request) {
        return axiosPostAuthorize(AddEditOrganisationBasicInformationAdmin, request)
    }
    async addEditOrganisationProfile(request) {
        return axiosPostAuthorize(AddEditOrganisationProfile, request)
    }
    async addEditOrganisationBussinessDetails(request) {
        return axiosPostAuthorize(AddEditOrganisationBussinessDetails, request)
    }
    async addEditOrganisationKeyRoles(request) {
        return axiosPostAuthorize(AddEditOrganisationKeyRoles, request)
    }
    async addEditCorporateCommunication(request) {
        return axiosPostAuthorize(AddEditCorporateCommunication, request)
    }
    async addEditOrganisationEmployees(request) {
        return axiosPostAuthorize(AddEditOrganisationEmployees, request)
    }
    async addEditFinancialDetail(request) {
        return axiosPostAuthorize(AddEditFinancialDetail, request)
    }
    async getAllCorporateSectionsById(request) {
        return axiosPostAuthorize(GetAllCorporateSectionsById, request)
    }
    async getSIISCorporateAccountInformationById(request) {
        return axiosGetAuthorize(GetSIISCorporateAccountInformationById, request)
    }
    async getOrganisationBasicInformationById(request) {
        return axiosGetAuthorize(GetOrganisationBasicInformationById, request)
    }
    async getChapterInformationById(request) {
        return axiosGetAuthorize(GetChapterInformationById, request)
    }
    async getOrganisationProfileById(request) {
        return axiosGetAuthorize(GetOrganisationProfileById, request)
    }
    async getOrganisationBussinessDetailsById(request) {
        return axiosGetAuthorize(GetOrganisationBussinessDetailsById, request)
    }
    async getCorporateMembershipSubscriptionById(request) {
        return axiosGetAuthorize(GetCorporateMembershipSubscriptionById, request)
    }
    async getProspectInformationById(request) {
        return axiosGetAuthorize(GetProspectInformationById, request)
    }
    async getGovernmentProgrammeDetailByProjectId(request) {
        return axiosGetAuthorize(GetGovernmentProgrammeDetailByProjectId, request);
    }
    async getCorporateFinancialInfo(request) {
        return axiosGetAuthorize(GetCorporateFinancialInfo, request);
    }
    async GetCorporateMemberFiles(request) {
        return axiosGetFileAuthorizeblob(GetCorporateMemberFiles, request);
    }
    async GetCorporateMemberFilesDownload(request) {
        return axiosGetFileAuthorizeblob(GetCorporateMemberFilesDownload, request);
    }
    async getCorporateFinanceReceiptInvoice(request) {
        return axiosGetFileAuthorizeblob(GetCorporateFinanceReceiptInvoice, request);
    }
    // async renewCorporateMember(request) {
    //     return axiosPostAuthorize(RenewCorporateMember, request);
    // }
    async getDropdownDataOfWorkheadAndGrade(request) {
        return axiosPostAuthorize(GetDropdownDataOfWorkheadAndGrade, request);
    }
    async getCompanyRegisteredWithBCAList(request) {
        return axiosGetAuthorize(GetCompanyRegisteredWithBCAList, request);
    }
    async getIndividualFinancialInfo(request) {
        return axiosGetAuthorize(GetIndividualFinancialInfo, request);
    }
    async getIndividualFinanceReceiptInvoice(request) {
        return axiosGetFileAuthorizeblob(GetIndividualFinanceReceiptInvoice, request);
    }

    async sendRenewalReminderMailForCorporateMember(request) {
        return axiosGetAuthorize(SendRenewalReminderMailForCorporateMember, request)
    }

    async SendPaymentLinkForCorporate(request) {
        return axiosGetAuthorize(SendPaymentLinkForCorporate, request)
    }
}