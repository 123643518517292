import React from "react";
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
function Fees(props) {
  return (
    <div>
      <div className="grid gap-8 py-4 grid-cols-1">
        <div className="relative justify-center detailedBlock p-4 pt-8 fees">
          <div className="flex flex-row items-center justify-between title pl-24">
            <div className="icon">Fees Collected</div>
          </div>
          <div className="text-[20px] pl-24">  {`Jan 2023 - Dec ${currentYear}`}</div>
        
          <div className="grid gap-0 grid-cols-5 items-center text-center py-4 border-b border-[#606060] pl-24">
            <div className="inner-number col-span-2 text-left text-[20px]">
              Membership Fee
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%]  translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{`$${props.dashboardDetails.membershipOrdinaryFee}`}</div>

                <div>Ordinary Member</div>
              </div>
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{`$${props.dashboardDetails.membershipAssociateFee}`}</div>
                <div>Associate Member</div>
              </div>
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{`$${props.dashboardDetails.membershipTradeFee}`}</div>
                <div>Trade Member</div>
              </div>
            </div>
            <div className="inner-number col-span-2 text-left text-[20px]">

            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{`$${props.dashboardDetails.membershipIndividualFee}`}</div>
                <div>Individual Member</div>
              </div>
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{`$${props.dashboardDetails.membershipSlotFee}`}</div>
                <div>SLOTs Member</div>
              </div>
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{`$${props.dashboardDetails.membershipCONQUASFee}`}</div>
                <div>Conquas Member</div>
              </div>
            </div>
            <div className="inner-number col-span-2 text-left text-[20px]">

            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div className="border-[#606060] px-2">
                <div className="inner-number">{`$${props.dashboardDetails.membershipTotalAmount}`}</div>
                <div>Total Amount</div>
              </div>
              <div className="border-r border-[#606060] h-[40px] absolute right-0 top-[50%] translate-y-[-50%]"></div>
            </div>

          </div>
          {/* <div className="grid gap-0 grid-cols-5 items-center text-bold text-center py-4 border-b border-[#606060] pl-24">
            <div className="col-span-2 text-left space-y-4 ">
              <div className="inner-number text-[20px]"> Sponsorship</div>
              <div>Global Tech Business Outlook 2022</div>
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%]  translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{`$${props.dashboardDetails.sponsorshipIndividualFee}`}</div>
                <div>Individual</div>
              </div>
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{`$${props.dashboardDetails.sponsorshipCorporateFee}`}</div>
                <div>Corporate</div>
              </div>
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{`$${props.dashboardDetails.sponsorshipTotalAmount}`}</div>
                <div>Total Amount</div>
              </div>
            </div>
          </div> */}
          {/* <div className="grid gap-0 grid-cols-5 items-center text-center py-4 border-b border-[#606060] pl-24">
            <div className="col-span-2 text-left space-y-4 ">
              <div className="inner-number text-[20px]">Events</div>
              <div>Global Tech Business Outlook 2022</div>
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%]  translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{props.dashboardDetails.eventsPendingPayment}</div>
                <div>Pending Payment</div>
              </div>
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{props.dashboardDetails.eventsPaid}</div>
                <div>Paid</div>
              </div>
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{`$${props.dashboardDetails.eventsTotalAmount}`}</div>
                <div>Total Amount</div>
              </div>
            </div>
          </div> */}

          {/* <div className="grid gap-0 grid-cols-5 items-center text-center py-4 pl-24">
            <div className="col-span-2 text-left">
              AI Business Matching Event
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%]  translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{props.dashboardDetails.businessMatchingPendingPayment}</div>
                <div>Pending Payment</div>
              </div>
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{props.dashboardDetails.businessMatchingPaid}</div>
                <div>Paid</div>
              </div>
            </div>
            <div className="py-2 border-[#606060] flex justify-center align-center relative">
              <div className="border-l border-[#606060] h-[40px] absolute left-0 top-[50%] translate-y-[-50%]"></div>
              <div>
                <div className="inner-number">{`$${props.dashboardDetails.businessMatchingTotalAmount}`}</div>
                <div>Total Amount</div>
              </div>
            </div>
          </div> */}

        </div>
      </div>
    </div>
  );
}

export default Fees;
