import React from "react";
import ValidationText from "../../../../utils/validation/ValidationText";
import DropdownSelect from "../../../dropdown/Dropdown";
import ButtonLoader from "../../../common/buttonLoader/ButtonLoader";

function FinanceCard(props) {
  return (
    <div>
      <div className="custom-checkbox-set">
        <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
          <div className="grid grid-cols-12 gap-6 w-full ">
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <div className={`flex items-start register-desc mb-5 ${props.isDisabledFlag ? 'disabled-input' : 'cursor-pointer'}`}>
                    <input
                      id="quotationNeeded"
                      name="quotationNeeded"
                      type="checkbox"
                      className={`h-4 w-4 text-[#005B9C] focus:ring-[#005B9C] rounded check-box-custom `}
                      onChange={(event) => props.handleChangeCheckBox(event)}
                      checked={props.corporateFinance.quotationNeeded}
                      value={props.corporateFinance.quotationNeeded}
                      disabled={props.isDisabledFlag ? true : false}
                    />
                    <label className="communication-label ml-3 pt-[2px] block text-[#000000] 2xl:text-[22px]">
                      Quotation Needed
                      <span className="text-[#c00000]">*</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <div className={`flex items-start register-desc mb-5 ${props.isDisabledFlag ? 'disabled-input' : 'cursor-pointer'}`}>
                    <input
                      id="sendInvoiceReminder"
                      name="sendInvoiceReminder"
                      type="checkbox"
                      className={`h-4 w-4 text-[#005B9C] focus:ring-[#005B9C] rounded check-box-custom`}
                      onChange={(event) => props.handleChangeCheckBox(event)}
                      checked={props.corporateFinance.sendInvoiceReminder}
                      value={props.corporateFinance.sendInvoiceReminder}
                      disabled={props.isDisabledFlag ? true : false}
                    />
                    <label className="communication-label ml-3 pt-[2px] block text-[#000000] 2xl:text-[22px]">
                      Send Invoice Reminder
                      <span className="text-[#c00000]">*</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                    Credit Terms
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <DropdownSelect
                    drpIdentity={"CreditTerms"}
                    optionArray={props.corporateCreditTerms}
                    setFilterParameters={props.setFilterParameters}
                    value={props.corporateFinance.creditTermsId}
                    disabled={props.isDisabledFlag ? true : false}
                  />
                  <ValidationText error={props.validState.error.creditTermsId} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Save Button */}
        <div className="grid grid-cols-12 gap-6 pb-10">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10">
            {/* <a
              className="flex items-center justify-end"
              href="/CreateCorporateMembers"
            > */}
            <div className="flex items-center justify-end">
              {
                props && props.isLoading === true ?
                  (
                    <ButtonLoader className="mt-4 btn btn-blue mr-5" />
                  )
                  :
                  (
                    <button
                      className={`w-32 px-7 py-3 btn-sm btn-blue text-xl text-white font-semibold ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                      onClick={() => props.onSave()}
                      disabled={props.isDisabledFlag ? true : false}
                    >
                      Save
                    </button>
                  )
              }
            </div>
            {/* </a> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default FinanceCard;