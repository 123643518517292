import { React, useRef, useState, useEffect } from "react";
import TextInput from "../../UIComponent/TextInput";
import MultiSelectDropdown from "../../dropdown/MultiSelectDropdown";
import ValidationText from "../../../utils/validation/ValidationText";
import DateInput from "../../inputs/DateInput";
import RadioButton from "../membershipTaskList/RadioButton";
import NumberInput from "../../UIComponent/NumberInput";

function BasicApplication(props) {
  const textRef = useRef();
  const [value] = useState();

  useEffect(() => {
    if (textRef && textRef.current) {
      textRef.current.style.height = "0px";
      const taHeight = textRef.current.scrollHeight;
      textRef.current.style.height = taHeight + "px";
    }
  }, [value]);

  return (
    <div className="w-full organisation-basic-information">
      <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Application For Tier <span className="text-[#c00000]">*</span>
                </h2>
                <MultiSelectDropdown
                  drpIdentity="Know_About_Us"
                  options={props.knowAboutusList}
                  // isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  // components={{ Option }}
                  //   onChange={props.handleChangeMultiDropdown}
                  //   allowSelectAll={false}
                  //   isDisabled={props.isDisabledFlag ? true : false}
                  //   value={props.knowAboutusList.find(
                  //     (aboutUs) =>
                  //       aboutUs.value ===
                  //       props.organizationBasicInformation.knowAboutUsId
                  //   )}
                />
              </div>
            </div>
            {/* <Validation error={props.validState.error.knowAboutUsId} /> */}

            {/* {props && props.organizationBasicInformation.knowAboutUsId === 5 ? (
              <div
                className={`dropdown mt-4 ${
                  props.isDisabledFlag ? "disabled-input" : null
                }`}
              >
                <TextInput
                  name="hearAboutOther"
                  value={props.organizationBasicInformation.hearAboutOther}
                  identity="BasicInformation"
                  handleChange={props.handleChange}
                  disabled={props.isDisabledFlag ? true : false}
                  onBlur={(e) => props.validate("companyName", props.organisationDetails)}
                />
              </div>
            ) : null} */}
          </div>
        </div>

        <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-12 mb-3">
          <div className="col-span-12">
            <div className="form-info-title">
              <h4 className="text-[#2d2d2d] small-title font-bold theme-color">
                Basic Application
              </h4>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-3 mb-3">
          <div className="lg:col-span-6 col-span-12 input-sec-texts">
            <h2 className="input-title mb-3">
              First Name
              <span className="text-[#C00000]"> *</span>
            </h2>

            <div className="dropdown">
              <TextInput
                placeholder=""
                name="Project Title"
                identity="projectTitle"
                // onBlur={() =>
                //   validateField("uenNumber", "Retrieve_CAPS_Application")
                // }
              />
              <ValidationText />
            </div>
          </div>

          <div className="lg:col-span-6 col-span-12 input-sec-texts">
            <h2 className="input-title mb-3">
              Last Name
              <span className="text-[#C00000]"> *</span>
            </h2>

            <div className="dropdown">
              <TextInput
                placeholder=""
                name="Project Value"
                identity="projectValue"
                // onBlur={() =>
                //   validateField("uenNumber", "Retrieve_CAPS_Application")
                // }
              />
              <ValidationText />
            </div>
          </div>

          <div className="lg:col-span-6 col-span-12 input-sec-texts">
            <h2 className="input-title mb-3">
              Date of Birth
              <span className="text-[#C00000]"> *</span>
            </h2>
            <div className="dropdown">
              <DateInput
                name="Date From"
                //   value={moment(
                //     props.basicApplicationDetails.dateOfACRARegistration
                //   )
                //   .format("YYYY-MM-DD")}
                identity="dateFrom"
                handleChange={(e) => props.handleChange(e, "BasicApplication")}
                // onBlur={() =>
                //   props.validateField(
                //     "dateOfACRARegistration",
                //     "Basic_Application"
                //   )
                // }
              />
              <ValidationText
              //   error={
              //     props.validStateBasicApplication.error
              //       .dateOfACRARegistration
              //   }
              />
            </div>
          </div>

          <div className="lg:col-span-6 col-span-12 input-sec-texts">
            <h2 className="input-title mb-3">
              Identification No (Last 3 digit & alphabets)
              <span className="text-[#C00000]"> *</span>
            </h2>
            <div className="dropdown">
              <TextInput
                name="Project Duration"
                identity="projectDuration"
                autoComplete="off"
                // onBlur={() =>
                //   validateField("password", "Retrieve_CAPS_Application")
                // }
              />
              <ValidationText />
            </div>
          </div>

          <div className="lg:col-span-6 col-span-12 input-sec-texts company-type">
            <h2 className="input-title mb-3">
              Sex
              <span className="text-[#C00000]"> *</span>
            </h2>
            <div className="flex items-center w-full">
              <div className="flex items-center">
                <RadioButton
                  id="companytype"
                  name="companytype"
                  className="form-radio-caps"
                  //   value={props.basicApplicationDetails.sex}
                  //   checked={props.basicApplicationDetails.sex === true}
                  handleRadioButtons={(e) =>
                    props.handleChange(e, "Basic_Application_Radio")
                  }
                />
                <span className="ml-3">Male</span>
              </div>
              <div className="flex items-center ml-10">
                <RadioButton
                  id="companytype"
                  name="companytype"
                  className="form-radio-caps"
                  //   value={props.basicApplicationDetails.sex}
                  //   checked={props.basicApplicationDetails.sex === false}
                  handleRadioButtons={(e) =>
                    props.handleChange(e, "Basic_Application_Radio")
                  }
                />
                <span className="ml-3">Female</span>
              </div>
            </div>
          </div>

          <div className="lg:col-span-6 col-span-12 input-sec-texts">
            <h2 className="input-title mb-3">
              Mailing Address Line 1<span className="text-[#C00000]"> *</span>
            </h2>
            <div className="dropdown">
              <TextInput
                name="Project Duration"
                identity="projectDuration"
                autoComplete="off"
                // onBlur={() =>
                //   validateField("password", "Retrieve_CAPS_Application")
                // }
              />
              <ValidationText />
            </div>
          </div>

          <div className="lg:col-span-6 col-span-12 input-sec-texts">
            <h2 className="input-title mb-3">
              Mailing Address Line 2<span className="text-[#C00000]"> *</span>
            </h2>
            <div className="dropdown">
              <TextInput
                name="Project Duration"
                identity="projectDuration"
                autoComplete="off"
                // onBlur={() =>
                //   validateField("password", "Retrieve_CAPS_Application")
                // }
              />
              <ValidationText />
            </div>
          </div>

          <div className="lg:col-span-6 col-span-12 input-sec-texts">
            <h2 className="input-title mb-3">
              Mailing Address Line 3<span className="text-[#C00000]"> *</span>
            </h2>
            <div className="dropdown">
              <TextInput
                name="Project Duration"
                identity="projectDuration"
                autoComplete="off"
                // onBlur={() =>
                //   validateField("password", "Retrieve_CAPS_Application")
                // }
              />
              <ValidationText />
            </div>
          </div>

          <div className="lg:col-span-6 col-span-12 input-sec-texts">
            <h2 className="input-title mb-3">
              Postal Code
              <span className="text-[#C00000]"> *</span>
            </h2>
            <div className="dropdown">
              <NumberInput
                placeholder=""
                name="Project Involved"
                identity="projectInvolved"
                //   handleChange={(e) =>
                //     props.handleChange(e, "BasicApplication")
                //   }
                onBlur={() =>
                  props.validateField("mobileNumber", "Basic_Application")
                }
              />
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Country <span className="text-[#c00000]">*</span>
                </h2>
                <MultiSelectDropdown
                  drpIdentity="Project_Type"
                  options={props.typeOfProjects}
                  // isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  // components={{ Option }}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={false}
                  isDisabled={props.isDisabledFlag ? true : false}
                  //   value={props.typeOfProjects.find(type => type.value === props.organizationBasicInformation.projectTypeId)}
                />
              </div>
            </div>
            {/* <Validation error={props.validState.error.projectTypeId} /> */}
          </div>

          <div className="lg:col-span-6 col-span-12 input-sec-texts">
            <h2 className="input-title mb-3">
              Contact Number
              <span className="text-[#C00000]"> *</span>
            </h2>
            <div className="dropdown">
              <NumberInput
                placeholder=""
                name="Project Involved"
                identity="projectInvolved"
                //   handleChange={(e) =>
                //     props.handleChange(e, "BasicApplication")
                //   }
                onBlur={() =>
                  props.validateField("mobileNumber", "Basic_Application")
                }
              />
            </div>
          </div>

          <div className="lg:col-span-6 col-span-12 input-sec-texts">
            <h2 className="input-title mb-3">
              Email Address
              <span className="text-[#C00000]"> *</span>
            </h2>
            <div className="dropdown">
              <TextInput
                name="Email Address"
                identity=" emailAddress"
                autoComplete="off"
                //   onBlur={() =>
                //     validateField("password", "Retrieve_CAPS_Application")
                //   }
              />
              <ValidationText />
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Place of Birth <span className="text-[#c00000]">*</span>
                </h2>
                <MultiSelectDropdown
                  drpIdentity="Project_Type"
                  options={props.typeOfProjects}
                  // isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  // components={{ Option }}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={false}
                  isDisabled={props.isDisabledFlag ? true : false}
                  //   value={props.typeOfProjects.find(type => type.value === props.organizationBasicInformation.projectTypeId)}
                />
              </div>
            </div>
            {/* <Validation error={props.validState.error.projectTypeId} /> */}
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Nationality <span className="text-[#c00000]">*</span>
                </h2>
                <MultiSelectDropdown
                  drpIdentity="Project_Type"
                  options={props.typeOfProjects}
                  // isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  // components={{ Option }}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={false}
                  isDisabled={props.isDisabledFlag ? true : false}
                  //   value={props.typeOfProjects.find(type => type.value === props.organizationBasicInformation.projectTypeId)}
                />
              </div>
            </div>
            {/* <Validation error={props.validState.error.projectTypeId} /> */}
          </div>

          <div className="xl:col-span-12 lg:col-span-12 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                Upload Full Face Photo/Passport Photo <span className="text-[#c00000]">*</span>
                </h2>
                <div className="dropdown">
                <div className="select-dropdown w-full">
                  <form className="custom-uploader custom-file-upload-btn flex pt-3">
                    <div className="border border-[#f3f3f3] btn btn-download btn-absa cursor-pointer bg-[#acacac]">
                      <label
                        htmlFor={`Upload_ISO9001_Document`}
                        className="text-uplod text-white cursor-pointe"
                      >
                        <b>Upload File</b>
                      </label>
                      <input
                        className="sr-only"
                        id={`Upload_ISO9001_Document`}
                        type="file"
                        onChange={(event) =>
                          props.onFileChange(event, "Upload_ISO9001_Document")
                        }
                      />
                    </div>
                  </form>
                  {/* {props && props.organisationProfile.uploadISO9001Path ? (
                        <div className="mt-5 file-uploaded-txt flex items-center">
                          <img
                            src="/check-orange.png"
                            className="w-[25px] mr-3"
                            alt=""
                          />
                          <span className="text-[#000]">
                            {props.organisationProfile.uploadISO9001Path}
                          </span>
                        </div>
                      ) : null} */}
                  {/* <ValidationText error={props.validState.error.selectedCountryId} /> */}
                </div>
              </div>
              </div>
            </div>
            {/* <Validation error={props.validState.error.projectTypeId} /> */}
          </div>

        </div>
      </div>
    </div>
  );
}
export default BasicApplication;
