// Import React Libraries and Components
import React from 'react'
import moment from 'moment/moment';
import ModalBasic from '../../../pages/component/ModalBasic'
import DatePicker from '../../datePicker/DatePicker';
import DropdownSelect from '../../dropdown/Dropdown'
import TextInput from '../../UIComponent/TextInput';
import ValidationText from '../../../utils/validation/ValidationText';

export default function GenerateCreditNote(props) {
    const invoiceCancel = props.isInvoiceCancel === true ? true : false;
    return (
        <ModalBasic
            id="show-individual-profile-modal"
            modalOpen={props.generateCreditNote}
            setModalOpen={props.setOpenModal}
        >
            <div className="bg-white mb-10">
                <div className="">
                    <div className="grid grid-cols-12 gap-6 w-full ">
                        <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                            <h1 className="text-4xl text-[#000000] font-bold leading-loose pl-12">
                                Generate Credit Note
                            </h1>
                        </div>
                    </div>
                    <hr className="mt-2 border-t border-t-[#c9c9c9]"></hr>
                    <div className="grid grid-cols-12 gap-6 w-full ">
                        <div className="col-span-12 2xl:pt-7 lg:mt-4 items-center">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                            <div className="flex items-center">
                                                <h3 class="small-title font-bold theme-color px-12">
                                                    {/* Month */}
                                                    Effective Date
                                                </h3>
                                            </div>
                                            <div className="flex items-center px-12">
                                                <DatePicker
                                                    name="creditNoteMonth"
                                                    handleChange={props.handleChange}
                                                    identity="CreditNote"
                                                    value={props.addEditCreditNote.creditNoteMonth === "" || props.addEditCreditNote.creditNoteMonth === null ? moment(props.individualCreditNotePaymentInfo.subscriptionStartDate).format("DD MMM YYYY") : moment(props.addEditCreditNote.creditNoteMonth).format("yyyy-MM-DD")}
                                                    minDate={moment().format("yyyy-MM-DD")}
                                                    disabled={invoiceCancel}
                                                    isCurserAllowed={invoiceCancel}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6 w-full ">
                        <div className="col-span-12 2xl:pt-5 lg:mt-4 items-center">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                            <div className="flex items-center">
                                                <h3 class="small-title font-bold theme-color px-12">
                                                    Itemised item
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex items-center">
                                                <h3 class="small-title font-bold theme-color px-12">
                                                    Amount
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex items-center">
                                                <h3 class="small-title font-bold theme-color px-12">
                                                    Refund Amount
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-2 border-t border-t-[#c9c9c9] mx-12"></hr>
                    <div className="grid grid-cols-12 gap-6 w-">
                        <div className="col-span-12 2xl:pt-7  lg:mt-4 items-center">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                            <div className="flex item-center">
                                                <h4 class="small-title  text-[24px] theme-color px-12">
                                                    {props.individualCreditNotePaymentInfo.membershipTypeName} for period {moment(props.individualCreditNotePaymentInfo.subscriptionStartDate).format("DD MMM YYYY")} through {moment(props.individualCreditNotePaymentInfo.subcriptionEndDate).format("DD MMM YYYY")}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex items-center">
                                                <h4 class="small-title text-[24px] theme-color px-12">
                                                    {props.individualCreditNotePaymentInfo.annualFee}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex  px-12">
                                                <TextInput
                                                    placeholder=""
                                                    type="text"
                                                    name="annualRefund"
                                                    value={props.addEditCreditNote.annualRefund}
                                                    identity="CreditNote"
                                                    handleChange={props.handleChange}
                                                    onBlur={(e) => props.validate2("annualRefund", props.addEditCreditNote)}
                                                    disabled={invoiceCancel}
                                                    isCurserAllowed={invoiceCancel}
                                                />
                                            </div>
                                            <div className="flex  px-12">
                                                {/* <ValidationText error={props.validCreditNote2.error.annualRefund} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-2 border-t border-t-[#c9c9c9] mx-12"></hr>
                    <div className="grid grid-cols-12 gap-6 w-">
                        <div className="col-span-12 2xl:pt-7  lg:mt-4 items-center">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                            <div className="flex item-center">
                                                <h4 class="small-title  text-[24px] theme-color px-12">
                                                    Sub total
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex items-center">
                                                <h4 class="small-title text-[24px] theme-color px-12">
                                                    {props.individualCreditNotePaymentInfo.subTotalAmount}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex  px-12">
                                                <h4 class="small-title text-[24px] theme-color px-12 font-bold text-left">
                                                    {props.addEditCreditNote.subTotalRefund}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-2 border-t border-t-[#c9c9c9] mx-12"></hr>
                    <div className="grid grid-cols-12 gap-6 w-">
                        <div className="col-span-12 2xl:pt-7  lg:mt-4 items-center">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                            <div className="flex item-center">
                                                <h4 class="small-title  text-[24px] theme-color px-12">
                                                    GST
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex items-center">
                                                <h4 class="small-title text-[24px] theme-color px-12">
                                                    {props.individualCreditNotePaymentInfo.gstAmount}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex  px-12">
                                                <h4 class="small-title text-[24px] theme-color px-12 font-bold">
                                                    {props.addEditCreditNote.gstRefund}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-2 border-t border-t-[#c9c9c9] mx-12"></hr>
                    <div className="grid grid-cols-12 gap-6 w-">
                        <div className="col-span-12 2xl:pt-7  lg:mt-4 items-center">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                            <div className="flex item-center">
                                                <h4 class="small-title text-[24px] theme-color px-12">
                                                    Discount
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex items-center">
                                                <h4 class="small-title text-[24px] theme-color px-12">
                                                    -{props.individualCreditNotePaymentInfo.discountedAmount}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex  px-12">
                                                <h4 class="small-title text-[24px] theme-color px-12 font-bold">
                                                    -{props.addEditCreditNote.discountRefund}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-2 border-t border-t-[#c9c9c9] mx-12"></hr>
                    <div className="grid grid-cols-12 gap-6 w-">
                        <div className="col-span-12 2xl:pt-7  lg:mt-4 items-center">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                            <div className="flex item-center">
                                                <h4 class="small-title  text-[24px] theme-color px-12 font-bold">
                                                    Total Refund Amount
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex items-center">
                                                <h4 class="small-title text-[24px] theme-color px-12">
                                                    {props.individualCreditNotePaymentInfo.totalAmount}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                                            <div className="flex  px-12">
                                                <h4 class="small-title text-[24px] theme-color px-12 font-bold">
                                                    {props.addEditCreditNote.totalRefund}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-6 w-full ">
                    <div className="col-span-12 2xl:pt-7 lg:mt-4 items-center">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                        <div className="flex items-center">
                                            <h3 class="small-title font-bold theme-color px-12">
                                                Cancellation Reason
                                            </h3>
                                        </div>
                                        <div className="flex items-center w-full px-12" >
                                            <DropdownSelect
                                                disabled={false}
                                                drpIdentity={"cancel_reason"}
                                                optionArray={props.cancellationReason}
                                                value={props.individualCreditNotePaymentInfo.cancellationReason}
                                                setFilterParameters={props.setFilterParameters}
                                                onBlur={(e) => props.validate("cancellationReason", props.individualCreditNotePaymentInfo)}
                                            />
                                        </div>
                                        <div className="flex items-center w-full px-12">
                                            <ValidationText error={props.validCreditNote.error.cancellationReason} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {props.isOtherCancellationReason ?
                    <>
                        <div className="grid grid-cols-12 gap-6 w-full ">
                            <div className="col-span-12 2xl:pt-7 lg:mt-4 items-center">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="col-span-12">
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                                <div className="flex items-center">
                                                    <h3 class="small-title font-bold theme-color px-12">
                                                        Others
                                                    </h3>
                                                </div>
                                                <div className="flex items-center px-12">
                                                    <TextInput
                                                        placeholder=""
                                                        type="text"
                                                        name="cancellationReason"
                                                        value={props.addEditCreditNote.cancellationReason}
                                                        identity="CreditNoteOthers"
                                                        handleChange={props.handleChange}
                                                        onBlur={(e) => props.validate2("cancellationReason", props.addEditCreditNote)}
                                                    />
                                                </div>
                                                <div className="flex items-center px-12">
                                                    {/* <ValidationText error={props.validCreditNote2.error.cancellationReason} /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    : null
                }
                {/* Delete and Save Button */}
                <div className="grid grid-cols-12 gap-6 w-full pt-16 px-10 ">
                    <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                        <div className="flex items-center justify-center">
                            <div className="savebtn">
                                <button className=" px-14 py-3 bg-[#005B9C] text-xl text-white font-semibold"
                                    onClick={() => props.addFinanceCreditNote()}
                                >
                                    Generate
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalBasic>
    )
}