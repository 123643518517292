import React, { useState } from "react";
import DropdownSelect from "../../dropdown/Dropdown";
import TextInput from "../../UIComponent/TextInput";
import DatePicker from "../../datePicker/DatePicker";
import NumberInput from "../../UIComponent/NumberInput";
import Validation from '../../../utils/validation/ValidationText';
import ImageCropperWithUpload from "../../ImageUpload/UploadImage";
import MultiSelectDropdown from "../../dropdown/MultiSelectDropdown";
import moment from "moment";
import ImageViewer from "./ImageViewer";


function IndividualBasicInformation(props) {

  const [imageViewer, setImageViewer] = useState(false);

  return (
    <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
      <div className="">
        <div className="grid grid-cols-12 gap-6 w-full items-center mt-5">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  First Name <span className="text-[#c00000]">*</span>
                </h2>
                <div className={`dropdwn-input flex items-center ${props.isDisabledFlag ? 'disabled-input' : 'cursor-pointer'}`}>
                  {/* <DropdownSelect
                    drpIdentity={"mrMrsId"}
                    optionArray={props.mrMrs}
                    setFilterParameters={props.setFilterParameters}
                    value={props.individualBasicInforamtion.mrMrsId}
                  /> */}
                  <TextInput
                    placeholder=""
                    name="firstName"
                    value={props.individualBasicInforamtion.firstName}
                    identity="IndividualBasicInformation"
                    handleChange={props.handleChange}
                    //onBlur={(e) => props.validate("firstName", props.individualBasicInforamtion)}
                    disabled={props.isDisabledFlag ? true : false}
                  />
                </div>
                {/* <Validation error={props.validState.error.firstName} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className={`col-span-12 ${props.isDisabledFlag ? 'disabled-input' : 'cursor-pointer'}`}>
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Last Name <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  name="lastName"
                  value={props.individualBasicInforamtion.lastName}
                  identity="IndividualBasicInformation"
                  handleChange={props.handleChange}
                  //onBlur={(e) => props.validate("lastName", props.individualBasicInforamtion)}
                  disabled={props.isDisabledFlag ? true : false}
                />
                {/* <Validation error={props.validState.error.lastName} /> */}
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className={`col-span-12 ${props.isDisabledFlag ? 'disabled-input' : 'cursor-pointer'}`}>
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Date of Birth<span className="text-[#c00000]">*</span>
                </h2>
                <DatePicker
                  name="dateOfBirth"
                  handleChange={props.handleChange}
                  identity="IndividualBasicInformation"
                  value={moment(props.individualBasicInforamtion.dateOfBirth).format("yyyy-MM-DD")}
                  //onBlur={() => props.validate("dateOfBirth", props.individualBasicInforamtion)}
                  isDisable={props.isDisabledFlag ? true : false}
                />
                {/* <Validation error={props.validState.error.dateOfBirth} /> */}
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className={`col-span-12 ${props.isDisabledFlag ? 'disabled-input' : 'cursor-pointer'}`}>
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Mailing Address Line 1 <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  name="mailingAddress1"
                  value={props.individualBasicInforamtion.mailingAddress1}
                  identity="IndividualBasicInformation"
                  handleChange={props.handleChange}
                  //onBlur={(e) => props.validate("mailingAddress1", props.individualBasicInforamtion)}
                  disabled={props.isDisabledFlag ? true : false}
                />
                {/* <Validation error={props.validState.error.mailingAddress1} /> */}
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className={`col-span-12 ${props.isDisabledFlag ? 'disabled-input' : 'cursor-pointer'}`}>
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Mailing Address Line 2
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <TextInput
                  placeholder=""
                  name="mailingAddress2"
                  value={props.individualBasicInforamtion.mailingAddress2}
                  identity="IndividualBasicInformation"
                  handleChange={props.handleChange}
                  disabled={props.isDisabledFlag ? true : false}
                // onBlur={(e) => props.validate("mailingAddress2", props.individualBasicInforamtion)}
                />
                {/* <Validation error={props.validState.error.mailingAddress2} /> */}
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className={`col-span-12 ${props.isDisabledFlag ? 'disabled-input' : 'cursor-pointer'}`}>
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Mailing Address Line 3
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <TextInput
                  placeholder=""
                  name="mailingAddress3"
                  value={props.individualBasicInforamtion.mailingAddress3}
                  identity="IndividualBasicInformation"
                  handleChange={props.handleChange}
                  disabled={props.isDisabledFlag ? true : false}
                // onBlur={(e) => props.validate("mailingAddress3", props.individualBasicInforamtion)}
                />
                {/* <Validation error={props.validState.error.mailingAddress3} /> */}
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Country/Region<span className="text-[#c00000]">*</span>
                </h2>
                <MultiSelectDropdown
                  drpIdentity="Country"
                  options={props.countryList}
                  //isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  // components={{ Option }}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={false}
                  value={props.countryList.filter(country => country.value === props.individualBasicInforamtion.countryId)}
                  isDisabled={props.isDisabledFlag ? true : false}
                />
                {/* <DropdownSelect
                  drpIdentity={"Country"}
                  optionArray={props.countryList}
                  setFilterParameters={props.setFilterParameters}
                  value={props.individualBasicInforamtion.countryId}
                /> */}
                {/* <Validation error={props.validState.error.countryId} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className={`col-span-12 ${props.isDisabledFlag ? 'disabled-input' : 'cursor-pointer'}`}>
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Postal code <span className="text-[#c00000]">*</span>
                </h2>
                <NumberInput
                  placeholder=""
                  name="postalCode"
                  maxLength={6}
                  value={props.individualBasicInforamtion.postalCode}
                  identity="IndividualBasicInformation"
                  handleChange={props.handleChange}
                  //onBlur={(e) => props.validate("postalCode", props.individualBasicInforamtion)}
                  disabled={props.isDisabledFlag ? true : false}
                />
                {/* <Validation error={props.validState.error.postalCode} /> */}
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className={`col-span-12 ${props.isDisabledFlag ? 'disabled-input' : 'cursor-pointer'}`}>
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Contact Number <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  name="contactNo"
                  value={props.individualBasicInforamtion.contactNo}
                  identity="IndividualBasicInformation"
                  handleChange={props.handleChange}
                  //onBlur={(e) => props.validate("contactNo", props.individualBasicInforamtion)}
                  disabled={props.isDisabledFlag ? true : false}
                />
                {/* <Validation error={props.validState.error.contactNo} /> */}
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className={`col-span-12 ${props.isDisabledFlag ? 'disabled-input' : 'cursor-pointer'}`}>
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Email Address <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  name="email"
                  value={props.individualBasicInforamtion.email}
                  identity="IndividualBasicInformation"
                  handleChange={props.handleChange}
                  //onBlur={(e) => props.validate("email", props.individualBasicInforamtion)}
                  disabled={props.isDisabledFlag ? true : false}
                />
                {/* <Validation error={props.validState.error.email} /> */}
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Place of Birth<span className="text-[#c00000]">*</span>
                </h2>
                {/* <DropdownSelect
                  drpIdentity={"Birth_Place"}
                  optionArray={props.placeOfBirthList}
                  setFilterParameters={props.setFilterParameters}
                  value={props.individualBasicInforamtion.placeOfBirthId}
                /> */}
                <MultiSelectDropdown
                  drpIdentity="Birth_Place"
                  options={props.placeOfBirthList}
                  //isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  // components={{ Option }}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={false}
                  value={props.placeOfBirthList.filter(place => place.value === props.individualBasicInforamtion.placeOfBirthId)}
                  isDisabled={props.isDisabledFlag ? true : false}
                />
                {/* <Validation error={props.validState.error.placeOfBirthId} /> */}
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Nationality<span className="text-[#c00000]">*</span>
                </h2>
                <DropdownSelect
                  drpIdentity={"Nationality"}
                  optionArray={props.nationalityList}
                  setFilterParameters={props.setFilterParameters}
                  value={props.individualBasicInforamtion.nationalityTypeId}
                  disabled={props.isDisabledFlag ? true : false}
                />
                {/* <Validation error={props.validState.error.nationalityTypeId} /> */}
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Your Highest Education Level<span className="text-[#c00000]">*</span>
                </h2>
                <DropdownSelect
                  drpIdentity={"Education_Level"}
                  optionArray={props.educationLevelList}
                  setFilterParameters={props.setFilterParameters}
                  value={props.individualBasicInforamtion.educationLevelId}
                  disabled={props.isDisabledFlag ? true : false}
                />
                <Validation error={props.validState.error.educationLevelId} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className={`col-span-12 ${props.isDisabledFlag ? 'disabled-input' : 'cursor-pointer'}`}>
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Title of Course <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  name="titleOfCourse"
                  value={props.individualBasicInforamtion.titleOfCourse}
                  identity="IndividualBasicInformation"
                  handleChange={props.handleChange}
                  //onBlur={(e) => props.validate("titleOfCourse", props.individualBasicInforamtion)}
                  disabled={props.isDisabledFlag ? true : false}
                />
                {/* <Validation error={props.validState.error.titleOfCourse} /> */}
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className={`col-span-12 ${props.isDisabledFlag ? 'disabled-input' : 'cursor-pointer'}`}>
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Current Company Name <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  name="companyName"
                  value={props.individualBasicInforamtion.companyName}
                  identity="IndividualBasicInformation"
                  handleChange={props.handleChange}
                  //onBlur={(e) => props.validate("companyName", props.individualBasicInforamtion)}
                  disabled={props.isDisabledFlag ? true : false}
                />
                {/* <Validation error={props.validState.error.companyName} /> */}
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className={`col-span-12 ${props.isDisabledFlag ? 'disabled-input' : 'cursor-pointer'}`}>
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Job Title <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  name="jobTitle"
                  value={props.individualBasicInforamtion.jobTitle}
                  identity="IndividualBasicInformation"
                  handleChange={props.handleChange}
                  // onBlur={(e) => props.validate("jobTitle", props.individualBasicInforamtion)}
                  disabled={props.isDisabledFlag ? true : false}
                />
                {/* <Validation error={props.validState.error.jobTitle} /> */}
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className={`col-span-12 ${props.isDisabledFlag ? 'disabled-input' : 'cursor-pointer'}`}>
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Email Address (Work) <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  name="emailOfWork"
                  value={props.individualBasicInforamtion.emailOfWork}
                  identity="IndividualBasicInformation"
                  handleChange={props.handleChange}
                  // onBlur={(e) => props.validate("emailOfWork", props.individualBasicInforamtion)}
                  disabled={props.isDisabledFlag ? true : false}
                />
                {/* <Validation error={props.validState.error.emailOfWork} /> */}
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  How do you hear about us?<span className="text-[#c00000]">*</span>
                </h2>
                <DropdownSelect
                  drpIdentity={"Heared_About_Us"}
                  optionArray={props.konwAboutUsList}
                  setFilterParameters={props.setFilterParameters}
                  value={props.individualBasicInforamtion.howDoYouHearId}
                  disabled={props.isDisabledFlag ? true : false}
                />
                {/* <Validation error={props.validState.error.howDoYouHearId} /> */}
              </div>
            </div>
          </div>

          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            {/* <div className="grid grid-cols-12 gap-6"> */}
            {/* <div className="col-span-12"> */}
            <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
              In 100 words, Tell us more about yourself
              <span className="text-[#c00000]">*</span>
            </h2>
            <div className={`${props.isDisabledFlag ? 'disabled-input' : 'cursor-pointer'}`}>
              <textarea
                className="w-full"
                placeholder=""
                rows="7"
                type="text"
                name="description"
                //maxLength={props.characterLimit}
                value={props.individualBasicInforamtion.description}
                onChange={(e) => props.handleChange(e, "IndividualBasicInformation")}
                // onBlur={(e) => props.validate("description", props.individualBasicInforamtion)}
                disabled={props.isDisabledFlag ? true : false}
              />
            </div>
            {/* <Validation error={props.validState.error.description} /> */}
          </div>
          {
            props.characterLimit - props.individualBasicInforamtion.descriptionWordsCount <= 0 ?
              (
                <div className="text-[#ffffff] bg-[#717171] w-[300px] rounded text-center">
                  You reach the character enter limit
                </div>
              )
              :
              props.characterLimit - props.individualBasicInforamtion.descriptionWordsCount <= 50 ?
                (
                  <div className={`mb-1 rounded text-[#ffffff] w-[80px] text-center ${props && props.characterLimit - props && props.individualBasicInforamtion.descriptionWordsCount < 50 ? "bg-[#C00000]" : "bg-blue-500"}`}>
                    {props.individualBasicInforamtion.descriptionWordsCount}/{props.characterLimit}
                  </div>
                )
                : null
          }
        </div>
      </div>
      {/* Save Button Section Start*/}
      <div className="grid grid-cols-12 gap-6 mt-5 pb-10">
        <div className="xl:col-span-12 lg:col-span-12 col-span-12 mt-5">
          <div className="right-btn w-full">
            <div className="flex items-center justify-end mb-5">
              {
                props && props.individualBasicInforamtion.photo ?
                  (
                    <button className="2xl:px-7 lg:px-5 py-2 btn btn-blue-border text-xl border text-[#005B9C]"
                      // onClick={() => props.GetCorporateMemberFiles(true)} disabled={props.organizationBasicInformation.organisationInformationId ? false : true}
                      onClick={() => setImageViewer(true)}
                    >
                      <span className="text-xl text-[#005B9C] font-semibold tracking-normal underline">
                        View Photo/Passport Photo
                      </span>
                    </button>
                  )
                  :
                  (
                    <div className={`${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                      <ImageCropperWithUpload
                        key="Main"
                        height={10}
                        width={10}
                        isCheckValidation={true}
                        MaxFileSize={2097152}
                        MaxFileSizeMessage={"2MB"}
                        onSaveImage={props.saveMainImage}
                        imageIdentity="IndividualMembers"
                        intitImage={props.individualBasicInforamtion.photo}
                        aspactRatio={550 / 550}
                        uploadBtn={"Upload Photo"}
                        isDisabled={props.isDisabledFlag ? true : false}
                        className2={`${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                      // dimensionText={
                      //   "(Dimension: 200px by 200px Maximum Size: 2MB)"
                      // }
                      />
                      {/* <Validation error={props.validState.error.photo} /> */}
                    </div>
                  )
              }
              {props.individualBasicInforamtion.constructionCertification && props.individualBasicInforamtion.constructionCertification !== "" ?
                <>
                  <button className="ml-5 btn btn-blue-border text-xl border text-[#005B9C]"
                    onClick={() => props.getIndividualMemberFiles(false)} disabled={props.individualBasicInforamtion.individualMemberId ? false : true}
                  >
                    <span className="text-xl text-[#005B9C] font-semibold tracking-normal underline">
                      View Construction-Related Certification(s)
                    </span>
                  </button>
                </>
                :
                <>
                  <div className="ml-10">
                    <form className={`custom-uploader custom-file-upload-btn flex cursor-pointer ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                      <div className=''>
                        <label
                          htmlFor={`Upload_Documents`}
                          aria-controls="add-edit-modal"
                          className={`btn btn-blue-border text-xl lg:text-sm border text-[#005B9C] ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                        >
                          Upload Construction-Related Certification
                        </label>
                        {/* <label
                        htmlFor={`Upload_Documents`}
                        className="text-uplod text-white"
                      >
                        Upload Construction-Related Certification
                      </label> */}
                        <input
                          className="sr-only"
                          // key={`FileQuestion${key}`}
                          id={`Upload_Documents`}
                          type="file"
                          onChange={(event) => props.onFileChange(event, "Upload_Documents")}
                          disabled={props.isDisabledFlag ? true : false}
                        />
                      </div>

                    </form>
                    <div className="">
                      {/* <Validation error={props.validState.error.constructionCertification} /> */}
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
        <div className="xl:col-span-12 lg:col-span-12 col-span-12">
          <a
            className="flex items-center justify-end"

          >
            <button className={`w-32 px-7 py-3 btn-sm btn-blue text-xl text-white font-semibold ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
              onClick={() => props.onSave()}
              disabled={props.isDisabledFlag ? true : false}
            >
              Save
            </button>
          </a>
        </div>
      </div>
      {/* Save Button Section End*/}
      {/* <div className="send-clarification-model"> */}
      <ImageViewer
        ImageViewerModal={imageViewer}
        individualImage={props.individualBasicInforamtion}
        setOpenModal={() => setImageViewer(false)}
        onClose={(e) => {
          e.stopPropagation();
          setImageViewer(false);
        }}
      />
      {/* </div> */}

    </div >
  );
}
export default IndividualBasicInformation;