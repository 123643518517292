import React from "react";
import ModalBasic from "../../component/ModalBasic";
import TextInput from "../../../components/UIComponent/TextInput";
import MultiSelectDropdown from "../../../components/dropdown/MultiSelectDropdown";

const IndividualQuickDetailModal = (props) => {
    return (
        <ModalBasic
            id="send-invoice-modal"
            modalOpen={props.setSendInvoiceModal}
            setModalOpen={props.setOpenModal}
            title=""
            noCloseIcon={false}
        >

            <div className="w-full">
                <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
                    <div className="grid grid-cols-12 gap-6 w-full ">
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        First Name{" "}<span className="text-[#c00000]">*</span>
                                        {/* <span className="text-[#c00000]">*</span> */}
                                    </h2>
                                    <div className="disabled-input">
                                        <input
                                            className={`w-full outline-none appearance-none `}
                                            placeholder=""
                                            type="text"
                                            name="companyUEN"
                                            identity="BasicInformation"
                                            value={props.individualBasicInforamtion.firstName}
                                            disabled={true}
                                            autoComplete={"off"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Last Name <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`dropdwn-input flex items-center disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="text"
                                            name="companyName"
                                            value={props.individualBasicInforamtion.lastName}
                                            identity="BasicInformation"
                                            //disabled={props.organizationBasicInformation.organisationInformationId ? true : false}
                                            autoComplete={"off"}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Date Of Birth<span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={` disabled-input`}>
                                        <input
                                            className="date-pickers w-full custom-input"
                                            type="date"
                                            name="incorporationDate"
                                            identity="BasicInformation"
                                            //    max={moment(new Date()).format("yyyy-MM-DD")}
                                            // min={props.membershipSubscription.membershipTypeId === 3 ? subtractYears(4, new Date()) : null}
                                            //    value={moment(props.individualBasicInforamtion.incorporationDate).format("yyyy-MM-DD")}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Company Name (In Chinese)
                                    </h2>
                                    <div className={`flex items-center disabled-input`}>
                                        <TextInput
                                            //className={`${props.organizationBasicInformation.organisationInformationId ? 'bg-[#f2f2f2]' : null}`}
                                            placeholder=""
                                            type="text"
                                            name="chinesCompanyName"
                                            // value={props.organizationBasicInformation.chinesCompanyName}
                                            identity="BasicInformation"
                                            autoComplete={"off"}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Job Title  <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`flex items-center disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="text"
                                            name="jobTitle"
                                            // value={props.organizationBasicInformation.jobTitle}
                                            identity="BasicInformation"
                                            autoComplete={"off"}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Representative's First Name <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`flex items-center left-border-none disabled-input`}>
                                        <div className="w-[130px]">
                                            <DropdownSelect
                                                drpIdentity={"NamePrefix"}
                                                optionArray={props.mrMrs}
                                                // value={props.organizationBasicInformation.mrMrsId}
                                                disabled={true}
                                            />
                                        </div>
                                        <TextInput
                                            placeholder=""
                                            type="text"
                                            name="firstName"
                                            // value={props.organizationBasicInformation.firstName}
                                            identity="BasicInformation"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                                        Representative's Last Name{" "}
                                        <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="text"
                                            name="lastName"
                                            // value={props.organizationBasicInformation.lastName}
                                            identity="BasicInformation"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Mailing Address Line 1 <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`dropdwn-input flex items-center disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="text"
                                            name="companyAddress"
                                            value={props.individualBasicInforamtion.mailingAddress1}
                                            identity="BasicInformation"
                                            disabled={true}
                                            autoComplete={"off"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Mailing Address Line 2
                                    </h2>
                                    <div className={`dropdwn-input flex items-center disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="text"
                                            name="companyAddress2"
                                            value={props.individualBasicInforamtion.mailingAddress2}
                                            identity="BasicInformation"
                                            disabled={true}
                                            autoComplete={"off"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Mailing Address Line 3
                                    </h2>
                                    <div className={`dropdwn-input flex items-center disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="text"
                                            name="companyAddress3"
                                            value={props.individualBasicInforamtion.mailingAddress3}
                                            identity="BasicInformation"
                                            disabled={true}
                                            autoComplete={"off"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Country/Region <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <MultiSelectDropdown
                                        drpIdentity="Country"
                                        options={props.countryList}
                                        //isMulti
                                        // isMulti
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        // components={{ Option }}
                                        onChange={props.handleChangeMultiDropdown}
                                        allowSelectAll={false}
                                        value={props.countryList.filter(country => country.value === props.individualBasicInforamtion.countryId)}
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Postal Code{" "}
                                        <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="number"
                                            name="postalCode"
                                            maxLength={6}
                                            identity="BasicInformation"
                                            value={props.individualBasicInforamtion.postalCode}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Contact Number{" "}
                                        <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="number"
                                            name="contactNo"
                                            maxLength={10}
                                            value={props.individualBasicInforamtion.contactNo}
                                            identity="BasicInformation"
                                            autoComplete={"off"}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Email Address <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="email"
                                            name="email"
                                            value={props.individualBasicInforamtion.email}
                                            identity="BasicInformation"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Place of Birth<span className="text-[#c00000]">*</span>
                                    </h2>
                                    <MultiSelectDropdown
                                        drpIdentity="Company_Activity"
                                        options={props.placeOfBirthList}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        // components={{ Option }}
                                        // onChange={props.handleChangeMultiDropdown}
                                        allowSelectAll={true}
                                        isDisabled={true}
                                        value={props.placeOfBirthList.find(type => type.value === props.individualBasicInforamtion.placeOfBirthId)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Nationality <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <MultiSelectDropdown
                                        drpIdentity="Company_Activity"
                                        options={props.nationalityList}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        // components={{ Option }}
                                        // onChange={props.handleChangeMultiDropdown}
                                        allowSelectAll={true}
                                        isDisabled={true}
                                        value={props.nationalityList.find(type => type.value === props.individualBasicInforamtion.nationalityTypeId)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Your Highest Education Level <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <MultiSelectDropdown
                                        drpIdentity="Project_Type"
                                        options={props.highestEducationList}
                                        // isMulti
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        // components={{ Option }}
                                        onChange={props.handleChangeMultiDropdown}
                                        allowSelectAll={false}
                                        isDisabled={true}
                                        value={props.highestEducationList.find(type => type.value === props.individualBasicInforamtion.educationLevelId)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Title of Course  <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="email"
                                            name="email"
                                            value={props.individualBasicInforamtion.titleOfCourse
                                            }
                                            identity="BasicInformation"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Current Company Name   <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="email"
                                            name="email"
                                            value={props.individualBasicInforamtion.companyName}
                                            identity="BasicInformation"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Job Title  <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="email"
                                            name="email"
                                            value={props.individualBasicInforamtion.jobTitle}
                                            identity="BasicInformation"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Email Address (Work)  <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="email"
                                            name="email"
                                            value={props.individualBasicInforamtion.email}
                                            identity="BasicInformation"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        How do you hear about us? <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <MultiSelectDropdown
                                        drpIdentity="Employee_Local"
                                        options={props.hearAboutUsList}
                                        // isMulti
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        // components={{ Option }}
                                        onChange={props.handleChangeMultiDropdown}
                                        allowSelectAll={false}
                                        isDisabled={true}
                                        value={props.hearAboutUsList.find(empLocal => empLocal.value === props.individualBasicInforamtion.howDoYouHearId)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        In 100 words, Tell us more about yourself{" "}
                                        <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`disabled-input`}>
                                        <textarea
                                            name="companyDescription"
                                            rows="7"
                                            className='w-full'
                                            maxLength={300}
                                            value={props.individualBasicInforamtion.description}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalBasic>
    );
}
export default IndividualQuickDetailModal;