import React from "react";


const SectionWithBorder = (props) => {
    return (
        <>
            <div className="border-solid border border-[#000000] w-full mb-8">
                <div className=" grid grid-cols-grid-6 border-b border-[#000000]">
                    <caption className="col-start-1 col-end-3 text-xl text-left py-4 pl-4 text-[#000000] font-bold">{props.title}</caption>
                    <p
                        className="col-end-7 col-span-1 cursor-pointer py-4 pr-4 text-[#0e7fe1] text-right text-sm "
                        //onClick={(e) => props.onQuickLink(e)}
                    >
                       {/* <a href={props.location} target="_blank">{props.quickLink}</a>  */}
                       <a href={props.location} target="_blank" rel="noopener noreferrer">{props.quickLink}</a>

                    </p>
                </div>
                {props.children}
            </div>
        </>
    );
}
export default SectionWithBorder;