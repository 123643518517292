import React from "react";
import DatePicker from "../../datePicker/DatePicker";
import DropdownSelect from "../../dropdown/Dropdown";
import moment from "moment";
import Validation from '../../../utils/validation/ValidationText';
import TextInput from "../../UIComponent/TextInput";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";

function IndividualMembershipSubscription(props) {
  console.log("Membership Typed List:", props.membershipTypes)
  return (
    <div>
      <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Membership Type
                  <span className="text-[#c00000]">*</span>
                </h2>
                <DropdownSelect
                  drpIdentity={"SCALMembershipInformation"}
                  //optionArray={props.scalMembershipInforamtion.membershipTypeId === 4? props.membershipTypeForNewMembers : props.membershipTypes}
                  optionArray={props.membershipTypes}
                  setFilterParameters={props.setFilterParameters}
                  value={props.scalMembershipInforamtion.membershipTypeId}
                  disabled={props.scalMembershipInforamtion.membershipTypeId === 4 ? true : props.isDisabledFlag ? true : false}
                />
                <Validation error={props.validState.error.membershipTypeId} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Billing Category
                  <span className="text-[#c00000]">*</span>
                </h2>
                <DropdownSelect
                  drpIdentity={"corporateMemberBillingCategoryId"}
                  optionArray={props.billingCategory}
                  setFilterParameters=""
                  value={props.scalMembershipInforamtion.billingCategoryId}
                  // onBlur={(e) => props.validate("corporateMemberbillingCategoryId",props.scalMembershipInforamtion)}
                  disabled={true}
                />
                {/* <Validation error={props.validState.error.corporateMemberbillingCategoryId}/> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Subscription Start Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input dropdwn-input-email flex items-center">
                  <DatePicker
                    isDisable={true}
                    name="subscriptionStartDate"
                    handleChange={props.handleChange}
                    identity="MembershipSubscription"
                    value={moment(props.scalMembershipInforamtion.subscriptionStartDate).format("yyyy-MM-DD")}
                  // onBlur={(e) => props.validate("corporateJoinedDate", props.scalMembershipInforamtion)}
                  />
                  {/* <Validation error={props.validState.error.subscriptionStartDate} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Subcription End Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input dropdwn-input-email flex items-center">
                  <DatePicker
                    isDisable={true}
                    name="subcriptionEndDate"
                    handleChange={props.handleChange}
                    identity="MembershipSubscription"
                    value={moment(props.scalMembershipInforamtion.subcriptionEndDate).format("yyyy-MM-DD")}
                  // onBlur={(e) => props.validate("corporateJoinedDate", props.scalMembershipInforamtion)}
                  />
                  {/* <Validation error={props.validState.error.subcriptionEndDate} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Updated By
                </h2>
                <DropdownSelect
                  disabled={true}
                  drpIdentity={"updatedById"}
                  optionArray={props._by}
                  setFilterParameters=""
                  value={props.scalMembershipInforamtion.updatedBy}
                // onBlur={(e) => props.validate("updatedById", props.scalMembershipInforamtion)}
                />
                {/* <Validation error={props.validState.error.updatedById} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Last Updated Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input">
                  {props.scalMembershipInforamtion.updatedAt === "" || props.scalMembershipInforamtion.updatedAt === null ?

                    <TextInput
                      disabled={true}
                      placeholder=""
                      type="text"
                      name="linkedIn"
                      value="None"
                      identity="BasicInformation"
                    />
                    :
                    <DatePicker
                      isDisable={true}
                      name="updatedAt"
                      handleChange={props.handleChange}
                      identity="updatedAt"
                      value={moment(props.scalMembershipInforamtion.updatedAt).format("yyyy-MM-DD")}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          {props.scalMembershipInforamtion.individualSubscriptionId > 0 ?
            null
            :
            <>
              <div className='col-span-10 mt-0 mb-5'>
                <div className="flex item-center flex-col">
                  {
                    props && props.isPaymentProofLoader ?
                      <ButtonLoader type='btn btn-blue' />
                      :
                      (
                        <form className="custom-uploader custom-file-upload-btn flex cursor-pointer">
                          <div className="border border-[#f3f3f3] btn btn-blue">
                            <label htmlFor="Upload_Payment_Proof" className={`text-uplod text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>Upload Payment Proof</label>
                            <input className="sr-only" id="Upload_Payment_Proof" type="file"
                              onChange={(event) => props.onFileChange(event, "Upload_Payment_Proof")}
                              disabled={props.isDisabledFlag ? true : false}
                            />
                          </div>
                        </form>
                      )
                  }

                  {/* <ValidationText error={props.validState.error.uploadPaymentProof} /> */}
                  <Validation error={props.validState.error.uploadPaymentProof} />

                  {
                    props && props.scalMembershipInforamtion.uploadPaymentProof ?
                      (
                        <div className='mt-5 file-uploaded-txt  items-center'>
                          <div className="img-and-text-side-by-side">
                            <img src='/check-orange.png' className='w-[25px] mr-3' alt='' />
                            <span className='text-[#000]'>
                              {props.scalMembershipInforamtion.uploadPaymentProof}
                            </span>
                          </div>
                          <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                            Comments
                            <span className="text-[#c00000]">*</span>
                          </h2>
                          <textarea
                            className="w-full"
                            id="comments"
                            rows="4"
                            name="comments"
                            value={props.scalMembershipInforamtion.comments}
                            onChange={(e) => props.handleChange(e, 'comments')}
                          //   onBlur={() => this.validateField("comments")}
                          />
                          <Validation error={props.validStateIndProfile.error.comments} />
                        </div>
                      )
                      : null
                  }
                </div>
              </div>
            </>
          }
        </div>
      </div>
      {/* Save Button */}
      <div className="grid grid-cols-12 gap-6 pb-10">
        <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10">
          <a
            className="flex items-center justify-end"
        
          >
            {
              props && props.isLoading ?
                (
                  <ButtonLoader type='btn btn-blue' />
                )
                :
                (
                  <button
                    className={`w-32 px-7 py-3 btn-sm btn-blue text-xl text-white font-semibold ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                    onClick={() => props.onSave()}
                    disabled={props.isDisabledFlag ? true : false}
                  >
                    Save
                  </button>
                )
            }
          </a>
        </div>
      </div>
    </div>
  );
}
export default IndividualMembershipSubscription;