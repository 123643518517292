import React, { Component } from "react";
import Table from "../../../components/table/Table";
import { Navigate } from "react-router-dom";
import moment from "moment";
import SwalServices from "../../../services/swalServices/SwalServices";
import ActionContainer from "../../../components/UIComponent/ActionTableComponent/actionContainer";
import DropdownAction from "../../../components/dropdown/DropdownAction";
import MembershipTaskListServices from "../../../services/axiosServices/apiServices/MembershipTaskListServices";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import MembershipTaskListProcess from "./MembershipTaskListProcess";
import {
  CommonValidationMessages,
} from "../../../utils/Messages";
import {
  isValidForm,
  validate,
} from "../../../utils/validation/CommonValidator";
import SendForApprove from "./SendApproveModal";
import SendClarifications from "./SendClarifications";
import SendPaymentLink from "./SendPaymentLink";
import SendQuotation from "./SendQuotation";
import SendInvoice from "./SendInvoice";
import EscalateModal from "./EscalateModal";
import { CommonSuccessMessages } from "../../../utils/Messages";
import BatchJobService from "../../../services/axiosServices/apiServices/BatchJobServices"
import SendRejectionReason from "../slotsMembers/SendRejectionReason";

export default class MembershipTaskList extends Component {
  constructor(props) {
    super(props);
    this.TaskListServices = new MembershipTaskListServices();
    this.batchJobService = new BatchJobService();
    this.swalServices = new SwalServices();
    this.state = {
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        dateFrom: null,
        dateTo: null,
        filterBySource: '',
        filterBySourceId: 0,
      },
      totalResultes: "",
      taskList: [],
      isLoading: false,
      isLoadingPayment: false,
      isLoadingQuotation: false,
      isLoadingInvoice: false,
      isLoadingApprove: false,
      isEscalateLodaing: false,
      route: null,
      redirect: null,
      membershipTypes: [],
      transactionTypes: [],
      billingCategories: [],
      membershipRevenueList: [],
      selectedTaskId: 0,
      sourceList: [
        {
          id: 1,
          name: "Admin"
        },
        {
          id: 2,
          name: "Web"
        },
      ],
      users: [],
      tenureList: [],
      designationList: [],
      // numberOfChapters: [
      //   { id: 0, name: "0" },
      //   { id: 1, name: "1" },
      //   { id: 2, name: "2" },
      //   { id: 3, name: "3" },
      //   { id: 4, name: "4" },
      //   { id: 5, name: "5" },
      // ],
      numberOfChapters: [
        { id: 1, name: "0" },
        { id: 2, name: "1" },
        { id: 3, name: "2" },
        { id: 4, name: "3" },
        { id: 5, name: "4" },
        { id: 6, name: "5" },
      ],
      taskDetails: {
        taskId: 0,
        corporateMemberId: 0,
        companyName: "",
        chineseCompanyName: "",
        companyUEN: "",
        incorporationDate: "",
        firstName: "",
        lastName: "",
        //designation: "",
        email: "",
        contactNo: "",
        billingCategoryId: 1,
        updatedBy: 0,
        subscriptionStartDate: "",
        subscriptionEndDate: "",
        //lastUpdatedDate: "",
        membershipTypeId: 1,
        //membershipRevenueId: 1,
        transactionTypeId: 1,
        //IsApprovalRequired: false,
        //additionalPromotion: "",
        //commentsToMember: "",
        //internalNotes: "",
        //commentsToMemberInv: "",
        //internalNotesInv: "",
        //membershipTenureId: 0,
        //numberOfChapter: 0,
        //createdBy: 0,
        startMembershipOn: "",
        isStartMembership: false,
        //poNumber: "",
        //quotationNeeded: false,
        isAppliedForRenewal: false,
        startMembership: false,
        //comments: "",
        //selectedDesignation: [],
        ordinaryApplicationFee: "",
        annualOrdinaryRevenueFee: "",
        selectedMembershipType: "",
        applicationFee: 0,
        annualRevenueFee: 0,// membershipFess:0,
        mrMrsId: 0,
        businessEmail: "",
        isApplicationFee: false,
        statusId: 0,
      },
      setRejectionReason: false,
      sendRejectionReason: '',
      corporatePricingDetails: {},
      setDropdownOpen: false,
      membershipTaskListProcessModal: false,
      setAddeditModalOpen: false,
      setSendClarifications: false,
      setPaymentLink: false,
      sendQuotation: false,
      sendInvoice: false,
      sendEscalation: false,
      basePriceTable: [],
      allowableDiscountTable: [],
      percentageDiscountTable: [],
      systemCalculatedTable: [],
      disablePaymentButton: true,
      disableSubscription: true,
      sendEscalate: {
        firstName: "",
        lastName: "",
        companyName: "",
        companyUEN: "",
        email: "",
        contactNo: "",
        internalNotes: "",
      },
      countryList: [],
      companyMainActivityList: [],
      typesOfProject: [],
      publicprojectsForm: [],
      fullStaffEmployeeLocal: [],
      fullStaffEmployeeForeginer: [],
      hearAboutUsList: [],
      annualTurnoverList: [],
      status: [],
      validationRule: {
        companyName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "comapany name"
            ),
          },
        ],
        // chineseCompanyName: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "comapany name in chinese"
        //     ),
        //   },
        // ],
        companyUEN: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "company UEN"
            ),
          },
        ],
        incorporationDate: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "incorporation date"
            ),
          },
        ],
        firstName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "first name"
            ),
          },
        ],
        lastName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "last name"
            ),
          },
        ],
        // selectedDesignation: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.SelectRequired.replace(
        //       "{0}",
        //       "designation"
        //     ),
        //   },
        // ],
        email: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "business email"
            ),
          },
        ],
        contactNo: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "contact number"
            ),
          },
        ],
        membershipTypeId: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "membership type"
            ),
          },
        ],
        // membershipRevenueId: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "revenue type"
        //     ),
        //   },
        // ],
        transactionTypeId: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "transaction type"
            ),
          },
        ],
      },
      invoiceValidation: {
        membershipTenureId: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "membership tenure"
            ),
          },
        ],
        numberOfChapter: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "no. of chapters"
            ),
          },
        ],
        // commentsToMemberInv: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "comments"
        //     ),
        //   },
        // ],
        // internalNotesInv: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "internal notes"
        //     ),
        //   },
        // ],
        // startMembershipOn: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "start membership date"
        //     ),
        //   },
        // ],
      },
      validState: {
        isValid: true,
        error: {},
      },
      validStateForInvoice: {
        isValid: true,
        error: {},
      },
      clarification: '',
      // validStateMemberSub: {
      //     isValid: true,
      //     error: {}
      // },
      // validStatePricingTable: {
      //     isValid: true,
      //     error: {}
      // },
      // validStateInvGen: {
      //     isValid: true,
      //     error: {}
      // },
      // validationRuleMemberSub: {
      //     isValid: true,
      //     error: {}
      // },
      // validationRulePricingTable: {
      //     isValid: true,
      //     error: {}
      // },
      // validationRuleInvGen: {
      //     isValid: true,
      //     error: {}
      // },
      actionDropdown: ["Process"],
      organizationBasicInformation: {
        companyTypeId: 0,
        organisationInformationId: 0,
        companyUEN: "",
        companyName: "",
        chinesCompanyName: "",
        incorporationDate: "",
        firstName: "",
        lastName: "",
        designation: "",
        email: "",
        businessEmail1: "",
        contactNo: "",
        companyDomain: "",
        mrMrsId: 0,
        selectedDesignation: [],
        companyAddress: "",
        companyAddress2: "",
        companyAddress3: "",
        companyActivity: [],
        countryId: 153,
        countryName: "",
        //postalCode: 0,
        membershipRevenueId: 0,
        aCRACertificatePath: "",
        projectTrackRecordReportPath: "",
        jobTitle: "",
        membershipTypeId: 1,
        isActive: false,
        postalCode: "",
        companyDescription: "",
        businessTelNo: "",
        isCompanyRegisterWithBCA: true,
        companyScopeList: [],
        businessContactNumber: "",
        projectTypeId: 0,
        fullTimeStaffLocalId: 0,
        fullTimeStaffForeignersId: 0,
        knowAboutUsId: 0,
        otherProjectForm: "",
        hearAboutIsOther: false,
        hearAboutOther: ""
      },
      companyActivity: [],
      publicProjectsForms: [],
      mrMrs : [],
      countriesWithPresence: [],
      organizationRoleArray: [],
      getToKnowUsArr: [],
      mainGroupList: [],
      typeOfProjects: [],
      fulltimeEmployeeLocal: [],
      fullTimeEmployeeForeigners: [],
      knowAboutusList: [],
      annualTurnover: [],
      engagementType: [],
      reasonOfLeaving: [],
      companyRegisteredWithBCAList : []
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    // this.getAllTaskList();
    this.getCorporateMemberPricing();
    this.getAllDropdownsForTaskList();
    this.getAllDropdownForCorporateMember();
  }

  getAllTaskList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.TaskListServices.getAllTaskList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        // var tasks = {};
        // tasks.taskId =
        // tasks.corporateMemberId =
        // tasks.companyName =
        // tasks.companyUEN =
        // tasks.fullName =
        // tasks.email =
        // tasks.industry =
        // tasks.source =
        // tasks.registrationDate =
        let details = response.responseItem.responseContent.itemList;
        details.map((key) => {
          key.registrationDate = moment(key.registrationDate).format(
            "DD MMM YYYY"
          );

          if (key.source === null || key.source === "") {
            key.source = "-";
          }
          if (key.Email === null || key.Email === "") {
            key.Email = "-";
          }
          if (key.FullName === null || key.FullName === "") {
            key.FullName = "-";
          }
          if (key.CompanyName === null || key.CompanyName === "") {
            key.CompanyName = "-";
          }
          if (key.CompanyUEN === null || key.CompanyUEN === "") {
            key.CompanyUEN = "-";
          }
          if (key.registrationDate === null || key.registrationDate === "") {
            key.registrationDate = "-";
          }

       return details; });
        this.setState({ taskList: details });
        this.setState({
          totalResultes: response.responseItem.responseContent.totalCount,
        });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  getAllDropdownsForTaskList = () => {
    this.TaskListServices.getAllDropdownsForTaskList().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        var membershipArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "MembershipType"
        );
        var transactionArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "TransactionType"
        );
        var billingCategoryArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "BillingCategory"
        );
        var membershipRevenueArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "MembershipRevenue"
        );
        // var sourceArr = response.responseItem.responseContent.filter(
        //   (drp) => drp.dropdownName === "CorporateProspectSource"
        // );
        var userArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Users"
        );
        var tenureArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Tenure"
        );

        var designation = response.responseItem.responseContent.filter(drp => drp.dropdownName === 'Designation');
        designation.map(function (item) { delete item['dropdownName']; return item;});
        designation = designation.map(p => ({
          value: p.id, label: p.name
        }));


        console.log("tenure : ", tenureArr);

        membershipArr.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        transactionArr.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        billingCategoryArr.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        membershipRevenueArr.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        //sourceArr.map(function (item) {
        //delete item["dropdownName"];
        //});
        userArr.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        this.setState({
          membershipTypes: membershipArr,
          transactionTypes: transactionArr,
          billingCategories: billingCategoryArr,
          membershipRevenueList: membershipRevenueArr,
          //sourceList: sourceArr,
          users: userArr,
          tenureList: tenureArr,
          designationList: designation,
        }, () => this.getAllTaskList());
      } else {
        this.swalServices.Error(response.message);
        this.getAllTaskList();
      }
      this.setState({ isLoading: false });
    });
  };

  getTaskByCompanyId = (companyId) => {
    this.TaskListServices.getTaskByCompanyId(companyId).then((response) => {
      console.log("Check Corporate Id :", response)
      if (response.statusCode === 200 && response.responseItem != null) {
        response.responseItem.responseContent.transactionTypeId =
          response.responseItem.responseContent.transactionTypeId === null
            ? 1
            : response.responseItem.responseContent.transactionTypeId;

        // if (response.responseItem.responseContent.designation !== null && response.responseItem.responseContent.designation !== "") {
        //   var array = response.responseItem.responseContent.designation.split(',');
        //   var designation = [];
        //   array.forEach(topic => {
        //     designation.push(this.state.designationList.find(deg => deg.label === topic));
        //   });

        // }
        this.setState(
          { taskDetails: response.responseItem.responseContent },
          () => {
            let data = { ...this.state.taskDetails };
            console.log("Task My Details :", this.state.taskDetails);
            //data.selectedDesignation = designation;
            data.startMembership = data.startMembershipOn ? true : false;
            this.setState({ taskDetails: data });
            // this.getPriceTabelForTaskListProcess();
            //this.getCorporatePriceTabelForTaskListProcess();
          },
          () => this.setState({ validState: { isValid: true, error: {} } })
        );
        // let details = this.state.taskDetails;
        // details.numberOfChapter= response.responseItem.responseContent.additionalChapter;
        // this.setState({taskDetails : details});
        console.log(" TASK Details : ", this.state.taskDetails);
        this.setState({ setAddeditModalOpen: true });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  handleChangeSendRejectionReasonDetails = (data) => {
    if (typeof data === "string") {
      this.setState({
        sendRejectionReason: data,
      });
    }
    //this.setState({ sendRejectionReason: data });
    //const value = event.target.value;
    //this.setState({ sendRejectionReason: value });
  };

  // getPriceTabelForTaskListProcess = () => {
  //   var request = {
  //     MembershipTypeId: this.state.taskDetails.membershipTypeId,
  //     MembershipRevenueId: this.state.taskDetails.membershipRevenueId,
  //     CorporateMemberId: this.state.taskDetails.corporateMemberId,
  //     MembershipTenureId: 1,
  //     MembershipCategoryId: this.state.taskDetails.transactionTypeId,
  //   };
  //   this.TaskListServices.getPriceTabelForTaskListProcess(request).then(
  //     (response) => {
  //       if (response.statusCode === 200 && response.responseItem != null) {
  //         var basePriceTable = response.responseItem.responseContent.filter(
  //           (drp) => drp.tableName === "BasePriceTable"
  //         );
  //         var allowableDiscountTable =
  //           response.responseItem.responseContent.filter(
  //             (drp) => drp.tableName === "AllowableDiscountTable"
  //           );
  //         var percentageDiscountTable =
  //           response.responseItem.responseContent.filter(
  //             (drp) => drp.tableName === "PercentageDiscountTable"
  //           );
  //         var systemCalculatedTable =
  //           response.responseItem.responseContent.filter(
  //             (drp) => drp.tableName === "SystemCalculatedTable"
  //           );

  //         basePriceTable.forEach((element, key) => {
  //           basePriceTable[key].status = "Ok";
  //           if (this.state.taskDetails.isAppliedForRenewal === true) {
  //             basePriceTable[key].entranceFee = 0;
  //           }
  //         });
  //         if (allowableDiscountTable.length === 0) {
  //           allowableDiscountTable.forEach((element, key) => {
  //             allowableDiscountTable.push(element);
  //           });
  //         }
  //         let diff = "";
  //         if (this.state.taskDetails.membershipTypeId === 3) {
  //           if (!request.subscriptionEndDate && !request.subscriptionStartDate) {
  //             let todayDate = new Date();
  //             let inCorDate = new Date(this.state.taskDetails.incorporationDate);
  //             let Difference_In_Time = todayDate.getTime() - inCorDate.getTime();
  //             let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  //             let Difference_In_Years = Difference_In_Days / 365;
  //             diff = Math.floor(Difference_In_Years);
  //           }
  //         }
  //         if (percentageDiscountTable.length === 0) {
  //           basePriceTable.forEach((element, key) => {
  //             var obj = {
  //               row: key + 11,
  //               year: key + 1,
  //               entranceFee: 0,
  //               annualFee: 0,
  //               includedChapter: 1,
  //               newChapterPrice: 0,
  //               tableName: "PercentageDiscountTable",
  //               status: "Ok",
  //               isDeleted: true
  //             };
  //             if (key + 1 > diff) {
  //               obj.isDeleted = false;
  //             } else {
  //               obj.isDeleted = true;
  //             }
  //             percentageDiscountTable.push(obj);
  //           });
  //         }
  //         if (systemCalculatedTable.length === 0) {
  //           basePriceTable.forEach((element, key) => {
  //             var obj = {
  //               row: key + 16,
  //               year: key + 1,
  //               entranceFee: 0,
  //               annualFee: 0,
  //               includedChapter: 0,
  //               newChapterPrice: 0,
  //               tableName: "SystemCalculatedTable",
  //               isDeleted: true
  //             };
  //             if (key + 1 > diff) {
  //               obj.isDeleted = false;
  //             } else {
  //               obj.isDeleted = true;
  //             }
  //             systemCalculatedTable.push(obj);
  //           });
  //         }
  //         var forPayment = percentageDiscountTable.filter(
  //           (arr) =>
  //             arr.status === "Required Approval" || arr.status === "Rejected"
  //         );
  //         if (forPayment.length > 0) {
  //           this.setState({ disablePaymentButton: true });
  //         } else {
  //           this.setState({ disablePaymentButton: false });
  //         }
  //         this.setState(
  //           {
  //             basePriceTable: basePriceTable,
  //             allowableDiscountTable: allowableDiscountTable,
  //             percentageDiscountTable: percentageDiscountTable,
  //             systemCalculatedTable: systemCalculatedTable,
  //           },
  //           () => {
  //             if (
  //               this.state.taskDetails.taskId &&
  //               this.state.taskDetails.taskId > 0
  //             ) {
  //               this.getExistingDiscountTableByTaskId(
  //                 this.state.taskDetails.taskId
  //               );
  //             } else {
  //               this.getCalculatedFinalPrice();
  //             }
  //             //this.getCalculatedFinalPrice(allowableDiscountTable);
  //           }
  //         );
  //       } else {
  //         this.swalServices.Error(response.message);
  //       }
  //       this.setState({ isLoading: false });
  //     }
  //   );
  // };

  getCorporateMemberPricing = () => {
    this.setState({ isLoading: true });
    this.TaskListServices.getCorporateMemberPricing().then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({ corporatePricingDetails: response.responseItem.responseContent });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      }
    );
  };

  // getExistingDiscountTableByTaskId = (taskId) => {
  //   this.TaskListServices.getExistingDiscountTableByTaskId(taskId).then(
  //     (response) => {
  //       if (response.statusCode === 200 && response.responseItem != null) {
  //         var percentageDiscount = response.responseItem.responseContent.filter(
  //           (drp) => drp.tableName === "PercentageDiscountTable"
  //         );
  //         // if (percentageDiscount.length === 0) {
  //         //   percentageDiscount = [];
  //         //   basePrice.forEach((element, key) => {
  //         //     var obj = {
  //         //       row: key + 11,
  //         //       year: key + 1,
  //         //       entranceFee: 0,
  //         //       annualFee: 0,
  //         //       includedChapter: 0,
  //         //       newChapterPrice: 0,
  //         //       tableName: "PercentageDiscountTable",
  //         //       status: "Ok",
  //         //     };
  //         //     percentageDiscount.push(obj);
  //         //   });
  //         // }
  //         if (response.responseItem.responseContent.length === 5) {
  //           this.setState(
  //             { percentageDiscountTable: percentageDiscount },
  //             () => {
  //               this.getCalculatedFinalPrice();
  //             }
  //           );
  //         } else {
  //           this.getCalculatedFinalPrice();
  //         }
  //       } else {
  //         this.swalServices.Error(response.message);
  //       }
  //       this.setState({ isLoading: false });
  //     }
  //   );
  // };

  getCalculatedFinalPrice = () => {
    this.setState({ isLoading: true });
    var request = [];
    request = this.state.basePriceTable.concat(
      this.state.percentageDiscountTable
    );
    // request.push(this.state.basePriceTable);
    // request.push(this.state.percentageDiscountTable);
    this.TaskListServices.getCalculatedFinalPrice(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState(
          {
            systemCalculatedTable: response.responseItem.responseContent,
          },
          () => {
            var forPayment = this.state.percentageDiscountTable.filter(
              (arr) =>
                arr.status === "Required Approval" || arr.status === "Rejected"
            );
            if (forPayment.length > 0) {
              this.setState({ disablePaymentButton: true });
            } else {
              this.setState({ disablePaymentButton: false });
            }
            this.setAllPricingTables();
          }
        );
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  setAllPricingTables = () => {
    let setBasePriceTable = [...this.state.basePriceTable];
    let setAllowableDiscountTable = [...this.state.allowableDiscountTable];
    let setPercentageDiscountTable = [...this.state.percentageDiscountTable];
    let setSystemCalculatedTable = [...this.state.systemCalculatedTable];

    let diff = "";
    if (this.state.taskDetails.membershipTypeId === 3) {
      if (!this.state.taskDetails.subscriptionEndDate && !this.state.taskDetails.subscriptionStartDate) {
        let todayDate = new Date();
        let inCorDate = new Date(this.state.taskDetails.incorporationDate);
        let Difference_In_Time = todayDate.getTime() - inCorDate.getTime();
        let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        let Difference_In_Years = Difference_In_Days / 365;
        diff = Math.floor(Difference_In_Years);
      }
    }

    for (let index = 0; index < 1; index++) {
      if (index + 1 > diff) {
        setBasePriceTable[index].isDeleted = false;
        setAllowableDiscountTable[index].isDeleted = false;
        setPercentageDiscountTable[index].isDeleted = false;
        setSystemCalculatedTable[index].isDeleted = false;
      } else {
        setBasePriceTable[index].isDeleted = true;
        setAllowableDiscountTable[index].isDeleted = true;
        setPercentageDiscountTable[index].isDeleted = true;
        setSystemCalculatedTable[index].isDeleted = true;
      }
    }

    this.setState({
      basePriceTable: setBasePriceTable, allowableDiscountTable: setAllowableDiscountTable,
      percentageDiscountTable: setPercentageDiscountTable, systemCalculatedTable: setSystemCalculatedTable
    });
  }

  onViewOrderDetails = (value) => {
    this.setState({
      route: "https://web.sgtech-test.infixsofttech.com/Articles" + value,
    });
  };

  // sendEscalateMailToMember = () => {
  //   //let data = this.state.taskDetails;

  //   var request = this.state.sendEscalate;
  //   request.firstName = this.state.taskDetails.firstName;
  //   request.lastName = this.state.taskDetails.lastName;
  //   request.companyName = this.state.taskDetails.companyName;
  //   request.companyUEN = this.state.taskDetails.companyUEN;
  //   request.email = this.state.taskDetails.email;
  //   request.contactNo = this.state.taskDetails.contactNo;
  //   request.internalNotes = this.state.taskDetails.internalNotes;
  //   console.log("Send data :", request);
  //   this.setState({ isEscalateLodaing: true });
  //   this.TaskListServices.sendEscalateMailToMember(request).then((response) => {
  //     if (response.statusCode === 200 && response.responseItem != null) {
  //       this.setState({ sendEscalation: true }, () =>
  //         this.setState({ setAddeditModalOpen: false })
  //       );
  //       // this.swalServices.Success("Sended.");
  //     } else {
  //       this.swalServices.Error(response.message);
  //     }
  //     this.setState({ isEscalateLodaing: false });
  //   });
  // };

  addEditMembershipTask = () => {
    let isValidInput = this.isValidateAllFields();
    console.log("isValidInput : ", isValidInput);
    if (isValidInput) {
      this.setState({ isLoadingApprove: true });
      var pricingList = [];
      var request = { ...this.state.taskDetails, ...pricingList };
      request.taskId = this.state.selectedTaskId;
    //warning   // var forPayment = this.state.percentageDiscountTable.filter(
      //   (arr) => arr.status === "Required Approval" || arr.status === "Rejected"
      // );
      request.IsApprovalRequired = true;
      request.IsPaymentLinkSent = false;
      // if (forPayment.length > 0) {
      //   request.IsApprovalRequired = true;
      // }
      // else {
      //   request.IsApprovalRequired = false;
      // }
      request.pricingList = this.state.systemCalculatedTable.concat(
        this.state.percentageDiscountTable,
        this.state.basePriceTable
      );
      // if (request.selectedDesignation && request.selectedDesignation.length > 0) {
      //   var arr1 = request.selectedDesignation.map(function (item) {
      //     delete item.bad;
      //     return item.label;
      //   });
      //   request.designation = arr1.join([',']);
      // } else {
      //   request.designation = "";
      // }

      this.TaskListServices.addEditMembershipTaskAndSendForApproval(
        request
      ).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          //window.location.replace('https://web.sgtech-test.infixsofttech.com//membership/MembershipPayment/' + response.responseItem.responseContent.keyId)
          this.setState({ setSendApprove: true }, () => {
            this.setState({ setAddeditModalOpen: false });
            this.getAllTaskList();
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoadingApprove: false });
      });
    }
  };

  // getPriceTabelByCorporateMemberId = (companyId) => {
  //   this.TaskListServices.getPriceTabelByCorporateMemberId(companyId).then(
  //     (response) => {
  //       if (response.statusCode === 200 && response.responseItem != null) {
  //         var basePriceTable = response.responseItem.responseContent.filter(
  //           (drp) => drp.tableName === "BasePriceTable"
  //         );
  //         var allowableDiscountTable =
  //           response.responseItem.responseContent.filter(
  //             (drp) => drp.tableName === "AllowableDiscountTable"
  //           );
  //         var percentageDiscountTable =
  //           response.responseItem.responseContent.filter(
  //             (drp) => drp.tableName === "PercentageDiscountTable"
  //           );
  //         var systemCalculatedTable =
  //           response.responseItem.responseContent.filter(
  //             (drp) => drp.tableName === "SystemCalculatedTable"
  //           );
  //         var obj = {
  //           row: 1,
  //           year: 1,
  //           EntranceFee: 0,
  //           AnnualFee: 0,
  //           newChapterPrice: 0,
  //           tableName: "",
  //         };

  //         if (allowableDiscountTable.length === 0) {
  //           basePriceTable.forEach((element, key) => {
  //             allowableDiscountTable.push(element);
  //           });
  //         }
  //         if (percentageDiscountTable.length === 0) {
  //           basePriceTable.forEach((element, key) => {
  //             percentageDiscountTable.push(element);
  //           });
  //         }
  //         if (systemCalculatedTable.length === 0) {
  //           basePriceTable.forEach((element, key) => {
  //             systemCalculatedTable.push(element);
  //           });
  //         }
  //         this.setState({
  //           basePriceTable: basePriceTable,
  //           allowableDiscountTable: basePriceTable,
  //           percentageDiscountTable: percentageDiscountTable,
  //           systemCalculatedTable: systemCalculatedTable,
  //         });
  //       } else {
  //         this.swalServices.Error(response.message);
  //       }
  //       this.setState({ isLoading: false });
  //     }
  //   );
  // };

  addEditMembershipTaskAndSendPaymentLink = () => {
    if (this.state.taskDetails.isApplicationFee === true) {
      this.swalServices
        .Confirm(
          CommonSuccessMessages.Confirm_Payment.replace("{0}", "Payment Link"),
          CommonSuccessMessages.Send_Payment_Link.replace("{0}", "Payment Link"),
          CommonSuccessMessages.Yes_Text,
          CommonSuccessMessages.No_Text
        )
        .then((response) => {
          console.log("Confirm Response", response);
          let resp = response;
          if (resp === true) {
            let isValidInput = this.isValidateAllFields();
            if (isValidInput) {
              var pricingList = [];
              var request = { ...this.state.taskDetails, ...pricingList };
              request.taskId = this.state.selectedTaskId;
             
              request.IsPaymentLinkSent = true;

            
              this.setState({ isLoadingPayment: true });
              
              this.TaskListServices.addEditMembershipTaskAndSendPaymentLink(
                request
              ).then((response) => {
                console.log("addEditMembershipTaskAndSendPaymentLink", response);
                if (
                  response.statusCode === 200 &&
                  response.responseItem != null
                ) {
                  console.log(" For Batch job -: ", response)
               
                  
                  this.setState({ setPaymentLink: true }, () =>
                    this.setState({ setAddeditModalOpen: false })
                  );
                } else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isLoadingPayment: false });
              });
            }
          }
        });
    } else {
      this.swalServices.Error("Please accept application fee.");
    }
  };

  // addMembershipBatchJobRecordsAfterPayment = (req) => {
  //   this.batchJobService.addMembershipBatchJobRecordsAfterPayment(req).then((response) => {
  //     if (response.statusCode === 200 && response.responseItem.responseContent != null) {
  //       let res = response;
  //     } else {
  //       this.swalServices.Error(response.message);
  //     }
  //   });
  // };
  // sendInvoiceEmailToCorporateMember = () => {
  //   let isValidInput = this.isValidateAllFieldsForInvoice();
  //   if (isValidInput) {
  //     this.setState({ isLoadingInvoice: true });
  //     var pricingList = [...this.state.systemCalculatedTable];
  //     var request = { ...this.state.taskDetails };
  //     var priceObj = pricingList.find(
  //       (price) => price.year === request.membershipTenureId
  //     );
  //     request.year = priceObj.year;
  //     request.entranceFee = priceObj.entranceFee;
  //     let validationYear = ""
  //     if (request.membershipTypeId === 3) {
  //       request.annualFee = 0;
  //       if (request.subscriptionEndDate && request.subscriptionStartDate) {
  //         if (!isNaN(Date.parse(request.subscriptionStartDate)) && !isNaN(Date.parse(request.subscriptionEndDate))) {
  //           let sDate = new Date(request.subscriptionStartDate);
  //           let eDate = new Date(request.subscriptionEndDate);
  //           yearDifference = eDate.getFullYear() - sDate.getFullYear();
  //           yearDifference = yearDifference === 0 ? 1 : yearDifference;
  //           validationYear = yearDifference;
  //           pricingList.forEach((priceObj, PriceIndex) => {
  //             if (priceObj.year > yearDifference && priceObj.year <= request.membershipTenureId) {
  //               request.annualFee = request.annualFee + priceObj.annualFee;
  //             }
  //           });
  //         }
  //       }
  //       else {
  //         pricingList.forEach((priceObj, PriceIndex) => {
  //           if (priceObj.year <= request.membershipTenureId) {
  //             request.annualFee = request.annualFee + priceObj.annualFee;
  //           }
  //         });
  //       }
  //     }
  //     else {
  //       request.annualFee = priceObj.annualFee;
  //     }
  //     //request.annualFee = priceObj.annualFee;
  //     request.includedChapter = priceObj.includedChapter;
  //     request.additionalChapter = request.numberOfChapter;
  //     request.newChapterPrice = priceObj.newChapterPrice;
  //     request.tableName = priceObj.tableName;
  //     request.additionalChapterPrice = priceObj.newChapterPrice;
  //     request.firstName = this.state.taskDetails.firstName;
  //     request.lastName = this.state.taskDetails.lastName;
  //     request.companyName = this.state.taskDetails.companyName;
  //     request.email = this.state.taskDetails.email;
  //     request.commentsToMember = this.state.taskDetails.commentsToMember;
  //     request.additionalPromotion = this.state.taskDetails.additionalPromotion;
  //     request.internalNotes = this.state.taskDetails.internalNotes;

  //     // if (request.selectedDesignation && request.selectedDesignation.length > 0) {
  //     //   var arr1 = request.selectedDesignation.map(function (item) {
  //     //     delete item.bad;
  //     //     return item.label;
  //     //   });
  //     //   request.designation = arr1.join([',']);
  //     // } else {
  //     //   request.designation = "";
  //     // }
  //     if (request.year > validationYear) {
  //       this.TaskListServices.sendInvoiceEmailToCorporateMember(request).then(
  //         (response) => {
  //           if (response.statusCode === 200 && response.responseItem != null) {
  //             // this.swalServices.Success(SuccessMessage.Send_Success.replace("{0}", "Invoice"), () => this.setState({ setAddeditModalOpen: false }));
  //             this.setState({ sendInvoice: true }, () =>
  //               this.setState({ setAddeditModalOpen: false })
  //             );
  //           } else {
  //             this.swalServices.Error(response.message);
  //           }
  //           this.setState({ isLoadingInvoice: false });
  //         }
  //       );
  //     }
  //     else {
  //       this.swalServices.Error("Can't select year of membership less than the purchased year");
  //       this.setState({ sendQuotation: true }, () =>
  //         this.setState({ setAddeditModalOpen: false })
  //       );
  //     }

  //   }
  // };

  sendQuotationEmailToCorporateMember = () => {
    let isValidInput = this.isValidateAllFieldsForInvoice();
    if (isValidInput) {
      this.setState({ isLoadingQuotation: true });
      var pricingList = [...this.state.systemCalculatedTable];
      var request = { ...this.state.taskDetails };
      var priceObj = pricingList.find(
        (price) => price.year === request.membershipTenureId
      );
      request.year = priceObj.year;
      request.entranceFee = priceObj.entranceFee;
      let yearDifference = '';
      let validationYear = ""
      let diff = "";
      if (request.membershipTypeId === 3) {
        request.annualFee = 0;
        if (request.subscriptionEndDate && request.subscriptionStartDate) {
          if (!isNaN(Date.parse(request.subscriptionStartDate)) && !isNaN(Date.parse(request.subscriptionEndDate))) {
            let sDate = new Date(request.subscriptionStartDate);
            let eDate = new Date(request.subscriptionEndDate);
            yearDifference = eDate.getFullYear() - sDate.getFullYear();
            yearDifference = yearDifference === 0 ? 1 : yearDifference;
            validationYear = yearDifference
            // Count no. of days
            let inCorDate = new Date(request.incorporationDate);
            let Difference_In_Time = eDate.getTime() - inCorDate.getTime();
            let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            let Difference_In_Years = Difference_In_Days / 365;
            diff = Math.floor(Difference_In_Years);
            // diff = eDate.getFullYear() - inCorDate.getFullYear();
            pricingList.forEach((priceObj, PriceIndex) => {
              if (priceObj.year > yearDifference && priceObj.year <= request.membershipTenureId) {
                request.annualFee = request.annualFee + priceObj.annualFee;
              }
            });
          }
        }
        else {
          pricingList.forEach((priceObj, PriceIndex) => {
            if (priceObj.year <= request.membershipTenureId) {
              request.annualFee = request.annualFee + priceObj.annualFee;
            }
          });
        }
        if (request.membershipTypeId === 3) {
          if (request.subscriptionEndDate === null && request.subscriptionStartDate === null) {
            let todayDate = new Date();
            let inCorDate = new Date(request.incorporationDate);
            // diff = todayDate.getFullYear() - inCorDate.getFullYear();
            let Difference_In_Time = todayDate.getTime() - inCorDate.getTime();
            let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            let Difference_In_Years = Difference_In_Days / 365;
            diff = Math.floor(Difference_In_Years);
          }
        }
      }
      else {
        request.annualFee = priceObj.annualFee;
      }
      request.includedChapter = priceObj.includedChapter;
      request.additionalChapter = request.numberOfChapter;
      request.newChapterPrice = priceObj.newChapterPrice * request.year;
      request.tableName = priceObj.tableName;
      request.additionalChapterPrice = priceObj.newChapterPrice;
      request.firstName = this.state.taskDetails.firstName;
      request.lastName = this.state.taskDetails.lastName;
      request.companyName = this.state.taskDetails.companyName;
      request.email = this.state.taskDetails.email;
      request.commentsToMember = this.state.taskDetails.commentsToMember;
      request.additionalPromotion = this.state.taskDetails.additionalPromotion;
      request.internalNotes = this.state.taskDetails.internalNotes;

      request.startMembershipOn = this.state.taskDetails.startMembership ? this.state.taskDetails.startMembershipOn : null;
      // request.startMembership = this.state.taskDetails.startMembership ? true : false;
      request.corporateMemberId = this.state.taskDetails.corporateMemberId;
      request.taskId = this.state.taskDetails.taskId;

      request.pricingList = this.state.systemCalculatedTable.concat(
        this.state.percentageDiscountTable,
        this.state.basePriceTable
      );

      // if (request.selectedDesignation && request.selectedDesignation.length > 0) {
      //   var arr1 = request.selectedDesignation.map(function (item) {
      //     delete item.bad;
      //     return item.label;
      //   });
      //   request.designation = arr1.join([',']);
      // } else {
      //   request.designation = "";
      // }
      if (request.year > diff && diff >= 0) {
        if (request.year > validationYear) {
          this.TaskListServices.sendQuotationEmailToCorporateMember(request).then(
            (response) => {
              // if (xyz > request.year && request.year ) {
              if (response.statusCode === 200 && response.responseItem != null) {
                let reset = this.state.taskDetails;
                reset.commentsToMemberInv = "";
                reset.internalNotesInv = "";
                this.setState({ taskDetails: reset });
                // this.swalServices.Success(SuccessMessage.Send_Success.replace("{0}", "Quotation"), () => this.setState({ setAddeditModalOpen: false }));
                this.setState({ sendQuotation: true }, () =>
                  this.setState({ setAddeditModalOpen: false })
                );
              } else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isLoadingQuotation: false });
            }
          );
        }
        else {
          this.swalServices.Error("Can't select year of membership less than the purchased year");
          this.setState({ isLoadingQuotation: false });
        }
      }
      else {
        this.swalServices.Error("Cant select membership years less than the incorporation date");
        this.setState({ isLoadingQuotation: false });
      }

    }

  };

  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.taskDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.taskDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  validateFieldForInvoice = (key) => {
    const newValidState = validate(
      key,
      this.state.taskDetails,
      this.state.invoiceValidation,
      this.state.validStateForInvoice
    );
    this.setState({ validStateForInvoice: newValidState });
  };

  isValidateAllFieldsForInvoice = () => {
    const newValidState = isValidForm(
      this.state.taskDetails,
      this.state.invoiceValidation,
      this.state.validStateForInvoice
    );
    this.setState({ validStateForInvoice: newValidState });
    return newValidState.isValid;
  };

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getAllTaskList(newPagination);
  };

  setFilterParameters = (id, drpIdentity) => {
    let detail = this.state.taskDetails;
    if (drpIdentity === "billingCategories") {
      detail["billingCategoryId"] = id;
      //this.validateField("resourceTypeId");
    } else if (drpIdentity === "updatedBy") {
      detail["updatedBy"] = id;
    } else if (drpIdentity === "membershipTypes") {
      detail["membershipTypeId"] = id === 0 ? 1 : id;
      this.getPriceTabelForTaskListProcess();
      this.validateField("membershipTypeId");
    } else if (drpIdentity === "membershipRevenue") {
      detail["membershipRevenueId"] = id === 0 ? 1 : id;
      this.getPriceTabelForTaskListProcess();
      this.validateField("membershipRevenueId");
    } else if (drpIdentity === "transactionTypes") {
      detail["transactionTypeId"] = id === 0 ? 1 : id;
      this.getPriceTabelForTaskListProcess();
      this.validateField("transactionTypeId");
    } else if (drpIdentity === "membershipTenure") {
      detail["membershipTenureId"] = id;
      this.validateFieldForInvoice("membershipTenureId");
    } else if (drpIdentity === "chapters") {
      if (id !== 0 || id < 0) {
        detail["numberOfChapter"] = id - 1;
        this.validateFieldForInvoice("numberOfChapter");
      } else {
        detail["numberOfChapter"] = id - 1;
        this.validateFieldForInvoice("numberOfChapter");
      }

    }
    this.setState({ taskDetails: { ...detail } });
  };

  setFilterParametersForList = (id, drpIdentity) => {
    let detail = this.state.pagination;
    if (drpIdentity === "sourceFilter") {
      detail["filterBySource"] = '';
      if (id === 1) {
        detail["filterBySource"] = 'Admin';
      } else if (id === 2) {
        detail["filterBySource"] = 'Web';
      }
    }
    detail["filterBySourceId"] = id;
    this.setState({ pagination: { ...detail } });
    this.getAllTaskList();
  };

  searchByName = (value) => {
    let detail = this.state.pagination;
    detail.searchText = "";
    this.setState({ pagination: { ...detail } });
    if (value.length > 2) {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getAllTaskList();
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.pagination;
    let fulldate = (detail[name] = value === "" ? null : value);
    let date = new Date(fulldate); //1/1/1753 12:00:00 AM and 12/31/9999 11:59:59 PM
    let startLimit = new Date("1/1/1753 12:00:00 AM");
    let endLimit = new Date("12/31/9999 11:59:59 PM");
    detail[name] = value;
    this.setState({ pagination: { ...detail } }, () => {
      if (startLimit < date && date < endLimit) {
        this.getAllTaskList();
      } else if (fulldate === null) {
        this.getAllTaskList();
      }
    });
  }

  handleChangeInPopup(event) {
    const value = event.target.value.toUpperCase();
    const name = event.target.name;
    let detail = this.state.taskDetails;
    detail[name] = value;
    this.setState({ taskDetails: { ...detail } });
  }

  handleChangeInPriceTable(value, name, year, tableName) {
    var table = this.state.percentageDiscountTable;
    var allowable = this.state.allowableDiscountTable;
    //warning//var allowableValue = Number(allowable[year][name]);
    var approvalStatus = [];
    value = isNaN(value) ? 0 : Number(value);
    if (value > 100) {
      value = 100;
    }
    // if (tableName === "PercentageDiscountTable") {
    table[year][name] = value;
    if (table[year]["annualFee"] > allowable[year]["annualFee"]) {
      approvalStatus.push("Required Approval");
    } else {
      approvalStatus.push("Ok");
    }
    if (table[year]["entranceFee"] > allowable[year]["entranceFee"]) {
      approvalStatus.push("Required Approval");
    } else {
      approvalStatus.push("Ok");
    }
    if (table[year]["includedChapter"] > allowable[year]["includedChapter"]) {
      approvalStatus.push("Required Approval");
    } else {
      approvalStatus.push("Ok");
    }
    if (table[year]["newChapterPrice"] > allowable[year]["newChapterPrice"]) {
      approvalStatus.push("Required Approval");
    } else {
      approvalStatus.push("Ok");
    }
    if (approvalStatus.indexOf("Required Approval") > -1) {
      table[year]["status"] = "Required Approval";
    } else {
      table[year]["status"] = "Ok";
    }
    this.setState({ percentageDiscountTable: table }, () =>
      this.getCalculatedFinalPrice()
    );
  }

  onDropDownChange = (index) => {
    this.setState({ setDropdownOpen: true });
    this.setState({ setDropdownIndex: index });
  };

  handleChangeCheckBox = (e) => {
    const { checked } = e.target;

    let details = this.state.taskDetails;
    if (checked) {
      details.quotationNeeded = true;
    } else {
      details.quotationNeeded = false;
    }
    this.setState({ taskDetails: details });
  };

  setSendClarificationsFlag = () => {
    this.setState({ setSendClarifications: true });
  }

  rejectMemberApplication = (flag) => {
    let request = {}
    if ((this.state.sendRejectionReason !== null && this.state.sendRejectionReason !== "") || flag === 'UnReject') {
      request.corporateMemberId = this.state.taskDetails.corporateMemberId;
      request.sendRejectionReason = this.state.sendRejectionReason;
      if (flag === 'Rejected') {
        request.isRejected = true;
      } else if (flag === 'UnReject') {
        request.isRejected = false;
      }
      this.setState({ isLoading: true }, () => {
        // this.swalServices.Confirm(CommonSuccessMessages.Confirm_QuestionOfReject.replace("{0}", "Member"), CommonSuccessMessages.Confirm_Reject.replace("{0}", "Member"),
        //   CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        //     if (response) {
        this.TaskListServices.rejectMemberApplication(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.setState({ setAddeditModalOpen: false, setRejectionReason: false, sendRejectionReason: "" }, () => {
              this.getAllTaskList();
              this.swalServices.Success(`Corporate Member ${flag} successfully`);
            })
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        })
        //}
        // });
        this.setState({ isLoading: false });
      });
    } else {
      this.setState({ isLoading: false });
      this.swalServices.Error("Please enter detail")
    }
  }

  setSendRejectionReasonFlag = () => {
    this.setState({ setRejectionReason: true });
  }

  // unRejectMemberApplication = () => {
  //   let request = {}
  //   request.corporateMemberId = this.state.taskDetails.corporateMemberId;
  //   this.setState({ isLoading: true }, () => {
  //     this.swalServices.Confirm(CommonSuccessMessages.Confirm_QuestionOfReject.replace("{0}", "Member"), CommonSuccessMessages.Confirm_Reject.replace("{0}", "Member"),
  //       CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
  //         if (response) {
  //           this.TaskListServices.unRejectMemberApplication(request).then((response) => {
  //             if (response.statusCode === 200 && response.responseItem != null) {
  //               this.setState({ setAddeditModalOpen: false })
  //               this.swalServices.Success("Corporate Member Rejected successfully");
  //               this.getAllTaskList();
  //             } else {
  //               this.swalServices.Error(response.message);
  //             }
  //             this.setState({ isLoading: false });
  //           })
  //         }
  //       });
  //     this.setState({ isLoading: false });
  //   });
  //}

  handleChangeMembershipOnCheckBox = (e) => {
    const {  checked } = e.target;

    let details = this.state.taskDetails;
    if (checked) {
      details.startMembership = true;
    } else {
      details.startMembership = false;
    }
    this.setState({ taskDetails: details });
    console.log("details.startMembership :", details.startMembership);
  };

  selectTask = (value, e) => {
    e.stopPropagation();
    if (value > 0) {
      this.getTaskByCompanyId(value);
      // this.getPriceTabelByCorporateMemberId(value);
      // this.getPriceTabelForTaskListProcess();
    }
  };
  // handleChangeMultiDropdown(selected, identity) {
  //   var profile = this.state.taskDetails;
  //   if (identity === "Designation_TaskList") {
  //     profile.selectedDesignation = selected;

  //     this.setState({ taskDetails: profile });

  //   }
  // }

  handleRadioButtons = (event, identity, applicationFees, membershipFees) => {
  
    if (identity === "membershipType") {
      // let value = event.target.value;
      //let checked = event.target.value;
      let detail = this.state.taskDetails;
      let corporatePricingDetails = this.state.corporatePricingDetails;
      corporatePricingDetails.selectedMembershipType = event.target.value;
      corporatePricingDetails.applicationFee = applicationFees;
      corporatePricingDetails.annualRevenueFee = membershipFees;
      if (event.target.value === 'Ordinary Member') {
        detail.membershipTypeId = 1;
      } else if (event.target.value === 'Associate Member') {
        detail.membershipTypeId = 2;
      } else if (event.target.value === 'Trade Member') {
        detail.membershipTypeId = 3;
      }
      this.setState({ corporatePricingDetails: { ...corporatePricingDetails }, taskDetails: detail });
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ setDropdownOpen: false });
    }
  }

  actionClick = (index, value, option, event) => {
    var id = this.state.taskList[index].corporateMemberId;
    this.setState({ selectedTaskId: this.state.taskList[index].taskId }, () => {
      this.selectTask(id, event);
    });

    // if(value && value > 0){
    //     this.getTaskByCompanyId(value);
    // }
  };

  actions = (element, index, value) => {
    return element !== "corporateMemberId" ? null : (
      <>
        <ActionContainer>
          <DropdownAction
            key={index}
            value={value}
            id={index}
            index={index}
            options={this.state.actionDropdown}
            align="right"
            onOptionClick={this.actionClick.bind(this)}
          />
        </ActionContainer>
      </>
    );
  };

  onProcessModalClose = (e) => {
    e.stopPropagation();
    this.setState({ setAddeditModalOpen: false });
  };

  handleChangeClarificationDetails = (event) => {
    const value = event.target.value;
    //const name = event.target.name;
    this.setState({ clarification: value }, () => console.log("Clarification Value :", this.state.clarification));

  };

  getClarificationDetails = () => {

    let request = {
      corporateMemberId: this.state.taskDetails.corporateMemberId,
      clarificationDetailse: this.state.clarification,
    };
    this.TaskListServices.getClarificationDetails(request).then((response) => {
      console.log("My Response :", response);
      if (response.statusCode === 200 && response.responseItem != null) {
        // this.swalServices.Success("Success");
        // this.setState({
        //   slotsPricingTable: response.responseItem.responseContent,
        // });
        this.swalServices.Success("Clarification mail sent successfully");
        this.setState({ setSendClarifications: false })
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  GetOrganisationBasicInformationById = () => {
    this.TaskListServices.getOrganisationBasicInformationById(this.state.taskDetails.corporateMemberId).then((response) => {
      console.log("My Response :", response);
      if (response.statusCode === 200 && response.responseItem != null) {
         console.log("orgranisation detail: ",response.responseItem);
         if (response.responseItem.responseContent != null) {
          let res = { ...this.state.organizationBasicInformation };
          res.organisationInformationId = response.responseItem.responseContent.organisationInformationId;
          res.companyUEN = response.responseItem.responseContent.companyUEN;
          res.companyName = response.responseItem.responseContent.companyName;
          res.chinesCompanyName = response.responseItem.responseContent.chineseCompanyName;
          res.incorporationDate = response.responseItem.responseContent.incorporationDate;
          res.firstName = response.responseItem.responseContent.representativeFirstName;
          res.lastName = response.responseItem.responseContent.representativeLastName;
          res.email = response.responseItem.responseContent.businessEmail;
          res.businessEmail1 = response.responseItem.responseContent.businessEmail1;
          res.contactNo = response.responseItem.responseContent.businessContactNumber;
          res.mrMrsId = response.responseItem.responseContent.mrMrsId;
          res.companyAddress = response.responseItem.responseContent.companyAddress;
          res.companyAddress2 = response.responseItem.responseContent.companyAddress2;
          res.companyAddress3 = response.responseItem.responseContent.companyAddress3;
          // res.companyActivity = response.responseItem.responseContent.companyActivity; 
          res.countryId = response.responseItem.responseContent.countryId;
          res.postalCode = response.responseItem.responseContent.postalCode;
          res.membershipRevenueId = response.responseItem.responseContent.membershipRevenueId;
          res.aCRACertificatePath = response.responseItem.responseContent.acraCertificatePath;
          res.projectTrackRecordReportPath = response.responseItem.responseContent.projectTrackRecordReportPath;
          res.jobTitle = response.responseItem.responseContent.designation;
          res.companyDescription = response.responseItem.responseContent.companyDescription;
          res.isCompanyRegisterWithBCA = response.responseItem.responseContent.isCompanyRegisterWithBCA;
          let projectFrom = response.responseItem.responseContent.publicProjectFrom;
          if (response.responseItem.responseContent.companyActivity) {
            let array = (response.responseItem.responseContent.companyActivity !== null || response.responseItem.responseContent.companyActivity !== "") ? response.responseItem.responseContent.companyActivity.split(',') : [];
            res.companyActivity = [];
            array.forEach(topic => {
              res.companyActivity.push(this.state.companyActivity.find(topics => topics.value === Number(topic)));
            });
          }

          let publicProjectFormObj = this.state.publicProjectsForms;

          projectFrom.forEach(element => {
            //let obj = publicProjectFormObj.filter(s => s.value === element.publicProjectformId);
            if (element.isOther) {
              publicProjectFormObj.find(s => s.value === element.publicProjectformId).isChecked = true;
              publicProjectFormObj.find(s => s.value === element.publicProjectformId).isOthers = true;
              publicProjectFormObj.find(s => s.value === element.publicProjectformId).publicProjectformMappingId = element.publicProjectformMappingId;
              res.otherProjectForm = element.publicProjectformName;


            } else {
              // obj.isChecked = true
              publicProjectFormObj.find(s => s.value === element.publicProjectformId).isChecked = true;
              publicProjectFormObj.find(s => s.value === element.publicProjectformId).publicProjectformMappingId = element.publicProjectformMappingId;
            }
            this.setState({ publicProjectsForms: [...publicProjectFormObj] });
          })

          res.projectTypeId = response.responseItem.responseContent.projectTypeId;
          res.fullTimeStaffLocalId = response.responseItem.responseContent.fullTimeStaffLocalId;
          res.fullTimeStaffForeignersId = response.responseItem.responseContent.fullTimeStaffForeignersId;
          res.knowAboutUsId = response.responseItem.responseContent.knowAboutUsId;
          res.hearAboutIsOther = response.responseItem.responseContent.hearAboutIsOther;
          if (res.hearAboutIsOther === true) {
            res.hearAboutOther = response.responseItem.responseContent.hearAboutOther;
          } else {
            res.hearAboutOther = "";
          }
          this.setState({ organizationBasicInformation: res }, () => {
            this.GetCompanyRegisteredWithBCAList();
          });
        }
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  GetCompanyRegisteredWithBCAList = () => {
    this.TaskListServices.getCompanyRegisteredWithBCAList(this.state.taskDetails.corporateMemberId).then((response) => {
      console.log("My Response : ", response);
      if (response.statusCode === 200 && response.responseItem != null) {
        var newList = response.responseItem.responseContent;

        newList = newList.map((p) => ({
          mainGroup: p.scopeMainGroupName,
          workhead: p.scopeWorkheadName,
          grade: p.scopeGradeName,
          companyScopeMappingId: p.companyScopeMappingId,
          corporateMemberId: 0,
          scopeMainGroupId: 0,
          scopeWorkheadId: 0,
          scopeGradeId: 0,
        }));
        // this.setState({ companyRegisteredWithBCAList: response.responseItem.responseContent });
        this.setState({ companyRegisteredWithBCAList: newList });
        let data = this.state.organizationBasicInformation;
        data.companyScopeList = newList;
        this.setState({ organizationBasicInformation: data });
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  getAllDropdownForCorporateMember = () => {
    this.TaskListServices.getAllDropdownForCorporateMember().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {

        var status = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "AccountStatus"
        );

       // var recordType = response.responseItem.responseContent.filter(
         // (drp) => drp.dropdownName === "RecordType"
       // );

        //var billingCategory = response.responseItem.responseContent.filter(
        //  (drp) => drp.dropdownName === "BillingCategory"
       // );

        var domain = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Domains"
        );

        var organisationRoles = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "OrganisationRoles"
        );
        var membershipTypes = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "MembershipTypes"
        );

       // var countriesSingleDrp = response.responseItem.responseContent.filter(
        //  (drp) => drp.dropdownName === "Countries"
       // );


       // var companyEmploymentSize = response.responseItem.responseContent.filter(
        //  (drp) => drp.dropdownName === "CompanyEmploymentSize"
       // );

        var getToKnowUsArr = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "GetToKnowUs")


        var domainArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Domains"
        );
        domainArr.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        domainArr = domainArr.map((p) => ({
          id: p.id,
          name: "@" + p.name,
          
        }));


        organisationRoles.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        organisationRoles = organisationRoles.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        var matching = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Matching"
          
        );
        matching.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        matching = matching.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        var mrMrs = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "MrMrs"
        );

        var countries = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Countries"
        );
        countries.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        countries = countries.map((p) => ({ value: p.id, label: p.name }));

        var designation = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Designation");
        designation.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        designation = designation.map((p) => ({ value: p.id, label: p.name }));

        var companyActivity = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "MainActivitiesOfCompany");
        companyActivity.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        companyActivity = companyActivity.map((p) => ({ value: p.id, label: p.name }));

        var ScopeMainGroup = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "ScopeMainGroup");
        ScopeMainGroup.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        ScopeMainGroup = ScopeMainGroup.map((p) => ({ value: p.id, label: p.name }));

        var ProjectTypeList = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "ProjectTypeList");
        ProjectTypeList.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        ProjectTypeList = ProjectTypeList.map((p) => ({ value: p.id, label: p.name }));

        var LocalOrPRFullTimeStaffEmployeedList = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "LocalOrPRFullTimeStaffEmployeedList");
        LocalOrPRFullTimeStaffEmployeedList.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        LocalOrPRFullTimeStaffEmployeedList = LocalOrPRFullTimeStaffEmployeedList.map((p) => ({ value: p.id, label: p.name }));

        var ForeignersFullTimeStaffEmployeedList = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "ForeignersFullTimeStaffEmployeedList");
        ForeignersFullTimeStaffEmployeedList.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        ForeignersFullTimeStaffEmployeedList = ForeignersFullTimeStaffEmployeedList.map((p) => ({ value: p.id, label: p.name }));

        var CorporateGetToKnowUs = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "CorporateGetToKnowUs");
        CorporateGetToKnowUs.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        CorporateGetToKnowUs = CorporateGetToKnowUs.map((p) => ({ value: p.id, label: p.name, isOthers: false }));

        var PublicProjectsfromlist = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "PublicProjectsfromlist");
        PublicProjectsfromlist.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        PublicProjectsfromlist = PublicProjectsfromlist.map((p) => ({ value: p.id, label: p.name, isChecked: false, isOthers: false }));

        var annualTurnoverList = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "AnnualTurnOver");
        annualTurnoverList.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        annualTurnoverList = annualTurnoverList.map((p) => ({ value: p.id, label: p.name, isChecked: false, isOthers: false }));

        var ScopeWorkhead = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "ScopeWorkhead");
        ScopeWorkhead.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        ScopeWorkhead = ScopeWorkhead.map((p) => ({ value: p.id, label: p.name }))

        var gradeList = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "ScopeGrade");
        gradeList.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        gradeList = gradeList.map((p) => ({ value: p.id, label: p.name }));

        var EngagementType = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "EngagementType");
        EngagementType.map(function (item) {
          delete item["dropdownName"];
          return item;
        });

        var _By = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "By");
        _By.map(function (item) {
          delete item["dropdownName"];
          return item;
        });

        var ReasonForLeaving = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "ReasonForLeaving");
        ReasonForLeaving.map(function (item) {
          delete item["dropdownName"];
          return item;
        });

        this.setState({
          status: status,
          mrMrs: mrMrs,
          countriesWithPresence: countries,
          organizationRoleArray: organisationRoles,
          membershipTypes: membershipTypes,
          getToKnowUsArr: getToKnowUsArr,
          designationList: designation,
          countryList: countries,
          companyActivity: companyActivity,
          mainGroupList: ScopeMainGroup,
          typeOfProjects: ProjectTypeList,
          fulltimeEmployeeLocal: LocalOrPRFullTimeStaffEmployeedList,
          fullTimeEmployeeForeigners: ForeignersFullTimeStaffEmployeedList,
          knowAboutusList: CorporateGetToKnowUs,
          publicProjectsForms: PublicProjectsfromlist,
          annualTurnoverList: annualTurnoverList,
          engagementType: EngagementType, _by: _By,
          reasonOfLeaving: ReasonForLeaving,
        }, () => {
          if (this.state.corporateMemberId > 0) {
            this.getSIISCorporateAccountInformationById(this.state.corporateMemberId);

            this.setState({ emailDomain: domain }, () =>
              this.getDomainByOrganisationId(this.state.corporateMemberId)
            );
            this.setState({ domainArr: domainArr }, () =>
              this.getDomainByOrganisationId(this.state.corporateMemberId)
            );
          }
        });
      } else {
        if (this.state.corporateMemberId > 0) {
          this.getSIISCorporateAccountInformationById(this.state.corporateMemberId);
          this.setState({ emailDomain: domain }, () =>
            this.getDomainByOrganisationId(this.state.corporateMemberId)
          );
          this.setState({ domainArr: domainArr }, () =>
            this.getDomainByOrganisationId(this.state.corporateMemberId)
          );
        }
        //this.swalServices.Error(response.message);
      }
    });
  };

  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <>
        <div className="main-body-section">
          <div className="bg-white px-8 pt-10 pb-12">
            <div className="grid grid-cols-12 gap-6 w-full items-center">
              <div className="2xl:col-span-7 lg:col-span-7 col-span-12 pr-2">
                <h2 className="text-xl text-[#000000] font-bold mb-1">
                  Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="grid grid-cols-12 gap-6 items-center w-full">
                  <div className="2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                    <input
                      id="default"
                      name="dateFrom"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      type="date"
                      value={this.state.pagination.dateFrom}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                  <div className="2xl:col-span-2 lg:col-span-2 col-span-12 w-full text-center">
                    <h2 className="text-xl theme-color">To</h2>
                  </div>
                  <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                    <input
                      id="default"
                      name="dateTo"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      type="date"
                      value={this.state.pagination.dateTo}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="2xl:col-span-1 lg:col-span-1 col-span-12 pt-8 text-center">
                <div className="border-l-[1px] border-[#000000] h-[48px] inline-block mx-auto"></div>
              </div>
              <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                <h2 className="text-xl text-[#000000] font-bold mb-1">
                  Source
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>

                <div className="">
                  <DropdownSelect
                    drpIdentity={"sourceFilter"}
                    optionArray={this.state.sourceList}
                    setFilterParameters={this.setFilterParametersForList.bind(this)}
                    value={this.state.pagination.filterBySourceId}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-6 gap-4 pt-10 px-8">
            <h2 className="col-start-1 col-end-3 text-3xl font-bold text-[#000000]">
              {`Total:${this.state.totalResultes}`}
            </h2>
            <div className="col-end-7 col-span-3 grid grid-cols-3 items-center">
              <caption className="text-[#000000] text-xl text-right pr-5">Search by Company </caption>
              <input
                id="default"
                className="col-span-2 text-lg font-bold h-[55px] w-full text-[#000000] border-white"
                type="text"
                onChange={(e) => this.searchByName(e.target.value)}
              />
            </div>
          </div>
          <main className="pb-10">
            <div className="w-full mx-auto">
              <div className="relative">
                <div>
                  <div className=" mt-6">
                    <Table
                      columns={[
                        {
                          name: "registrationDate",
                          title: "Registration Date",
                        },
                        { name: "FullName", title: "Name" },
                        { name: "Email", title: "Email Address" },
                        { name: "CompanyName", title: "Company Name" },
                        // { name: "Industry", title: "Industry" },
                        { name: "CompanyUEN", title: "Company UEN" },
                        { name: "source", title: "Source" },
                        { name: "StatusName", title: "Status" },
                        { name: "corporateMemberId", title: "Action" },
                      ]}
                      pagination={this.state.pagination}
                      rows={this.state.taskList}
                      sortingColumns={["source", "registrationDate"]}
                      isLoading={this.state.isLoading}
                      totalResultes={this.state.totalResultes}
                      totalCount={this.state.totalResultes}
                      setPagination={this.setPagination.bind(this)}
                      customScope={[
                        {
                          column: "corporateMemberId",
                          renderTableData: this.actions.bind(this),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div className="member-task-process">
          <MembershipTaskListProcess
            handleChangeCheckBox={this.handleChangeCheckBox.bind(this)}
            handleChangeMembershipOnCheckBox={this.handleChangeMembershipOnCheckBox.bind(this)}
            setAddeditModalOpen={this.state.setAddeditModalOpen}
            membershipTypes={this.state.membershipTypes}
            transactionTypes={this.state.transactionTypes}
            billingCategories={this.state.billingCategories}
            membershipRevenueList={this.state.membershipRevenueList}
            sourceList={this.state.sourceList}
            users={this.state.users}
            tenureArr={this.state.tenureList}
            numberOfChapters={this.state.numberOfChapters}
            taskDetails={this.state.taskDetails}
            basePriceTable={this.state.basePriceTable}
            allowableDiscountTable={this.state.allowableDiscountTable}
            percentageDiscountTable={this.state.percentageDiscountTable}
            systemCalculatedTable={this.state.systemCalculatedTable}
            //disablePaymentButton={this.state.disablePaymentButton}
            disableSubscription={this.state.disableSubscription}
            validState={this.state.validState}
            validStateForInvoice={this.state.validStateForInvoice}
            setModalOpen={() => this.setState({ setAddeditModalOpen: false })}
            handleChange={this.handleChangeInPopup.bind(this)}
            handleChangeInPriceTable={this.handleChangeInPriceTable.bind(this)}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ setAddeditModalOpen: false });
            }}
            validateField={this.validateField.bind(this)}
            validateFieldForInvoice={this.validateFieldForInvoice.bind(this)}
            setFilterParameters={this.setFilterParameters.bind(this)}
            addEditMembershipTask={this.addEditMembershipTask.bind(this)}
            addEditMembershipTaskAndSendPaymentLink={this.addEditMembershipTaskAndSendPaymentLink.bind(
              this
            )}
            // sendInvoiceEmailToCorporateMember={this.sendInvoiceEmailToCorporateMember.bind(
            //   this
            // )}
            sendQuotationEmailToCorporateMember={this.sendQuotationEmailToCorporateMember.bind(
              this
            )}
            //sendEscalateMailToMember={this.sendEscalateMailToMember.bind(this)}
            closeModalOnBack={() => {
              this.setState({ setAddeditModalOpen: false });
            }}
            isLoading={this.state.isLoading}
            isLoadingApprove={this.state.isLoadingApprove}
            isLoadingPayment={this.state.isLoadingPayment}
            isLoadingQuotation={this.state.isLoadingQuotation}
            isLoadingInvoice={this.state.isLoadingInvoice}
            isEscalateLodaing={this.state.isEscalateLodaing}
            //handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
            designationList={this.state.designationList}
            // 
            handleRadioButtons={this.handleRadioButtons.bind(this)}
            corporatePricingDetails={this.state.corporatePricingDetails}
            setSendClarificationsFlag={this.setSendClarificationsFlag.bind(this)}
            rejectMemberApplication={this.setSendRejectionReasonFlag.bind(this)}
            UnrejectMemberApplication={this.rejectMemberApplication.bind(this)}
            //unRejectMemberApplication={this.unRejectMemberApplication.bind(this)}

            countryList={this.state.countryList}
            companyMainActivityList={this.state.companyMainActivityList}
            typesOfProject={this.state.typesOfProject}
            publicprojectsForm={this.state.publicprojectsForm}
            fullStaffEmployeeLocal={this.state.fullStaffEmployeeLocal}
            fullStaffEmployeeForeginer={this.state.fullStaffEmployeeForeginer}
            hearAboutUsList={this.state.hearAboutUsList}
            annualTurnoverList={this.state.annualTurnoverList}
            GetOrganisationBasicInformationById = {this.GetOrganisationBasicInformationById.bind(this)}
            GetCompanyRegisteredWithBCAList = {this.GetCompanyRegisteredWithBCAList.bind(this)}
            organizationBasicInformation={this.state.organizationBasicInformation}
            companyActivity={this.state.companyActivity}
            publicProjectsForms={this.state.publicProjectsForms}
            mrMrs={this.state.mrMrs}
            mainGroupList={this.state.mainGroupList}
            workheadList={this.state.workheadList}
            gradeList={this.state.gradeList}										
            typeOfProjects={this.state.typeOfProjects}
            fulltimeEmployeeLocal={this.state.fulltimeEmployeeLocal}
            fullTimeEmployeeForeigners={this.state.fullTimeEmployeeForeigners}
            knowAboutusList={this.state.knowAboutusList}
            companyRegisteredWithBCAList={this.state.companyRegisteredWithBCAList}

          />
        </div>
        <div className="membership-task-list-process-sec">
          <SendForApprove
            companyName={this.state.taskDetails.companyName}
            email={this.state.taskDetails.email}
            setSendApproveModal={this.state.setSendApprove}
            setOpenModal={() => this.setState({ setSendApprove: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ setSendApprove: false });
            }}
          />
          <div className="send-clarification-model">
            <SendClarifications
              clarificationModal={this.state.setSendClarifications}
              clarification={this.state.clarification}
              setOpenModal={() => this.setState({ setSendClarifications: false })}
              handleChangeClarificationDetails={this.handleChangeClarificationDetails.bind(this)}
              // onClose={(e) => {e.stopPropagation();
              //   this.setState({ setSendClarifications: false });
              // }}
              getClarificationDetails={this.getClarificationDetails.bind(this)}
            />
          </div>

          <SendPaymentLink
            companyName={this.state.taskDetails.companyName}
            email={this.state.taskDetails.email}
            paymentLinkModal={this.state.setPaymentLink}
            setOpenModal={() => this.setState({ setPaymentLink: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ setPaymentLink: false });
            }}
          />
          <SendQuotation
            companyName={this.state.taskDetails.companyName}
            email={this.state.taskDetails.email}
            setSendQuotationModal={this.state.sendQuotation}
            setOpenModal={() => this.setState({ sendQuotation: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ sendQuotation: false });
            }}
          />
          <SendInvoice
            companyName={this.state.taskDetails.companyName}
            email={this.state.taskDetails.email}
            setSendInvoiceModal={this.state.sendInvoice}
            setOpenModal={() => this.setState({ sendInvoice: false })}
            onClose={(e) => {

              e.stopPropagation();
              this.setState({ sendInvoice: false });
            }}
          />
          <EscalateModal
            companyName={this.state.taskDetails.companyName}
            email={this.state.taskDetails.email}
            setSendInvoiceModal={this.state.sendEscalation}
            setOpenModal={() => this.setState({ sendEscalation: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ sendEscalation: false });
            }}
          />

          <SendRejectionReason
            rejectionModal={this.state.setRejectionReason}
            sendRejectionReason={this.state.sendRejectionReason}
            isLoadingRejectAplication={this.state.isLoading}
            setOpenModal={() => this.setState({ setRejectionReason: false })}
            //handleChangeSendRejectionReasonDetails={this.handleChangeSendRejectionReasonDetails.bind(this)}
            // onClose={(e) => {e.stopPropagation();
            //   this.setState({ setSendClarifications: false });
            // }}
            onTinyEceEditorChange={(data) =>
              this.handleChangeSendRejectionReasonDetails(data)
            }
            btn_color={'btn-blue'}
            rejectSLOTSMemberApplication={this.rejectMemberApplication.bind(this)}
          />
        </div>
      </>
    );
  }
}
