export const DeleteButtonIcon = () => (
  <svg
    className="menu-svg"
    width="27"
    height="27"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 135 135"
  >
    <title>Delete</title>
    <g transform="translate(0.000000,135.000000) scale(0.100000,-0.100000)">
      <path
        d="M504 1336 c-32 -14 -64 -67 -64 -105 0 -20 -6 -21 -147 -23 -124 -3 -148 -5 -148 -18 0 -10 12 -16 32 -18 28 -3 33 -7 33 -30 0 -72 41 -1021 46 -1049 3 -19 17 -42 35 -57 l31 -26 354 0 c386 0 398 2 422 56 9 18 19 196 33
                      543 11 284 23 526 25 539 3 17 11 22 34 22 23 0 30 4 30 20 0 19 -7 20 -168 20 l-168 0 -11 43 c-7 23 -24 53 -38 67 -25 24 -33 25 -163 27 -100 2 -145 -1 -168 -11z m305 -43 c11 -10 24 -33 27 -50 l7 -33 -186 0 -186 0 10 33 c17 60
                      36 67 179 67 107 0 131 -3 149 -17z m305 -225 c-3 -57 -14 -300 -23 -541 -16 -390 -20 -440 -36 -457 -17 -19 -32 -20 -376 -20 l-359 0 -14 23 c-12 18 -19 120 -36 497 -11 261 -20 503 -20 538 l0 62 435 0 435 0 -6 -102z"
        fill="#000000"
      />
      <path
        d="M436 964 c-10 -25 -7 -747 3 -763 27 -42 31 8 31 390 0 326 -2 389 -14 389 -8 0 -17 -7 -20 -16z"
        fill="#000000"
      />
      <path
        d="M665 968 c-3 -7 -4 -184 -3 -393 3 -330 5 -380 18 -380 13 0 15 50 15 389 0 303 -3 390 -12 393 -7 3 -15 -1 -18 -9z"
        fill="#000000"
      />
      <path
        d="M895 968 c-3 -7 -4 -184 -3 -393 3 -330 5 -380 18 -380 13 0 15 50 15 389 0 303 -3 390 -12 393 -7 3 -15 -1 -18 -9z"
        fill="#000000"
      />
    </g>
  </svg>
);

export const EditButtonIcon = () => (
  <svg
    className="menu-svg"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 88 88"
  >
    <title>Edit</title>
    <g transform="translate(0.000000,88.000000) scale(0.100000,-0.100000)">
      <path
        d="M645 871 c-11 -5 -142 -132 -290 -282 l-271 -274 -41 -145 c-23 -80 -38 -149 -34 -153 5 -4 74 11 153 34 l146 41 282 283 c332 333 318 302 197 422 -72 72 -105 89 -142 74z m123 -105 c40 -41 72 -79 72 -85 0 -16 -90 -111
                            -104 -111 -6 0 -49 38 -96 85 l-84 85 49 50 c28 29 58 50 70 50 14 0 49 -28 93 -74z m-198 -91 l34 -36 -191 -191 -191 -191 -16 32 c-10 19 -24 31 -36 31 -11 0 -20 3 -20 7 0 9 372 383 380 383 4 0 21 -16 40 -35z m103 -103 l37 -38
                            -194 -187 c-107 -103 -197 -187 -200 -187 -3 0 -6 8 -6 19 0 11 -12 23 -30 31 -16 7 -30 15 -30 19 0 7 373 381 380 381 3 0 23 -17 43 -38z m-488 -320 c12
                            -21 36 -45 58 -57 32 -17 37 -24 35 -49 -3 -25 -9 -31 -48 -42 l-45 -14 -51 53 -51 52 14 48 c12 41 18 47 41 47 21 0 32 -8 47 -38z m-51 -171 c-3 -3 -23 -11 -45 -17 -44 -13 -45 -12 -28 50 l11 38 34 -33 c18 -18 31 -35 28 -38z"
        fill="#000000"
      />
    </g>
  </svg>
);

export const CancelButtonIcon = () => (
  <svg
    className="menu-svg"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122.88 122.88"
  >
    <title>Cancel</title>
    <path
      class="cls-1"
      d="M61.44,0A61.44,61.44,0,1,1,0,61.44,61.44,61.44,0,0,1,61.44,0Z"
      fill="#000000"
    />
    <path
      class="cls-2"
      d="M35.38,49.72c-2.16-2.13-3.9-3.47-1.19-6.1l8.74-8.53c2.77-2.8,4.39-2.66,7,0L61.68,46.86,73.39,35.15c2.14-2.17,3.47-3.91,6.1-1.2L88,42.69c2.8,2.77,2.66,4.4,0,7L76.27,61.44,88,73.21c2.65,2.58,2.79,4.21,0,7l-8.54,8.74c-2.63,2.71-4,1-6.1-1.19L61.68,76,49.9,87.81c-2.58,2.64-4.2,2.78-7,0l-8.74-8.53c-2.71-2.63-1-4,1.19-6.1L47.1,61.44,35.38,49.72Z"
      fill="#000000"
    />
  </svg>
);

export const SettingsButtonIcon = () => (
  <svg
    className="menu-svg"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 478 478"
  >
    <title>Setting</title>
    <path
      d="M454.2,189.101l-33.6-5.7c-3.5-11.3-8-22.2-13.5-32.6l19.8-27.7c8.4-11.8,7.1-27.9-3.2-38.1l-29.8-29.8
			c-5.6-5.6-13-8.7-20.9-8.7c-6.2,0-12.1,1.9-17.1,5.5l-27.8,19.8c-10.8-5.7-22.1-10.4-33.8-13.9l-5.6-33.2
			c-2.4-14.3-14.7-24.7-29.2-24.7h-42.1c-14.5,0-26.8,10.4-29.2,24.7l-5.8,34c-11.2,3.5-22.1,8.1-32.5,13.7l-27.5-19.8
			c-5-3.6-11-5.5-17.2-5.5c-7.9,0-15.4,3.1-20.9,8.7l-29.9,29.8c-10.2,10.2-11.6,26.3-3.2,38.1l20,28.1
			c-5.5,10.5-9.9,21.4-13.3,32.7l-33.2,5.6c-14.3,2.4-24.7,14.7-24.7,29.2v42.1c0,14.5,10.4,26.8,24.7,29.2l34,5.8
			c3.5,11.2,8.1,22.1,13.7,32.5l-19.7,27.4c-8.4,11.8-7.1,27.9,3.2,38.1l29.8,29.8c5.6,5.6,13,8.7,20.9,8.7c6.2,0,12.1-1.9,17.1-5.5
			l28.1-20c10.1,5.3,20.7,9.6,31.6,13l5.6,33.6c2.4,14.3,14.7,24.7,29.2,24.7h42.2c14.5,0,26.8-10.4,29.2-24.7l5.7-33.6
			c11.3-3.5,22.2-8,32.6-13.5l27.7,19.8c5,3.6,11,5.5,17.2,5.5l0,0c7.9,0,15.3-3.1,20.9-8.7l29.8-29.8c10.2-10.2,11.6-26.3,3.2-38.1
			l-19.8-27.8c5.5-10.5,10.1-21.4,13.5-32.6l33.6-5.6c14.3-2.4,24.7-14.7,24.7-29.2v-42.1
			C478.9,203.801,468.5,191.501,454.2,189.101z M451.9,260.401c0,1.3-0.9,2.4-2.2,2.6l-42,7c-5.3,0.9-9.5,4.8-10.8,9.9
			c-3.8,14.7-9.6,28.8-17.4,41.9c-2.7,4.6-2.5,10.3,0.6,14.7l24.7,34.8c0.7,1,0.6,2.5-0.3,3.4l-29.8,29.8c-0.7,0.7-1.4,0.8-1.9,0.8
			c-0.6,0-1.1-0.2-1.5-0.5l-34.7-24.7c-4.3-3.1-10.1-3.3-14.7-0.6c-13.1,7.8-27.2,13.6-41.9,17.4c-5.2,1.3-9.1,5.6-9.9,10.8l-7.1,42
			c-0.2,1.3-1.3,2.2-2.6,2.2h-42.1c-1.3,0-2.4-0.9-2.6-2.2l-7-42c-0.9-5.3-4.8-9.5-9.9-10.8c-14.3-3.7-28.1-9.4-41-16.8
			c-2.1-1.2-4.5-1.8-6.8-1.8c-2.7,0-5.5,0.8-7.8,2.5l-35,24.9c-0.5,0.3-1,0.5-1.5,0.5c-0.4,0-1.2-0.1-1.9-0.8l-29.8-29.8
			c-0.9-0.9-1-2.3-0.3-3.4l24.6-34.5c3.1-4.4,3.3-10.2,0.6-14.8c-7.8-13-13.8-27.1-17.6-41.8c-1.4-5.1-5.6-9-10.8-9.9l-42.3-7.2
			c-1.3-0.2-2.2-1.3-2.2-2.6v-42.1c0-1.3,0.9-2.4,2.2-2.6l41.7-7c5.3-0.9,9.6-4.8,10.9-10c3.7-14.7,9.4-28.9,17.1-42
			c2.7-4.6,2.4-10.3-0.7-14.6l-24.9-35c-0.7-1-0.6-2.5,0.3-3.4l29.8-29.8c0.7-0.7,1.4-0.8,1.9-0.8c0.6,0,1.1,0.2,1.5,0.5l34.5,24.6
			c4.4,3.1,10.2,3.3,14.8,0.6c13-7.8,27.1-13.8,41.8-17.6c5.1-1.4,9-5.6,9.9-10.8l7.2-42.3c0.2-1.3,1.3-2.2,2.6-2.2h42.1
			c1.3,0,2.4,0.9,2.6,2.2l7,41.7c0.9,5.3,4.8,9.6,10,10.9c15.1,3.8,29.5,9.7,42.9,17.6c4.6,2.7,10.3,2.5,14.7-0.6l34.5-24.8
			c0.5-0.3,1-0.5,1.5-0.5c0.4,0,1.2,0.1,1.9,0.8l29.8,29.8c0.9,0.9,1,2.3,0.3,3.4l-24.7,34.7c-3.1,4.3-3.3,10.1-0.6,14.7
			c7.8,13.1,13.6,27.2,17.4,41.9c1.3,5.2,5.6,9.1,10.8,9.9l42,7.1c1.3,0.2,2.2,1.3,2.2,2.6v42.1H451.9z"
      fill="#939393"
    />
    <path
      d="M239.4,136.001c-57,0-103.3,46.3-103.3,103.3s46.3,103.3,103.3,103.3s103.3-46.3,103.3-103.3S296.4,136.001,239.4,136.001
			z M239.4,315.601c-42.1,0-76.3-34.2-76.3-76.3s34.2-76.3,76.3-76.3s76.3,34.2,76.3,76.3S281.5,315.601,239.4,315.601z"
      fill="#939393"
    />
  </svg>
);

export const CloseButtonIcon = () => (
  <svg class="w-4 h-4 fill-current">
    <title>Close</title>
    <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z"></path>
  </svg>
);

export const MembershipIcon = () => (
  <svg
    className="menu-svg"
    width="24"
    height="24"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
  >
    <path
      d="M48.014,42.889l-9.553-4.776C37.56,37.662,37,36.756,37,35.748v-3.381c0.229-0.28,0.47-0.599,0.719-0.951
                                  c1.239-1.75,2.232-3.698,2.954-5.799C42.084,24.97,43,23.575,43,22v-4c0-0.963-0.36-1.896-1-2.625v-5.319
                              c0.056-0.55,0.276-3.824-2.092-6.525C37.854,1.188,34.521,0,30,0s-7.854,1.188-9.908,3.53C17.724,6.231,17.944,9.506,18,10.056
                              v5.319c-0.64,0.729-1,1.662-1,2.625v4c0,1.217,0.553,2.352,1.497,3.109c0.916,3.627,2.833,6.36,3.503,7.237v3.309
                              c0,0.968-0.528,1.856-1.377,2.32l-8.921,4.866C8.801,44.424,7,47.458,7,50.762V54c0,4.746,15.045,6,23,6s23-1.254,23-6v-3.043
                              C53,47.519,51.089,44.427,48.014,42.889z"
      fill="#000000"
    />
  </svg>
);

export const FinancialIcon = () => (
  <svg
    className="menu-svg"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <g transform="translate(0.0,100.0) scale(0.100000,-0.100000)">
      <path
        d="M433 963 c-8 -33 -13 -39 -48 -47 -35 -8 -40 -7 -60 18 l-21 27 -47 -26 c-58 -33 -59 -35 -46 -74 10 -28 8 -34 -16 -58 -24 -24 -29 -26 -57 -15 -38 14 -40 13 -73 -45 l-26 -47 25 -19 c19 -13 26 -28 26 -50 0 -43 -9 -53
                                -53 -61 l-38 -7 3 -57 c3 -57 3 -57 40 -66 33 -8 37 -13 43 -49 6 -35 4 -42 -20 -61 -27 -22 -27 -23 -11 -49 9 -15 23 -38 31 -51 13 -22 17 -23 50 -13 33 10 38 8 63 -16 24 -25 25 -30 15 -58 -15 -39 -14 -41 45 -74 l47 -26 21 27
                                c20 24 25 26 60 17 34 -7 40 -14 48 -46 l10 -37 58 0 c55 0 58 1 64 28 3 15 7 32 9 38 2 6 21 15 41 19 34 6 41 4 60 -20 l22 -27 46 27 c57 33 58 35 44 72 -10 26 -8 32 17 57 26 26 31 27 62 18 33 -10 35 -9 59 30 37 58 38 64 9 87
                                -24 18 -26 24 -18 57 7 30 15 40 43 49 34 11 35 14 38 68 3 55 2 57 -25 63 -15 3 -33 7 -39 9 -6 2 -15 20 -18 40 -6 31 -3 42 15 61 19 20 20 27 10 51 -6 15 -19 38 -29 51 -16 20 -20 21 -49 10 -30 -11 -35 -10 -62 16 -28 27 -29 29
                                -15 61 l14 34 -52 31 -52 32 -22 -27 c-20 -25 -25 -27 -60 -19 -31 8 -39 16 -49 44 -11 34 -14 35 -67 38 l-56 3 -9 -38z m169 -103 c351 -108 370 -580 28 -711 -224 -86 -461 54 -500 298 -18 111 24 238 108 322 103 103 226 134 364 91z"
        fill="#000000"
      />
      <path
        d="M400 749 c-182 -82 -217 -323 -67 -456 55 -47 116 -67 189 -61 272 23 329 404 78 517 -59 27 -141 27 -200 0z m140 -54 c0 -14 6 -28 13 -30 73 -26 70 -22 51 -54 -17 -28 -17 -28 -55 -13 -31 12 -44 13 -65 3 -36 -16 -27
                                -31 41 -68 70 -37 95 -65 95 -103 0 -32 -33 -78 -66 -90 -17 -7 -24 -17 -24 -35 0 -22 -4 -25 -35 -25 -30 0 -35 3 -35 23 0 19 -9 28 -40 40 -42 16 -48 30 -26 60 12 16 16 16 42 2 36 -19 74 -19 99 -1 27 19 13 44 -39 67 -73 32 -106
                                65 -106 105 0 37 36 84 65 84 10 0 15 10 15 30 0 28 3 30 35 30 31 0 35 -3 35 -25z"
        fill="#000000"
      />
    </g>
  </svg>
);

export const FieldMaintananceIcon = () => (
  <svg
    className="menu-svg"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)">
      <path
        d="M187 890 c-5 -21 -41 -28 -52 -10 -11 18 -42 -8 -49 -40 -5 -26 -9 -30 -25 -25 -26 8 -48 -29 -31 -51 15 -17 6 -44 -15 -44 -23 0 -19 -47 5 -60 22 -12 27 -36 8 -42 -23 -8 6 -53 33 -53 19 0 25 -6 27 -27 4 -31 35 -46 57
                                  -28 11 9 18 7 34 -9 24 -24 61 -28 61 -7 0 17 35 27 48 14 17 -17 55 1 49 23 -6 21 17 44 36 36 22 -8 39 29 25 54 -11 18 -10 23 10 36 25 18 31 63 7 63 -21 0 -30 27 -16 44 16 19 -7 58 -30 51 -19 -7 -41 17 -34 36 3 8 -5 20 -17
                                  28 -16 10 -23 10 -26 2 -6 -18 -40 -13 -52 9 -6 11 -19 20 -29 20 -10 0 -21 -9 -24 -20z m65 -40 c145 -41 169 -233 37 -301 -52 -26 -126 -22 -167 8 -37 27 -72 91 -72 131 0 82 74 165 155 171 6 1 27 -4 47 -9z"
        fill="#000000"
      />
      <path
        d="M162 798 c-65 -32 -80 -133 -27 -188 88 -92 236 16 181 132 -28 58 -96 83 -154 56z"
        fill="#000000"
      />
      <path
        d="M710 851 c0 -25 -40 -35 -56 -14 -11 16 -14 16 -38 -1 -19 -12 -25 -23 -21 -35 7 -22 -19 -46 -40 -37 -9 3 -21 -4 -31 -20 -15 -21 -15 -27 -2 -42 19 -22 12 -52 -13 -52 -27 0 -27 -60 0 -67 26 -7 32 -33 13 -55 -13 -15
                                  -13 -21 2 -42 10 -16 22 -23 31 -20 21 8 47 -15 40 -36 -4 -12 4 -23 21 -35 25 -16 28 -16 39 -2 15 21 55 11 55 -14 0 -28 60 -27 67 0 6 25 33 32 55 13 14 -12 20 -11 42 3 14 10 23 22 19 31 -7 20 17 46 38 41 12 -3 24 4 34 20 14
                                  21 15 27 3 41 -19 22 -12 49 13 55 27 7 28 67 0 67 -24 0 -36 41 -17 55 22 16 -7 65 -33 58 -27 -7 -52 18 -40 39 6 12 2 20 -17 33 -22 14 -28 15 -42 3 -22 -19 -49 -12 -55 13 -7 27 -67 28 -67 0z m119 -66 c146 -74 145 -272 -2 -341
                                  -65 -30 -110 -30 -167 -1 -146 73 -143 281 5 348 55 25 107 24 164 -6z"
        fill="#000000"
      />
      <path
        d="M706 750 c-110 -34 -130 -191 -31 -256 37 -24 106 -22 143 4 34 25 62 77 62 117 0 88 -92 160 -174 135z"
        fill="#000000"
      />
      <path
        d="M334 389 c-3 -6 -16 -8 -28 -5 -23 6 -55 -9 -43 -20 9 -9 -12 -36 -23 -29 -4 3 -13 -2 -20 -10 -10 -12 -10 -18 0 -30 10 -12 9 -16 -4 -21 -20 -8 -21 -38 -1 -54 12 -10 13 -16 4 -26 -9 -11 -8 -18 1 -29 7 -9 15 -13 18
                                  -10 7 7 33 -16 27 -25 -3 -4 2 -13 10 -20 12 -10 18 -10 29 0 12 9 17 7 28 -9 17 -27 32 -27 51 1 12 18 18 20 25 10 11 -16 45 -4 38 14 -6 15 17 37 28 27 12 -12 26 20 20 44 -4 16 -1 25 10 29 21 8 21 33 0 41 -10 4 -14 13 -10 27 6
                                  23 -9 55 -20 43 -10 -10 -35 13 -28 25 9 15 -17 28 -43 21 -14 -3 -23 -1 -23 6 0 14 -37 14 -46 0z m75 -37 c35 -19 71 -72 71 -105 0 -41 -32 -86 -76 -107 -38 -19 -49 -20 -79 -10 -50 16 -85 62 -85 111 0 53 22 92 62 112 43 21 66 21 107 -1z"
        fill="#000000"
      />
      <path
        d="M295 298 c-31 -37 -29 -77 7 -115 61 -65 163 13 128 98 -25 59 -92 68 -135 17z"
        fill="#000000"
      />
    </g>
  </svg>
);

export const AccountManagementIcon = () => (
  <svg
    className="menu-svg"
    width="24"
    height="24"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 490 490"
  >
    <g>
      <g>
        <path
          d="M311.5,184.4c0,36.1-29.2,65.3-65.3,65.3s-65.3-29.2-65.3-65.3s29.2-65.3,65.3-65.3S311.5,148.4,311.5,184.4z M245,0
                                    C109.9,0,0,109.9,0,245s109.9,245,245,245s245-109.9,245-245S380.1,0,245,0z M357.3,404.7c-8.2-65.7-35.2-133-111.1-133
                            c-76.5,0-103.3,68.3-111.3,134.5C83.5,371,49.7,311.9,49.7,245c0-107.7,87.6-195.3,195.3-195.3S440.3,137.3,440.3,245
                            C440.3,310.9,407.5,369.3,357.3,404.7z"
          fill="#000000"
        />
      </g>
    </g>
  </svg>
);

export const AdminDashboardIcon = () => (
  <svg
    className="menu-svg"
    width="24"
    height="24"
    viewBox="0 0 513 513"
    fill={"#000000"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_4)">
      <path
        d="M256.009 42.671C256.007 42.671 256.004 42.671 256.002 42.671C256.001 42.671 256.001 42.671 256 42.671C255.999 42.671 255.998 42.671 255.997 42.671C185.326 42.674 121.349 71.308 75.03 117.606C75.014 117.622 74.996 117.635 74.98 117.651C74.963 117.668 74.95 117.686 74.933 117.703C28.688 163.976 0.072 227.867 0.011 298.445C0.011 298.521 0 298.595 0 298.671C0 298.744 0.01 298.814 0.011 298.886C0.061 359.087 20.973 416.125 58.526 461.59C62.579 466.497 68.61 469.338 74.974 469.338H437.022C443.386 469.338 449.417 466.497 453.47 461.59C491.077 416.06 512.009 358.94 512.009 298.671C512.009 157.289 397.391 42.671 256.009 42.671ZM426.68 426.671H85.316C62.035 395.694 47.604 359.01 43.733 320.004H106.667C118.449 320.004 128 310.453 128 298.671C128 286.889 118.449 277.338 106.667 277.338H43.734C47.993 234.433 64.964 195.272 90.825 163.667L105.145 177.987C113.476 186.318 126.984 186.318 135.315 177.987C143.646 169.656 143.646 156.148 135.315 147.817L120.994 133.496C152.599 107.632 191.759 90.659 234.666 86.398V149.339C234.666 161.121 244.217 170.672 255.999 170.672C267.781 170.672 277.332 161.121 277.332 149.339V86.396C320.238 90.655 359.4 107.628 391.008 133.492L376.683 147.817C368.352 156.148 368.352 169.656 376.683 177.987C385.014 186.318 398.522 186.318 406.853 177.987L421.179 163.661C447.046 195.268 464.021 234.432 468.282 277.338H405.332C393.55 277.338 383.999 286.889 383.999 298.671C383.999 310.453 393.55 320.004 405.332 320.004H468.282C464.409 359.001 449.97 395.686 426.68 426.671Z"
        fill="#000000"
      />
      <path
        d="M190.102 225.069L277.258 326.545L309.124 280.526L190.102 225.069Z"
        fill="#000000"
      />
      <path
        d="M320 362.671H192C180.218 362.671 170.667 372.222 170.667 384.004C170.667 395.786 180.218 405.337 192 405.337H320C331.782 405.337 341.333 395.786 341.333 384.004C341.333 372.222 331.782 362.671 320 362.671Z"
        fill="#000000"
      />
    </g>
  </svg>
);


export const CpasInterviewIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 256 256" >
  <defs></defs>
  <g style={{ stroke: 'none', strokeWidth: 0, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'none', fillRule: 'nonzero', opacity: 1 }} transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
    <path d="M 45 49.519 L 45 49.519 c -7.68 0 -13.964 -6.284 -13.964 -13.964 v -5.008 c 0 -7.68 6.284 -13.964 13.964 -13.964 h 0 c 7.68 0 13.964 6.284 13.964 13.964 v 5.008 C 58.964 43.236 52.68 49.519 45 49.519 z" style={{ stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(0,0,0)', fillRule: 'nonzero', opacity: 1 }} />
    <path d="M 52.863 51.438 c -2.362 1.223 -5.032 1.927 -7.863 1.927 s -5.501 -0.704 -7.863 -1.927 C 26.58 53.014 18.414 62.175 18.414 73.152 v 14.444 c 0 1.322 1.082 2.403 2.403 2.403 h 48.364 c 1.322 0 2.403 -1.082 2.403 -2.403 V 73.152 C 71.586 62.175 63.42 53.014 52.863 51.438 z" style={{ stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(0,0,0)', fillRule: 'nonzero', opacity: 1 }} />
    <path d="M 71.277 34.854 c -2.362 1.223 -5.032 1.927 -7.863 1.927 c -0.004 0 -0.007 0 -0.011 0 c -0.294 4.412 -2.134 8.401 -4.995 11.43 c 10.355 3.681 17.678 13.649 17.678 24.941 v 0.263 h 11.511 c 1.322 0 2.404 -1.082 2.404 -2.404 V 56.568 C 90 45.59 81.834 36.429 71.277 34.854 z" style={{ stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(0,0,0)', fillRule: 'nonzero', opacity: 1 }} />
    <path d="M 63.414 0 c -7.242 0 -13.237 5.589 -13.898 12.667 c 8 2.023 13.947 9.261 13.947 17.881 v 2.385 c 7.657 -0.027 13.914 -6.298 13.914 -13.961 v -5.008 C 77.378 6.284 71.094 0 63.414 0 z" style={{ stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(0,0,0)', fillRule: 'nonzero', opacity: 1 }} />
    <path d="M 13.915 73.152 c 0 -11.292 7.322 -21.261 17.677 -24.941 c -2.861 -3.029 -4.702 -7.019 -4.995 -11.43 c -0.004 0 -0.007 0 -0.011 0 c -2.831 0 -5.5 -0.704 -7.863 -1.927 C 8.166 36.429 0 45.59 0 56.568 v 14.444 c 0 1.322 1.082 2.404 2.404 2.404 h 11.511 V 73.152 z" style={{ stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(0,0,0)', fillRule: 'nonzero', opacity: 1 }} />
    <path d="M 26.536 32.932 v -2.385 c 0 -8.62 5.946 -15.858 13.947 -17.881 C 39.823 5.589 33.828 0 26.586 0 c -7.68 0 -13.964 6.284 -13.964 13.964 v 5.008 C 12.622 26.635 18.879 32.905 26.536 32.932 z" style={{ stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(0,0,0)', fillRule: 'nonzero', opacity: 1 }} />
  </g>
</svg>
);