import React from "react";
import ModalBasic from "../../component/ModalBasic";

const SendQuotation = (props) => {
    return (
        <ModalBasic
            id="send-quotation-modal"
            modalOpen={props.setSendQuotationModal}
            setModalOpen={props.setOpenModal}
            title=""
            noCloseIcon={true}
        >
            <div className="text-center pb-2">
                <h1 className="text-xl text-[#000000] font-normal leading-loose p-8 py-4">
                    The quotation of {props.companyName} has ben sent successfully to {props.email}
                </h1>
                <button
                    className="btn btn-blue text-base text-[#fff] font-bold my-4"
                    onClick={props.onClose}
                >
                    OK
                </button>
            </div >
        </ModalBasic>
    );
}
export default SendQuotation;   