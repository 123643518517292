import React from "react";
import ModalBasic from "../../component/ModalBasic";
import TinyEceEditor from "../../../components/ckEditor/TinyMCEEditor";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";

const SendRejectionReason = (props) => {
    return (
        <ModalBasic
            id="send-payment-link-modal"
            modalOpen={props.rejectionModal}
            setModalOpen={props.setOpenModal}
            title="Send Rejection Reason Detail"
        // noCloseIcon={true}
        >
            <div className="pb-6 px-7 mt-2">
                <div>
                    <TinyEceEditor
                        key={"sendRejectionReason"}
                        onChange={(e) => props.onTinyEceEditorChange(e, "sendRejectionReason")}
                        data={props.sendRejectionReason}                    
                    />
                    {/* <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-25 text-lg text-[#000000]"
                        type="text"
                        name="sendRejectionReason"
                        onChange={(e) => props.handleChangeSendRejectionReasonDetails(e)}
                        value={props.sendRejectionReason}
                    /> */}
                    {/* <textarea
                        id="default"
                        name="sendRejectionReason" //name="companyDescription"
                        rows="100"
                        className="form-input w-full description-rejection" 
                        //className='w-full'
                        maxLength={800}
                        onChange={(e) => props.handleChangeSendRejectionReasonDetails(e)} //onChange={(e) => props.handleChange(e)}
                        value={props.sendRejectionReason} //value={props.corporateMemberSignUpData.companyDescription}
                        //onBlur={() => props.validateField("companyDescription")}
                    /> */}
                </div>
                <div className="text-center">
                    {
                        props && props.isLoadingRejectAplication === true ?
                            <ButtonLoader className="btn btn-orange mt-8" />
                            :
                            <button
                                className={`btn ${props.btn_color ? props.btn_color : 'btn-blue'} text-base text-[#fff] font-bold mt-8`}
                                onClick={() => props.rejectSLOTSMemberApplication('Rejected')}
                            >
                                OK
                            </button>
                    }
                </div>
            </div >
        </ModalBasic>
    );
}
export default SendRejectionReason;