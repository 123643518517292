import { CommonValidationMessages } from "../../../utils/Messages";

// SLOTS Member Validation Rules
export const SLOTSMemberValidationRules = {
    companyName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company name"
            ),
        },
    ],
    uenNumber: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company UEN"
            ),
        },
    ],
    dateOfIncorporation: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "date of incorporation"
            ),
        },
    ],
    mailingAddress1: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "mailing address"
            ),
        },
    ],
    countryId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "country"
            ),
        },
    ],
    postalCode: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "postal code"
            ),
        },
    ],
    businessTelNo: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "business telephone number"
            ),
        },
    ],
    businessEmail: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "business email address "
            ),
        },
        {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
        },
    ],
    annualTurnOverId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "annual turnover"
            ),
        },
    ],
    companyDescription: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company description"
            ),
        },
    ],
    fileListOfcopyofcpfContribution: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "List Of copy of cpfContribution file"
            ),
        },
    ],
    // specifyReason: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "specify reason"
    //         ),
    //     },
    // ],
    // moreInformation: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "more information"
    //         ),
    //     },
    // ],
    numberOfStaffEmployedLocalId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "number of staff employed local"
            ),
        },
    ],
    foreignWorkPermitHolderId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "foregin work permit holder"
            ),
        },
    ],
    headOfCompanyFirstName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "first name"
            ),
        },
    ],
    headOfCompanyLastName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "last name"
            ),
        },
    ],
    jobTitle: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "job tilte"
            ),
        },
    ],
    directOfficeNumber: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "direct office number"
            ),
        },
    ],
    mobileNumber: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "mobile number"
            ),
        },
    ],
    email: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "email address "
            ),
        },
    ],
    uploadACRACertificate: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "document"),
        }
    ],
    // uploadWPOnlineRecord: [
    //     {
    //         type: "require",
    //         message: Messages.CommonValidationMessages.SelectRequired.replace("{0}", "document"),
    //     }
    // ],
    latest6monthsCPFContribution: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "document"),
        }
    ],
    copyOfCPFContributionsOfCompletedProjects: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "document"),
        }
    ],
    approvedTechnicalQualification: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "document"),
        }
    ],
    companyBankStatementsOf6Month: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "document"),
        }
    ],
    projectTrackRecordsOfOnGoingProject: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "document"),
        }
    ],
    supportingDocFormAOngoing: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "document"),
        }
    ],
    supportingDocFormAOnComplete: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "document"),
        }
    ],
    projectTrackRecordsOfCompletedProject: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "document"),
        }
    ],
};

// SLOTS Member Authorised Representative Validation Rules
export const SLOTSAuthorisedRepresentativeValidationRules = {
    roleId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "role"
            ),
        },
    ],
    firstName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "first name "
            ),
        },
    ],
    lastName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "last name "
            ),
        },
    ],
    jobTitle: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "job title "
            ),
        },
    ],
    directOfficeNumber: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "direct office number "
            ),
        },
    ],
    mobileNumber: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "mobile number "
            ),
        },
    ],
    emailAddress: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "email address "
            ),
        },
        {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
        },
    ],
};
export const SLOTSMemberOrganisationProfileValidationRules = {
    annualTurnOverId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "annual turnover"
            ),
        },
    ],
    companyDescription: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company description"
            ),
        },
    ],
   
    numberOfStaffEmployedLocalId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "number of staff employed local"
            ),
        },
    ],
    foreignWorkPermitHolderId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "foregin work permit holder"
            ),
        },
    ],
    headOfCompanyFirstName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "first name"
            ),
        },
    ],
    headOfCompanyLastName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "last name"
            ),
        },
    ],
    jobTitle: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "job tilte"
            ),
        },
    ],
    directOfficeNumber: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "direct office number"
            ),
        },
    ],
    mobileNumber: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "mobile number"
            ),
        },
    ],
    email: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "email address"
            ),
        },
    ]
  
   

}


export const SLOTSMemberBasicApplicationValidationRules = {
   
    companyName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company name"
            ),
        },
    ],
    uenNumber: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company UEN"
            ),
        },
    ],
    dateOfIncorporation: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "date of incorporation"
            ),
        },
    ],
    mailingAddress1: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "mailing address"
            ),
        },
    ],
    countryId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "country"
            ),
        },
    ],
    postalCode: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "postal code"
            ),
        },
    ],
    businessTelNo: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "business telephone number"
            ),
        },
    ],
    businessEmail: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "business email address "
            ),
        },
        {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
        },
    ],



}


// SLOTS Member BCA Validation Rules
export const SLOTSMemberBCAScopeValidationRules = {
    mainGroupId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "maingroup"
            ),
        },
    ],
    workheadId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "workhead"
            ),
        },
    ],
    gradeId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "grade"
            ),
        },
    ],
}

// SLOTS Member Registration Validation Rules
export const SLOTSMemberRegistrationScopeValidationRules = {
    selectedMainGroupId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "maingroup"
            ),
        },
    ],
    selectedWorkheadId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "workhead"
            ),
        },
    ],
}