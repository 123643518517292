import {
    GetAllStudentMembersList,
    GetAllDropDownsForStudentMember,
    SaveStudentMemberClarificationDetails,
    GetStudentMemberDetailByMemberId,
    EditStudentMemberDetails,
    UploadPdf,
    StoreBase64Image,
    DownloadCertificateForStudentMemberById,
    DownloadCertificateForStudentMemberByPath,
    DownloadReceiptForStudentMemberById,
    DownloadInvoiceForStudentMemberById
} from "../ApiEndPoints";
import {
    axiosGet,
    axiosGetFileAuthorizeblob,
    axiosPost,
    axiosPostAuthorize,
    axiosPostWithoutEncryption,

} from "../AxiosRequests";

export default class StudentMemberService {

    async getStudentMemberList(request) {
        return axiosPost(GetAllStudentMembersList, request);
    }
    async getAllDropDownsForStudentMember(request) {
        return axiosGet(GetAllDropDownsForStudentMember, request);
    }
    async SaveStudentMemberClarificationDetails(request) {
        return axiosPost(SaveStudentMemberClarificationDetails, request)
    }
    async GetStudentMemberDetailByMemberId(request) {
        return axiosGet(GetStudentMemberDetailByMemberId, request)
    }
    async uploadPDF(request) {
        let formData = new FormData();
        formData.append("file", request.file);
        formData.append("filename", request.filename);
        formData.append("storagePath", request.storagePath);

        return axiosPostWithoutEncryption(UploadPdf, formData, true);
    }
    async EditStudentMemberDetails(request) {
        return axiosPost(EditStudentMemberDetails, request);
    }
    async storeBase64Image(request) {
        return axiosPostWithoutEncryption(StoreBase64Image, request, false);
    }
    async DownloadCertificateForStudentMemberById(request) {
        return axiosGetFileAuthorizeblob(DownloadCertificateForStudentMemberById, request)
    }
    async DownloadCertificateForStudentMemberByPath(request) {
        return axiosGetFileAuthorizeblob(DownloadCertificateForStudentMemberByPath, request)
    }
    async DownloadInvoiceForStudentMemberById(request) {
        return axiosGetFileAuthorizeblob(DownloadInvoiceForStudentMemberById, request)
    }
    async DownloadReceiptForStudentMemberById(request) {
        return axiosGetFileAuthorizeblob(DownloadReceiptForStudentMemberById, request)
    }
} 