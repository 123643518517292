import React, { Component } from "react";
import Table from "../../components/table/Table";
import { Navigate } from "react-router-dom";
import SwalServices from "../../services/swalServices/SwalServices";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import DropdownAction from "../../components/dropdown/DropdownAction";
import { encryptAES } from '../../utils/Encryption';
import SlotMemberService from "../../services/axiosServices/apiServices/SlotMemberService";
import DropdownSelect from "../../components/dropdown/Dropdown";

export default class SLOTSAccreditationViewOnly extends Component {
  constructor(props) {
    super(props);
    this.slotMemberService = new SlotMemberService();;
    this.swalServices = new SwalServices();
    this.state = {
      route: null,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        dateFrom: null,
        dateTo: null,
        filterBySource: 0,
        statusId: 0,
      },
      slotMemberList: [],
      searchByName: "",
      searchByEmailAddress: "",
      actionDropdown: ['View'],
      statusList: [],
    };
  }

  componentDidMount() {
    this.getAllDropDownsForSLOTSMember();
    //this.getSlotMemberList();
  }

  getAllDropDownsForSLOTSMember = () => {
    this.slotMemberService.getAllDropDownsForSLOTSMember().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        var status = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Status");
        status.map(function (item) { delete item["dropdownName"]; return item; });
        this.setState({ statusList: status });
      }
    });
    this.getSlotMemberList();
  }

  getSlotMemberList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.slotMemberService.getSlotMemberList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ slotMemberList: response.responseItem.responseContent.itemList });
        this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }
  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getSlotMemberList();
  };

  searchByName = (value, identity) => {
    let detail = this.state.pagination;
    if (identity === "CompanyName") {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getSlotMemberList();
  }

  setFilterParameters = (id) => {
    let detail = this.state.pagination;
    detail.pageNo = 1;
    detail.statusId = id;
    this.setState({ pagination: { ...detail } }, () => {
      this.getSlotMemberList();
    });
  }

  actions = (element, index, value) => {
    return element !== "slotsMemberId" ? null : (
      <>
        <ActionContainer>
          <DropdownAction
            key={index}
            index={index}
            value={value}
            options={this.state.actionDropdown}
            align="right"
            onOptionClick={this.actionClick.bind(this)}
          />
        </ActionContainer>
      </>
    );
  };

  actionClick = (index, value, option) => {
    // if (value && value > 0 && option === 'Deactivate') {
    //   this.deActiveIndividualMember(value);
    // }
    // if (value && value > 0 && option === 'Activate') {
    //   this.activeInActiveIndividualMember(value);
    // }
    if (value && value > 0 && option === 'View') {
      console.log("check individual member ", value);
      this.viewSLOTSMember(value);
    }
    // if (value && value > 0 && option === 'Delete') {
    //   this.deleteIndividualMember(value);
    // }
  }

  viewSLOTSMember = (value) => {
    if (value && value > 0) {
      this.setState({ route: '/EditSLOTSMember/SLOTSMemberId?=' + encryptAES(value) + "ReadOnly" });
    }
  }

  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <>
        <div className="main-body-section">

          <div className="bg-white px-8 pt-10 pb-12">
            <div className="grid grid-cols-12 gap-6 w-full items-center">
              <div className="2xl:col-span-3 lg:col-span-4 col-span-12">
                <h2 className="text-xl text-[#000000] font-bold mb-1">
                  Search by Company
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="col-end-7 col-span-3 grid grid-cols-3 items-center">
                  <input
                    id="default"
                    className="col-span-4 text-lg font-bold h-[55px] w-full text-[#000000] border-white"
                    type="text"
                    onChange={(e) => this.searchByName(e.target.value, 'CompanyName')}
                  />
                </div>
              </div>

              <div className="2xl:col-span-3 lg:col-span-4 col-span-12">
                <h2 className="text-xl text-[#000000] font-bold mb-1">
                  Search by Status
                </h2>
                <div className="col-end-6 col-span-4 grid grid-cols-1 items-center">
                  <DropdownSelect
                    drpIdentity={"workgroupMappingId"}
                    optionArray={this.state.statusList}
                    setFilterParameters={this.setFilterParameters.bind(this)}
                    value={this.state.pagination.statusId}
                  />
                </div>
              </div>

              <div className="2xl:col-span-1 lg:col-span-1 col-span-12 pt-8 text-center">
                <div className="border-l-[1px] border-[#000000] h-[48px] inline-block mx-auto"></div>
              </div>
              <div className="2xl:col-span-5 lg:col-span-6 col-span-12 pr-2">
                <h2 className="text-xl text-[#000000] font-bold mb-1">
                  Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="grid grid-cols-12 gap-6 items-center w-full">
                  <div className="2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                    <input
                      id="default"
                      name="dateFrom"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      type="date"
                      value={this.state.pagination.dateFrom}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                  <div className="2xl:col-span-1 lg:col-span-1 col-span-12 w-full text-center">
                    <h2 className="text-xl theme-color">To</h2>
                  </div>
                  <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                    <input
                      id="default"
                      name="dateTo"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      type="date"
                      value={this.state.pagination.dateTo}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-6 gap-4 pt-10 px-8">
            <h2 className="col-start-1 col-end-3 text-3xl font-bold text-[#000000]">
              {`Total:${this.state.totalResultes}`}
            </h2>
          </div>
          <main className="pb-10">
            <div className="w-full mx-auto">
              <div className="relative">
                <div>
                  <div className=" mt-6">
                    <Table
                      columns={[
                        { name: "name", title: "Name" },
                        { name: "uenNumber", title: "UEN Number" },
                        { name: "email", title: "Email Address" },
                        { name: "companyName", title: "Company Name" },
                        { name: "statusName", title: "Status" },
                        { name: "slotsMemberId", title: "Action" },
                      ]}
                      pagination={this.state.pagination}
                      rows={this.state.slotMemberList}
                      sortingColumns={["source"]}
                      isLoading={this.state.isLoading}
                      totalResultes={this.state.totalResultes}
                      totalCount={this.state.totalResultes}
                      setPagination={this.setPagination.bind(this)}
                      customScope={[
                        {
                          column: "slotsMemberId",
                          renderTableData: this.actions.bind(this),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}