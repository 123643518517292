import React, { Component } from "react";
import Table from "../../../components/table/Table";
import { Navigate } from "react-router-dom";
import moment from "moment";
import SwalServices from "../../../services/swalServices/SwalServices";
import ActionContainer from "../../../components/UIComponent/ActionTableComponent/actionContainer";
import DropdownAction from "../../../components/dropdown/DropdownAction";
import MembershipTaskListServices from "../../../services/axiosServices/apiServices/MembershipTaskListServices";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import IndividualMembershipTaskListProcess from "./IndividualMembershipTaskListProcess";
import { CommonValidationMessages } from "../../../utils/Messages";
import {
  isValidForm,
  validate,
} from "../../../utils/validation/CommonValidator";
import SendPaymentLink from "./SendPaymentLink";
import { CommonSuccessMessages } from "../../../utils/Messages";
import { yearDifference } from "../../../utils/DateUtils";
import RejectMemberApplication from "./RejectMemberApplication";
import SendRejectionReason from "../slotsMembers/SendRejectionReason";

export default class IndividualMemberTaskList extends Component {
  constructor(props) {
    super(props);
    this.TaskListServices = new MembershipTaskListServices();
    this.swalServices = new SwalServices();
    this.state = {
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        dateFrom: null,
        dateTo: null,
        filterBySource: "",
        filterBySourceId: 0,
      },
      totalResultes: "",
      taskList: [],
      setRejectionReason: false,
      sendRejectionReason: "",
      isLoading: false,
      isLoadingPayment: false,
      isLoadingQuotation: false,
      isLoadingInvoice: false,
      isLoadingApprove: false,
      isEscalateLodaing: false,
      route: null,
      redirect: null,
      membershipTypes: [],
      transactionTypes: [],
      billingCategories: [],
      membershipRevenueList: [],
      selectedTaskId: 0,
      sourceList: [
        {
          id: 1,
          name: "Admin",
        },
        {
          id: 2,
          name: "Web",
        },
      ],
      users: [],
      tenureList: [],
      designationList: [],
      taskDetails: {
        taskId: 0,
        individualMemberId: 0,
        companyName: "",
        firstName: "",
        lastName: "",
        email: "",
        contactNo: "",
        billingCategoryId: 0,
        //updatedBy: ,
        subscriptionStartDate: "",
        subscriptionEndDate: "",
        membershipTypeId: 0,
        //transactionTypeId: ,
        //startMembershipOn: ,
        isAppliedForRenewal: false,
        isApplicationFee: false,
        individualStatusId: 0,
      },
      setDropdownOpen: false,
      membershipTaskListProcessModal: false,
      setAddeditModalOpen: false,
      setPaymentLink: false,
      sendQuotation: false,
      sendInvoice: false,
      sendEscalation: false,
      basePriceTable: [],
      allowableDiscountTable: [],
      percentageDiscountTable: [],
      systemCalculatedTable: [],
      disablePaymentButton: true,
      disableSubscription: true,
      isRejectMemberApplication: false,
      // sendEscalate: {
      //   firstName: "",
      //   lastName: "",
      //   companyName: "",
      //   companyUEN: "",
      //   email: "",
      //   contactNo: "",
      //   internalNotes: "",
      // },
      countryList: [],
      corporatePricingDetails: {},
      placeOfBirthList: [],
      nationalityList: [],
      highestEducationList: [],
      hearAboutUsList: [],
      validationRule: {
        companyName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "comapany name"
            ),
          },
        ],
        // companyUEN: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "company UEN"
        //     ),
        //   },
        // ],
        // incorporationDate: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "incorporation date"
        //     ),
        //   },
        // ],
        firstName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "first name"
            ),
          },
        ],
        lastName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "last name"
            ),
          },
        ],
        // selectedDesignation: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.SelectRequired.replace(
        //       "{0}",
        //       "designation"
        //     ),
        //   },
        // ],
        email: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "email address"
            ),
          },
        ],
        contactNo: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "contact number"
            ),
          },
        ],
        membershipTypeId: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "membership type"
            ),
          },
        ],
        // membershipRevenueId: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "revenue type"
        //     ),
        //   },
        // ],
        // transactionTypeId: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "transaction type"
        //     ),
        //   },
        // ],
      },
      invoiceValidation: {
        membershipTenureId: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "membership tenure"
            ),
          },
        ],
        numberOfChapter: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "no. of chapters"
            ),
          },
        ],
        // commentsToMemberInv: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "comments"
        //     ),
        //   },
        // ],
        // internalNotesInv: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "internal notes"
        //     ),
        //   },
        // ],
        // startMembershipOn: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "start membership date"
        //     ),
        //   },
        // ],
      },
      validState: {
        isValid: true,
        error: {},
      },
      validStateForInvoice: {
        isValid: true,
        error: {},
      },
      // validStateMemberSub: {
      //     isValid: true,
      //     error: {}
      // },
      // validStatePricingTable: {
      //     isValid: true,
      //     error: {}
      // },
      // validStateInvGen: {
      //     isValid: true,
      //     error: {}
      // },
      // validationRuleMemberSub: {
      //     isValid: true,
      //     error: {}
      // },
      // validationRulePricingTable: {
      //     isValid: true,
      //     error: {}
      // },
      // validationRuleInvGen: {
      //     isValid: true,
      //     error: {}
      // },
      actionDropdown: ["Process"],
      individualBasicInforamtion: {
        individualMemberId: 0,
        mrMrsId: 0,
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        mailingAddress1: "",
        mailingAddress2: "",
        mailingAddress3: "",
        countryId: 153,
        postalCode: "",
        contactNo: "",
        email: "",
        placeOfBirthId: 0,
        nationalityTypeId: 0,
        photo: "",
        description: "",
        educationLevelId: 0,
        titleOfCourse: "",
        constructionCertification: "",
        companyName: "",
        jobTitle: "",
        emailOfWork: "",
        howDoYouHearId: 0,
        membershipTypeId: 0,
      },
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.getAllIndividualMemberTaskList();
    this.getAllDropdownsForTaskList();
    this.getCorporateMemberPricing();
  }

  getCorporateMemberPricing = () => {
    //this.setState({ isLoading: true });
    
    this.TaskListServices.getCorporateMemberPricing().then((response) => {
      
      if (response.statusCode === 200 && response.responseItem != null) {
        console.log(
          "individual infor  :  ",
          response.responseItem.responseContent
        );
        this.setState({
          corporatePricingDetails: response.responseItem.responseContent,
        });
      } else {
        this.swalServices.Error(response.message);
      }
      // this.setState({ isLoading: false });
    });
  };
  getAllIndividualMemberTaskList = (pagination = this.state.pagination) => {
    //this.setState({ isLoading: true });
    
    this.TaskListServices.getAllIndividualMemberTaskList(pagination).then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent.itemList;
          details.map((key) => {
            key.registrationDate = moment(key.registrationDate).format(
              "DD MMM YYYY"

            );

            if (key.source === null || key.source === "") {
              key.source = "-";
            }
            if (key.Email === null || key.Email === "") {
              key.Email = "-";
            }
            if (key.FullName === null || key.FullName === "") {
              key.FullName = "-";
            }
            if (key.CompanyName === null || key.CompanyName === "") {
              key.CompanyName = "-";
            }
            if (key.CompanyUEN === null || key.CompanyUEN === "") {
              key.CompanyUEN = "-";
            }
            if (key.registrationDate === null || key.registrationDate === "") {
              key.registrationDate = "-";
            }
         return key; });
          this.setState({ taskList: details });
          this.setState({
            totalResultes: response.responseItem.responseContent.totalCount,
          });
        } else {
          this.swalServices.Error(response.message);
        }
        //this.setState({ isLoading: false });
      }
    );
  };

  getAllDropdownsForTaskList = () => {
    this.TaskListServices.getAllDropDownsForIndividualMember().then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          
          var billingCategoryArr = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "BillingCategory"
          );

          var nationality = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Nationality");
          nationality.map(function (item) { delete item["dropdownName"]; return item; });
          nationality = nationality.map((p) => ({ value: p.id, label: p.name }));
          console.log("nationality + ", nationality)
          this.setState({ nationalityList: nationality });


          var educationLevel = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "EducationLevel");
          educationLevel.map(function (item) { delete item["dropdownName"]; return item; });
           educationLevel = educationLevel.map((p) => ({ value: p.id, label: p.name }));
          this.setState({ highestEducationList: educationLevel });


         
        var hearAboutUs = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "HearAboutUs");
        hearAboutUs.map(function (item) { delete item["dropdownName"]; return item;});
        hearAboutUs = hearAboutUs.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ hearAboutUsList: hearAboutUs });


      


          var country = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Country");
          country.map(function (item) { delete item["dropdownName"];return item; });
          country = country.map((p) => ({ value: p.id, label: p.name }));
          this.setState({ countryList: country, placeOfBirthList: country });
          // var membershipArr = response.responseItem.responseContent.filter(
          //   (drp) => drp.dropdownName === "MembershipType"
          // );
          // var transactionArr = response.responseItem.responseContent.filter(
          //   (drp) => drp.dropdownName === "TransactionType"
          // );
          // var membershipRevenueArr = response.responseItem.responseContent.filter(
          //   (drp) => drp.dropdownName === "MembershipRevenue"
          // );
          // var sourceArr = response.responseItem.responseContent.filter(
          //   (drp) => drp.dropdownName === "CorporateProspectSource"
          // );
          // var userArr = response.responseItem.responseContent.filter(
          //   (drp) => drp.dropdownName === "Users"
          // );
          // var tenureArr = response.responseItem.responseContent.filter(
          //   (drp) => drp.dropdownName === "Tenure"
          // );

          // var designation = response.responseItem.responseContent.filter(drp => drp.dropdownName === 'Designation');
          // designation.map(function (item) { delete item['dropdownName']; });
          // designation = designation.map(p => ({
          //   value: p.id, label: p.name
          // }));

          //console.log("tenure : ", tenureArr);

          billingCategoryArr.map(function (item) {
            delete item["dropdownName"];
            return item;
          });
          // membershipArr.map(function (item) {
          //   delete item["dropdownName"];
          // });
          // transactionArr.map(function (item) {
          //   delete item["dropdownName"];
          // });
          // membershipRevenueArr.map(function (item) {
          //   delete item["dropdownName"];
          // });
          // sourceArr.map(function (item) {
          //   delete item["dropdownName"];
          // });
          // userArr.map(function (item) {
          //   delete item["dropdownName"];
          // });
          console.log(nationality);
          this.setState(
            {
              // membershipTypes: membershipArr,
              // transactionTypes: transactionArr,
              billingCategories: billingCategoryArr,
              // membershipRevenueList: membershipRevenueArr,
              // sourceList: sourceArr,
              // users: userArr,
              // tenureList: tenureArr,
              // designationList: designation,
            },
            () => this.getAllIndividualMemberTaskList()
          );
        } else {
          this.swalServices.Error(response.message);
          this.getAllIndividualMemberTaskList();
        }
        this.setState({ isLoading: false });
      }
    );
  };

  // getTaskByCompanyId = (companyId) => {

  //   this.setState({ setAddeditModalOpen: true });
  //   this.TaskListServices.getTaskByCompanyId(companyId).then((response) => {
  //     if (response.statusCode === 200 && response.responseItem != null) {
  //       response.responseItem.responseContent.transactionTypeId =
  //         response.responseItem.responseContent.transactionTypeId === null
  //           ? 1
  //           : response.responseItem.responseContent.transactionTypeId;
  //       if (response.responseItem.responseContent.designation !== null && response.responseItem.responseContent.designation !== "") {
  //         var array = response.responseItem.responseContent.designation.split(',');
  //         var designation = [];
  //         array.forEach(topic => {
  //           designation.push(this.state.designationList.find(deg => deg.label === topic));
  //         });
  //       }
  //       this.setState(
  //         { taskDetails: response.responseItem.responseContent },
  //         () => {
  //           let data = { ...this.state.taskDetails };
  //           data.selectedDesignation = designation;
  //           data.startMembership = data.startMembershipOn ? true : false;
  //           this.setState({ taskDetails: data });
  //           this.getPriceTabelForTaskListProcess();
  //         },
  //         () => this.setState({ validState: { isValid: true, error: {} } })
  //       );
  //       // let details = this.state.taskDetails;
  //       // details.numberOfChapter= response.responseItem.responseContent.additionalChapter;
  //       // this.setState({taskDetails : details});
  //       console.log(" TASK Details : ", this.state.taskDetails);
  //       this.setState({ setAddeditModalOpen: true });
  //     } else {
  //       this.swalServices.Error(response.message);
  //     }
  //     this.setState({ isLoading: false });
  //   });
  // };

  getTaskByIndividualMemberId = (id) => {
    
    this.TaskListServices.getTaskByIndividualMemberId(id).then((response) => {
      console.log(response);
      if (response.statusCode === 200 && response.responseItem != null) {
        // response.responseItem.responseContent.transactionTypeId =
        //   response.responseItem.responseContent.transactionTypeId === null
        //     ? 1
        //     : response.responseItem.responseContent.transactionTypeId;

        // if (response.responseItem.responseContent.designation !== null && response.responseItem.responseContent.designation !== "") {
        //   var array = response.responseItem.responseContent.designation.split(',');
        //   var designation = [];
        //   array.forEach(topic => {
        //     designation.push(this.state.designationList.find(deg => deg.label === topic));
        //   });

        // }
        this.setState(
          { taskDetails: response.responseItem.responseContent },
          () => {
            // let data = { ...this.state.taskDetails };
            // data.selectedDesignation = designation;
            // data.startMembership = data.startMembershipOn ? true : false;
            // this.setState({ taskDetails: data });
            //this.getPriceTabelForTaskListProcess();
          }
          //() => this.setState({ validState: { isValid: true, error: {} } })
        );
        // let details = this.state.taskDetails;
        // details.numberOfChapter= response.responseItem.responseContent.additionalChapter;
        // this.setState({taskDetails : details});
        //console.log(" TASK Details : ", this.state.taskDetails);
        this.setState({ setAddeditModalOpen: true });

        this.getIndividualBasicInformationById(response.responseItem.responseContent.individualMemberId);
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  getPriceTabelForTaskListProcess = () => {
    var request = {
      MembershipTypeId: this.state.taskDetails.membershipTypeId,
      MembershipRevenueId: this.state.taskDetails.membershipRevenueId,
      CorporateMemberId: this.state.taskDetails.corporateMemberId,
      MembershipTenureId: 1,
      MembershipCategoryId: this.state.taskDetails.transactionTypeId,
    };
    this.TaskListServices.getPriceTabelForTaskListProcess(request).then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          var basePriceTable = response.responseItem.responseContent.filter(
            (drp) => drp.tableName === "BasePriceTable"
          );
          var allowableDiscountTable =
            response.responseItem.responseContent.filter(
              (drp) => drp.tableName === "AllowableDiscountTable"
            );
          var percentageDiscountTable =
            response.responseItem.responseContent.filter(
              (drp) => drp.tableName === "PercentageDiscountTable"
            );
          var systemCalculatedTable =
            response.responseItem.responseContent.filter(
              (drp) => drp.tableName === "SystemCalculatedTable"
            );

          basePriceTable.forEach((element, key) => {
            basePriceTable[key].status = "Ok";
            if (this.state.taskDetails.isAppliedForRenewal === true) {
              basePriceTable[key].entranceFee = 0;
            }
          });
          if (allowableDiscountTable.length === 0) {
            allowableDiscountTable.forEach((element, key) => {
              allowableDiscountTable.push(element);
            });
          }
          let diff = "";
          if (this.state.taskDetails.membershipTypeId === 3) {
            if (
              !request.subscriptionEndDate &&
              !request.subscriptionStartDate
            ) {
              let todayDate = new Date();
              let inCorDate = new Date(
                this.state.taskDetails.incorporationDate
              );
              let Difference_In_Time =
                todayDate.getTime() - inCorDate.getTime();
              let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
              let Difference_In_Years = Difference_In_Days / 365;
              diff = Math.floor(Difference_In_Years);
            }
          }
          if (percentageDiscountTable.length === 0) {
            basePriceTable.forEach((element, key) => {
              var obj = {
                row: key + 11,
                year: key + 1,
                entranceFee: 0,
                annualFee: 0,
                includedChapter: 1,
                newChapterPrice: 0,
                tableName: "PercentageDiscountTable",
                status: "Ok",
                isDeleted: true,
              };
              if (key + 1 > diff) {
                obj.isDeleted = false;
              } else {
                obj.isDeleted = true;
              }
              percentageDiscountTable.push(obj);
            });
          }
          if (systemCalculatedTable.length === 0) {
            basePriceTable.forEach((element, key) => {
              var obj = {
                row: key + 16,
                year: key + 1,
                entranceFee: 0,
                annualFee: 0,
                includedChapter: 0,
                newChapterPrice: 0,
                tableName: "SystemCalculatedTable",
                isDeleted: true,
              };
              if (key + 1 > diff) {
                obj.isDeleted = false;
              } else {
                obj.isDeleted = true;
              }
              systemCalculatedTable.push(obj);
            });
          }
          var forPayment = percentageDiscountTable.filter(
            (arr) =>
              arr.status === "Required Approval" || arr.status === "Rejected"
          );
          if (forPayment.length > 0) {
            this.setState({ disablePaymentButton: true });
          } else {
            this.setState({ disablePaymentButton: false });
          }
          this.setState(
            {
              basePriceTable: basePriceTable,
              allowableDiscountTable: allowableDiscountTable,
              percentageDiscountTable: percentageDiscountTable,
              systemCalculatedTable: systemCalculatedTable,
            },
            () => {
              if (
                this.state.taskDetails.taskId &&
                this.state.taskDetails.taskId > 0
              ) {
                this.getExistingDiscountTableByTaskId(
                  this.state.taskDetails.taskId
                );
              } else {
                this.getCalculatedFinalPrice();
              }
              //this.getCalculatedFinalPrice(allowableDiscountTable);
            }
          );
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      }
    );
  };

  getExistingDiscountTableByTaskId = (taskId) => {
    this.TaskListServices.getExistingDiscountTableByTaskId(taskId).then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          var percentageDiscount = response.responseItem.responseContent.filter(
            (drp) => drp.tableName === "PercentageDiscountTable"
          );
          // if (percentageDiscount.length === 0) {
          //   percentageDiscount = [];
          //   basePrice.forEach((element, key) => {
          //     var obj = {
          //       row: key + 11,
          //       year: key + 1,
          //       entranceFee: 0,
          //       annualFee: 0,
          //       includedChapter: 0,
          //       newChapterPrice: 0,
          //       tableName: "PercentageDiscountTable",
          //       status: "Ok",
          //     };
          //     percentageDiscount.push(obj);
          //   });
          // }
          if (response.responseItem.responseContent.length === 5) {
            this.setState(
              { percentageDiscountTable: percentageDiscount },
              () => {
                this.getCalculatedFinalPrice();
              }
            );
          } else {
            this.getCalculatedFinalPrice();
          }
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      }
    );
  };

  getCalculatedFinalPrice = () => {
    this.setState({ isLoading: true });
    var request = [];
    request = this.state.basePriceTable.concat(
      this.state.percentageDiscountTable
    );
    // request.push(this.state.basePriceTable);
    // request.push(this.state.percentageDiscountTable);
    this.TaskListServices.getCalculatedFinalPrice(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState(
          {
            systemCalculatedTable: response.responseItem.responseContent,
          },
          () => {
            var forPayment = this.state.percentageDiscountTable.filter(
              (arr) =>
                arr.status === "Required Approval" || arr.status === "Rejected"
            );
            if (forPayment.length > 0) {
              this.setState({ disablePaymentButton: true });
            } else {
              this.setState({ disablePaymentButton: false });
            }
            this.setAllPricingTables();
          }
        );
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  setAllPricingTables = () => {
    let setBasePriceTable = [...this.state.basePriceTable];
    let setAllowableDiscountTable = [...this.state.allowableDiscountTable];
    let setPercentageDiscountTable = [...this.state.percentageDiscountTable];
    let setSystemCalculatedTable = [...this.state.systemCalculatedTable];

    let diff = "";
    if (this.state.taskDetails.membershipTypeId === 3) {
      if (
        !this.state.taskDetails.subscriptionEndDate &&
        !this.state.taskDetails.subscriptionStartDate
      ) {
        let todayDate = new Date();
        let inCorDate = new Date(this.state.taskDetails.incorporationDate);
        let Difference_In_Time = todayDate.getTime() - inCorDate.getTime();
        let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        let Difference_In_Years = Difference_In_Days / 365;
        diff = Math.floor(Difference_In_Years);
      }
    }

    for (let index = 0; index < 4; index++) {
      if (index + 1 > diff) {
        setBasePriceTable[index].isDeleted = false;
        setAllowableDiscountTable[index].isDeleted = false;
        setPercentageDiscountTable[index].isDeleted = false;
        setSystemCalculatedTable[index].isDeleted = false;
      } else {
        setBasePriceTable[index].isDeleted = true;
        setAllowableDiscountTable[index].isDeleted = true;
        setPercentageDiscountTable[index].isDeleted = true;
        setSystemCalculatedTable[index].isDeleted = true;
      }
    }

    this.setState({
      basePriceTable: setBasePriceTable,
      allowableDiscountTable: setAllowableDiscountTable,
      percentageDiscountTable: setPercentageDiscountTable,
      systemCalculatedTable: setSystemCalculatedTable,
    });
  };

  onViewOrderDetails = (value) => {
    this.setState({
      route: "https://web.sgtech-test.infixsofttech.com/Articles" + value,
    });
  };

  // sendEscalateMailToMember = () => {
  //   //let data = this.state.taskDetails;

  //   var request = this.state.sendEscalate;
  //   request.firstName = this.state.taskDetails.firstName;
  //   request.lastName = this.state.taskDetails.lastName;
  //   request.companyName = this.state.taskDetails.companyName;
  //   request.companyUEN = this.state.taskDetails.companyUEN;
  //   request.email = this.state.taskDetails.email;
  //   request.contactNo = this.state.taskDetails.contactNo;
  //   request.internalNotes = this.state.taskDetails.internalNotes;
  //   console.log("Send data :", request);
  //   this.setState({ isEscalateLodaing: true });
  //   this.TaskListServices.sendEscalateMailToMember(request).then((response) => {
  //     if (response.statusCode === 200 && response.responseItem != null) {
  //       this.setState({ sendEscalation: true }, () =>
  //         this.setState({ setAddeditModalOpen: false })
  //       );
  //       // this.swalServices.Success("Sended.");
  //     } else {
  //       this.swalServices.Error(response.message);
  //     }
  //     this.setState({ isEscalateLodaing: false });
  //   });
  // };

  addEditMembershipTask = () => {
    let isValidInput = this.isValidateAllFields();
    console.log("isValidInput : ", isValidInput);
    if (isValidInput) {
      this.setState({ isLoadingApprove: true });
      var pricingList = [];
      var request = { ...this.state.taskDetails, ...pricingList };
      request.taskId = this.state.selectedTaskId;
      // var forPayment = this.state.percentageDiscountTable.filter(
      //   (arr) => arr.status === "Required Approval" || arr.status === "Rejected"
      // );
      request.IsApprovalRequired = true;
      request.IsPaymentLinkSent = false;
      // if (forPayment.length > 0) {
      //   request.IsApprovalRequired = true;
      // }
      // else {
      //   request.IsApprovalRequired = false;
      // }
      request.pricingList = this.state.systemCalculatedTable.concat(
        this.state.percentageDiscountTable,
        this.state.basePriceTable
      );
      if (
        request.selectedDesignation &&
        request.selectedDesignation.length > 0
      ) {
        var arr1 = request.selectedDesignation.map(function (item) {
          delete item.bad;
          return item.label;
        });
        request.designation = arr1.join([","]);
      } else {
        request.designation = "";
      }

      this.TaskListServices.addEditMembershipTaskAndSendForApproval(
        request
      ).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          //window.location.replace('https://web.sgtech-test.infixsofttech.com//membership/MembershipPayment/' + response.responseItem.responseContent.keyId)
          this.setState({ setSendApprove: true }, () => {
            this.setState({ setAddeditModalOpen: false });
            this.getAllIndividualMemberTaskList();
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoadingApprove: false });
      });
    }
  };

  getPriceTabelByCorporateMemberId = (companyId) => {
    this.TaskListServices.getPriceTabelByCorporateMemberId(companyId).then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          var basePriceTable = response.responseItem.responseContent.filter(
            (drp) => drp.tableName === "BasePriceTable"
          );
          var allowableDiscountTable =
            response.responseItem.responseContent.filter(
              (drp) => drp.tableName === "AllowableDiscountTable"
            );
          var percentageDiscountTable =
            response.responseItem.responseContent.filter(
              (drp) => drp.tableName === "PercentageDiscountTable"
            );
          var systemCalculatedTable =
            response.responseItem.responseContent.filter(
              (drp) => drp.tableName === "SystemCalculatedTable"
            );
          // var obj = {
          //   row: 1,
          //   year: 1,
          //   EntranceFee: 0,
          //   AnnualFee: 0,
          //   newChapterPrice: 0,
          //   tableName: "",
          // };

          if (allowableDiscountTable.length === 0) {
            basePriceTable.forEach((element, key) => {
              allowableDiscountTable.push(element);
            });
          }
          if (percentageDiscountTable.length === 0) {
            basePriceTable.forEach((element, key) => {
              percentageDiscountTable.push(element);
            });
          }
          if (systemCalculatedTable.length === 0) {
            basePriceTable.forEach((element, key) => {
              systemCalculatedTable.push(element);
            });
          }
          this.setState({
            basePriceTable: basePriceTable,
            allowableDiscountTable: basePriceTable,
            percentageDiscountTable: percentageDiscountTable,
            systemCalculatedTable: systemCalculatedTable,
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      }
    );
  };

  addEditIndividualMembershipTaskAndSendPaymentLink = () => {
    if (this.state.taskDetails.isApplicationFee) {
      this.swalServices
        .Confirm(
          CommonSuccessMessages.Confirm_Payment.replace("{0}", "Payment Link"),
          CommonSuccessMessages.Send_Payment_Link.replace(
            "{0}",
            "Payment Link"
          ),
          CommonSuccessMessages.Yes_Text,
          CommonSuccessMessages.No_Text
        )
        .then((response) => {
          //console.log("Confirm Response", response);
          let resp = response;
          if (resp === true) {
            
            let isValidInput = this.isValidateAllFields();
            if (isValidInput) {
              //var pricingList = [];
              var request = { ...this.state.taskDetails };
              request.taskId = this.state.selectedTaskId;
              // request.push(this.state.systemCalculatedTable.concat(
              //   this.state.percentageDiscountTable
              // ));
              // request.pricingList = [];
              // request.pricingList = this.state.systemCalculatedTable.concat(
              //   this.state.percentageDiscountTable,
              //   this.state.basePriceTable
              // );
              // request.pricingList.push(this.state.basePriceTable);
              // request.pricingList.push(this.state.percentageDiscountTable);
              // request.percentageDiscountTable = this.state.percentageDiscountTable;
              request.IsApprovalRequired = false;
              request.IsPaymentLinkSent = true;
              this.setState({ isLoadingPayment: true });

              this.TaskListServices.addEditIndividualMembershipTaskAndSendPaymentLink(
                request
              ).then((response) => {
                //console.log("addEditMembershipTaskAndSendPaymentLink", response);
                if (
                  response.statusCode === 200 &&
                  response.responseItem != null
                ) {
                  // window.location.replace('https://web.sgtech-test.infixsofttech.com//membership/MembershipPayment/' + response.responseItem.responseContent.keyId)
                  // this.setState({ setAddeditModalOpen: false }, () =>
                  //   this.getAllIndividualMemberTaskList()
                  // );
                  //this.swalServices.Success(
                  //  SuccessMessage.Update_Success.replace("{0}", "Task")
                  //);
                  // this.addEditMembershipTask()
                  this.setState({ setPaymentLink: true }, () =>
                    this.setState({ setAddeditModalOpen: false })
                  );
                } else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isLoadingPayment: false });
              });
            }
          }
        });
    } else {
      this.swalServices.Error("Please accept application fee.");
    }
  };

  sendInvoiceEmailToCorporateMember = () => {
    let isValidInput = this.isValidateAllFieldsForInvoice();
    if (isValidInput) {
      this.setState({ isLoadingInvoice: true });
      var pricingList = [...this.state.systemCalculatedTable];
      var request = { ...this.state.taskDetails };
      var priceObj = pricingList.find(
        (price) => price.year === request.membershipTenureId
      );
      request.year = priceObj.year;
      request.entranceFee = priceObj.entranceFee;
      let validationYear = "";
      if (request.membershipTypeId === 3) {
        request.annualFee = 0;
        if (request.subscriptionEndDate && request.subscriptionStartDate) {
          if (
            !isNaN(Date.parse(request.subscriptionStartDate)) &&
            !isNaN(Date.parse(request.subscriptionEndDate))
          ) {
            let sDate = new Date(request.subscriptionStartDate);
            let eDate = new Date(request.subscriptionEndDate);
            yearDifference = eDate.getFullYear() - sDate.getFullYear();
            yearDifference = yearDifference === 0 ? 1 : yearDifference;
            validationYear = yearDifference;
            pricingList.forEach((priceObj, PriceIndex) => {
              if (
                priceObj.year > yearDifference &&
                priceObj.year <= request.membershipTenureId
              ) {
                request.annualFee = request.annualFee + priceObj.annualFee;
              }
            });
          }
        } else {
          pricingList.forEach((priceObj, PriceIndex) => {
            if (priceObj.year <= request.membershipTenureId) {
              request.annualFee = request.annualFee + priceObj.annualFee;
            }
          });
        }
      } else {
        request.annualFee = priceObj.annualFee;
      }
      //request.annualFee = priceObj.annualFee;
      request.includedChapter = priceObj.includedChapter;
      request.additionalChapter = request.numberOfChapter;
      request.newChapterPrice = priceObj.newChapterPrice;
      request.tableName = priceObj.tableName;
      request.additionalChapterPrice = priceObj.newChapterPrice;
      request.firstName = this.state.taskDetails.firstName;
      request.lastName = this.state.taskDetails.lastName;
      request.companyName = this.state.taskDetails.companyName;
      request.email = this.state.taskDetails.email;
      request.commentsToMember = this.state.taskDetails.commentsToMember;
      request.additionalPromotion = this.state.taskDetails.additionalPromotion;
      request.internalNotes = this.state.taskDetails.internalNotes;

      if (
        request.selectedDesignation &&
        request.selectedDesignation.length > 0
      ) {
        var arr1 = request.selectedDesignation.map(function (item) {
          delete item.bad;
          return item.label;
        });
        request.designation = arr1.join([","]);
      } else {
        request.designation = "";
      }
      if (request.year > validationYear) {
        this.TaskListServices.sendInvoiceEmailToCorporateMember(request).then(
          (response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              // this.swalServices.Success(SuccessMessage.Send_Success.replace("{0}", "Invoice"), () => this.setState({ setAddeditModalOpen: false }));
              this.setState({ sendInvoice: true }, () =>
                this.setState({ setAddeditModalOpen: false })
              );
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoadingInvoice: false });
          }
        );
      } else {
        this.swalServices.Error(
          "Can't select year of membership less than the purchased year"
        );
        this.setState({ sendQuotation: true }, () =>
          this.setState({ setAddeditModalOpen: false })
        );
      }
    }
  };

  setSendRejectionReasonFlag = () => {
    this.setState({ setRejectionReason: true });
  };
  handleChangeSendRejectionReasonDetails = (data) => {
    // const value = event.target.value;
    // this.setState({ sendRejectionReason: value });
    if (typeof data === "string") {
      this.setState({
        sendRejectionReason: data,
      });
    }
  };
  rejectMemberApplication = (flag) => {
    if (
      (this.state.sendRejectionReason !== null &&
        this.state.sendRejectionReason !== "") ||
      flag === "UnReject"
    ) {
      let request = {};
      request.individualMemberId = this.state.taskDetails.individualMemberId;
      request.sendRejectionReason = this.state.sendRejectionReason;
      if (flag === "Rejected") {
        request.isRejected = true;
      } else if (flag === "UnReject") {
        request.isRejected = false;
      }
      this.setState({ isLoading: true }, () => {
        // this.swalServices.Confirm(CommonSuccessMessages.Confirm_QuestionOfReject.replace("{0}", "Member"), CommonSuccessMessages.Confirm_Reject.replace("{0}", "Member"),
        //   CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
        //     if (response) {
        this.TaskListServices.rejectMemberApplication(request).then(
          (response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              this.setState(
                {
                  isRejectMemberApplication: true,
                  setRejectionReason: false,
                  sendRejectionReason: "",
                },
                () => this.setState({ setAddeditModalOpen: false })
              );
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
          }
        );
      });
      // });
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false }, () => {
        this.swalServices.Error("Please enter detail");
      });
    }
  };

  sendQuotationEmailToCorporateMember = () => {
    let isValidInput = this.isValidateAllFieldsForInvoice();
    if (isValidInput) {
      this.setState({ isLoadingQuotation: true });
      var pricingList = [...this.state.systemCalculatedTable];
      var request = { ...this.state.taskDetails };
      var priceObj = pricingList.find(
        (price) => price.year === request.membershipTenureId
      );
      request.year = priceObj.year;
      request.entranceFee = priceObj.entranceFee;
      let yearDifference = "";
      let validationYear = "";
      let diff = "";
      if (request.membershipTypeId === 3) {
        request.annualFee = 0;
        if (request.subscriptionEndDate && request.subscriptionStartDate) {
          if (
            !isNaN(Date.parse(request.subscriptionStartDate)) &&
            !isNaN(Date.parse(request.subscriptionEndDate))
          ) {
            let sDate = new Date(request.subscriptionStartDate);
            let eDate = new Date(request.subscriptionEndDate);
            yearDifference = eDate.getFullYear() - sDate.getFullYear();
            yearDifference = yearDifference === 0 ? 1 : yearDifference;
            validationYear = yearDifference;
            // Count no. of days
            let inCorDate = new Date(request.incorporationDate);
            let Difference_In_Time = eDate.getTime() - inCorDate.getTime();
            let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            let Difference_In_Years = Difference_In_Days / 365;
            diff = Math.floor(Difference_In_Years);
            // diff = eDate.getFullYear() - inCorDate.getFullYear();
            pricingList.forEach((priceObj, PriceIndex) => {
              if (
                priceObj.year > yearDifference &&
                priceObj.year <= request.membershipTenureId
              ) {
                request.annualFee = request.annualFee + priceObj.annualFee;
              }
            });
          }
        } else {
          pricingList.forEach((priceObj, PriceIndex) => {
            if (priceObj.year <= request.membershipTenureId) {
              request.annualFee = request.annualFee + priceObj.annualFee;
            }
          });
        }
        if (request.membershipTypeId === 3) {
          if (
            request.subscriptionEndDate === null &&
            request.subscriptionStartDate === null
          ) {
            let todayDate = new Date();
            let inCorDate = new Date(request.incorporationDate);
            // diff = todayDate.getFullYear() - inCorDate.getFullYear();
            let Difference_In_Time = todayDate.getTime() - inCorDate.getTime();
            let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            let Difference_In_Years = Difference_In_Days / 365;
            diff = Math.floor(Difference_In_Years);
          }
        }
      } else {
        request.annualFee = priceObj.annualFee;
      }
      request.includedChapter = priceObj.includedChapter;
      request.additionalChapter = request.numberOfChapter;
      request.newChapterPrice = priceObj.newChapterPrice * request.year;
      request.tableName = priceObj.tableName;
      request.additionalChapterPrice = priceObj.newChapterPrice;
      request.firstName = this.state.taskDetails.firstName;
      request.lastName = this.state.taskDetails.lastName;
      request.companyName = this.state.taskDetails.companyName;
      request.email = this.state.taskDetails.email;
      request.commentsToMember = this.state.taskDetails.commentsToMember;
      request.additionalPromotion = this.state.taskDetails.additionalPromotion;
      request.internalNotes = this.state.taskDetails.internalNotes;

      request.startMembershipOn = this.state.taskDetails.startMembership
        ? this.state.taskDetails.startMembershipOn
        : null;
      // request.startMembership = this.state.taskDetails.startMembership ? true : false;
      request.corporateMemberId = this.state.taskDetails.corporateMemberId;
      request.taskId = this.state.taskDetails.taskId;

      request.pricingList = this.state.systemCalculatedTable.concat(
        this.state.percentageDiscountTable,
        this.state.basePriceTable
      );

      if (
        request.selectedDesignation &&
        request.selectedDesignation.length > 0
      ) {
        var arr1 = request.selectedDesignation.map(function (item) {
          delete item.bad;
          return item.label;
        });
        request.designation = arr1.join([","]);
      } else {
        request.designation = "";
      }
      if (request.year > diff && diff >= 0) {
        if (request.year > validationYear) {
          this.TaskListServices.sendQuotationEmailToCorporateMember(
            request
          ).then((response) => {
            // if (xyz > request.year && request.year ) {
            if (response.statusCode === 200 && response.responseItem != null) {
              let reset = this.state.taskDetails;
              reset.commentsToMemberInv = "";
              reset.internalNotesInv = "";
              this.setState({ taskDetails: reset });
              // this.swalServices.Success(SuccessMessage.Send_Success.replace("{0}", "Quotation"), () => this.setState({ setAddeditModalOpen: false }));
              this.setState({ sendQuotation: true }, () =>
                this.setState({ setAddeditModalOpen: false })
              );
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoadingQuotation: false });
          });
        } else {
          this.swalServices.Error(
            "Can't select year of membership less than the purchased year"
          );
          this.setState({ isLoadingQuotation: false });
        }
      } else {
        this.swalServices.Error(
          "Cant select membership years less than the incorporation date"
        );
        this.setState({ isLoadingQuotation: false });
      }
    }
  };

  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.taskDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.taskDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  validateFieldForInvoice = (key) => {
    const newValidState = validate(
      key,
      this.state.taskDetails,
      this.state.invoiceValidation,
      this.state.validStateForInvoice
    );
    this.setState({ validStateForInvoice: newValidState });
  };

  isValidateAllFieldsForInvoice = () => {
    const newValidState = isValidForm(
      this.state.taskDetails,
      this.state.invoiceValidation,
      this.state.validStateForInvoice
    );
    this.setState({ validStateForInvoice: newValidState });
    return newValidState.isValid;
  };

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getAllIndividualMemberTaskList(newPagination);
  };

  setFilterParameters = (id, drpIdentity) => {
    let detail = this.state.taskDetails;
    if (drpIdentity === "billingCategories") {
      detail["billingCategoryId"] = id;
      //this.validateField("resourceTypeId");
    } else if (drpIdentity === "updatedBy") {
      detail["updatedBy"] = id;
    } else if (drpIdentity === "membershipTypes") {
      detail["membershipTypeId"] = id === 0 ? 1 : id;
      this.getPriceTabelForTaskListProcess();
      this.validateField("membershipTypeId");
    } else if (drpIdentity === "membershipRevenue") {
      detail["membershipRevenueId"] = id === 0 ? 1 : id;
      this.getPriceTabelForTaskListProcess();
      this.validateField("membershipRevenueId");
    } else if (drpIdentity === "transactionTypes") {
      detail["transactionTypeId"] = id === 0 ? 1 : id;
      this.getPriceTabelForTaskListProcess();
      this.validateField("transactionTypeId");
    } else if (drpIdentity === "membershipTenure") {
      detail["membershipTenureId"] = id;
      this.validateFieldForInvoice("membershipTenureId");
    } else if (drpIdentity === "chapters") {
      if (id !== 0 || id < 0) {
        detail["numberOfChapter"] = id - 1;
        this.validateFieldForInvoice("numberOfChapter");
      } else {
        detail["numberOfChapter"] = id - 1;
        this.validateFieldForInvoice("numberOfChapter");
      }
    }
    this.setState({ taskDetails: { ...detail } });
  };

  setFilterParametersForList = (id, drpIdentity) => {
    let detail = this.state.pagination;
    // if (drpIdentity === "sourceFilter") {
    //   detail["filterBySource"] = id;
    // }
    if (drpIdentity === "sourceFilter") {
      detail["filterBySource"] = "";
      if (id === 1) {
        detail["filterBySource"] = "Admin";
      } else if (id === 2) {
        detail["filterBySource"] = "Web";
      }
    }
    detail["filterBySourceId"] = id;
    this.setState({ pagination: { ...detail } });
    this.getAllIndividualMemberTaskList();
  };

  searchByName = (value) => {
    let detail = this.state.pagination;
    detail.searchText = "";
    this.setState({ pagination: { ...detail } });
    if (value.length > 2) {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getAllIndividualMemberTaskList();
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.pagination;
    let fulldate = (detail[name] = value === "" ? null : value);
    let date = new Date(fulldate); //1/1/1753 12:00:00 AM and 12/31/9999 11:59:59 PM
    let startLimit = new Date("1/1/1753 12:00:00 AM");
    let endLimit = new Date("12/31/9999 11:59:59 PM");
    detail[name] = value;
    this.setState({ pagination: { ...detail } }, () => {
      if (startLimit < date && date < endLimit) {
        this.getAllIndividualMemberTaskList();
      } else if (fulldate === null) {
        this.getAllIndividualMemberTaskList();
      }
    });
  }

  handleChangeInPopup(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.taskDetails;
    detail[name] = value;
    this.setState({ taskDetails: { ...detail } });
  }

  handleChangeInPriceTable(value, name, year, tableName) {
    var table = this.state.percentageDiscountTable;
    var allowable = this.state.allowableDiscountTable;
    //var allowableValue = Number(allowable[year][name]);
    var approvalStatus = [];
    value = isNaN(value) ? 0 : Number(value);
    if (value > 100) {
      value = 100;
    }
    // if (tableName === "PercentageDiscountTable") {
    table[year][name] = value;
    if (table[year]["annualFee"] > allowable[year]["annualFee"]) {
      approvalStatus.push("Required Approval");
    } else {
      approvalStatus.push("Ok");
    }
    if (table[year]["entranceFee"] > allowable[year]["entranceFee"]) {
      approvalStatus.push("Required Approval");
    } else {
      approvalStatus.push("Ok");
    }
    if (table[year]["includedChapter"] > allowable[year]["includedChapter"]) {
      approvalStatus.push("Required Approval");
    } else {
      approvalStatus.push("Ok");
    }
    if (table[year]["newChapterPrice"] > allowable[year]["newChapterPrice"]) {
      approvalStatus.push("Required Approval");
    } else {
      approvalStatus.push("Ok");
    }
    if (approvalStatus.indexOf("Required Approval") > -1) {
      table[year]["status"] = "Required Approval";
    } else {
      table[year]["status"] = "Ok";
    }
    this.setState({ percentageDiscountTable: table }, () =>
      this.getCalculatedFinalPrice()
    );
  }

  onDropDownChange = (index) => {
    this.setState({ setDropdownOpen: true });
    this.setState({ setDropdownIndex: index });
  };

  handleChangeCheckBox = (e) => {
    const {  checked } = e.target;

    let details = this.state.taskDetails;
    if (checked) {
      details.quotationNeeded = true;
    } else {
      details.quotationNeeded = false;
    }
    this.setState({ taskDetails: details });
  };

  handleChangeMembershipOnCheckBox = (e) => {
    const {  checked } = e.target;

    let details = this.state.taskDetails;
    if (checked) {
      details.startMembership = true;
    } else {
      details.startMembership = false;
    }
    this.setState({ taskDetails: details });
    console.log("details.startMembership :", details.startMembership);
  };

  selectTask = (value, e) => {
    e.stopPropagation();
    if (value > 0) {
      this.getTaskByIndividualMemberId(value);
      // this.getPriceTabelByCorporateMemberId(value);
      // this.getPriceTabelForTaskListProcess();
    }
  };
  handleChangeMultiDropdown(selected, identity) {
    var profile = this.state.taskDetails;
    if (identity === "Designation_TaskList") {
      profile.selectedDesignation = selected;
      this.setState({ taskDetails: profile });
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ setDropdownOpen: false });
    }
  }

  actionClick = (index, value, option, event) => {
    
   // var id = this.state.taskList[index].taskId;
    this.setState({ selectedTaskId: this.state.taskList[index].taskId }, () => {
      /// this.selectTask(id, event);
    });
    if (value && value > 0) {
      this.getTaskByIndividualMemberId(value);
    }
  };

  actions = (element, index, value) => {
    return element !== "taskId" ? null : (
      <>
        <ActionContainer>
          <DropdownAction
            key={index}
            value={value}
            id={index}
            index={index}
            options={this.state.actionDropdown}
            align="right"
            onOptionClick={this.actionClick.bind(this)}
          />
        </ActionContainer>
      </>
    );
  };

  onProcessModalClose = (e) => {
    e.stopPropagation();
    this.setState({ setAddeditModalOpen: false });
  };

  getIndividualBasicInformationById = (id) => {
    if (id > 0) {
      this.TaskListServices.getIndividualBasicInformationById(id).then(
        (response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            console.log("Individual basic Information : ", response);
            // var individualInfo = response.responseItem.responseContent;
            this.setState({
              individualBasicInforamtion: response.responseItem.responseContent,
            });
          } else {
            this.swalServices.Error(response.message);
          }
        }
      );
    }
  };

  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <>
        <div className="main-body-section">
          <div className="bg-white px-8 pt-10 pb-12">
            <div className="grid grid-cols-12 gap-6 w-full items-center">
              <div className="2xl:col-span-7 lg:col-span-7 col-span-12 pr-2">
                <h2 className="text-xl text-[#000000] font-bold mb-1">
                  Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="grid grid-cols-12 gap-6 items-center w-full">
                  <div className="2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                    <input
                      id="default"
                      name="dateFrom"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      type="date"
                      value={this.state.pagination.dateFrom}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                  <div className="2xl:col-span-2 lg:col-span-2 col-span-12 w-full text-center">
                    <h2 className="text-xl theme-color">To</h2>
                  </div>
                  <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                    <input
                      id="default"
                      name="dateTo"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      type="date"
                      value={this.state.pagination.dateTo}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="2xl:col-span-1 lg:col-span-1 col-span-12 pt-8 text-center">
                <div className="border-l-[1px] border-[#000000] h-[48px] inline-block mx-auto"></div>
              </div>
              <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                <h2 className="text-xl text-[#000000] font-bold mb-1">
                  Source
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>

                <div className="">
                  <DropdownSelect
                    drpIdentity={"sourceFilter"}
                    optionArray={this.state.sourceList}
                    setFilterParameters={this.setFilterParametersForList.bind(
                      this
                    )}
                    value={this.state.pagination.filterBySourceId}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-6 gap-4 pt-10 px-8">
            <h2 className="col-start-1 col-end-3 text-3xl font-bold text-[#000000]">
              {`Total:${this.state.totalResultes}`}
            </h2>
            <div className="col-end-7 col-span-3 grid grid-cols-3 items-center">
              <caption className="text-[#000000] text-xl text-right pr-5">
                Search by Company{" "}
              </caption>
              <input
                id="default"
                className="col-span-2 text-lg font-bold h-[55px] w-full text-[#000000] border-white"
                type="text"
                onChange={(e) => this.searchByName(e.target.value)}
              />
            </div>
          </div>
          <main className="pb-10">
            <div className="w-full mx-auto">
              <div className="relative">
                <div>
                  <div className=" mt-6">
                    <Table
                      columns={[
                        {
                          name: "registrationDate",
                          title: "Registration Date",
                        },
                        { name: "FullName", title: "Name" },
                        { name: "Email", title: "Email Address" },
                        { name: "CompanyName", title: "Company Name" },
                        // { name: "Industry", title: "Industry" },
                        //{ name: "CompanyUEN", title: "Company UEN" },
                        { name: "source", title: "Source" },
                        { name: "StatusName", title: "Status" },
                        { name: "taskId", title: "Action" },
                      ]}
                      pagination={this.state.pagination}
                      rows={this.state.taskList}
                      sortingColumns={["source"]}
                      isLoading={this.state.isLoading}
                      totalResultes={this.state.totalResultes}
                      totalCount={this.state.totalResultes}
                      setPagination={this.setPagination.bind(this)}
                      customScope={[
                        {
                          column: "taskId",
                          renderTableData: this.actions.bind(this),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div className="member-task-process">
          <IndividualMembershipTaskListProcess
            handleChangeCheckBox={this.handleChangeCheckBox.bind(this)}
            handleChangeMembershipOnCheckBox={this.handleChangeMembershipOnCheckBox.bind(
              this
            )}
            setAddeditModalOpen={this.state.setAddeditModalOpen}
            membershipTypes={this.state.membershipTypes}
            transactionTypes={this.state.transactionTypes}
            billingCategories={this.state.billingCategories}
            membershipRevenueList={this.state.membershipRevenueList}
            sourceList={this.state.sourceList}
            users={this.state.users}
            tenureArr={this.state.tenureList}
            numberOfChapters={this.state.numberOfChapters}
            taskDetails={this.state.taskDetails}
            basePriceTable={this.state.basePriceTable}
            allowableDiscountTable={this.state.allowableDiscountTable}
            percentageDiscountTable={this.state.percentageDiscountTable}
            systemCalculatedTable={this.state.systemCalculatedTable}
            disablePaymentButton={this.state.disablePaymentButton}
            disableSubscription={this.state.disableSubscription}
            validState={this.state.validState}
            validStateForInvoice={this.state.validStateForInvoice}
            setModalOpen={() => this.setState({ setAddeditModalOpen: false })}
            handleChange={this.handleChangeInPopup.bind(this)}
            handleChangeInPriceTable={this.handleChangeInPriceTable.bind(this)}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ setAddeditModalOpen: false });
            }}
            validateField={this.validateField.bind(this)}
            validateFieldForInvoice={this.validateFieldForInvoice.bind(this)}
            setFilterParameters={this.setFilterParameters.bind(this)}
            addEditMembershipTask={this.addEditMembershipTask.bind(this)}
            addEditIndividualMembershipTaskAndSendPaymentLink={this.addEditIndividualMembershipTaskAndSendPaymentLink.bind(
              this
            )}
            sendInvoiceEmailToCorporateMember={this.sendInvoiceEmailToCorporateMember.bind(
              this
            )}
            sendQuotationEmailToCorporateMember={this.sendQuotationEmailToCorporateMember.bind(
              this
            )}
            //sendEscalateMailToMember={this.sendEscalateMailToMember.bind(this)}
            closeModalOnBack={() => {
              this.setState({ setAddeditModalOpen: false });
            }}
            isLoading={this.state.isLoading}
            isLoadingApprove={this.state.isLoadingApprove}
            isLoadingPayment={this.state.isLoadingPayment}
            isLoadingQuotation={this.state.isLoadingQuotation}
            isLoadingInvoice={this.state.isLoadingInvoice}
            isEscalateLodaing={this.state.isEscalateLodaing}
            handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(
              this
            )}
            designationList={this.state.designationList}
            corporatePricingDetails={this.state.corporatePricingDetails}
            UnrejectMemberApplication={this.rejectMemberApplication.bind(this)}
            rejectMemberApplication={this.setSendRejectionReasonFlag.bind(this)}
            countryList={this.state.countryList}
            placeOfBirthList={this.state.placeOfBirthList}
            nationalityList={this.state.nationalityList}
            highestEducationList={this.state.highestEducationList}
            hearAboutUsList={this.state.hearAboutUsList}
            individualBasicInforamtion={this.state.individualBasicInforamtion}
           
          />
        </div>
        <div className="membership-task-list-process-sec">
          {/* <SendForApprove
            companyName={this.state.taskDetails.companyName}
            email={this.state.taskDetails.email}
            setSendApproveModal={this.state.setSendApprove}
            setOpenModal={() => this.setState({ setSendApprove: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ setSendApprove: false });
            }}
          /> */}
          <SendPaymentLink
            companyName={this.state.taskDetails.companyName}
            email={this.state.taskDetails.email}
            paymentLinkModal={this.state.setPaymentLink}
            setOpenModal={() => this.setState({ setPaymentLink: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ setPaymentLink: false });
            }}
          />
          <RejectMemberApplication
            //companyName={this.state.taskDetails.companyName}
            //email={this.state.taskDetails.email}
            isRejectMemberApplication={this.state.isRejectMemberApplication}
            setOpenModal={() =>
              this.setState({ isRejectMemberApplication: false })
            }
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ isRejectMemberApplication: false });
              this.getAllIndividualMemberTaskList();
            }}
          />

          <SendRejectionReason
            rejectionModal={this.state.setRejectionReason}
            sendRejectionReason={this.state.sendRejectionReason}
            isLoadingRejectAplication={this.state.isLoading}
            setOpenModal={() => this.setState({ setRejectionReason: false })}
            //handleChangeSendRejectionReasonDetails={this.handleChangeSendRejectionReasonDetails.bind(
            //  this
           // )}
            // onClose={(e) => {e.stopPropagation();
            //   this.setState({ setSendClarifications: false });
            // }}
			      onTinyEceEditorChange={(data) =>
              this.handleChangeSendRejectionReasonDetails(data)
            }
            btn_color={"btn-blue"}
            rejectSLOTSMemberApplication={this.rejectMemberApplication.bind(
              this
            )}
          />
          {/* <SendQuotation
            companyName={this.state.taskDetails.companyName}
            email={this.state.taskDetails.email}
            setSendQuotationModal={this.state.sendQuotation}
            setOpenModal={() => this.setState({ sendQuotation: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ sendQuotation: false });
            }}
          /> */}
          {/* <SendInvoice
            companyName={this.state.taskDetails.companyName}
            email={this.state.taskDetails.email}
            setSendInvoiceModal={this.state.sendInvoice}
            setOpenModal={() => this.setState({ sendInvoice: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ sendInvoice: false });
            }}
          /> */}
          {/* <EscalateModal
            companyName={this.state.taskDetails.companyName}
            email={this.state.taskDetails.email}
            setSendInvoiceModal={this.state.sendEscalation}
            setOpenModal={() => this.setState({ sendEscalation: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ sendEscalation: false });
            }}
          /> */}
        </div>
      </>
    );
  }
}
