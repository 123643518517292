import React from "react"
const DateInput = (props) => {
    return (
        <input
            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
            type="date"
            name={props.name}
            // max={moment(new Date()).format("yyyy-MM-DD")}
            value={props.value}
            onChange={(event) => props.handleChange(event, props.identity)}
            placeholder={props.placeholder ? props.placeholder : null}
            onBlur={props.onBlur ? () => props.onBlur() : null}
        />
    );
}
export default DateInput;