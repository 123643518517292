import React from "react";

function ProspectsCorporateMember(props) {
  return (
    <div>
      <div className="grid gap-8 py-4 grid-cols-2">
        <div className="relative justify-center prospects p-4 pl-24">
          <div className="flex flex-row items-center justify-between title">
            <div className="icon">Prospects</div>
            <div className="number">{props.dashboardDetails.totalProspects}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>Not Started</div>
            <div className="underline">{props.dashboardDetails.notStarted}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>In Progress</div>
            <div className="underline">{props.dashboardDetails.inProgress}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>Closed - Successful</div>
            <div className="underline">{props.dashboardDetails.closedSuccessful}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>Closed - To Revisit</div>
            <div className="underline">{props.dashboardDetails.closedToRevisit}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>Closed - Lost</div>
            <div className="underline">{props.dashboardDetails.closedLost}</div>
          </div>
        </div>
        <div className="relative justify-center corporateMember p-4 pl-24">
          <div className="flex flex-row items-center justify-between title">
            <div className="icon">Corporate Member</div>
            <div className="number">{props.dashboardDetails.totalCorporateMember}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>Expiry within 60 days</div>
            <div className="underline">{props.dashboardDetails.expiryWithin60}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>Expiry within 45 days</div>
            <div className="underline">{props.dashboardDetails.expiryWithin45}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>Expiry within 30 days</div>
            <div className="underline">{props.dashboardDetails.expiryWithin30}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>Renewed last 7 days</div>
            <div className="underline">{props.dashboardDetails.renewedlast7}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProspectsCorporateMember;
