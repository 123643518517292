import React, { useRef } from "react";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import ValidationText from "../../../utils/validation/ValidationText";
import DateInput from "../../../components/inputs/DateInput";
import NumberInput from "../../../components/UIComponent/NumberInput";

const CPDEditClaimApproval = () => {
  const fileInputRef = useRef(null);

  const handleFileUploadClick = () => {
    // Logic to handle file upload, e.g., trigger file input click
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    // Handle file upload logic here
    const file = e.target.files[0];
    console.log("Uploaded file:", file);
  };
  const handleApproveCPDPoints = () => {
    // Logic to handle approving CPD points
    console.log("CPD Points Approved");
  };

  const handleSave = () => {
    // Logic to handle saving data
    console.log("Data saved");
  };

  return (
    <div>
      <div className="main-body-section">
        <div className="flex">
          <div className="relative flex flex-col flex-1 "></div>
        </div>
        <main className="pb-10">
          <div className="custom-card shadow-lg mb-10">
            <div className="col-span-12 mb-4">
              <div className="form-info-title">
                <h4 className="text-[#2d2d2d] small-title font-bold theme-color">
                  CPD Details
                </h4>
              </div>
            </div>

            <div className="filter-info grid justify-between grid-cols-12 lg:gap-2 md:gap-2 2xl:gap-20">
              <div className="filter-types 2xl:col-span-5 lg:col-span-6 col-span-12">
                <h2 className="text-xl text-[#000000] font-bold mb-1">
                  CPD Certificate Number
                  <span className="text-[#C00000]"> *</span>
                </h2>
                <input
                  id="default"
                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                />
              </div>

              <div className="lg:col-span-6 xl:col-span-5 2xl:col-span-5 col-span-12 input-sec-texts">
                <h2 className="text-xl text-[#000000] font-bold mb-1">
                  Claim Points
                  <span className="text-[#C00000]"> *</span>
                </h2>

                <div className="dropdown">
                  <NumberInput
                    placeholder=""
                    name="Claim Points"
                    identity="claimPoints"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-12 mb-4 mt-8">
              <div className="form-info-title">
                <h4 className="text-[#2d2d2d] small-title font-bold theme-color">
                  Activity
                </h4>
              </div>
            </div>

            <div className="filter-info grid justify-between grid-cols-12 gap-5">
              <div className="filter-types 2xl:col-span-10 lg:col-span-10 col-span-12">
                <h2 className="text-xl text-[#000000] font-bold mb-1">
                  Course
                  <span className="text-[#C00000]"> *</span>
                </h2>
                <input
                  id="default"
                  className="form-input rounded-none w-full shadow-red text-lg"
                  type="text"
                />
              </div>

              <div className="col-span-5 grid">
                <div className="bg-white">
                  <h2 className="input-title mb-3">
                    Date of Incorporation
                    <span className="text-[#C00000]"> *</span>
                  </h2>

                  <div className="dropdown">
                    <DateInput
                    // Add props for DateInput if necessary
                    />
                  </div>
                </div>
              </div>

              <div className="grid ml-20 col-span-5">
                <div className="bg-white col-span-4">
                  <h2 className="input-title mb-3">
                    Course Time
                    <span className="text-[#C00000]"> *</span>
                  </h2>

                  <div className="flex justify-between">
                    <div className="dropdown">
                      <input
                        className="form-input rounded-none w-full shadow-red py-3 text-lg"
                        type="text"
                        placeholder="1:00 PM"
                      />
                    </div>
                    <h2 className="m-auto font-bold text-lg mx-4">to</h2>
                    <div className="dropdown">
                      <input
                        className="form-input rounded-none w-full shadow-red py-3 text-lg"
                        type="text"
                        placeholder="3:00 PM"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-12 grid">
                <div className="bg-white">
                  <h2 className="input-title mb-3">
                    <span
                      className="cursor-pointer text-[#cacaca] ml-1"
                      onClick={handleFileUploadClick}
                    >
                      <span className="input-title">
                        Certificate for Verification
                      </span>
                      <span className="text-[#C00000]"> *</span>
                      (PDF, DOC, DOCX, XLS, XLSX files only; Max of 5mb)
                    </span>
                    <input
                      ref={fileInputRef}
                      id="certificateUpload"
                      type="file"
                      className="hidden"
                      accept=".pdf,.doc,.docx,.xls,.xlsx"
                      onChange={handleFileInputChange}
                    />
                  </h2>
                </div>
              </div>

              <div className="col-span-12 flex justify-between mt-4">
                <button
                  className="bg-[#1d8b2c] hover:bg-green-600 text-white px-6 py-3 mr-4 text-xl font-bold"
                  onClick={handleApproveCPDPoints}
                >
                  Approved CPD Points
                </button>
                <button
                  className="bg-blue-900 hover:bg-blue-800 text-white px-9 py-3 text-xl font-bold"
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
              
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default CPDEditClaimApproval;
