import React from "react";
import ModalBasic from "../../component/ModalBasic";
import MultiSelectDropdown from "../../../components/dropdown/MultiSelectDropdown";
import ValidationText from "../../../utils/validation/ValidationText";
const TopupRegistrationHeadModal = (props) => {
  return (
    <ModalBasic
      id="send-payment-link-modal"
      modalOpen={props.TopupModal}
      // setModalOpen={props.setOpenModal}
      setModalOpen={(e) => { props.setOpenModal(e); props.getSLOTSMemberDetailByMemberId(props.SLOTSApplicationDetails.slotsMemberId) }}

      title="Update Registration Head(s)"
      noCloseIcon={false}
    >
      <div className="pb-6 px-7 mt-2">
        <div className="grid grid-cols-12 gap-4 w-full justify-between mt-8">
          {/* <div className="lg:col-span-8 col-span-12">
                <h2 className="input-title mb-3">
                   Old Registration head(s)                  
                </h2>
              </div> */}
          {/* <div className="col-span-12">
                <div className="table-section">
                  <div className="overflow-x-auto">
                    <table className="w-full">
                      <thead className="bg-[#e78e3c] text-white">
                        <tr>
                          <th className="whitespace-nowrap text-left py-3 px-5">
                            Selected Scope
                          </th>
                          <th className="whitespace-nowrap text-left py-3 px-5">
                            Workhead
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {props &&
                          props.SLOTSApplicationDetails.slotsRegistrationHeadScope?.map(
                            (scope, key) => {
                              return (
                                <tr
                                  className="bg-[#eeeeee] text-black border-t-4 border-[#ffffff]"
                                  key={key}
                                >
                                  <td className="whitespace-nowrap text-left py-2 px-5">
                                    {scope.mainGroupName}
                                  </td>
                                  <td className="whitespace-nowrap text-left py-2 px-5">
                                    {scope.workheadName}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> */}
        </div>
        <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-10">
          <div className="md:col-span-4 col-span-12">
            <h2 className="input-title mb-3">
              Main Group
              <span className="text-[#C00000]">*</span>
            </h2>
            <div className="dropdown">
              <div className="select-dropdown w-full">
                <MultiSelectDropdown
                  drpIdentity="Main_Group_Scope2"
                  options={props.mainGroupListPricing}
                  // isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  onChange={props.handleChangeInDropdown}
                  allowSelectAll={false}
                  value={props.mainGroupListPricing.filter(
                    (mainGroup) =>
                      mainGroup.value ===
                      props.selectedSLOTSRegistrationScope
                        .selectedMainGroupId
                  )}
                />
                <ValidationText
                  error={
                    props.validStateregistrationScope.error
                      .selectedMainGroupId
                  }
                />
              </div>
            </div>
          </div>
          <div className="md:col-span-4 col-span-12">
            <h2 className="input-title mb-3">
              Workhead
              <span className="text-[#C00000]">*</span>
            </h2>
            <div className="dropdown">
              <div className="select-dropdown w-full">
                <MultiSelectDropdown
                  drpIdentity="Workhead_Scope2"
                  options={props.workheadListPricing}
                  // isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  onChange={props.handleChangeInDropdown}
                  allowSelectAll={false}
                  value={props.workheadListPricing.filter(
                    (workhead) =>
                      workhead.value ===
                      props.selectedSLOTSRegistrationScope
                        .selectedWorkheadId
                  )}
                />
                <ValidationText
                  error={
                    props.validStateregistrationScope.error
                      .selectedWorkheadId
                  }
                />
              </div>
            </div>
          </div>
          <div className="md:col-span-4 col-span-12">
            <div className="flex justify-end mt-8">
              <button
                className="btn btn-orange-border"
                onClick={(event) =>
                  props.handleChange(event, "Add_SLOTS_Registration_Scope")
                }
              >
                <span className="fa fa-plus"></span>
                <span className="ml-2">Add Scope</span>
              </button>
            </div>
          </div>
        </div>
        {props.SLOTSApplicationDetails.slotsRegistrationHeadScopeTopUp
          ?.length > 0 ? (
          <>
            <div className="grid grid-cols-12 gap-4 w-full justify-between mt-8">
              <div className="lg:col-span-8 col-span-12">
                <h2 className="input-title mb-3">
                  SLOTs registration head(s) (Top-Up)
                  {/* <span className="text-[#C00000]">*</span> */}
                </h2>
              </div>
              <div className="col-span-12">
                <div className="table-section">
                  <div className="overflow-x-auto">
                    <table className="w-full">
                      <thead className="bg-[#e78e3c] text-white">
                        <tr>
                          <th className="whitespace-nowrap text-left py-3 px-5">
                            Selected Scope
                          </th>
                          <th className="whitespace-nowrap text-left py-3 px-5">
                            Workhead
                          </th>
                          <th className='whitespace-nowrap text-center py-3 px-5'>

                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.SLOTSApplicationDetails.slotsRegistrationHeadScopeTopUp?.map(
                          (scope, key) => {
                            return (
                              <tr
                                className="bg-[#eeeeee] text-black border-t-4 border-[#ffffff]"
                                key={key}
                              >
                                <td className="whitespace-nowrap text-left py-2 px-5">
                                  {scope.mainGroupName}
                                </td>
                                <td className="whitespace-nowrap text-left py-2 px-5">
                                  {scope.workheadName}
                                </td>
                                <td className='whitespace-nowrap text-center py-2 px-5'>
                                  <button
                                    className='action-btn' onClick={(event) => props.removeRegistrationHead(event, key)}
                                  >
                                    <img className='h-[30px] ' src='../delete-icon.svg' alt=''>
                                    </img>
                                  </button>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>{" "}
          </>
        ) : null}


        {props.SLOTSApplicationDetails.slotsRegistrationHeadScopeTopUp
          ?.length > 0 ? (
          <>
            <div className="grid grid-cols-12 gap-4 w-full justify-between">
              <div className="lg:col-span-6 col-span-12 mt-5">
                <h2 className="input-title">
                  Price Calculator
                  <span className="text-[#C00000]">*</span>
                </h2>
              </div>
              <div className="col-span-12">
                <div className="table-section">
                  <div className="overflow-x-auto">
                    <table className="w-full">
                      <thead className="bg-[#e78e3c] text-white">
                        <tr>
                          <th className="whitespace-nowrap text-left py-3 px-5">
                            Description
                          </th>
                          <th className="whitespace-nowrap text-left py-3 px-5">
                            Quantity
                          </th>
                          <th className="whitespace-nowrap text-center py-3 px-5">
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.currentPriceCalculatorArray.map(
                          (price, key) => {
                            return (
                              <tr
                                className="bg-[#eeeeee] text-black border-t-4 border-[#ffffff]"
                                key={key}
                              >
                                <td className="whitespace-nowrap text-left py-2 px-5">
                                  {price.description}
                                </td>
                                <td className="whitespace-nowrap text-left py-2 px-5">
                                  {price.quantity}
                                </td>
                                <td className="whitespace-nowrap text-center py-2 px-5">
                                  ${price.Amount}
                                </td>
                              </tr>
                            );
                          }
                        )}
                        {/* <tr className='bg-[#eeeeee] text-black border-t-4 border-[#ffffff]'>
                                                    <td className='whitespace-nowrap text-left py-2 px-5' colSpan="2">
                                                        Subtotal (before GST)
                                                    </td>
                                                    <td className='whitespace-nowrap text-center py-2 px-5'>
                                                        $700
                                                    </td>
                                                </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}


        <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-10">


          {/* {props.SLOTSApplicationDetails.statusId === 4 && props.currentPriceCalculatorArray[2].Amount < props.SLOTSApplicationDetails.priceCalculator[2].amount ?
            <>
              <div className="flex md:col-span-6 col-span-12">
                <button
                  className="btn btn-orange text-base text-[#fff] font-bold mt-8"
                  onClick={(e) => { props.onClose(e); props.updateSLOTSRegistrationHeadScopeTopUp() }}
                >
                  Update With Main Price
                </button>
              </div>
            </>
            :
            null
          } */}

          <div className="flex justify-end md:col-span-6 col-span-12">
            <button
              className="btn btn-orange text-base text-[#fff] font-bold mt-8"
              onClick={(e) => { props.onClose(e); props.getSLOTSMemberDetailByMemberId(props.SLOTSApplicationDetails.slotsMemberId) }}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </ModalBasic>
  );
};
export default TopupRegistrationHeadModal;
