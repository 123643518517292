
import React, { Component } from "react";
import Table from "../../components/table/Table";
import moment from "moment";
import SwalServices from "../../services/swalServices/SwalServices";
import PageLoader from "../../components/common/pageLoader/PageLoader";
import { saveAs } from "file-saver";
import ReportForSlotsServices from "../../services/axiosServices/apiServices/ReportForSlotsServices";
import DropdownAction from "../../components/dropdown/DropdownAction";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";


export default class ReportForSLOTs extends Component {
    constructor(props) {
        super(props);

        this.ReportForSlotsServices = new ReportForSlotsServices();
        this.swalServices = new SwalServices();
        this.state = {
            generateallactiveSLOTsRecordsList: [],
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                MembershipType: 0
            },
            totalCount: 0,
            totalResultes: 0,
            previousReportFor: "",
            previousReportStartDate: "",
            previousReportEndDate: "",
            isLoading: false,
            outstandingInvoicesList: [],
            actionDropdownPaid: ["Download"],
        };
    }

    reset = () => {
        let resetDetails = this.state.transactionReportRequest
        resetDetails.currentDate = ""
        resetDetails.transactionReportFor = ""
        this.setState({ transactionReportRequest: resetDetails })
    }

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getAllForSLOTsRecords();
    };

    generateallactiveSLOTsRecords = () => {
        this.ReportForSlotsServices.generateallactiveSLOTsRecords().then((response) => {

            if (response.statusCode === 200 && response.responseItem !== null) {
                if (response.responseItem.responseContent !== null) {
                    let data = response.responseItem.responseContent;
                    if (data.length > 0) {
                        data.map((x) => {
                            x.subscriptionStartDate = x.subscriptionStartDate ? moment(x.subscriptionStartDate).format("DD MMM YYYY") : "";
                            x.subcriptionEndDate = x.subcriptionEndDate ? moment(x.subcriptionEndDate).format("DD MMM YYYY") : "";
                            console.log(x.subscriptionStartDate);
                            return x;
                        })

                    }
                }
            }
            else if (response.statusCode === 409) {
                this.swalServices.Error(response.message);
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.getAllForSLOTsRecords()
            // this.setState({ isLoading: false });
        });
    }

    downloadSheetForSLOTsRecords = (momReportForSLOTsId) => {

        //let request = { ...this.state.pagination };
        let newDate = new Date()
        let request = [];
        request.push(momReportForSLOTsId)
        this.ReportForSlotsServices.downloadSheetForSLOTsRecords(request).then((response) => {
            if (response.status === 200 && response.data != null) {
                var blob = new Blob([response.data])
                saveAs(blob, `MOMReportForSLOTs_` + moment(newDate).format("DDMMMYYYY") + ".xlsx");
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }

    componentDidMount() {
        this.getAllForSLOTsRecords()
    }

    getAllForSLOTsRecords = () => {
        this.setState({ isLoading: true }, () => {

            this.ReportForSlotsServices.getAllForSLOTsRecords(this.state.pagination).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    let data = response.responseItem.responseContent.itemList;
                    data.map((x) => {
                        x.dateOfGeneration = x.dateOfGeneration ? moment(x.dateOfGeneration).format("DD-MMM-yyyy on hh:mm a") : "-";
                        return x;
                    });
                    this.setState({ generateallactiveSLOTsRecordsList: data }, () => {
                        this.setState({ isLoading: false })
                        this.setState({ totalResultes: response.responseItem.responseContent.totalCount }, () => console.log("Total Result :", this.state.totalResultes));
                    });

                } else {
                    this.swalServices.Error("Something went wrong.");
                    this.setState({ isLoading: false });
                }
            })
        });

    }

    actions = (element, index, value) => {
        return element !== "momReportForSLOTsId" ? null : (
            <div className="relative w-full credit-note-action-dropdown">
                <ActionContainer>
                    <div className="mr-8">
                        <DropdownAction
                            key={index}
                            value={value}
                            id={index}
                            index={index}
                            options={this.state.actionDropdownPaid}
                            align="right"
                            onOptionClick={this.actionClick.bind(this)}
                        />
                    </div>
                </ActionContainer>
            </div>
        );
    };

    actionClick = (index, value, option, event) => {
        if (option === "Download") {
            //if (value && value > 0) {
            let momReportForSLOTsId = this.state.generateallactiveSLOTsRecordsList[index].momReportForSLOTsId;
            this.downloadSheetForSLOTsRecords(momReportForSLOTsId)
            //}
        }
    };

    Generate = () => {
        let detail = this.state.pagination;
        detail.pageNo = 1;
        //generate
        this.setState({ pagination: detail }, () => { this.generateallactiveSLOTsRecords() })
    }

    GenerateExportMembershipData = () => {
        this.setState({ isLoading: true }, () => {
            if (this.state.pagination.MembershipType > 0) {
                this.financeService.GenerateExportMembershipData(this.state.pagination.MembershipType).then((response) => {
                    if (response.statusCode === 200 && response.responseItem != null) {
                        this.GetAllExportMembershipDataRecords();
                    } else {
                        this.swalServices.Error("Something went wrong.");
                        this.setState({ isLoading: false });
                    }
                })
            } else {
                this.swalServices.Alert("Please select  Membership type.");
            }
        });
    }

    render() {
        return (
            <div className="main-body-section finance-body-sec">
                <div className="bg-white">
                    <div className="grid grid-cols-12 gap-6 w-full items-center p-8">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                            <div className="col-span-12 w-full">
                                <div className="flex items-center justify-between w-full">
                                    <h2 className="text-2xl font-bold theme-color">
                                        MOM Report for SLOTs
                                    </h2>
                                    <button className="btn btn-blue text-xl border text-white "
                                        onClick={() => this.Generate()}
                                    >
                                        Generate
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-span-4">
                            <button className="btn btn-blue text-xl border text-white mt-8" onClick={() => this.generateallactiveSLOTsRecords()}>
                           Show MOM Report  ForSLOTs 
                            </button>
                        </div> */}
                        {/* {this.state.generateallactiveSLOTsRecordsList &&
                            this.state.generateallactiveSLOTsRecordsList.length > 0
                            ? */}
                        {/* <div className="col-span-4">
                            <button className="btn btn-blue text-xl border text-white mt-8" onClick={() => this.downloadSheetForSLOTsRecords()}>
                                Download Excel
                            </button>
                        </div> */}
                        {/* : null} */}
                    </div>
                </div>
                {/* Add/Edit GST End */}
                {/* Section 3 Start */}

                <main className="pb-10">
                    <div className="w-full mx-auto">
                        <div className="relative">
                            <div>
                                <div className=" mt-6">
                                    {this.state.isLoading ? <PageLoader /> :
                                        <Table
                                            columns={[
                                                { name: "dateOfGeneration", title: "Generation Date" },
                                                { name: "membershipType", title: "TYPE OF MEMBERSHIP", },
                                                { name: "momReportForSLOTsId", title: "Actions" },

                                            ]}
                                            pagination={this.state.pagination}
                                            rows={this.state.generateallactiveSLOTsRecordsList}
                                            sortingColumns={[""]}
                                            isLoading={this.state.isLoading}
                                            totalResultes={this.state.totalResultes}
                                            totalCount={this.state.totalResultes}
                                            setPagination={this.setPagination.bind(this)}
                                            customScope={[
                                                {
                                                    column: "momReportForSLOTsId",
                                                    renderTableData: this.actions.bind(this),
                                                },
                                            ]}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}