
import React, { Component } from "react";
import Selector from "../../components/dropdown/commonDropdown/Selector";
import Table from "../../components/table/Table";
import BookingServices from "../../services/axiosServices/apiServices/BookingServices";
import moment from "moment";
import SwalServices from "../../services/swalServices/SwalServices";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import { CommonValidationMessages } from "../../utils/Messages";
import ValidationText from "../../utils/validation/ValidationText";
import EntryManagementServices from "../../services/axiosServices/apiServices/EntryManagementServices";
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import MembershipPricingServices from "../../services/axiosServices/apiServices/MembershipPricingServices";
import PageLoader from "../../components/common/pageLoader/PageLoader";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import { saveAs } from "file-saver";


export default class TransactionReport extends Component {
    constructor(props) {
        super(props);
        this.BookingServices = new BookingServices();
        this.entryServices = new EntryManagementServices();
        this.swalServices = new SwalServices();
        this.commonServices = new CommonServices();
        this.membershipPricingServices = new MembershipPricingServices();
        this.financeService = new FinanceServices();
        this.state = {
            statusList: [
                {
                    value: 1,
                    label: "Membership",
                },
                {
                    value: 2,
                    label: "External Invoice",
                },
            ],
            // transactionReportRequest: {
            //     currentDate: "",
            //     transactionReportFor: ""
            // },
            transactionRecordList: [],
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "CreatedAt",
                orderFlag: 0,
                searchText: "",
                startDate: "",
                endDate: "",
                transactionReportFor: "Membership"
            },
            totalCount: 0,
            totalResultes: 0,
            previousReportFor: "",
            previousReportStartDate: "",
            previousReportEndDate: "",
            isLoading: false,
            outstandingInvoicesList: [],
            validationRule: {
                startDate: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "Start date for transaction report"),
                    },
                ],
                endDate: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "End date for transaction report"),
                    },
                    {
                        type: "minEndDate",
                        minEndDate: "startDate",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "greater end date")
                    }
                ],
                transactionReportFor: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "type of transaction report"),
                    },
                ],
            },
            validState: {
                isValid: true,
                error: {},
            },
        };
    }

    reset = () => {
        let resetDetails = this.state.transactionReportRequest
        resetDetails.currentDate = ""
        resetDetails.transactionReportFor = ""
        this.setState({ transactionReportRequest: resetDetails })
    }

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getTransactionRecordList();
    };

    handleChangeInDropdown(selected, drpIdentity) {
        if (drpIdentity === "TransactionReportType") {
            var dataArr = this.state.pagination
            if (selected.value > 0) {
                dataArr.transactionReportFor = selected.label;
            } else {
                dataArr.transactionReportFor = "";
            }
            this.setState({ transactionRecordRequest: dataArr }, () => {
                this.validateField("transactionReportFor");
            })
        }
    }


    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'startDate') {
            let date = this.state.pagination
            date.startDate = value
            this.setState({ startDate: date })
        }
        if (name === 'endDate') {
            let date = this.state.pagination
            date.endDate = value
            this.setState({ endDate: date })
        }
    }

    
    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.pagination,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.pagination,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };

    // generateTransactionReport = () => {
    //     let isValidateAllFields = this.isValidateAllFields()
    //     if (isValidateAllFields) {
    //         if (this.state.transactionReportRequest.currentDate) {
    //             this.setState({ isLoading: true });
    //             let request = {};
    //             request.currentDate = moment(this.state.transactionReportRequest.currentDate).format("DD MMM YYYY");
    //             request.transactionReportFor = this.state.transactionReportRequest.transactionReportFor;
    //             this.financeServices.generateTransactionReport(request).then((response) => {
    //                 if (response.statusCode === 200 && response.responseItem != null) {
    //                     this.swalServices.Success("Voucher record generated successfully.");
    //                     this.getAllTransactionReportList();
    //                     this.reset()
    //                 }
    //                 else if (response.statusCode === 409) {
    //                     this.swalServices.Error(response.message);
    //                     this.reset()
    //                 }
    //                 else {
    //                     this.swalServices.Error(response.message);
    //                     this.reset()
    //                 }
    //                 this.setState({ isLoading: false });
    //             });
    //         }
    //     }
    // }

    getTransactionRecordList = () => {
        let isValidateAllFields = this.isValidateAllFields()
        if (isValidateAllFields === true) {
            if (this.state.pagination.startDate && this.state.pagination.endDate) {
                this.setState({ isLoading: true });
                let request = { ...this.state.pagination };
                request.startDate = moment(this.state.pagination.startDate).format("DD MMM YYYY");
                request.endDate = moment(this.state.pagination.endDate).format("DD MMM YYYY");
                console.log(this.state.pagination)
                this.financeService.getTransactionRecordList(request).then((response) => {
                    if (response.statusCode === 200 && response.responseItem !== null) {
                        if (response.responseItem.responseContent !== null) {
                            let details = response.responseItem.responseContent.itemList
                            if (details.length > 0) {
                                details.map((date) => {
                                    date.receiptDate = date.receiptDate ? moment(date.receiptDate).format("DD MMM YYYY") : ""
                                    return date;
                                })
                            }
                            this.setState({
                                transactionRecordList: details,
                                totalCount: response.responseItem.responseContent.totalCount,
                                totalResultes: response.responseItem.responseContent.totalCount,
                                previousReportFor: this.state.pagination.transactionReportFor,
                                previousReportStartDate: this.state.pagination.startDate,
                                previousReportEndDate: this.state.pagination.endDate
                            })
                        }
                    }
                    else if (response.statusCode === 409) {
                        this.swalServices.Error(response.message);
                    }
                    else {
                        this.swalServices.Error(response.message);
                    }
                    this.setState({ isLoading: false });
                });
            }
        }
    }

    downloadSheetForTransactionRecord = () => {
        let isValidateAllFields = this.isValidateAllFields()
        if (isValidateAllFields) {
            if (this.state.pagination.startDate && this.state.pagination.endDate) {
                let request = { ...this.state.pagination };
                request.startDate = moment(this.state.pagination.startDate).format("DD MMM YYYY");
                request.endDate = moment(this.state.pagination.endDate).format("DD MMM YYYY");
                this.financeService.downloadSheetForTransactionRecord(request).then((response) => {
                    if (response.status === 200 && response.data != null) {
                        this.setState({
                            previousReportFor: this.state.pagination.transactionReportFor,
                            previousReportStartDate: this.state.pagination.startDate,
                            previousReportEndDate: this.state.pagination.endDate
                        })
                        var blob = new Blob([response.data])
                        saveAs(blob, `${this.state.pagination.transactionReportFor}_TransactionReport` + ".xlsx");
                    }
                    else {
                        this.swalServices.Error(response.message);
                    }
                    this.setState({ isLoading: false });
                });
            }
        }
    }

    render() {
        return (
            <div className="main-body-section finance-body-sec">
                {/* Add/Edit GST Start */}
                <div className="bg-white">
                    <div className="grid grid-cols-12 gap-6 w-full items-center px-8 py-10">
                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                for="default"
                            >
                                Start Date<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="">
                                <input
                                    id="startDate"
                                    name="startDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(this.state.pagination.startDate).format("yyyy-MM-DD")}
                                    onChange={(event) => this.handleChange(event)}
                                    onBlur={() => this.validateField("startDate")}
                                //    disabled
                                />
                            </div>
                            <ValidationText error={this.state.validState.error.startDate} />
                        </div>

                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                for="default"
                            >
                                End Date<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="">
                                <input
                                    id="endDate"
                                    name="endDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(this.state.pagination.endDate).format("yyyy-MM-DD")}
                                    onChange={(event) => this.handleChange(event)}
                                    onBlur={() => this.validateField("endDate")}
                                //    disabled
                                />
                            </div>
                            <ValidationText error={this.state.validState.error.endDate} />
                        </div>

                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                for="default"
                            >
                                Transcation Report For<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="">
                                <Selector
                                    options={this.state.statusList}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    onChange={this.handleChangeInDropdown.bind(this)}
                                    allowSelectAll={false}
                                    value={this.state.statusList.find(
                                        (item) =>
                                            item.label === this.state.pagination.transactionReportFor
                                    )}
                                    drpIdentity="TransactionReportType"
                                    disabled
                                />
                            </div>
                            <ValidationText error={this.state.validState.error.transactionReportFor} />
                        </div>

                        <div className="col-span-4">
                            <button className="btn btn-blue text-xl border text-white mt-8" onClick={() => this.getTransactionRecordList()}>
                                Show Transaction Report
                            </button>
                        </div>
                        {this.state.transactionRecordList &&
                            this.state.transactionRecordList.length > 0 &&
                            this.state.previousReportFor === this.state.pagination.transactionReportFor &&
                            this.state.previousReportStartDate === this.state.pagination.startDate &&
                            this.state.previousReportEndDate === this.state.pagination.endDate ?
                        <div className="col-span-4">
                            <button className="btn btn-blue text-xl border text-white mt-8" onClick={() => this.downloadSheetForTransactionRecord()}>
                                Download Excel
                            </button>
                        </div>
                         : null}
                    </div>
                </div>
                {/* Add/Edit GST End */}
                {/* Section 3 Start */}
                <div className="grid grid-cols-12 gap-6 w-full items-center justify-center px-8 py-10">
                    <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                        {this.state.isLoading ? <PageLoader /> :
                            <Table
                                columns={[
                                    { name: "invoiceNo", title: "Invoice No", },
                                    { name: "paymentStatus", title: "Payment Status" },
                                    // { name: "paymentVia", title: "payment Mode" },
                                    // { name: "totalPrice", title: "Total Invoice Amount" },
                                    // { name: "voucherCode", title: "Voucher Code" },
                                    { name: "receiptDate", title: "Payment Date" },
                                    { name: "transactionId", title: "Order Id" },
                                ]}
                                pagination={this.state.pagination}
                                rows={this.state.transactionRecordList}
                                sortingColumns={[""]}
                                isLoading={this.state.isLoading}
                                totalResultes={this.state.totalResultes}
                                totalCount={this.state.totalCount}
                                setPagination={this.setPagination.bind(this)}
                            />
                            // {/* // <table className="w-full mx-auto my-5 fixed_header batch-job-extraction-table border">
                            // //     <thead>
                            // //         <tr className="text-base font-bold flex">
                            // //             <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                            // //                 <div className="font-bold tble-th-txt text-left">
                            // //                     Generate Date
                            // //                 </div>
                            // //             </th>
                            // //             <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                            // //                 <div className="font-bold tble-th-txt text-left">
                            // //                     Used Count
                            // //                 </div>
                            // //             </th>
                            // //             <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                            // //                 <div className="font-bold tble-th-txt text-left">
                            // //                     Used For
                            // //                 </div>
                            // //             </th>
                            // //             <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                            // //                 <div className="font-bold tble-th-txt text-left">
                            // //                     Start Date
                            // //                 </div>
                            // //             </th>
                            // //             <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                            // //                 <div className="font-bold tble-th-txt text-left">
                            // //                     End Date
                            // //                 </div>
                            // //             </th>

                            // //             <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                            // //                 <div className="font-bold tble-th-txt text-center">Action</div>
                            // //             </th>
                            // //         </tr>
                            // //     </thead>
                            // //     <tbody className="scrollit text-sm custom-tbody">
                            // //         {this.state.transactionReportList.map((e, key) => {
                            // //             return (
                            // //                 <tr className="custom-tbl-td flex items-center" key={key}>
                            // //                     <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%] ">
                            // //                         {moment(e.transactionReportGenerationDate).format("DD MMM YYYY")}
                            // //                     </td>
                            // //                     <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                            // //                         {e.transactionReportCount}
                            // //                     </td>

                            // //                     <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                            // //                         {e.transactionReportFor}
                            // //                     </td>
                            // //                     <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                            // //                         {moment(e.transactionReportStartDate).format("DD MMM YYYY")}
                            // //                     </td>
                            // //                     <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                            // //                         {moment(e.transactionReportEndDate).format("DD MMM YYYY")}
                            // //                     </td>

                            // //                     <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                            // //                         <button
                            // //                             className="action-btn mx-auto flex items-center"
                            // //                             aria-controls="add-edit-modal"
                            // //                             onClick={() => {
                            // //                                 this.downloadSheetByTransactionReportId(e.transactionReportId, e.transactionReportFor, e.transactionReportStartDate);
                            // //                             }}
                            // //                         >
                            // //                             <p className="ml-2 tool-tip-txt underline text-[#81a3b8]">
                            // //                                 Download
                            // //                             </p>
                            // //                         </button>
                            // //                     </td>
                            // //                 </tr>
                            // //             );
                            // //         })}
                            // //     </tbody>
                            // // </table> */}
                        }
                    </div>
                </div>
                {/* Section 3 End */}
            </div>
        );
    }
}