import React from "react"
const TextInput = (props) => {
    return (
        <input
            className={`appearance-none w-full custom-input ${props.className}`}
            placeholder={props.placeholder}
            type="text"
            name={props.name}
            value={props.value}
            onChange={(event) => props.handleChange(event)}
            onBlur={props.onBlur ? () => props.onBlur() : null}
            disabled={props.disabled === true ? true : false}
            autoComplete={props.autoComplete ? props.autoComplete : "off"}
        />
    );
}
export default TextInput;