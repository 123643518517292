import React, { useState } from "react";

import Table from "../../../components/table/Table";
import { Link } from "react-router-dom";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import DateInput from "../../../components/inputs/DateInput";
import ValidationText from "../../../utils/validation/ValidationText";

const AssignInterview = () => {
  const [data, setData] = useState([
    {
      name: "John Lim",
      email: "john@gmail.com",
      tier: "Accredited Construction Professional A-Star",
      submissionDate: "10 Dec 2023",
      interviewDate: "3 Jan 2024",
      interviewTime: "2.30pm",
      batchSN: "123",
      selected: false, // Add a 'selected' property to each item
    },
    {
      name: "Alex Tan",
      email: "alex@gmail.com",
      tier: "Accredited Construction Professional A-Star",
      submissionDate: "10 Dec 2023",
      interviewDate: "3 Jan 2024",
      interviewTime: "3.00pm",
      batchSN: "123",
      selected: false,
    },
    {
      name: "Marcus Yeo",
      email: "marcus@gmail.com",
      tier: "Accredited Construction Professional A-Star",
      submissionDate: "10 Dec 2023",
      interviewDate: "3 Jan 2024",
      interviewTime: "3.30pm",
      batchSN: "123",
      selected: false,
    },
    {
      name: "Mark Lim",
      email: "mark@gmail.com",
      tier: "Accredited Construction Professional A-Star",
      submissionDate: "10 Dec 2023",
      interviewDate: "-",
      interviewTime: "-",
      batchSN: "-",
      selected: false,
    },
    {
      name: "James Ong",
      email: "james@gmail.com",
      tier: "Accredited Construction Professional A-Star",
      submissionDate: "10 Dec 2023",
      interviewDate: "-",
      interviewTime: "-",
      batchSN: "-",
      selected: false,
    },
  ]);

  const [selectAll, setSelectAll] = useState(false);

  // Function to handle checkbox click for each row
  const handleCheckboxChange = (index) => {
    const newData = [...data];
    newData[index].selected = !newData[index].selected;
    setData(newData);
    checkSelectAll(newData);
  };

  // Function to handle checkbox click in the header
  const handleSelectAll = () => {
    const newData = data.map((item) => ({
      ...item,
      selected: !selectAll,
    }));
    setData(newData);
    setSelectAll(!selectAll);
  };

  // Function to check if all checkboxes are selected
  const checkSelectAll = (newData) => {
    setSelectAll(newData.every((item) => item.selected));
  };

  return (
    <div>
      <div className="main-body-section">
        <div className="flex">
          <div className="relative flex flex-col flex-1 "></div>
        </div>
        <main className="pb-10">
          <div className="custom-card shadow-lg mb-10">
            <div className="filter-info grid grid-cols-12 lg:gap-2 md:gap-2 gap-6">

            <div className="col-span-12 mb-4">
              <div className="form-info-title">
                <h4 className="text-[#2d2d2d] small-title font-bold theme-color">
                Application Details
                </h4>
              </div>
            </div>

              <div className="filter-types 2xl:col-span-5  lg:col-span-6 col-span-12">
                <h2 className="text-xl text-[#000000] font-bold mb-1">
                  Application Name
                  <span className="text-[#C00000]"> *</span>
                </h2>

                <input
                  id="default"
                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                />
              </div>

              <div className="lg:col-span-6 xl:col-span-3 col-span-12">
                <h2 className="text-xl text-[#000000] font-bold mb-1">
                  Interview Birth
                  <span className="text-[#C00000]"> *</span>
                </h2>
                <div className="dropdown">
                  <DateInput name="Date From" identity="dateFrom" />
                  <ValidationText />
                </div>
              </div>

              <div className="filter-types 2xl:col-span-3 lg:col-span-6 col-span-12">
                <h2 className="text-xl text-[#000000] font-bold mb-1">
                  Interview Time
                  <span className="text-[#C00000]"> *</span>
                </h2>
                <DropdownSelect drpIdentity={"Select_Type"} />
              </div>

              <div className="filter-types 2xl:col-span-5 2xl:ml-0 lg:col-span-6 col-span-12  md:ml-0  md:col-span-5">
                <h2 className="text-xl text-[#000000] font-bold mb-1">
                  Email Address
                  <span className="text-[#C00000]"> *</span>
                </h2>
                <input
                  id="default"
                  className="form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="text"
                />
              </div>
            </div>
          </div>

          <div className="relative">
            <div>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200">
                  <thead className="bg-[#e9e7e7]">
                    <tr className="bg-gray-100 font-bold text-2xl text-start">
                      <th className="border-b border-gray-200 px-4 py-7 text-start">
                        <input
                          type="checkbox"
                          className="form-checkbox min-h-8 min-w-8 max-h-8 max-w-8  text-blue-600 mr-2"
                          onChange={handleSelectAll}
                          checked={selectAll}
                        />
                        Name
                      </th>
                      <th className="border-b border-gray-200 px-4 py-7 text-start">
                        Email
                      </th>
                      <th className="border-b border-gray-200 px-4 py-7 text-start">
                        Tier
                      </th>
                      <th className="border-b border-gray-200 px-4 py-7 text-start">
                        Submission Date
                      </th>
                      <th className="border-b border-gray-200 px-4 py-7 text-start">
                        Interview Date
                      </th>
                      <th className="border-b border-gray-200 px-4 py-7 text-start">
                        Interview Time
                      </th>
                      <th className="border-b border-gray-200 px-4 py-7 text-start whitespace-nowrap">
                        Batch SN
                      </th>
                      <th className="border-b border-gray-200 px-4 py-7 text-start">
                        Actions
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index} className="text-start text-2xl">
                        <td className="border-t-4 border-gray-300 px-4 py-4 whitespace-nowrap">
                          <div className="flex flex-shrink items-center">
                            <input
                              type="checkbox"
                              className="form-checkbox min-h-8 min-w-8 max-h-8 max-w-8 text-blue-600 mr-2"
                              checked={item.selected}
                              onChange={() => handleCheckboxChange(index)}
                            />
                            <span className="truncate">{item.name}</span>
                          </div>
                        </td>
                        <td className="border-t-4 border-gray-300 px-4 py-4">
                          {item.email}
                        </td>
                        <td className="border-t-4 border-gray-300 px-4 py-4">
                          {item.tier}
                        </td>
                        <td className="border-t-4 border-gray-300 px-4 py-4">
                          {item.submissionDate}
                        </td>
                        <td className="border-t-4 border-gray-300 px-4 py-4">
                          {item.interviewDate}
                        </td>
                        <td className="border-t-4 border-gray-300 px-4 py-4">
                          {item.interviewTime}
                        </td>
                        <td className="border-t-4 border-gray-300 px-4 py-4">
                          {item.batchSN}
                        </td>
                        <td className="border-t-4 border-gray-300 px-4 py-4">
                          <a href="#" className="text-blue-600 underline">
                            View
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-row-reverse items-center m-4">
                <button className="bg-[#1c248b] hover:bg-[#1c238bd5] text-white font-bold py-4 px-6 text-2xl">
                  Assign Interview Date
                </button>
              </div>
            </div>
          </div>

        </main>
      </div>
    </div>
  );
};

export default AssignInterview;
