import React from "react"
const TextInput = (props) => {
    return (
        <input
            className={`w-full outline-none appearance-none form-input rounded-none ${props.className} ${props.isCurserAllowed === true ? 'cursor-not-allowed bg-[#e9e9ea]  border-[#000000]  text-[#000000]' : ''}`}
            placeholder={props.placeholder}
            type={props.type}
            name={props.name}
            value={props.value}
            min={props.min}
            max={props.max}
            maxLength={props.maxLength}
            onChange={(event) => props.handleChange(event, props.identity)}
            onBlur={(e) => props.onBlur ? props.onBlur(props.identity) : null}
            disabled={props.disabled === true ? true : false}
            autoComplete={props.autoComplete ? props.autoComplete : "off"}
        />
    );
}
export default TextInput;
