
import React, { Component } from "react";
import Table from "../../components/table/Table";
import moment from "moment";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import { CommonValidationMessages } from "../../utils/Messages";
import ValidationText from "../../utils/validation/ValidationText";
import PageLoader from "../../components/common/pageLoader/PageLoader";
import { saveAs } from "file-saver";
import SlotMemberService from "../../services/axiosServices/apiServices/SlotMemberService";
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import DropdownSelect from "../../components/dropdown/Dropdown";

export default class SlotRenewalReport extends Component {
    constructor(props) {
        super(props);
        this.CommonServices = new CommonServices();

        this.state = {


            SlotRenewalRecordList: [],
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "CreatedAt",
                orderFlag: 0,
                searchText: "",
                updateddate: "",
                endDate: "",
                memberType: 0,
                IsForDownloadRecords:0
            },
            typeList: [
                {
                    id: 1,
                    name: "Individual",
                },
                {
                    id: 2,
                    name: "CONQUAS",
                },
                {
                    id: 3,
                    name: "OAT",
                },
                {
                    id: 4,
                    name: "Slot",
                },
                {
                    id: 5,
                    name: "Student",
                },
            ],
            totalCount: 0,
            totalResultes: 0,
            previousReportFor: "",
            previousReportStartDate: "",
            previousReportEndDate: "",
            isLoading: false,
            outstandingInvoicesList: [],

            validationRule: {
                updateddate: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "Start date for OATI and Slots report"),
                    },
                ],
                endDate: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "End date for OATI and Slotsreport"),
                    }
                ],
                memberType: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "MemberType"),
                    }
                ],

            },
            validState: {
                isValid: true,
                error: {},
            },
        };
    }



    setPagination = (newPagination) => {

        this.setState({ pagination: newPagination });
        this.getOATIandslotsRecordList();
    };



  
    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'updateddate') {
            let date = this.state.pagination
            date.updateddate = value
            this.setState({ updateddate: date })
        }
        if (name === 'endDate') {
            let date = this.state.pagination
            date.endDate = value
            this.setState({ endDate: date })
        }
    }


    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.pagination,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.pagination,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };
    

    handleChangeInDropdown = (id, drpIdentity) => {

        let detail = this.state.pagination;
        if (drpIdentity === "MemberType") {

            detail.memberType = id
            this.setState({ pagination: detail })


            if (this.state.pagination.memberType !== 0) {

            }
        }
    }


    getOATIandslotsRecordList = () => {
      debugger
        let isValidateAllFields = this.isValidateAllFields()
        if (isValidateAllFields === true) {
        if (this.state.pagination.updateddate && this.state.pagination.endDate) {
            this.setState({ isLoading: true });
            let request = { ...this.state.pagination };
            request.updateddate = moment(this.state.pagination.updateddate).format("DD MMM YYYY");

            request.endDate = moment(this.state.pagination.endDate).format("DD MMM YYYY");
            console.log(this.state.pagination)

            this.CommonServices.getOATIandslotsRecordList(request).then((response) => {

                if (response.statusCode === 200 && response.responseItem !== null) {
                    if (response.responseItem.responseContent !== null) {
                        let details = response.responseItem.responseContent.itemList;
                        if (details.length > 0) {
                            details.map((date) => {

                                date.updatedAt = date.updatedAt ? moment(date.updatedAt).format("DD MMM YYYY") : "";

                                return date;
                            })
                        }
                        this.setState({

                            SlotRenewalRecordList: details,
                            totalCount: response.responseItem.responseContent.totalCount,
                            totalResultes: response.responseItem.responseContent.totalCount,

                            previousReportStartDate: this.state.pagination.updateddate,
                            previousReportEndDate: this.state.pagination.endDate
                        })
                    }
                }
                else if (response.statusCode === 409) {
                    this.swalServices.Error(response.message);
                }
                else {
                    this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
            });
        }
    }
    }


 downloadSheetForOATIandslotsRecordList = () => {
          
        let isValidateAllFields = this.isValidateAllFields()
        if (isValidateAllFields) {
            if (this.state.pagination.updateddate && this.state.pagination.endDate) {
                let request = { ...this.state.pagination };
                request.updateddate = moment(this.state.pagination.updateddate).format("DD MMM YYYY");
                request.endDate = moment(this.state.pagination.endDate).format("DD MMM YYYY");
                this.CommonServices.downloadSheetForOATIandslotsRecordList(request).then((response) => {
                    if (response.status === 200 && response.data != null) {
                        this.setState({

                            previousReportStartDate: this.state.pagination.updateddate,
                            previousReportEndDate: this.state.pagination.endDate
                        })
                        var blob = new Blob([response.data])
                   
                        saveAs(blob, 'Members Updated  Report.xlsx');

                    }
                    else {
                        this.swalServices.Error(response.message);
                    }
                    this.setState({ isLoading: false });
                });
            }
        }
    }


    render() {
        return (
            <div className="main-body-section finance-body-sec">
                {/* Add/Edit GST Start */}
                <div className="bg-white">
                    <div className="grid grid-cols-12 gap-6 w-full items-center px-8 py-10">
                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                for="default"
                            >
                                Start date<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="">
                                <input
                                    id="updateddate"
                                    name="updateddate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(this.state.pagination.updateddate).format("yyyy-MM-DD")}
                                    onChange={(event) => this.handleChange(event)}
                                    onBlur={() => this.validateField("updateddate")}
                                />
                            </div>
                            <ValidationText error={this.state.validState.error.updateddate} />
                        </div>
                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                for="default"
                            >
                                End date<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="">
                                <input
                                    id="endDate"
                                    name="endDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(this.state.pagination.endDate).format("yyyy-MM-DD")}
                                    onChange={(event) => this.handleChange(event)}
                                   onBlur={() => this.validateField("endDate")}
                                />
                            </div>
                            <ValidationText error={this.state.validState.error.endDate} />
                        </div>
                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                for="default"
                            >
                                Member Type
                                <span className="text-[#c00000]">*</span>
                            </label>
                            <DropdownSelect
                                drpIdentity="MemberType"
                                optionArray={this.state.typeList}
                                setFilterParameters={this.handleChangeInDropdown.bind(this)}
                                value={this.state.pagination.memberType}
                               onBlur={() => this.validateField("MemberType")}
                            />
                              <ValidationText error={this.state.validState.error.memberType} />

                        </div>
                     



                        <div className="col-span-4">
                            <button className="btn btn-blue text-xl border text-white mt-8" onClick={() => this.getOATIandslotsRecordList()}>
                                Show Updated Report
                            </button>
                        </div>
                        {
                        
                        this.state.SlotRenewalRecordList &&
                            this.state.SlotRenewalRecordList.length > 0 &&
                            this.state.previousReportStartDate === this.state.pagination.updateddate &&
                            this.state.previousReportEndDate === this.state.pagination.endDate ?
                            <div className="col-span-4">
                                <button className="btn btn-blue text-xl border text-white mt-8" onClick={() => this.downloadSheetForOATIandslotsRecordList()}>
                                    Download Excel
                                </button>
                            </div>
                            : null}
                    </div>
                </div>
                {/* Add/Edit GST End */}
                {/* Section 3 Start */}
                <div className="grid grid-cols-12 gap-6 w-full items-center justify-center px-8 py-10">
                    <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                        {this.state.isLoading ?
                            <PageLoader /> :
                            <Table
                                columns={[
                                    { name: "UpdatedAt", title: "Update Date", },
                                    { name: "CompanyName", title: "Company Name" },
                                    { name: "CompanyUEN", title: "Company UEN" },
                                    { name: "Address", title: "Address" },
                                    { name: "ContactNo", title: "Phone Number" },
                                    { name: "Email", title: "Email" },

                                ]}
                                pagination={this.state.pagination}
                                rows={this.state.SlotRenewalRecordList}
                                sortingColumns={[""]}
                                isLoading={this.state.isLoading}
                                totalResultes={this.state.totalResultes}
                                totalCount={this.state.totalCount}
                                setPagination={this.setPagination.bind(this)}
                            />

                        }
                    </div>
                </div>

            </div>
        );
    }
}