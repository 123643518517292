import { CommonValidationMessages } from "../../../utils/Messages";


// individualSIISAccountId: 0,
//         individualMemberId: 0,
//         accountStatus: 0,
//         individualJoinedDate: "",
//         updatedBy: 0,
//         updatedAt: "",
//         markForDeleted: false,
//         deletionReasonId: 0,
//         deletedAt: "",
//         deletionDate: "",
//         engagementScore: 0,
//         sIISIndividualId: "",
export const SIISIndividualAccountValRules = {
    accountStatus: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "account status"),
        }
    ],
    individualJoinedDate: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "joined date"),
        }
    ],
    // updatedAt: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace("{0}", "updated date"),
    //     }
    // ],
    // updatedBy: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace("{0}", "updated by"),
    //     }
    // ],
}

export const IndividualProfileValRules = {
    firstName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "first name"),
        }
    ],
    lastName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "last name"),
        }
    ],
    dateOfBirth: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "date of birth"),
        }
    ],
    mailingAddress1: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "mailing address line 1"),
        }
    ],
    // mailingAddress2: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "mailing address line 2"),
    //     }
    // ],
    // mailingAddress3: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "mailing address line 3"),
    //     }
    // ],
    countryId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "country"),
        }
    ],
    postalCode: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "postal code"),
        },
        {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid postal code"),
        },
        // {
        //     type: "maxLength",
        //     maxLength: 6,
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "valid postal code"),
        // },
        {
            type: "minLength",
            minLength: 6,
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid postal code"),
        },
    ],
    contactNo: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "contact number"),
        },
        {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "only digits"),
        },
        // {
        //     type: "maxLength",
        //     maxLength: 10,
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "between 8 and 10 digits"),
        // },
        // {
        //     type: "minLength",
        //     minLength: 8,
        //     message: CommonValidationMessages.FieldRequired.replace("{0}", "between 8 and 10 digits"),
        // },
    ],
    email: [
        {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'email'),
        },
    ],
    placeOfBirthId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "place of birth"),
        }
    ],
    nationalityTypeId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "nationality"),
        }
    ],
  
    educationLevelId: [
        {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'education level'),
        },
    ],
    titleOfCourse: [
        {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'course title'),
        },
    ],
    companyName: [
        {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'company name'),
        },
    ],
    jobTitle: [
        {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'job title'),
        },
    ],
    emailOfWork: [
        {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'work email'),
        },
    ],
    howDoYouHearId: [
        {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'hear about us'),
        },
    ],
    description: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "description"),
        },
    ],
    photo: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "photo"),
        }
    ],
    constructionCertification: [
        {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'construction related certificate'),
        },
    ],
}

export const EmploymentInformationValRules = {
    employmentCompanyName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "company name"),
        }
    ],
    employmentJobFunctionId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "job function"),
        }
    ],
    employmentJobLevelId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "job level"),
        }
    ],


}

export const IndividualContributionValRules = {
    roleId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "organisation role"),
        }
    ],
    startDate: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "start date"),
        }
    ],
    workgroupMappingId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "chapter/committee name"),
        }
    ],

}

export const CommitteeCardValRules = {
    workgroupMappingId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "chapter/committee name"),
        }
    ],
}

export const EngagementTrackingValRules = {
    engagementDate: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "engagement date"),
        }
    ],
    corporateEngagementTypeId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "engagement type"),
        }
    ],
    engagementDescription: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "engagement description"),
        }
    ],
}

export const SCALMembershipInformationRules = {
    membershipTypeId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "membership type"),
        }
    ],
}







