import { axiosGetAuthorize, axiosGetFileAuthorizeblob, axiosGetMultiParams,  axiosPostAuthorize, axiosPostFileAuthorizeblob } from "../AxiosRequests";
import {
  GetFinanceGSTSettingList,
  GetFinanceClassCodeSettingList,
  GetFinanceAccountCodeSettingList,
  AddEditFinanceGST,
  AddEditFinanceClassCode,
  AddEditFinanceAccountCode,
  AddEditFinanceCreditNoteApproval,
  GetFinanceCreditNoteApprovalList,
  //AddEditFinanceAdhokInvoiceDetail,
  //GetAllDropdownsForFinanceAdhokInvoices,
  //GetFinanceAdhocInvoiceList,
  //GetFinanceAdhokInvoiceDetail,
  //GetCreditNoteInfoForCorporateMember,
  GetCancellationReasonForCreditNote,
  //AddEditFinanceExternalServiceDetail,
  //GetFinanceExternalServiceDetailById,
  //AddDrawdownForExternalServiceItem,
  //GetFinanceCreditNoteById,
  //GetAllVoucherCodeList,
  //GetAllFinanceCreditNoteList,
  //GetFinanceCreditNoteStatusList,
  //FinanceApproveRejectCreditNoteApproval,
  //GetVoucherCodeDetailsById,
  //ActiveInActiveVoucherCode,
  //GetAllMemberListForVoucher,
  DeleteFinanceGlobal,
  //AddEditVoucherCodeDetails,
  //FinanceUpdateCreditNote,
  GetAllChapterListForVoucher,
  //FinanceUpdateStatusForCreditNote,
  //FinanceGetAllPendingEventApprovalList,
  //financeEventInvoiceApproval,
  FinanceGetInvoice,
  FinanceGetCreditNoteInvoice,
  //UpdatePaymentHistory,
  //UpdatePaymentHistoryForMembershipPayment,
  //UpdatePaymentHistoryForAdditionalChapter,
  GetAllExtractInvoicesList,
  
  ZipInvoices,
  GetTransactionRecordList,
  DownloadSheetForTransactionRecord,
  //FinanceAddCreditNotePaymentProofMapping,
  // AddCreditNoteInvoiceRecordsForBatchJob,
  // AddMembershipCreditNoteApprovalRecordsForBatchJob,
  // AddEventCreditNoteApprovalRecordsForBatchJob,
  // AddContinuosPeriodExtInvCNApprovalXeroRecords,
  GetCreditNoteInfoForCorporateMember,
  AddFinanceCreditNote,
  GetAllFinanceCreditNoteList,
  GetFinanceCreditNoteStatusList,
  GetFinanceCreditNoteById,
  FinanceApproveRejectCreditNoteApproval,
  FinanceUpdateCreditNote,
  FinanceUpdateStatusForCreditNote,
  GetCreditNoteInfoForIndividualMember,
  AddMembershipCreditNoteApprovalRecordsForBatchJob,
  GenerateExportMembershipData,
  DownloadSheetByExportMembershipDataId,
  GetAllExportMembershipDataRecords,
  GetAllDeferredIncomeReportList,
  GenerateDeferredIncomeReport,
  DownloadSheetByDeferredIncomeReportId
} from "../ApiEndPoints";

export default class FinanceServices {
  async getFinanceGSTSettingList(request) {
    return axiosGetAuthorize(GetFinanceGSTSettingList, request);
  }
  async getFinanceClassCodeSettingList(request) {
    return axiosGetAuthorize(GetFinanceClassCodeSettingList, request);
  }
  async getFinanceAccountCodeSettingList(request) {
    return axiosGetAuthorize(GetFinanceAccountCodeSettingList, request);
  }
  async getFinanceCreditNoteApprovalList(request) {
    return axiosGetAuthorize(GetFinanceCreditNoteApprovalList, request);
  }
  async addEditFinanceGST(request) {
    return axiosPostAuthorize(AddEditFinanceGST, request)
  }
  async addEditFinanceClassCode(request) {
    return axiosPostAuthorize(AddEditFinanceClassCode, request)
  }
  async addEditFinanceAccountCode(request) {
    return axiosPostAuthorize(AddEditFinanceAccountCode, request)
  }
  async addEditFinanceCreditNoteApproval(request) {
    return axiosPostAuthorize(AddEditFinanceCreditNoteApproval, request)
  }
  async getCreditNoteInfoForCorporateMember(request) {
    return axiosGetMultiParams(GetCreditNoteInfoForCorporateMember, request)
  }
   // For Deferred Income Reports
   async getAllDeferredIncomeReportList(request) {
    return axiosPostAuthorize(GetAllDeferredIncomeReportList, request); 
  }
  async generateDeferredIncomeReport(request) {
    return axiosPostAuthorize(GenerateDeferredIncomeReport, request);
  }
  async downloadSheetByDeferredIncomeReportId(request) {
    return axiosGetFileAuthorizeblob(DownloadSheetByDeferredIncomeReportId, request);
  }

  // async addEditFinanceAdhokInvoiceDetail(request) {
  //   return axiosPostAuthorize(AddEditFinanceAdhokInvoiceDetail, request)
  // }

  // async getAllDropdownsForFinanceAdhokInvoices(request) {
  //   return axiosGetAuthorize(GetAllDropdownsForFinanceAdhokInvoices, request)
  // }

  // async getFinanceAdhocInvoiceList(request) {
  //   return axiosPostAuthorize(GetFinanceAdhocInvoiceList, request)
  // }

  // async getFinanceAdhocInvoiceDetail(request) {
  //   return axiosGetAuthorize(GetFinanceAdhokInvoiceDetail, request);
  // }

  // async getCreditNoteInfoForCorporateMember(request) {
  //   return axiosGetMultiParams(GetCreditNoteInfoForCorporateMember, request);
  // }

  async getCancellationReasonForCreditNote(request) {
    return axiosGetAuthorize(GetCancellationReasonForCreditNote, request);
  }
  async addFinanceCreditNote(request) {
    return axiosPostAuthorize(AddFinanceCreditNote, request);
  }

  // async addEditFinanceExternalServiceDetail(request) {
  //   return axiosPostAuthorize(AddEditFinanceExternalServiceDetail, request);
  // }

  // async getFinanceExternalServiceDetailById(request) {
  //   return axiosGetAuthorize(GetFinanceExternalServiceDetailById, request);
  // }

  // async addDrawdownForExternalServiceItem(request) {
  //   return axiosPostAuthorize(AddDrawdownForExternalServiceItem, request);
  // }

  async getFinanceCreditNoteById(request) {
    return axiosGetAuthorize(GetFinanceCreditNoteById, request);
  }

  // async getAllVoucherCodeList(request) {
  //   return axiosPostAuthorize(GetAllVoucherCodeList, request);
  // }
  async getAllFinanceCreditNoteList(request) {
    return axiosPostAuthorize(GetAllFinanceCreditNoteList, request);
  }

  async getFinanceCreditNoteStatusList(request) {
    return axiosGetAuthorize(GetFinanceCreditNoteStatusList, request);
  }

  async financeApproveRejectCreditNoteApproval(request) {
    return axiosPostAuthorize(FinanceApproveRejectCreditNoteApproval, request);
  }

  // async getVoucherCodeDetailsById(request) {
  //   return axiosGetAuthorize(GetVoucherCodeDetailsById, request);
  // }
  // async activeInActiveVoucherCode(request) {
  //   return axiosPostAuthorize(ActiveInActiveVoucherCode, request);
  // }
  // async getAllMemberListForVoucher(request) {
  //   return axiosGetAuthorize(GetAllMemberListForVoucher, request);
  // }

  async deleteFinanceGST(request) {
    return axiosPostAuthorize(DeleteFinanceGlobal, request);
  }
  // async addEditVoucherCodeDetails(request) {
  //   return axiosPostAuthorize(AddEditVoucherCodeDetails, request);
  // }
  async getAllChapterListForVoucher(request) {
    return axiosGetAuthorize(GetAllChapterListForVoucher, request);
  }


  async financeUpdateCreditNote(request) {
    return axiosPostAuthorize(FinanceUpdateCreditNote, request);
  }
  async financeUpdateStatusForCreditNote(request) {
    return axiosPostAuthorize(FinanceUpdateStatusForCreditNote, request);
  }

  // async financeGetAllPendingEventApprovalList(request) {
  //   return axiosPostAuthorize(FinanceGetAllPendingEventApprovalList, request);
  // }

  // async financeApproveRejectEventApproval(request) {
  //   return axiosPostAuthorize(financeEventInvoiceApproval, request);
  // }

  async financeGetInvoice(request) {
    return axiosGetFileAuthorizeblob(FinanceGetInvoice, request);
  }

  async financeGetCreditNoteInvoice(request) {
    return axiosGetFileAuthorizeblob(FinanceGetCreditNoteInvoice, request);
  }

  // async updatePaymentHistory(request) {
  //   return axiosGetMultiParams(UpdatePaymentHistory, request);
  // }

  // async updatePaymentHistoryForMembershipPayment(request) {
  //   return axiosGetAuthorize(UpdatePaymentHistoryForMembershipPayment, request);
  // }

  // async updatePaymentHistoryForAdditionalChapter(request) {
  //   return axiosGetAuthorize(UpdatePaymentHistoryForAdditionalChapter, request);
  // }

  async GetAllExtractInvoicesList(request) {
    return axiosPostAuthorize(GetAllExtractInvoicesList, request);
  }
  // async getGSTRate(request) {
  //   return axiosGetAuthorize(GetGSTRate, request);
  // }
  async zipInvoices(request) {
    return axiosPostAuthorize(ZipInvoices, request);
  }

  async getTransactionRecordList(request) {
    return axiosPostAuthorize(GetTransactionRecordList, request);
  }
  async downloadSheetForTransactionRecord(request) {
    return axiosPostFileAuthorizeblob(DownloadSheetForTransactionRecord, request);
  }

  // async financeAddCreditNotePaymentProofMapping(request) {
  //   return axiosPostAuthorize(FinanceAddCreditNotePaymentProofMapping, request);
  // }

  // async addCreditNoteInvoiceRecordsForBatchJob(request) {
  //   return axiosGetAuthorize(AddCreditNoteInvoiceRecordsForBatchJob, request);
  // }

  // async addMembershipCreditNoteApprovalRecordsForBatchJob(request) {
  //   return axiosGetAuthorize(AddMembershipCreditNoteApprovalRecordsForBatchJob, request);
  // }
  // async addEventCreditNoteApprovalRecordsForBatchJob(request){
  //   return axiosGetAuthorize(AddEventCreditNoteApprovalRecordsForBatchJob, request);
  // }
  // async addContinuosPeriodExtInvCNApprovalXeroRecords(request){
  //   return axiosGetAuthorize(AddContinuosPeriodExtInvCNApprovalXeroRecords, request);
  // }

  async GetCreditNoteInfoForIndividualMember(request) {
    return axiosGetMultiParams(GetCreditNoteInfoForIndividualMember, request)
  }

  async addMembershipCreditNoteApprovalRecordsForBatchJob(request) {
    return axiosGetAuthorize(AddMembershipCreditNoteApprovalRecordsForBatchJob, request);
  }

  async GenerateExportMembershipData(request) {
    return axiosPostAuthorize(GenerateExportMembershipData, request)
  }

  async DownloadSheetByExportMembershipDataId(request) {
    return axiosGetFileAuthorizeblob(DownloadSheetByExportMembershipDataId, request)
  }
  async GetAllExportMembershipDataRecords(request) {
    return axiosPostAuthorize(GetAllExportMembershipDataRecords, request);
  }
  
  
}
