import { CommonValidationMessages } from "../../../utils/Messages";




export const StudentMemberValidationRules = { 
    firstName: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "first name"),
        }
    ],
    lastName: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "last name"),
        }
    ],
    mailingAddressLine1: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "mailing address line 1"),
        }
    ],
    countryId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "country"),
        }
    ],
    // OtherInstitution: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace("{0}", "OtherInstitution"),
    //     }
    // ],
    postalCode: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "postal code"),
        },
        {
            type: "minLength",
            minLength: 6,
            message: CommonValidationMessages.SelectRequired.replace("{0}", "valid postal code"),
        },
        {
            type: "maxLength",
            maxLength: 6,
            message: CommonValidationMessages.SelectRequired.replace("{0}", "valid postal code"),
        },
        {
            type: "number",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "only digits"),
        },
    ],
    contactNumber: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "contact number"),
        },

    ],
    // emailAddress: [
    //     {
    //         type: "require",
    //         message: Messages.CommonValidationMessages.SelectRequired.replace("{0}", "email address"),
    //     },

    // ],
    email: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "email address"),
        },

    ],

    nationalityTypeId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "nationality"),
        }
    ],
    
    // UploadStudentIDCard: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace("{0}", "photo"),
    //     }
    // ],
    tellUsMoreAboutYourSelf: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "description"),
        }
    ],
    nameOfCurrentInstitution: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "institute name"),
        }
    ],
    courseTitle: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "course title"),
        }
    ],
    currentlyInYear: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "current year"),
        }
    ],
    
    expectedGraduationDate: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "graduation date"),
        }
    ],
    typeOfCurrentOrLatestQualification: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "letest qualification"),
        }
    ],
    howDoYouHearAboutUs: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "know about us"),
        }
    ],
}