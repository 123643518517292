import React, { useState } from "react";
import BasicApplication from "../../../components/membership/cpasapplication/BasicApplication";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";

const ViewCPASApplication = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [isOpenBasicApplication, setIsOpenBasicApplication] = useState(false);
  const [isOpenEducationInformation, setIsOpenEducationInformation] = useState(false);
  const [isOpenEmploymentInformation, setIsOpenEmploymentInformation] = useState(false);
  const [isOpenProjectInformation, setIsOpenProjectInformation] = useState(false);

  const toggleSection = (section) => {
    switch (section) {
      case "BasicApplication":
        setIsOpenBasicApplication(!isOpenBasicApplication);
        break;
      case "EducationInformation":
        setIsOpenEducationInformation(!isOpenEducationInformation);
        break;
      case "EmploymentInformation":
        setIsOpenEmploymentInformation(!isOpenEmploymentInformation);
        break;
      case "ProjectInformation":
        setIsOpenProjectInformation(!isOpenProjectInformation);
        break;
      default:
        break;
    }
  };

  const handleButtonClick = (text) => {
    // Handle button click actions here
    console.log(`Button clicked: ${text}`);
  };

  return (
    <div className="main-body-section">
      {/* Basic Application */}
      <div className="bg-white border border-[#c9c9c9] mb-3">
        <div className="tab w-full border-t">
          <input
            className="absolute opacity-0 toggle-main"
            id="OBI"
            type="checkbox"
            name="corporate-d"
            value="BasicApplication"
            checked={isOpenBasicApplication}
            onChange={() => toggleSection("BasicApplication")}
          />
          <label
            className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
            htmlFor="OBI"
          >
            <span className="small-title font-bold theme-color pl-5">
              Basic Application
            </span>
          </label>
          <div
            className={`committee-info-overflow tab-content overflow-hidden leading-normal px-0 ${
              isOpenBasicApplication ? "" : "hidden"
            }`}
          >
            {isOpenBasicApplication && (
              <BasicApplication
                // Pass necessary props here
              />
            )}
          </div>
        </div>
      </div>

      {/* Education Information */}
      <div className="bg-white border border-[#c9c9c9] mb-3">
        <div className="tab w-full border-t">
          <input
            className="absolute opacity-0 toggle-main"
            id="Edu"
            type="checkbox"
            name="corporate-d"
            value="EducationInformation"
            checked={isOpenEducationInformation}
            onChange={() => toggleSection("EducationInformation")}
          />
          <label
            className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
            htmlFor="Edu"
          >
            <span className="small-title font-bold theme-color pl-5">
              Education Information
            </span>
          </label>
        </div>
      </div>

      {/* Employment Information */}
      <div className="bg-white border border-[#c9c9c9] mb-3">
        <div className="tab w-full border-t">
          <input
            className="absolute opacity-0 toggle-main"
            id="EI"
            type="checkbox"
            name="corporate-d"
            value="EmploymentInformation"
            checked={isOpenEmploymentInformation}
            onChange={() => toggleSection("EmploymentInformation")}
          />
          <label
            className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
            htmlFor="EI"
          >
            <span className="small-title font-bold theme-color pl-5">
              Employment Information
            </span>
          </label>
        </div>
      </div>

      {/* Project Information */}
      <div className="bg-white border border-[#c9c9c9] mb-3">
        <div className="tab w-full border-t">
          <input
            className="absolute opacity-0 toggle-main"
            id="PR"
            type="checkbox"
            name="corporate-d"
            value="ProjectInformation"
            checked={isOpenProjectInformation}
            onChange={() => toggleSection("ProjectInformation")}
          />
          <label
            className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
            htmlFor="PR"
          >
            <span className="small-title font-bold theme-color pl-5">
              Project Information
            </span>
          </label>
        </div>
      </div>

      {/* Send for Pre-Assessment Button */}
      <div className="col-span-12 mt-6 mb-5 flex justify-end gap-10 bg-transparent">
        <div className="flex justify-end border-[#1c1669] border-2">
          <button
            className="btn btn-caps font-bold text-2xl text-[#1c1669]"
            onClick={() => handleButtonClick("Send for Pre-Assessment")}
          >
            {isLoading ? <ButtonLoader /> : <span>Send for Pre-Assessment</span>}
          </button>
        </div>
      </div>

      {/* Pre-Assessment and Interview Result Buttons */}
      <div className="col-span-12 mt-6 mb-5 flex justify-between">
        <div className="bg-[#d6d6d6] col-span-6">
          <button
            className="btn py-10 lg:py-6 btn-caps font-bold text-2xl text-white application-btn"
            onClick={() => handleButtonClick("Pre-Assessment Result")}
          >
            {isLoading ? <ButtonLoader /> : <span>Pre-Assessment Result</span>}
          </button>
        </div>
        <div className="bg-[#d6d6d6] col-span-6">
          <button
            className="btn btn-caps font-bold lg:py-6 py-10 text-2xl text-white application-btn"
            onClick={() => handleButtonClick("Interview Result")}
          >
            {isLoading ? <ButtonLoader /> : <span>Interview Result</span>}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewCPASApplication;
