import React from "react";
import ModalBasic from "../../component/ModalBasic";
import TextInput from "../../../components/UIComponent/TextInput";
import moment from "moment";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import MultiSelectDropdown from "../../../components/dropdown/MultiSelectDropdown";
import RadioButton from "../../../components/membership/membershipTaskList/RadioButton";

import CheckboxInput from "../../../components/inputs/CheckboxInput";
const QuickLinkDetailModal = (props) => {
    return (
        <ModalBasic
            id="send-invoice-modal"
            modalOpen={props.setSendInvoiceModal}
            setModalOpen={props.setOpenModal}
            title=""
            noCloseIcon={false}
        >

            <div className="w-full">
                <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
                    <div className="grid grid-cols-12 gap-6 w-full ">
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Company UEN/Business Registration No.{" "}<span className="text-[#c00000]">*</span>
                                        {/* <span className="text-[#c00000]">*</span> */}
                                    </h2>
                                    <div className="disabled-input">
                                        <input
                                            className={`w-full outline-none appearance-none `}
                                            placeholder=""
                                            type="text"
                                            name="companyUEN"
                                            identity="BasicInformation"
                                            value={props.organizationBasicInformation.companyUEN}
                                            // value={props.organizationBasicInformation.companyUEN}
                                            disabled={true}
                                            autoComplete={"off"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Company Name <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`dropdwn-input flex items-center disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="text"
                                            name="companyName"
                                            value={props.organizationBasicInformation.companyName}
                                            // value={props.organizationBasicInformation.companyName}
                                            identity="BasicInformation"
                                            //disabled={props.organizationBasicInformation.organisationInformationId ? true : false}
                                            autoComplete={"off"}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Company Name (In Chinese)
                                    </h2>
                                    <div className={`flex items-center disabled-input`}>
                                        <TextInput
                                            //className={`${props.organizationBasicInformation.organisationInformationId ? 'bg-[#f2f2f2]' : null}`}
                                            placeholder=""
                                            type="text"
                                            name="chinesCompanyName"
                                            value={props.organizationBasicInformation.chinesCompanyName}
                                            // value={props.organizationBasicInformation.chinesCompanyName}
                                            identity="BasicInformation"
                                            autoComplete={"off"}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Incorporation Date<span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={` disabled-input`}>
                                        <input
                                            className="date-pickers w-full custom-input"
                                            type="date"
                                            name="incorporationDate"
                                            identity="BasicInformation"
                                            max={moment(new Date()).format("yyyy-MM-DD")}
                                            // min={props.membershipSubscription.membershipTypeId === 3 ? subtractYears(4, new Date()) : null}
                                            value={moment(props.organizationBasicInformation.incorporationDate).format("yyyy-MM-DD")}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Job Title  <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`flex items-center disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="text"
                                            name="jobTitle"
                                            value={props.organizationBasicInformation.jobTitle}
                                            identity="BasicInformation"
                                            autoComplete={"off"}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Representative's First Name <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`flex items-center left-border-none disabled-input`}>
                                        <div className="w-[130px]">
                                            <DropdownSelect
                                                drpIdentity={"NamePrefix"}
                                                optionArray={props.mrMrs}
                                                value={props.organizationBasicInformation.mrMrsId}
                                                disabled={true}
                                            />
                                        </div>
                                        <TextInput
                                            placeholder=""
                                            type="text"
                                            name="firstName"
                                            value={props.organizationBasicInformation.firstName}
                                            identity="BasicInformation"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                                        Representative's Last Name{" "}
                                        <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="text"
                                            name="lastName"
                                            value={props.organizationBasicInformation.lastName}
                                            identity="BasicInformation"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Business/Mailing Address Line 1 <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`dropdwn-input flex items-center disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="text"
                                            name="companyAddress"
                                            value={props.organizationBasicInformation.companyAddress}
                                            identity="BasicInformation"
                                            disabled={true}
                                            autoComplete={"off"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Business/Mailing Address Line 2
                                    </h2>
                                    <div className={`dropdwn-input flex items-center disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="text"
                                            name="companyAddress2"
                                            value={props.organizationBasicInformation.companyAddress2}
                                            identity="BasicInformation"
                                            disabled={true}
                                            autoComplete={"off"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Business/Mailing Address Line 3
                                    </h2>
                                    <div className={`dropdwn-input flex items-center disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="text"
                                            name="companyAddress3"
                                            value={props.organizationBasicInformation.companyAddress3}
                                            identity="BasicInformation"
                                            disabled={true}
                                            autoComplete={"off"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Postal Code{" "}
                                        <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="number"
                                            name="postalCode"
                                            maxLength={6}
                                            identity="BasicInformation"
                                            value={props.organizationBasicInformation.postalCode}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Country/Region <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <MultiSelectDropdown
                                        drpIdentity="Country"
                                        options={props.countryList}
                                        //isMulti
                                        // isMulti
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        // components={{ Option }}
                                        // onChange={props.handleChangeMultiDropdown}
                                        allowSelectAll={false}
                                        value={props.countryList.filter(country => country.value === props.organizationBasicInformation.countryId)}
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Email Address <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="email"
                                            name="email"
                                            value={props.organizationBasicInformation.email}
                                            identity="BasicInformation"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Business Contact Number{" "}
                                        <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="number"
                                            name="contactNo"
                                            maxLength={10}
                                            value={props.organizationBasicInformation.contactNo}
                                            identity="BasicInformation"
                                            autoComplete={"off"}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Business Email Address <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`disabled-input`}>
                                        <TextInput
                                            placeholder=""
                                            type="email"
                                            name="businessEmail1"
                                            value={props.organizationBasicInformation.businessEmail1}
                                            identity="BasicInformation"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Main Activities of Company <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <MultiSelectDropdown
                                        drpIdentity="Company_Activity"
                                        options={props.companyMainActivityList}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        // components={{ Option }}
                                        // onChange={props.handleChangeMultiDropdown}
                                        allowSelectAll={true}
                                        isDisabled={true}
                                        value={props.organizationBasicInformation.companyActivity}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Type of projects <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <MultiSelectDropdown
                                        drpIdentity="Project_Type"
                                        options={props.typesOfProject}
                                        // isMulti
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        // components={{ Option }}
                                        onChange={props.handleChangeMultiDropdown}
                                        allowSelectAll={false}
                                        isDisabled={true}
                                        value={props.typeOfProjects.find(type => type.value === props.organizationBasicInformation.projectTypeId)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Public projects from <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className="col-span-12">
                                        <div className='grid grid-cols-12 gap-4'>
                                            {props.publicProjectsForms.map(
                                                (item, index) =>
                                                    item.label !== 'Others (please specify)' ?
                                                        (<div className='md:col-span-4  col-span-12'>
                                                            <div className="flex logo-joint items-start">
                                                                <div className={`flex items-center register-desc pt-1 ${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                                                    <CheckboxInput
                                                                        id="isPublicProjectsForm"
                                                                        name={item.label}
                                                                        value={item.value}
                                                                        checked={item.isChecked}
                                                                        disabled={props.isDisabledFlag ? true : false}
                                                                        handleChange={(e) => props.handleChangeInCheckbox(e, "Public_Project_Forms", index)}
                                                                    />
                                                                </div>
                                                                <div className="pl-3 checkbox-text-part">
                                                                    <p className="check-detail">
                                                                        {item.label}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        )
                                                        :
                                                        (
                                                            <div className="col-span-12 mt-4">
                                                                <div className="flex logo-joint items-start">
                                                                    <div className={`flex items-center register-desc pt-1 ${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                                                        <CheckboxInput
                                                                            id="isPublicProjectsForm"
                                                                            name={item.label}
                                                                            value={item?.isOthers}
                                                                            checked={item?.isOthers}
                                                                            disabled={props.isDisabledFlag ? true : false}
                                                                            handleChange={(e) => props.handleChangeInCheckbox(e, "Public_Project_Forms", index)}
                                                                        />
                                                                    </div>
                                                                    <div className="pl-3 chekbox-text-part">
                                                                        <p className="check-detail">
                                                                            {item.label}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    item.isOthers ?

                                                                        (
                                                                            <div className={`dropdown mt-4 ${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                                                                <TextInput
                                                                                    placeholder=""
                                                                                    type="text"
                                                                                    name="otherProjectForm"
                                                                                    value={props.organizationBasicInformation.otherProjectForm}
                                                                                    identity="BasicInformation"
                                                                                    handleChange={props.handleChange}
                                                                                    disabled={props.isDisabledFlag ? true : false}
                                                                                    //     handleChange={(e) => props.handleChangeInCheckbox(e, "Public_Project_Forms",index)}
                                                                                    // onBlur={(e) => props.validate("jobTitle", props.organizationBasicInformation)}
                                                                                    // disabled={props.organizationBasicInformation.organisationInformationId ? true : false}
                                                                                    autoComplete={"off"}
                                                                                />
                                                                            </div>
                                                                        )
                                                                        : null
                                                                }
                                                            </div>
                                                        )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        No. of full time staff employed (Local/PR) <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <MultiSelectDropdown
                                        drpIdentity="Employee_Local"
                                        options={props.fulltimeEmployeeLocal}
                                        // isMulti
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        // components={{ Option }}
                                        onChange={props.handleChangeMultiDropdown}
                                        allowSelectAll={false}
                                        isDisabled={true}
                                        value={props.fulltimeEmployeeLocal.find(empLocal => empLocal.value === props.organizationBasicInformation.fullTimeStaffLocalId)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        No. of full time staff employed (Foreigners) <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <MultiSelectDropdown
                                        drpIdentity="Employee_Foreginrs"
                                        options={props.fullTimeEmployeeForeigners}
                                        // isMulti
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        // components={{ Option }}
                                        onChange={props.handleChangeMultiDropdown}
                                        allowSelectAll={false}
                                        isDisabled={true}
                                        value={props.fullTimeEmployeeForeigners.find(empForeginers => empForeginers.value === props.organizationBasicInformation.fullTimeStaffForeignersId)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        How do you hear about us? <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <MultiSelectDropdown
                                        drpIdentity="Know_About_Us"
                                        options={props.hearAboutUsList}
                                        // isMulti
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        // components={{ Option }}
                                        // onChange={props.handleChangeMultiDropdown}
                                        allowSelectAll={false}
                                        isDisabled={true}
                                        value={props.knowAboutusList.find(aboutUs => aboutUs.value === props.organizationBasicInformation.knowAboutUsId)}
                                    />
                                </div>
                            </div>

                            {
                                props && props.organizationBasicInformation.knowAboutUsId === 5 ?

                                    (
                                        <div className={`dropdown mt-4 disabled-input`}>
                                            <TextInput
                                                name="hearAboutOther"
                                                value={props.organizationBasicInformation.hearAboutOther}
                                                identity="BasicInformation"
                                                // handleChange={props.handleChange}
                                                disabled={true}
                                            />
                                        </div>
                                    )
                                    : null
                            }
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Company Description{" "}
                                        <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <div className={`disabled-input`}>
                                        <textarea
                                            name="companyDescription"
                                            rows="7"
                                            className='w-full'
                                            maxLength={300}
                                            value={props.organizationBasicInformation.companyDescription}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="col-span-12">
                                    <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                        Annual Turn Over{" "}
                                        <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <MultiSelectDropdown
                                        drpIdentity="Annual_Turnover"
                                        options={props.annualTurnoverList}
                                        // isMulti
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        // components={{ Option }}
                                        // onChange={props.handleChangeMultiDropdown}
                                        allowSelectAll={false}
                                        isDisabled={true}
                                        value={props.annualTurnoverList.find(turnOver => turnOver.value === props.organizationBasicInformation.membershipRevenueId)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-5 bg-gray-box md:py-7 py-4 md:px-10 px-2 bg-[#fafafa]'>
                        <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-2">
                            <div className="lg:col-span-6 col-span-12">
                                <h2 className="input-title">
                                    Is the company registered with BCA?
                                    <span className="text-[#C00000]">*</span>
                                </h2>
                                <div className={`flex items-center w-full disabled-input mt-3`}>
                                    <div className="flex items-center">
                                        <RadioButton
                                            id="isRegisterd"
                                            name="isCompanyRegisterWithBCA"
                                            value={props.organizationBasicInformation.isCompanyRegisterWithBCA}
                                            checked={props.organizationBasicInformation.isCompanyRegisterWithBCA === true}
                                            isDisabled={true}
                                        />
                                        <span className="ml-3">
                                            Yes
                                        </span>
                                    </div>
                                    <div className="flex items-center ml-10">
                                        <RadioButton
                                            id="isRegisterd"
                                            name="isCompanyRegisterWithBCA"
                                            value={props.organizationBasicInformation.isCompanyRegisterWithBCA}
                                            checked={props.organizationBasicInformation.isCompanyRegisterWithBCA === false}
                                            isDisabled={true}
                                        />
                                        <span className="ml-3">
                                            No
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* {
                                props && props.organizationBasicInformation.isCompanyRegisterWithBCA === true ?
                                    ( */}
                            <div className='col-span-12'>
                                <div className='table-section'>
                                    <div className='overflow-x-auto'>
                                        <table className='w-full'>
                                            <thead className='bg-[#0b3382] text-white'>
                                                <tr>
                                                    <th className='whitespace-nowrap text-left py-3 px-5'>
                                                        Selected Scope
                                                    </th>
                                                    <th className='whitespace-nowrap text-left py-3 px-5'>
                                                        Grade
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props && props.organizationBasicInformation.companyScopeList?.map((scope, key) => {
                                                    return (
                                                        <tr className='bg-[#eeeeee] text-black border-t-4 border-[#ffffff]'
                                                            key={key}
                                                        >
                                                            <td className='whitespace-nowrap text-left py-2 px-5'>
                                                                {`${scope.mainGroup} - ${scope.workhead} `}
                                                            </td>
                                                            <td className='whitespace-nowrap text-left py-2 px-5'>
                                                                {scope.grade}
                                                            </td>

                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/* )
                                    :
                                    null
                            } */}
                        </div>

                    </div>
                </div>
            </div>
        </ModalBasic>
    );
}
export default QuickLinkDetailModal;