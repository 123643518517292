import React from "react";
import AddEditNewProfile from "./AddEditNewProfile";

function OrganisationChapterRole(props) {
  return (
    <div>
      <div className="grid grid-cols-12 gap-6 w-full items-center  mb-5">
        <div className="xl:col-span-12 lg:col-span-6 col-span-12 h-full">
          <div className="bg-white border border-[#c9c9c9] h-full">
            <div className="heading-part px-7 py-4 border-b border-[#c9c9c9]">
              <div className="flex items-center justify-between">
                <div className="left-tiitle">
                  <h4 className="small-title font-bold theme-color">
                    Organisation Key Roles
                  </h4>
                </div>
                <div className="flex items-center justify-between right-id-sec">
                  <div className="flex items-center justify-end">
                    <button
                      className={`flex items-center ${props.isDisabledFlag ? 'cursor-not-allowed' :null}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.setOrganisationModel(true);
                        props.handleReset();
                        props.handleisAddEditAOrganisation();
                      }}
                      disabled={props.isDisabledFlag ? true : false}
                    >
                      <span className="text-xl text-[#005B9C] font-semibold mr-4 min-w-[30px] min-h-[20px] border border-[#005B9C]">
                        +
                      </span>
                      <span className="text-xl text-[#005B9C] font-semibold tracking-normal">
                        Add New Profile
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-7 pb-7 pt-4">
              <div className="grid grid-cols-12 gap-6 w-full ">
                <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                  <div className="overflow-x-auto">
                    <div className="maping-table">
                      <table className="w-full bg-white rounded-sm contribution-tble">
                        {/* Table body */}
                        <tbody className="text-sm custom-tbody">
                          {props.Organisationrole.map((Organisation, key) => (
                            <tr className="custom-tbl-td-contribution">
                              <td className="py-4 w-[40%] whitespace-nowrap text-[#005B9C] text-xl font-bold text-left">
                                {Organisation.firstName}
                              </td>
                              <td className="py-3 w-[35%] px-2 first:pl-5 last:pr-5 whitespace-nowrap text-[#000000] text-xl text-left">
                                {Organisation.role}
                              </td>
                              <td className="py-3 w-[25%] px-2 first:pl-5 last:pr-5 whitespace-nowrap text-[#000000] text-xl text-right">
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    props.setOrganisationModel(true);
                                    props.editOrganisationRole(
                                      Organisation.individualMemberId,
                                      e
                                    );
                                    props.handleisAddEditAOrganisation();
                                  }}
                                  disabled={props.isDisabledFlag ? true : false}
                                  className={`underline text-xl text-[#8DA4B5] ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                                >
                                  Edit Profile
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="add-edite-profile-popup">
        <AddEditNewProfile
          mrMrs={props.mrMrs}
          emailDomain={props.emailDomain}
          domainArr={props.domainArr}
          organisationDetails={props.organisationDetails}
          setFilterParameters={props.setFilterParameters}
          handleChange={props.handleChange}
          addEditNewProfile={props.addEditOrganisationModel}
          organizationRoleArray={props.organizationRoleArray}
          setOpenModal={() => props.setOrganisationModel(false)}
          onClose={(e) => {
            e.stopPropagation();
            props.setOrganisationModel(false);
          }}
          addEditOraganisation={props.addEditOraganisation}
          isAddEditAOrganisation={props.isAddEditAOrganisation}
          handleisAddEditAOrganisation={props.handleisAddEditAOrganisation}
          deleteOrganisationRole={props.deleteOrganisationRole}
          validate={props.validate}
          validState={props.validState}
          designationList={props.designationList}
          handleChangeMultiDropdown={props.handleChangeMultiDropdown}
        />
      </div>
    </div>
  );
}
export default OrganisationChapterRole;