import React from "react";
import MultiSelectDropdown from "../../dropdown/MultiSelectDropdown";
import moment from "moment";
import ValidationText from "../../../utils/validation/ValidationText";
import ButtonLoader from "../../common/buttonLoader/ButtonLoader";

const BasicAppication = (props) => {
    return (
        <div className="w-full">
            {/* {props.SLOTSApplicationDetails && props.SLOTSApplicationDetails.isExpiredMember === true ?
                <>
                    <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
                        <div className="grid grid-cols-12 gap-6 w-full ">
                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                            </div>
                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="col-span-12">
                                        <div className="check-box-sec flex items-center register-desc">
                                            <div className={`disabled-input`}>
                                                <CheckboxInput
                                                    id="isForceRenewal"
                                                    name="isForceRenewal"
                                                    className="cursor-pointer text-[#e1e1e1] focus:ring-[#e1e1e1] rounded check-box-custom"
                                                    checked={props.SLOTSApplicationDetails.isForceRenewal}
                                                    //value={props.SLOTSApplicationDetails.isForceRenewal}
                                                    handleChange={props.updateForceRenewalFlag}
                                                // disabled={props.isDisabledFlag ? true : false}
                                                />
                                            </div>
                                            {props.isForceRenewalLoader && props.isForceRenewalLoader === true ?
                                                <>
                                                    <span className="filter-lable font-bold text-[#757575] pl-4">
                                                        Loading...
                                                    </span>
                                                </>
                                                :
                                                <>
                                                    <span className="filter-lable font-bold text-[#757575] pl-4">
                                                        Force Renewal
                                                    </span>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>                            
                            </div>
                        </div>
                    </div>
                </> : null} */}

            <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
                <div className="grid grid-cols-12 gap-6 w-full ">
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Company Name<span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`w-full outline-none appearance-none `}
                                        placeholder=""
                                        type="text"
                                        name="companyName"
                                        identity="SLOTS_Information"
                                        value={props.SLOTSApplicationDetails.companyName}
                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        onBlur={(e) => props.validateField("companyName", props.SLOTSApplicationDetails)}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateBasicApplication.error.companyName} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Company Name (In Chinese)
                                    {/* <span className="text-[#c00000]">*</span> */}
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`w-full outline-none appearance-none`}
                                        placeholder=""
                                        type="text"
                                        name="chineseCompanyName"
                                        identity="SLOTS_Information"
                                        value={props.SLOTSApplicationDetails.chineseCompanyName}
                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts disabled-input">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Unique Entity Number (UEN)<span className="text-[#c00000]">*</span>
                                </h2>
                                <input
                                    className={`w-full outline-none appearance-none `}
                                    placeholder=""
                                    type="text"
                                    name="uenNumber"
                                    identity="SLOTS_Information"
                                    value={props.SLOTSApplicationDetails.uenNumber}
                                    onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                    onBlur={(e) => props.validateField("uenNumber", props.SLOTSApplicationDetails)}
                                    disabled={true}
                                    autoComplete={"off"}
                                />
                            </div>
                        </div>
                        <ValidationText error={props.validStateBasicApplication.error.uenNumber} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Date of Incorporation<span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`date-pickers w-full custom-input`}
                                        type="date"
                                        name="dateOfIncorporation"
                                        identity="SLOTS_Information"
                                        max={moment(new Date()).format("yyyy-MM-DD")}
                                        // min={props.membershipSubscription.membershipTypeId === 3 ? subtractYears(4, new Date()) : null}
                                        value={moment(props.SLOTSApplicationDetails.dateOfIncorporation).format("yyyy-MM-DD")}
                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        onBlur={(e) => props.validateField("dateOfIncorporation", props.SLOTSApplicationDetails)
                                        }
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateBasicApplication.error.dateOfIncorporation} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Business/Mailing Address Line 1<span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`w-full outline-none appearance-none`}
                                        placeholder=""
                                        type="text"
                                        name="mailingAddress1"
                                        identity="SLOTS_Information"
                                        value={props.SLOTSApplicationDetails.mailingAddress1}
                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        onBlur={(e) => props.validateField("mailingAddress1", props.SLOTSApplicationDetails)}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateBasicApplication.error.mailingAddress1} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Business/Mailing Address Line 2
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`w-full outline-none appearance-none`}
                                        placeholder=""
                                        type="text"
                                        name="mailingAddress2"
                                        identity="SLOTS_Information"
                                        value={props.SLOTSApplicationDetails.mailingAddress2}
                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Business/Mailing Address Line 3
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`w-full outline-none appearance-none`}
                                        placeholder=""
                                        type="text"
                                        name="mailingAddress3"
                                        identity="SLOTS_Information"
                                        value={props.SLOTSApplicationDetails.mailingAddress3}
                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Country/Region<span className="text-[#c00000]">*</span>
                                </h2>
                                <MultiSelectDropdown
                                    drpIdentity="Country"
                                    options={props.countryList}
                                    //isMulti
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    // components={{ Option }}
                                    onChange={props.handleChangeInDropdown}
                                    allowSelectAll={true}
                                    value={props.countryList.filter(country => country.value === props.SLOTSApplicationDetails.countryId)}
                                    disabled={props.isDisabledFlag ? true : false}
                                />
                            </div>
                        </div>
                        <ValidationText error={props.validStateBasicApplication.error.countryId} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Postal Code<span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`w-full outline-none appearance-none`}
                                        placeholder=""
                                        type="text"
                                        name="postalCode"
                                        identity="SLOTS_Information"
                                        value={props.SLOTSApplicationDetails.postalCode}
                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        onBlur={(e) => props.validateField("postalCode", props.SLOTSApplicationDetails)}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateBasicApplication.error.postalCode} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Business Tel No.<span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`w-full outline-none appearance-none`}
                                        placeholder=""
                                        type="text"
                                        name="businessTelNo"
                                        identity="SLOTS_Information"
                                        value={props.SLOTSApplicationDetails.businessTelNo}
                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        onBlur={(e) => props.validateField("businessTelNo", props.SLOTSApplicationDetails)}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateBasicApplication.error.businessTelNo} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Business Email<span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`w-full outline-none appearance-none`}
                                        placeholder=""
                                        type="text"
                                        name="businessEmail"
                                        identity="SLOTS_Information"
                                        value={props.SLOTSApplicationDetails.businessEmail}
                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        onBlur={(e) => props.validateField("businessEmail", props.SLOTSApplicationDetails)}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateBasicApplication.error.businessEmail} />
                    </div>
                </div>
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12 px-8">
                    <div className="flex items-center justify-end">
                        <div className="flex gap-3 items-center justify-between mt-3">
                            {props.isBasicApplicationLoading && props.isBasicApplicationLoading === true ?
                                <>
                                    <ButtonLoader className="w-[250px] p-3 mt-4 btn btn-orange" />
                                </>
                                :
                                <>
                                    <button
                                        className={`col-start-1 col-end-3 text-xl font-bold btn btn-orange-border w-[250px] text-red p-3 mt-4 ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                                        onClick={() => props.updateBasicAppicationDetails()}
                                        disabled={props.isDisabledFlag ? true : false}
                                    >
                                        Update Basic Application
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default BasicAppication;