import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { getAuthProps } from "../../utils/AuthenticationLibrary";
// import { number } from "../../utils/validation/ValidateField";
import SidebarLinkGroup from "./SidebarLinkGroup";
import {
  MembershipIcon,
  FinancialIcon,
  FieldMaintananceIcon,
  AccountManagementIcon,
  AdminDashboardIcon,
  CpasInterviewIcon,
} from "../../components/SVGConstants";

export default function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? true : storedSidebarExpanded === "false"
  );

  // Set Role
  const [role, setRoleId] = useState(0);

  useEffect(() => {
    let cookie = getAuthProps();
    const roleId = cookie && cookie.roleId ? Number(cookie.roleId) : 0;
    if (roleId > 0) {
      setRoleId(roleId);
    }
  }, []);

  // End Role
  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <div className="main-sidebar-sections">
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-white bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 
        ${sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"}`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto 
        lg:translate-x-0 transform h-screen  2xl:sidebar-expanded:!w-80 lg:sidebar-expanded:!w-60 2xl:!w-80 lg:w-[15rem] w-[15rem]  shrink-0 
        bg-white border-r transition-all duration-200 ease-in-out ${sidebarOpen ? "translate-x-0" : "translate-x-0"
          }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between 2xl:p-[15px] lg:p-[15px] p-[7px] border-b">
          {/* Logo */}
          <NavLink end to="/" className="block main-sidebar-logo">
            <img src="/logo.png" alt="" />
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8 px-4 pt-3 xl:mt-6 lg:mt-6 mt-0  pb-8 overflow-y-scroll lg:overflow-y-auto no-scrollbar">
          {/* Pages group */}
          <div>
            <ul className="mt-3">
              {/* Dashboard Normal Menu Start*/}
              <li
                className={`custom-make-menu rounded-sm 2xl:mb-12 lg:mb-[40px] mb-[20px] last:mb-0 ${pathname.includes("dashboard") && "bg-white"
                  }`}
              >
                <NavLink
                  end
                  to="Dashboard"
                  className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150 ${pathname.includes("dashboard") && "hover:theme-text-color"
                    }`}
                >
                  <div className="flex menu-icon-img">
                    <AdminDashboardIcon />
                    <p className="menu-title text-xl ml-2 font-bold  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Admin Dashboard
                    </p>
                  </div>
                </NavLink>
              </li>
              {/*Dashboard Normal Menu  End*/}

              {/*Account Management Dropdown-Menu Start */}
              {role > 0 && role === 1 ? (
                <>
                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/" || pathname.includes("Resource")
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/CreateAdminAccount"
                                ? "active hover-active-color"
                                : "hover:theme-text-color"
                              }  
                        ${pathname === "/ViewEditAdminAccount"
                                ? "active hover-active-color"
                                : "hover:theme-text-color"
                              }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex menu-icon-img">
                              <AccountManagementIcon />
                              <p className="menu-title text-xl ml-2 font-bold  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Account Management
                              </p>
                            </div>
                          </a>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="my-4 last:mb-0">
                                <NavLink
                                  end
                                  to="CreateAdminAccount"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/CreateAdminAccount"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Create Account
                                  </span>
                                </NavLink>
                              </li>
                              <li className="my-4 last:mb-0">
                                <NavLink
                                  end
                                  to="ViewEditAdminAccount"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ViewEditAdminAccount"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    View/Edit Account
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                </>
              ) : null}
              {/*Account Management Dropdown-Menu End */}

              {/*Membership Dropdown-Menu Start */}
              {role > 0 && (role === 1 || role === 2) ? (
                <SidebarLinkGroup
                  activecondition={
                    pathname === "/" || pathname.includes("Membership")
                  }
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          // className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150
                          // ${
                          //   (pathname === "/" ||
                          //     pathname.includes("Membership")) &&
                          //   "hover:theme-text-color"
                          // }`}
                          className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                            ${pathname === "/Membership/Pricing"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }  
                            ${pathname === "/MembershipCampaign"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname === "/Membership/MembershipTaskList"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname === "/Membership/MembershipFeeApproval"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname === "/ViewCorporateMembers"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname === "/IndividualMembers"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname ===
                              "/Membership/ChapterXCommitteeWorkgroupMapping"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname ===
                              "/Membership/NotificationAnnouncement"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname ===
                              "/Membership/MembershipDirectoryEnquiry"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname ===
                              "/AddEditMembershipCampaign/:membershipCampaignId"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname ===
                              "/CreateCorporateMembers/:CorporateMemberId"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname ===
                              "/CreateIndividualMembers/:individualMemberId"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname === "/Membership/cPASApplication"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname === "/Membership/cPASApplication"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        `}
                          onClick={(e) => {
                            e.preventDefault();
                            sidebarExpanded
                              ? handleClick()
                              : setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-basline justify-between">
                            <div className="flex menu-icon-img">
                              <MembershipIcon />
                              <p className="menu-title text-xl ml-2 font-bold  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Membership
                              </p>
                            </div>
                          </div>
                        </a>
                        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            {role === 1 ? (
                              <>
                                <li className=" my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/Pricing"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/Membership/Pricing"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Membership Pricing
                                    </span>
                                  </NavLink>
                                </li>

                                <li className=" my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/SLOTSPricing"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/Membership/SLOTSPricing"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      SLOTs Pricing
                                    </span>
                                  </NavLink>
                                </li>

                                <li className=" my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/StudentPricing"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/Membership/StudentPricing"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Student Pricing
                                    </span>
                                  </NavLink>
                                </li>
                                {/* <li className=" my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/CONQUASPricing"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/Membership/CONQUASPricing"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      CONQUAS Pricing
                                    </span>
                                  </NavLink>
                                </li> */}
                              </>
                            ) : null}
                            {role === 1 || role === 2 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/MembershipTaskList"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                      "/Membership/MembershipTaskList"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Membership Task List (Corporate)
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/IndividualMemberTaskList"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                      "/Membership/IndividualMemberTaskList"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Membership Task List (Individual)
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/StudentMemberTaskList"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                      "/Membership/StudentMemberTaskList"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Membership Task List (Student)
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="/ViewCorporateMembers"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ViewCorporateMembers"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      View Corporate Members
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="/IndividualMembers"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/IndividualMembers"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      View Individual Members
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/SLOTAccreditation"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                      "Membership/SLOTAccreditation"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      SLOTs Registration
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="StudentMember/StudentRegistration"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                      "StudentMember/StudentRegistration"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      View Student Members
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/cPASApplication"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                      "Membership/cPASApplication"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      CPAS Application
                                    </span>
                                  </NavLink>
                                </li>

                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/cPASApplication/ViewCPASApplication"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                      "Membership/cPASApplication/ViewCPASApplication"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      View CPAS Application
                                    </span>

                                  </NavLink>
                                </li>


                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/cPASApplication/AssignInterview"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                      "Membership/cPASApplication/AssignInterview"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Assign Interview
                                    </span>

                                  </NavLink>
                                </li>

                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/cPASApplication/CPDClaimApproval"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                        "Membership/cPASApplication/CPDClaimApproval"
                                        ? "hover-active-color"
                                        : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      CPD Claim Approval
                                    </span>

                                  </NavLink>
                                </li>

                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/cPASApplication/CPDEditClaimApproval"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                        "Membership/cPASApplication/CPDEditClaimApproval"
                                        ? "hover-active-color"
                                        : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      CPD Edit Claim Approval
                                    </span>

                                  </NavLink>
                                </li>

                                {/* <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/CONQUASRegistration"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "Membership/CONQUASRegistration"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      CONQUAS Registration
                                    </span>
                                  </NavLink>
                                </li> */}
                              </>
                            ) : null}
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              ) : null}
              {/*Membership Dropdown-Menu End */}

              {/* CPAS Interview Normal Menu Start*/}
              <li
                className={`custom-make-menu rounded-sm 2xl:mb-12 lg:mb-[40px] mb-[20px] last:mb-0 ${pathname.includes("CpasInterview") && "bg-white"
                  }`}
              >
                <NavLink
                  end
                  to="CpasInterview"
                  className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150 ${pathname.includes("CpasInterview") && "hover:theme-text-color"
                    }`}
                >
                  <div className="flex menu-icon-img">
                    <CpasInterviewIcon />
                    <p className="menu-title text-xl ml-2 font-bold  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      CPAS Interview
                    </p>
                  </div>
                </NavLink>
              </li>
              {/* CPAS Interview Normal Menu  End*/}

              {/*Accreditation Dropdown-Menu Start */}
              {role > 0 && (role === 1 || role === 2) ? (
                <SidebarLinkGroup
                  activecondition={
                    pathname === "/" || pathname.includes("Membership")
                  }
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          // className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150
                          // ${
                          //   (pathname === "/" ||
                          //     pathname.includes("Membership")) &&
                          //   "hover:theme-text-color"
                          // }`}
                          className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                            ${pathname === "/Membership/Pricing"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }  
                            ${pathname === "/MembershipCampaign"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname === "/Membership/MembershipTaskList"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname === "/Membership/MembershipFeeApproval"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname === "/ViewCorporateMembers"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname === "/IndividualMembers"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname ===
                              "/Membership/ChapterXCommitteeWorkgroupMapping"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname ===
                              "/Membership/NotificationAnnouncement"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname ===
                              "/Membership/MembershipDirectoryEnquiry"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname ===
                              "/AddEditMembershipCampaign/:membershipCampaignId"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname ===
                              "/CreateCorporateMembers/:CorporateMemberId"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                            ${pathname ===
                              "/CreateIndividualMembers/:individualMemberId"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        `}
                          onClick={(e) => {
                            e.preventDefault();
                            sidebarExpanded
                              ? handleClick()
                              : setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-basline justify-between">
                            <div className="flex menu-icon-img">
                              <MembershipIcon />
                              <p className="menu-title text-xl ml-2 font-bold  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Accreditation
                              </p>
                            </div>
                          </div>
                        </a>
                        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            {role === 1 ? (
                              <>
                                {/* <li className=" my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/Pricing"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/Membership/Pricing"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Membership Pricing
                                    </span>
                                  </NavLink>
                                </li>
                                <li className=" my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/SLOTSPricing"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/Membership/SLOTSPricing"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      SLOTs Pricing
                                    </span>
                                  </NavLink>
                                </li> */}
                                <li className=" my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/CONQUASPricing"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/Membership/CONQUASPricing"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      CONQUAS Pricing
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {role === 1 || role === 2 ? (
                              <>
                                {/* <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/MembershipTaskList"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                      "/Membership/MembershipTaskList"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Membership Task List (Corporate)
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/IndividualMemberTaskList"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                      "/Membership/IndividualMemberTaskList"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Membership Task List (Individual)
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="/ViewCorporateMembers"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ViewCorporateMembers"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      View Corporate Members
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="/IndividualMembers"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/IndividualMembers"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      View Individual Members
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/SLOTAccreditation"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "Membership/SLOTAccreditation"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      SLOTs Registration
                                    </span>
                                  </NavLink>
                                </li> */}
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="Membership/CONQUASRegistration"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                      "Membership/CONQUASRegistration"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      CONQUAS Registration
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              ) : null}
              {/*Accreditation Dropdown-Menu End */}
              {/* Membership(Read only) Dropdown-Menu Start */}
              {role > 0 && (role === 9 || role === 1) ? (
                <>
                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/" || pathname.includes("Resource")
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/CreateAdminAccount"
                                ? "active hover-active-color"
                                : "hover:theme-text-color"
                              }  
                        ${pathname === "/ViewEditAdminAccount"
                                ? "active hover-active-color"
                                : "hover:theme-text-color"
                              }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex menu-icon-img">
                              <MembershipIcon />
                              <p className="menu-title text-xl ml-2 font-bold  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Membership (Read Only)
                              </p>
                            </div>
                          </a>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-5 last:mb-0">
                                <NavLink
                                  end
                                  to="/CorporateMemberViewOnly"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/CorporateMemberViewOnly"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    View Corporate Members (Read Only)
                                  </span>
                                </NavLink>
                              </li>
                              <li className="mb-5 last:mb-0">
                                <NavLink
                                  end
                                  to="/IndividualMembersViewOnly"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/IndividualMembersViewOnly"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    View Individual Members (Read Only)
                                  </span>
                                </NavLink>
                              </li>
                              <li className="mb-5 last:mb-0">
                                <NavLink
                                  end
                                  to={{
                                    pathname: "SLOTSAccreditationViewOnly",
                                    aboutProps: "ViewOnly",
                                  }}
                                  exact
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/SLOTSAccreditationViewOnly"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    View SLOTs Registration (Read Only)
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                </>
              ) : null}
              {/* Membership(Read Only) Dropdown-Menu End */}

              {/*Financial  Dropdown-Menu Start */}
              {role > 0 && (role === 1 || role === 3) ? (
                <SidebarLinkGroup
                  activecondition={
                    pathname === "/" || pathname.includes("Financial")
                  }
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/financial/Accountpayable"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/financial/ViewBookingDetail"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/financial/GlobalSettingFinance"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/financial/PaymentReconciliation"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/CreditNoteApprovalProcess"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/CreditNoteManagement"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/BatchJobExtraction"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/CreditNoteApproval"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/AddEditExternalInvoice"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/ExternalInvoices"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/AddEditExternalInvoice"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }                         
                        ${pathname === "/financial/PaymentReconciliation"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/financial/VoucherCodeManagement"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        ${pathname === "/CreateVoucherCode"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            } 
                        `}
                          onClick={(e) => {
                            e.preventDefault();
                            sidebarExpanded
                              ? handleClick()
                              : setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-basline justify-between">
                            <div className="flex menu-icon-img">
                              <FinancialIcon />
                              <p className="menu-title text-xl ml-2 font-bold  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Financial
                              </p>
                            </div>
                          </div>
                        </a>
                        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            {role === 1 || role === 3 ? (
                              <>
                                <li className="my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/BatchJobExtraction"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/BatchJobExtraction"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Batch Job Extraction
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {role === 1 || role === 3 ? <></> : null}

                            {role === 1 || role === 3 ? (
                              <>
                                <li className="my-4 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/PaymentReconciliationExtraction"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname ===
                                      "/PaymentReconciliationExtraction"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Xero Payment Reconciliation
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            ) : null}
                            {role === 1 || role === 3 ? <></> : null}
                            {role === 1 || role === 3 ? (
                              <>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/CreditNoteApproval"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/CreditNoteApproval" ||
                                      pathname ===
                                      "/financial/CreditNoteManagement"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Credit Note
                                    </span>
                                  </NavLink>
                                </li>

                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/ExtractInvoices"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ExtractInvoices" ||
                                      pathname === "/financial/ExtractInvoices"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Extract Invoices
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/GlobalSettingFinance"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/GlobalSettingFinance"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Global Setting Finance
                                    </span>
                                  </NavLink>
                                </li>

                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/PaymentReconciliation"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/PaymentReconciliation"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Payment Reconciliation
                                    </span>
                                  </NavLink>
                                </li>

                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/ARAgingReport"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ARAgingReport"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      AR Aging Report
                                    </span>
                                  </NavLink>
                                </li>

                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/GSTReport"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/GSTReport"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      GST Report
                                    </span>
                                  </NavLink>
                                </li>

                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/TransactionReport"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/TransactionReport"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Transaction Report
                                    </span>
                                  </NavLink>
                                </li>
                                <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/DeferredIncomeReport"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/DeferredIncomeReport"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Deferred Income Report
                                    </span>
                                  </NavLink>
                                </li>
                                {/* <li className="mb-5 last:mb-0">
                                  <NavLink
                                    end
                                    to="financial/Export"
                                    className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/Export"
                                      ? "hover-active-color"
                                      : null
                                      }`}
                                  >
                                    <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Export
                                    </span>
                                  </NavLink>
                                </li> */}
                              </>
                            ) : null}
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              ) : null}
              {/*Financial Dropdown-Menu End */}
              {/*Field Maintenance Dropdown-Menu Start */}
              {role > 0 && role === 1 ? (
                <SidebarLinkGroup
                  activecondition={
                    pathname === "/" || pathname.includes("Field_Management")
                  }
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/DropdownManagement"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }
                        ${pathname === "/EmailTemplateManagement"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }  
                        ${pathname === "/ManageTags"
                              ? "active hover-active-color"
                              : "hover:theme-text-color"
                            }  
                      `}
                          onClick={(e) => {
                            e.preventDefault();
                            sidebarExpanded
                              ? handleClick()
                              : setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-basline justify-between">
                            <div className="flex menu-icon-img">
                              <FieldMaintananceIcon />
                              <p className="menu-title text-xl ml-2 font-bold  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Field Maintenance
                              </p>
                            </div>
                          </div>
                        </a>
                        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            <li className="my-4 last:mb-0">
                              <NavLink
                                end
                                to="/DropdownManagement"
                                className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/DropdownManagement"
                                  ? "hover-active-color"
                                  : null
                                  }`}
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Dropdown Management
                                </span>
                              </NavLink>
                            </li>
                            <li className="my-4 last:mb-0">
                              <NavLink
                                end
                                to="/ManageTags"
                                className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ManageTags"
                                  ? "hover-active-color"
                                  : null
                                  }`}
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Manage Tags
                                </span>
                              </NavLink>
                            </li>
                            <li className="my-4 last:mb-0">
                              <NavLink
                                end
                                to="/EmailTemplateManagement"
                                className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/EmailTemplateManagement"
                                  ? "hover-active-color"
                                  : null
                                  }`}
                              >
                                <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Email Template Management
                                </span>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              ) : null}
              {/*Field Maintenance Dropdown-Menu End */}
              {/*Account Management Dropdown-Menu Start */}
              {role > 0 && role === 1 ? (
                <>
                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/" || pathname.includes("Resource")
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/ReportForSLOTs"
                                ? "active hover-active-color"
                                : "hover:theme-text-color"
                              } `}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex menu-icon-img">
                              <AccountManagementIcon />
                              <p className="menu-title text-xl ml-2 font-bold  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Report
                              </p>
                            </div>
                          </a>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="my-4 last:mb-0">
                                <NavLink
                                  end
                                  to="report/ReportForSLOTs"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "report/ReportForSLOTs"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    MOM Report for SLOTs
                                  </span>
                                </NavLink>
                              </li>

                              <li className="my-4 last:mb-0">
                                <NavLink
                                  end
                                  to="report/Exports"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "report/Exports"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Export
                                  </span>
                                </NavLink>
                              </li>

                              <li className="my-4 last:mb-0">
                                <NavLink
                                  end
                                  to="report/SlotRenewalReport"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "report/Exports"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Slots Renewal Report
                                  </span>
                                </NavLink>
                              </li>
                              <li className="my-4 last:mb-0">
                                <NavLink
                                  end
                                  to="report/SlotNewlySubmittedReport"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "report/Exports"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Slots Newly Submitted Report
                                  </span>
                                </NavLink>
                              </li>
                              <li className="my-4 last:mb-0">
                                <NavLink
                                  end
                                  to="report/OATIandSlotsUpdateReport"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "report/OATIandSlotsUpdateReport"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  OATI and Slots Update Report
                                  </span>
                                </NavLink>
                              </li>

                              {/* <li className="my-4 last:mb-0">
                                <NavLink
                                  end
                                  to="ViewEditAdminAccount"
                                  className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ViewEditAdminAccount"
                                    ? "hover-active-color"
                                    : null
                                    }`}
                                >
                                  <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    View/Edit Account
                                  </span>
                                </NavLink>
                              </li> */}
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                </>
              ) : null}
              {/*Account Management Dropdown-Menu End */}
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 2xl:hidden lg:hidden hidden sm:inline-flex justify-end mt-auto">
          <div className="py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="theme-text-color"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
