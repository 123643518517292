import React from "react";
// import DropdownSelect from '../../../pages/websiteCms/corporateMemberDirectory/Dropdown'
import DropdownSelect from "../../dropdown/Dropdown";
import DatePicker from "../../datePicker/DatePicker";
import HistoryTable from "../../table/HistoryTable";
import moment from "moment";
import Validation from "../../../utils/validation/ValidationText";
import TextInput from "../../UIComponent/TextInput";
import ButtonLoader from "../../common/buttonLoader/ButtonLoader";
function EngagementTrackingNotes(props) {
  return (
    <div>
      <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="history-table">
              <HistoryTable
                engagementList={props.engagementList}
                onEdit={props.onEdit}
                isCorporate={props.isCorporate}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6 w-full mt-7">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Engagement Type
                  <span className="text-[#c00000]">*</span>
                </h2>
                <DropdownSelect
                  drpIdentity={"EngagementType"}
                  optionArray={props.engagementType}
                  setFilterParameters={props.setFilterParameters}
                  value={
                    props.engagementTrackingDetails.corporateEngagementTypeId
                  }
                  onBlur={(e) =>
                    props.validate(
                      "corporateEngagementTypeId",
                      props.engagementTrackingDetails
                    )
                  }
                  disabled={props.isDisabledFlag ? true : false}

                //value={props.engagementType.find(item => item.id === props.engagementTrackingDetails.corporateEngagementTypeId)}
                />
                <Validation
                  error={props.validState.error.corporateEngagementTypeId}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Engagement Date
                  <span className="text-[#c00000]">*</span>
                </h2>
                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                  <DatePicker
                    name="engagementDate"
                    //value={props.engagementTrackingDetails.engagementDate}
                    handleChange={props.handleChange}
                    identity="EngagementTracking"
                    value={moment(
                      props.engagementTrackingDetails.engagementDate
                    ).format("yyyy-MM-DD")}
                    onBlur={(e) =>
                      props.validate(
                        "engagementDate",
                        props.engagementTrackingDetails
                      )
                    }
                    isDisable={props.isDisabledFlag ? true : false}
                  />
                </div>
                <Validation error={props.validState.error.engagementDate} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Recorded by
                  <span className="text-[#c00000]">*</span>
                </h2>

                <DropdownSelect
                  drpIdentity={"RecordedBy"}
                  optionArray={props._By}
                  setFilterParameters={props.setFilterParameters}
                  value={props.engagementTrackingDetails.corporateEngagementRecordedById}
                  onBlur={(e) =>
                    props.validate(
                      "corporateEngagementRecordedById",
                      props.engagementTrackingDetails
                    )
                  }
                  disabled={props.isDisabledFlag ? true : false}
                />
                <Validation
                  error={props.validState.error.corporateEngagementRecordedById}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Follow Up
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>

                <DropdownSelect
                  drpIdentity={"FollowUpBy"}
                  optionArray={props._By}
                  setFilterParameters={props.setFilterParameters}
                  value={
                    props.engagementTrackingDetails
                      .corporateEngagementFollowUpById
                  }
                  disabled={props.isDisabledFlag ? true : false}
                // onBlur={(e) =>
                //   props.validate(
                //     "corporateEngagementFollowUpById",
                //     props.engagementTrackingDetails
                //   )
                // }
                />
                {/* <Validation
                  error={props.validState.error.corporateEngagementFollowUpById}
                /> */}
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Description
                  <span className="text-[#c00000]">*</span>
                </h2>
                <div className={`${props.isDisabledFlag ? 'disabled-input' :null}`}>
                  <textarea
                    id="default"
                    className="signup-text-area form-input rounded-none w-full shadow-red  text-lg"
                    name="engagementDescription"
                    rows="5"
                    value={props.engagementTrackingDetails.engagementDescription}
                    onChange={(event) =>
                      props.handleChange(event, "EngagementTracking")
                    }
                    onBlur={(e) =>
                      props.validate(
                        "engagementDescription",
                        props.engagementTrackingDetails
                      )
                    }
                    disabled={props.isDisabledFlag ? true : false}
                  />
                </div>
                <Validation
                  error={props.validState.error.engagementDescription}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="xl:col-span-6 lg:col-span-6 col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 disabled-input">
                  <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                    Last Updated Date
                  </h2>
                  <div className="disabled-input">
                    {props.engagementTrackingDetails.updatedAt === "" || props.engagementTrackingDetails.updatedAt === null ?

                      <TextInput
                        disabled={true}
                        placeholder=""
                        type="text"
                        name="linkedIn"
                        value="None"
                        identity="BasicInformation"
                      />
                      :
                      <DatePicker
                        isDisable={true}
                        name="updatedAt"
                        handleChange={props.handleChange}
                        identity="updatedAt"
                        value={moment(props.engagementTrackingDetails.updatedAt).format("yyyy-MM-DD")}
                      />
                    }
                  </div>
                  {/* <DatePicker
                    name="updatedAt"
                    isDisable={true}
                    handleChange={props.handleChange}
                    identity="EngagementTracking"
                    value={moment(props.engagementTrackingDetails.updatedAt).format("yyyy-MM-DD")}
                    onBlur={(e) => props.validate("lastUpdatedDate", props.engagementTrackingDetails)}
                  />
                  <Validation error={props.validState.error.lastUpdatedDate} /> */}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-6 mt-[1.5rem]">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Updated By
                </h2>
                <div className="disabled-input">
                  <DropdownSelect
                    drpIdentity={"UpdatedBy"}
                    optionArray={props._By}
                    setFilterParameters={props.setFilterParameters}
                    value={props.engagementTrackingDetails.updatedBy}
                    disabled={true}
                  />
                  {/* <TextInput
                    placeholder=""
                    type="text"
                    name="companyUEN"
                    disabled={true}
                  // value={props.corporateBasicInformation.companyUEN}
                  // identity="BasicInformation"
                  // handleChange={props.handleChange}
                  // value={this.state.searchbyParent}
                  // handleChange={(event) => {
                  //   this.setState({ searchByName: event.target.value });
                  // }}
                  // onBlur={(e) => props.validate("companyUEN", props.corporateBasicInformation)}
                  /> */}

                  {/* <DropdownSelect
                  drpIdentity={"FollowUpBy"}
                  optionArray={props._By}
                  setFilterParameters={props.setFilterParameters}
                  value={
                    props.engagementTrackingDetails
                      .corporateEngagementFollowUpById
                  }
                  onBlur={(e) =>
                    props.validate(
                      "corporateEngagementFollowUpById",
                      props.engagementTrackingDetails
                    )
                  }
                /> */}
                </div>
                {/* <Validation
                  error={props.validState.error.corporateEngagementFollowUpById}
                /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="grid grid-cols-12 gap-6">
              <div className="xl:col-span-12 lg:col-span-12 col-span-12 mt-7">
                <div className="flex items-center justify-end">
                  {
                    props && props.isLoading === true ?
                      (
                        <ButtonLoader type='btn btn-blue' />
                      )
                      :
                      (
                        <button
                          className={`btn btn-blue text-white py-3 ${props.isDisabledFlag ? 'cursor-not-allowed' :null}`}
                          onClick={() => props.onClick()}
                          disabled={props.isDisabledFlag ? true : false}
                        >
                          <span className="text-2xl text-white font-semibold mr-2 rounded-full border-2 border-white h-7 w-7 flex items-center justify-center">
                            +
                          </span>
                          <span className="text-xl text-white font-semibold tracking-normal">
                            Add/Save
                          </span>
                        </button>
                      )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default EngagementTrackingNotes;
