import moment from "moment";
import React, { Component } from "react";
import DropdownSelect from "../../components/dropdown/Dropdown";
import DropdownAction from "../../components/dropdown/DropdownAction";
import Table from "../../components/table/Table";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../services/swalServices/SwalServices";
import DateRangePickkerSelect from "../../components/TimePicker.js/DateRangePickerSelect";
import { isNullString } from "../../utils/Utils";
import { saveAs } from "file-saver";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
export default class ExtractInvoices extends Component {
    constructor(props) {
        super(props);
        this.financeService = new FinanceServices();
        this.swalServices = new SwalServices();
        const today = moment();
        this.state = {
            totalResultes: 0,
            extractInvoicesList: [],
            zipInvoicesList: [],
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                invoiceType: 0,
                dateFrom: '',
                dateTo: '',
                filterbyInvoiceNumber: '',
                filterbyComapanyName: ''
            },
            typeList: [
                {
                    id: 1,
                    name: "OAT and SLOTs",
                },
                {
                    id: 2,
                    name: "Individual and Student",
                },
                {
                    id: 3,
                    name: "CONQUAS",
                },
                // {
                //     id: 4,
                //     name: "CPAS",
                // },
            ],
            invoiceTypeId: 0,
            value: moment.range(today.clone().subtract(7, "days"), today.clone()),
            isOpen: false,
            actionDropdownPaid: ["View Invoice"],
            isCreatingZip: false,
            isLoading: false,

        };
    }

    changeIsOpenFalse = () => {
    };

    onToggle = () => {
        if (this.state.pagination.invoiceType > 0) {
            this.setState({ isOpen: true });
        }
    };

    onSelect = (value, states) => {
        this.setState({ value, states });
        let startDate;
        let endDate;
        startDate = value.start.format("YYYY/MM/DD HH:MM:SS");
        endDate = value.end.format("YYYY/MM/DD HH:MM:SS");
        let pageObj = { ...this.state.pagination };
        pageObj.dateFrom = startDate;
        pageObj.dateTo = endDate;

        this.setState({ pagination: pageObj }, () => {
            this.getAllExtractInvoicesList();
        });
        this.setState({ isOpen: !this.state.isOpen });
    };

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getAllExtractInvoicesList();
    };

    handleChangeInDropdown = (id, drpIdentity) => {

        let detail = this.state.pagination;
        if (drpIdentity === "InvoiceTypes") {
            detail.invoiceType = id
            this.setState({ pagination: detail })

            //this.setState({ invoiceType: id })
            if (this.state.pagination.invoiceType !== 0) {
                //this.getAllExtractInvoicesList()
            } else {
                this.setState({ extractInvoicesList: [] });
            }
        }
    }

    actionClick = (index, value, option, event) => {
        if (option === "View Invoice") {
            if (value && value > 0) {
                let path = this.state.extractInvoicesList[index].invoicePath;
                if (isNullString(path) !== '') {
                    this.financeGetCreditNoteInvoice(path);
                }
            }
        }
    };
    financeGetCreditNoteInvoice = (value) => {
        let request = [];
        request.push(value);
        if (isNullString(value) !== "") {
            this.financeService
                .financeGetCreditNoteInvoice(request)
                .then((response) => {
                    if (response.data !== null && response.status === 200) {
                        var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");

                        var filename = "";
                        filename = `Invoice-${formattedDate}`;

                        var file = new Blob([response.data], { type: "application/pdf" });

                        URL.createObjectURL(file);
                        window.open(URL.createObjectURL(file), "_blank");

                        saveAs(file, filename + ".pdf");
                    } else {
                        this.swalServices.Error(response.message);
                    }
                });
        } else {
            this.swalServices.Error("No Invoice is Available");
        }
    };

    getAllExtractInvoicesList = () => {
        this.setState({ isLoading: true }, () => {
            if (this.state.pagination.invoiceType > 0) {
                this.financeService.GetAllExtractInvoicesList(this.state.pagination).then((response) => {
                    if (response.statusCode === 200 && response.responseItem != null) {
                        let data = response.responseItem.responseContent.itemList;
                        data.map((x) => {
                            { x.invoiceDate ? x.invoiceDate = moment(x.invoiceDate).format("DD MMM YYYY") : x.invoiceDate = "-" }
                            { x.invoiceStatus ? x.invoiceStatus = x.invoiceStatus : x.invoiceStatus = "-" }
                            return x;
                        });

                        this.setState({ invoiceTypeId: this.state.pagination.invoiceType });
                        this.setState({ extractInvoicesList: data }, () => {
                            this.setState({ isLoading: false })
                        });
                        this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
                    } else {
                        this.swalServices.Error("Something went wrong.");
                        this.setState({ isLoading: false });
                    }
                })
            } else {
                this.swalServices.Alert("Please select invoice type.");
            }
        });

    }

    zipInvoices = () => {
        // let tempValidation = 0;
        let pagination = { ...this.state.pagination };
        if (pagination.invoiceType > 0) {
            let req = {};
            req.extractInvoicesList = this.state.extractInvoicesList;
            req.pagination = this.state.pagination;
            req.pagination.pageSize = this.state.totalResultes;

            if (req.extractInvoicesList.length > 0) {
                this.setState({ isCreatingZip: true });
                this.financeService.zipInvoices(req).then((response1) => {
                    if (response1.statusCode === 200 && response1.responseItem != null) {
                        if (isNullString(response1.responseItem.responseContent) !== "") {
                            let path = response1.responseItem.responseContent
                            let request = [path];
                            this.financeService.financeGetInvoice(request).then((response) => {
                                if (response !== null) {
                                    var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss")
                                    // let InvoiceSplit = response1.responseItem.responseContent;
                                    // const name = InvoiceSplit.split("/");
                                    let detail = ""
                                    // if (req.extractInvoicesList[0].invoiceType === "Event") {
                                    //     detail = "Event";
                                    // }
                                    if (req.extractInvoicesList[0].invoiceType === "Membership") {
                                        detail = "MembershipInvoice";
                                    }
                                    // if (req.extractInvoicesList[0].invoiceType === "External Invoice") {
                                    //     detail = "ExternalInvoice";
                                    // }
                                    var filename = `${detail}${formattedDate}`;

                                    var blob = new Blob([response.data])
                                    saveAs(blob, filename + ".zip");
                                    this.setState({ isCreatingZip: false });
                                } else {
                                    this.swalServices.Error(response.message);
                                }
                            });
                        }
                    } else {
                        this.swalServices.Error("Something went wrong.");
                        this.setState({ isCreatingZip: false });
                    }
                })
            }
            else {
                this.swalServices.Alert("No records found.");
                this.setState({ isCreatingZip: false });
            }
        }
        else {
            this.swalServices.Alert("Please select invoice type.");
        }
    }

    actions = (element, index, value) => {
        return element !== "Id" ? null : (
            <div className="relative w-full credit-note-action-dropdown">
                <ActionContainer>
                    <div className="mr-8">
                        {/* {console.log(element, index, value)} */}
                        <DropdownAction
                            key={index}
                            value={value}
                            id={index}
                            index={index}
                            options={this.state.actionDropdownPaid}
                            align="right"
                            onOptionClick={this.actionClick.bind(this)}
                        />
                        {/* {(this.state.adhocInvoiceList.find(x => x.financeAdhocInvoiceId === value)).status === 'Paid' ?
                  <DropdownAction
                    key={index}
                    value={value}
                    id={index}
                    index={index}
                    options={this.state.actionDropdownPaid}
                    align="right"
                    onOptionClick={this.actionClick.bind(this)}
                  />
                  :
                  <DropdownAction
                    key={index}
                    value={value}
                    id={index}
                    index={index}
                    options={this.state.actionDropdownPending}
                    align="right"
                    onOptionClick={this.actionClick.bind(this)}
                  />
                } */}

                    </div>
                </ActionContainer>
            </div>
        );
    };

    componentDidMount() {
        // this.getAllExtractInvoicesList();

        // document.addEventListener('contextmenu', (e) => {
        //     e.preventDefault();
        //   });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        let detail = this.state.pagination;
        detail[name] = value;
        this.setState({ pagination: detail });
        //this.getAllExtractInvoicesList();
    };


    search = () => {
        let detail = this.state.pagination;
        detail.pageNo = 1;
        this.setState({ pagination: detail }, () => { this.getAllExtractInvoicesList() })
    }

    render() {
        return (
            <div className="main-body-section finance-body-sec">
                <div className="bg-white pb-2 mt-10">
                    <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-10 pb-12">
                        <div className=" 2xl:col-span-2 lg:col-span-3 col-span-12 w-full">
                            <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                Invoice Number
                                <span className="text-[#c00000]">*</span>
                            </h2>
                            <input
                                id="default"
                                name="filterbyInvoiceNumber"
                                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                type="text"
                                onChange={(event) => this.handleChange(event)}
                                value={this.state.pagination.filterbyInvoiceNumber}
                            />
                        </div>

                        <div className=" 2xl:col-span-2 lg:col-span-3 col-span-12 w-full">
                            <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                Name
                                <span className="text-[#c00000]">*</span>
                            </h2>
                            <input
                                id="default"
                                name="filterbyComapanyName"
                                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                type="text"
                                onChange={(event) => this.handleChange(event)}
                                value={this.state.pagination.filterbyComapanyName}
                            />
                        </div>


                        <div className=" 2xl:col-span-2 lg:col-span-3 col-span-12 w-full">
                            <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                Invoice Type
                                <span className="text-[#c00000]">*</span>
                            </h2>
                            <DropdownSelect
                                drpIdentity="InvoiceTypes"
                                optionArray={this.state.typeList}
                                setFilterParameters={this.handleChangeInDropdown.bind(this)}
                                value={this.state.pagination.invoiceType}
                            />
                        </div>
                        <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                            <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                Invoice Date Range
                            </h2>
                            <div className="grid grid-cols-12 gap-6 items-center w-full">
                                <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                                    <input
                                        id="default"
                                        name="dateFrom"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="date"
                                        value={moment(
                                            this.state.pagination.dateFrom
                                        ).format("yyyy-MM-DD")}
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                </div>
                                <div className=" 2xl:col-span-1 lg:col-span-1 col-span-12 w-full text-center">
                                    <h2 className="text-xl theme-color">To</h2>
                                </div>
                                <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                                    <input
                                        id="default"
                                        name="dateTo"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="date"
                                        value={moment(
                                            this.state.pagination.dateTo
                                        ).format("yyyy-MM-DD")}
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                </div>
                            </div>
                            {/* <div className={`right-date-range ${this.state.pagination.invoiceType > 0 ? "" : "range-disabled"}`}>
                                <button className="w-full h-[58px] border-solid border-[#000000] btn  py-3 text-lg text-[#000000] flex items-left date-rang-sec relative">
                                    <div className="right-date-range">
                                        <div className="h-[45px] inline-block pt-2 w-full">
                                            <DateRangePickkerSelect
                                                pagination={this.state.pagination}
                                                value={this.state.value}
                                                onSelect={this.onSelect.bind(this)}
                                                isOpen={this.state.isOpen}
                                                onToggle={this.onToggle.bind(this)}
                                                changeIsOpenFalse={this.changeIsOpenFalse.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </button>
                            </div> */}
                        </div>
                        <div className="2xl:col-span-12 pt-10 lg:col-span-12 col-span-12 w-full">
                            <div className=" flex items-center justify-end w-full space-x-4">
                                <button className="btn btn-blue text-xl border text-white "
                                    onClick={() => this.search()}
                                >
                                    Search
                                </button>
                                {this.state.isCreatingZip === true ?
                                    <ButtonLoader type='btn btn-blue' /> :
                                    <button className="btn btn-blue text-xl border text-white "
                                        onClick={() => this.zipInvoices()}
                                    >
                                        Create Zip
                                    </button>
                                }


                            </div>
                        </div>


                    </div>

                </div>

                {/* Table Section Start */}
                <div className="mt-10 external-invoice-tbl">
                    {/* {this.state.invoiceTypeId === 1 ?
                        <> */}
                    <Table
                        columns={[
                            { name: "InvoiceNo", title: "Invoice Number" },
                            { name: "CompanyName", title: "Name" },
                            { name: "bookingReferenceNo", title: "Booking Reference Number" },
                            { name: "invoiceDate", title: "Invoice Date" },
                            { name: "InvoiceStatus", title: "Invoice Status" },
                            { name: "InvoiceType", title: "InvoiceType" },

                            { name: "Id", title: "Actions" },
                        ]}
                        rows={this.state.extractInvoicesList}
                        isLoading={this.state.isLoading}
                        sortingColumns={["", ""]}
                        pagination={this.state.pagination}
                        totalResultes={this.state.totalResultes}
                        totalCount={this.state.totalResultes}
                        setPagination={this.setPagination.bind(this)}
                        customScope={[
                            {
                                column: "Id",
                                renderTableData: this.actions.bind(this),
                            },
                            // {
                            //     column: "selectRow",
                            //     renderTableData: this.select.bind(this),
                            //     valueColumnName: "financeAdhocInvoiceId",
                            // },
                        ]}
                    />
                    {/* </>
                        :
                        <>
                            <Table
                                columns={[
                                    { name: "InvoiceNo", title: "Invoice Number" },
                                    { name: "CompanyName", title: "Individual Name" },
                                    { name: "invoiceDate", title: "Invoice Date" },
                                    { name: "InvoiceStatus", title: "Invoice Status" },
                                  
                                    { name: "InvoiceType", title: "InvoiceType" },
                                    { name: "Id", title: "Actions" },
                                ]}
                                rows={this.state.extractInvoicesList}
                                isLoading={this.state.isLoading}
                                sortingColumns={["", ""]}
                                pagination={this.state.pagination}
                                totalResultes={this.state.totalResultes}
                                totalCount={this.state.totalResultes}
                                setPagination={this.setPagination.bind(this)}
                                customScope={[
                                    {
                                        column: "Id",
                                        renderTableData: this.actions.bind(this),
                                    },
                                    // {
                                    //     column: "selectRow",
                                    //     renderTableData: this.select.bind(this),
                                    //     valueColumnName: "financeAdhocInvoiceId",
                                    // },
                                ]}
                            />
                        </>
                    } */}
                </div>
                {/*Table Section End */}

            </div>


        );
    }
}

