import {
    GetSlotMemberList,
    GetAllDropDownsForSLOTSMember,
    GetSLOTSMemberDetailByMemberId,
    GetDropdownDataOfWorkheadAndGrade,
    UploadPdf,
    SaveSlotMemberClarificationDetails,
    SendReconciliationUploadWorkshopProofLinkToSlots,
    SendPaymentLinkOfSLOTSMember,
    GetSlotsMemberFiles,
    EditSlotsMemberDetails,
    GetSLOTSMembershipSubscriptionById,
    GetSlotsMemberCertificate,
    RemoveCompanyRegisteredBCAScopeById,
    RejectMemberApplication,
    SaveSLOTsDocuments,
    DeleteSlotsMembersFiles,
    ExpireSlotsMemberApplication,
    GetMembershipPricingWithGst,
    AddSLOTSRegistrationHeadScopeTopUp,
    RemoveSLOTSRegistrationHeadScopeTopUp,
    AddEditAuthoriseRepresentativeSummary,
    RemoveSLOTSAuthoriseRepresentativeSummary,
    BuyAdditionalRegistrationHeadTopUp,
    AcceptRenewSLOTSMembership,
    UpdateBasicAppicationDetails,
    AddSLOTSRegistrationHeadScopeTopUpAdditional,
    RemoveSLOTSRegistrationHeadScopeTopUpAdditional,
    SendRenewalReminderMailForSlotsMembers,
    DeleteSLOTSMember,
    UpdateForceRenewalFlag,
    UpdateOrganisationProfileDetails,
    GetSlotRenewalRecordList,
    DownloadSheetForSlotRenewalRecord,
    GetSlotNewlySubmittedRecordList,
    DownloadSheetForSlotNewlySubmittedRecord
    ,SetSLOTsMemberPricing,
    UpdateSLOTSRegistrationHeadScopeTopUp,
    SendPaymentLinkForSlot
} from "../ApiEndPoints";
import {
    axiosGet,
    axiosGetAuthorize,
    axiosGetFileAuthorizeblob,
    axiosGetMultiParams,
    axiosPost,
    axiosPostAuthorize,
    axiosPostFileAuthorizeblob,
    axiosPostWithoutEncryption
} from "../AxiosRequests";

export default class SlotMemberService {

    async getSlotMemberList(request) {
        return axiosPostAuthorize(GetSlotMemberList, request);
    }
    async getAllDropDownsForSLOTSMember(request) {
        return axiosGet(GetAllDropDownsForSLOTSMember, request);
    }
    async getSLOTSMemberDetailByMemberId(request) {
        return axiosGet(GetSLOTSMemberDetailByMemberId, request)
    }
    async getDropdownDataOfWorkheadAndGrade(request) {
        return axiosPostAuthorize(GetDropdownDataOfWorkheadAndGrade, request);
    }
    async SaveSlotMemberClarificationDetails(request) {
        return axiosPost(SaveSlotMemberClarificationDetails, request)
    }
    async SendReconciliationUploadWorkshopProofLinkToSlots(request) {
        return axiosPost(SendReconciliationUploadWorkshopProofLinkToSlots, request)
    }
    async sendPaymentLinkOfSLOTSMember(request) {
        return axiosGet(SendPaymentLinkOfSLOTSMember, request);
    }
    async acceptRenewSLOTSMembership(request) {
        return axiosGet(AcceptRenewSLOTSMembership, request);
    }
    async GetSlotsMemberFiles(request) {
        return axiosGetFileAuthorizeblob(GetSlotsMemberFiles, request);
    }
    async EditSlotsMemberDetails(request) {
        return axiosPost(EditSlotsMemberDetails, request)
    }
    async getSLOTSMembershipSubscriptionById(request) {
        return axiosGetAuthorize(GetSLOTSMembershipSubscriptionById, request)
    }
    async getSlotsMemberCertificate(request) {
        return axiosGetFileAuthorizeblob(GetSlotsMemberCertificate, request)
    }
    async RemoveCompanyRegisteredBCAScopeById(request) {
        return axiosGet(RemoveCompanyRegisteredBCAScopeById, request)
    }
    async rejectSLOTSMemberApplication(request) {
        return axiosPostAuthorize(RejectMemberApplication, request)
    }
    async ExpireSlotsMemberApplication(request) {
        return axiosGetAuthorize(ExpireSlotsMemberApplication, request)
    }
    async uploadPDF(request) {
        let formData = new FormData();
        formData.append("file", request.file);
        formData.append("filename", request.filename);
        formData.append("storagePath", request.storagePath);

        return axiosPostWithoutEncryption(UploadPdf, formData, true);
    }
    async saveSLOTsDocuments(request) {
        return axiosPost(SaveSLOTsDocuments, request);
    }
    async deleteSlotsMembersFiles(request) {
        return axiosPost(DeleteSlotsMembersFiles, request);
    }
    async getMembershipPricingWithGst() {
        return axiosGet(GetMembershipPricingWithGst);
    }
    async AddSLOTSRegistrationHeadScopeTopUp(request) {
        return axiosPost(AddSLOTSRegistrationHeadScopeTopUp, request);
    }
    async RemoveSLOTSRegistrationHeadScopeTopUp(request) {
        return axiosGetAuthorize(RemoveSLOTSRegistrationHeadScopeTopUp, request);
    }
    async addEditAuthoriseRepresentativeSummary(request) {
        return axiosPost(AddEditAuthoriseRepresentativeSummary, request)
    }
    async removeSLOTSAuthoriseRepresentativeSummary(request) {
        return axiosPost(RemoveSLOTSAuthoriseRepresentativeSummary, request);
    }

    async BuyAdditionalRegistrationHeadTopUp(request) {
        return axiosPost(BuyAdditionalRegistrationHeadTopUp, request);
    }
    
    async updateBasicAppicationDetails(request) {
        return axiosPost(UpdateBasicAppicationDetails, request);
    }
    
    async AddSLOTSRegistrationHeadScopeTopUpAdditional(request) {
        return axiosPost(AddSLOTSRegistrationHeadScopeTopUpAdditional, request);
    }
    async RemoveSLOTSRegistrationHeadScopeTopUpAdditional(request) {
        return axiosGetAuthorize(RemoveSLOTSRegistrationHeadScopeTopUpAdditional, request);
    }
    async SendRenewalReminderMailForSlotsMembers(request) {
        return axiosGetAuthorize(SendRenewalReminderMailForSlotsMembers, request)
    }
    async deleteSLOTSMember(request) {
        return axiosPostAuthorize(DeleteSLOTSMember, request)
    }
    async updateForceRenewalFlag(request) {
        return axiosGetMultiParams(UpdateForceRenewalFlag, request);
    }
    async updateOrganisationProfileDetails(request) {
        return axiosPost(UpdateOrganisationProfileDetails, request);
    }
    async setSLOTsMemberPricing(request) {
        return axiosPost(SetSLOTsMemberPricing, request);
    }
    
    async getSlotRenewalRecordList(request) {
        return axiosPost(GetSlotRenewalRecordList, request);
    }
    async downloadSheetForSlotRenewalRecord(request) {
        return axiosPostFileAuthorizeblob(DownloadSheetForSlotRenewalRecord, request);
    }
    
    async getSlotNewlySubmittedRecordList(request) {
        return axiosPost(GetSlotNewlySubmittedRecordList, request);
    }
    async downloadSheetForSlotNewlySubmittedRecord(request) {
        return axiosPostFileAuthorizeblob(DownloadSheetForSlotNewlySubmittedRecord, request);
    }
    async updateSLOTSRegistrationHeadScopeTopUp(request) {
        return axiosPostAuthorize(UpdateSLOTSRegistrationHeadScopeTopUp, request);
    }
    async SendPaymentLinkForSlot(request) {
        return axiosGet(SendPaymentLinkForSlot, request)
    }

}