import { axiosGet, axiosGetAuthorize, axiosPostAuthorize } from '../AxiosRequests';
import { AddEditMembershipTaskAndSendForApproval, AddEditMembershipTaskAndSendPaymentLink, GetAllDropdownsForMembershipTaskList, GetAllSources, GetCalculatedFinalPrice, GetExistingDiscountTableByTaskId, GetPriceTabelByCorporateMemberId, GetTaskByCompanyId
    //, SendEscalateMailToMember 
// SCal
,GetAllTasksList
,GetCorporateMemberPricing
,GetAllIndividualMemberTaskList
,getClarificationDetails,
GetTaskByIndividualMemberId,
GetAllDropDownsForIndividualMember,
AddEditIndividualMembershipTaskAndSendPaymentLink,
RejectMemberApplication,
GetOrganisationBasicInformationById,
GetCompanyRegisteredWithBCAList,
GetAllDropdownsForCorporateMember,
GetIndividualBasicInformationById,
getAllStudentMemberTaskList,
GetTaskByStudentMemberId,
getStudentMembershipPricing,
acceptAndApprovedStudentApplication,

 
} from '../ApiEndPoints'

export default class MembershipTaskListServices {

    // async getAllTaskList(request) {
    //     return axiosPostAuthorize(GetAllTasksList, request)
    // }

    async getTaskByCompanyId(request) {
        return axiosGetAuthorize(GetTaskByCompanyId, request)
    }

    async addEditMembershipTaskAndSendForApproval(request) {
        return axiosPostAuthorize(AddEditMembershipTaskAndSendForApproval, request)
    }
    async getAllSources() {
        return axiosGetAuthorize(GetAllSources);
    }

    // async getAllMembershipTypes() {
    //     return axiosGetAuthorize(GetAllMembershipTypes);
    // }

    // async getAllTransactionaTypes() {
    //     return axiosGetAuthorize(GetAllTransactionaTypes);
    // }

    // async getAllBillingCategories() {
    //     return axiosGetAuthorize(GetAllBillingCategories);
    // }

    // async getAllMembershipRevenue() {
    //     return axiosGetAuthorize(GetAllMembershipRevenue);
    // }
    // async getPriceTabelForTaskListProcess(request) {
    //     return axiosPostAuthorize(GetAllPriceTables, request)
    // }
    // async getSourceLists() {
    //     return axiosGetAuthorize(GetAllCorporateProspectSource);
    // }
    async getAllDropdownsForTaskList() {
        return axiosGetAuthorize(GetAllDropdownsForMembershipTaskList);
    }
    async getPriceTabelByCorporateMemberId(request) {
        return axiosGetAuthorize(GetPriceTabelByCorporateMemberId, request)
    }
    async getCalculatedFinalPrice(request) {
        return axiosPostAuthorize(GetCalculatedFinalPrice, request)
    }
    
    async getExistingDiscountTableByTaskId(request) {
        return axiosGetAuthorize(GetExistingDiscountTableByTaskId, request)
    }
    // async sendInvoiceEmailToCorporateMember(request) {
    //     return axiosPostAuthorize(SendInvoiceEmailToCorporateMember, request);
    // }
    // async sendQuotationEmailToCorporateMember(request) {
    //     return axiosPostAuthorize(SendQuotationEmailToCorporateMember, request);
    // }
    // async sendEscalateMailToMember(request) {
    //     return axiosPostAuthorize(SendEscalateMailToMember, request);
    // }


    

    // Scal Services 
    async getAllTaskList(request) {
        return axiosPostAuthorize(GetAllTasksList, request)
    }
    async getCorporateMemberPricing(request) {
        return axiosGetAuthorize(GetCorporateMemberPricing, request)
    }

    async addEditMembershipTaskAndSendPaymentLink(request) {
        return axiosPostAuthorize(AddEditMembershipTaskAndSendPaymentLink, request)
    }
    async getAllIndividualMemberTaskList(request) {
        return axiosPostAuthorize(GetAllIndividualMemberTaskList, request)
    }
    async getAllStudentMemberTaskList(request) {
        return axiosPostAuthorize(getAllStudentMemberTaskList, request)
    }
     
    async getClarificationDetails(request) {
        return axiosPostAuthorize(getClarificationDetails, request)
    }

    // For Scal IndividualMember 
    async getTaskByIndividualMemberId(request) {
        return axiosGetAuthorize(GetTaskByIndividualMemberId, request)
    }
    async getAllDropDownsForIndividualMember() {
        return axiosGetAuthorize(GetAllDropDownsForIndividualMember);
    }
    async addEditIndividualMembershipTaskAndSendPaymentLink(request) {
        return axiosPostAuthorize(AddEditIndividualMembershipTaskAndSendPaymentLink, request)
    }
  

    async rejectMemberApplication(request) {
        return axiosPostAuthorize(RejectMemberApplication, request)
    }

    async getOrganisationBasicInformationById(request) {
        return axiosGetAuthorize(GetOrganisationBasicInformationById, request)
    }
    async getCompanyRegisteredWithBCAList(request) {
        return axiosGetAuthorize(GetCompanyRegisteredWithBCAList, request);
    }
    async getAllDropdownForCorporateMember(request) {
        return axiosGetAuthorize(GetAllDropdownsForCorporateMember, request);
    }
    async getIndividualBasicInformationById(request) {
        return axiosGetAuthorize(GetIndividualBasicInformationById, request);
    }

    //StudentMember
    async getTaskByStudentMemberId(request) {
        return axiosGetAuthorize(GetTaskByStudentMemberId, request)
    }
    async acceptAndApprovedStudentApplication(request) {
        return axiosGetAuthorize(acceptAndApprovedStudentApplication, request)
    }
    async getStudentMemerPricing() {
        return axiosGet(getStudentMembershipPricing);
    }
}
