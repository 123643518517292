import {
    GetCONQUASMemberList,
    GetAllDropDownsForCONQUASMember,
    EditCONQUASMemberDetails,
    GetCONQUASMemberDetailByMemberId,
    SentPaymentLinkOfCONQUASMember,
    AcceptAndApprovedCONQUASApplication,
    SaveConquasMemberClarificationDetails,
    RejectCONQUASMemberApplication,
    UpdateCONQUASMemberDetails,
    GetCONQUASMemberCertificate,
    GetCONQUASMemberFiles,
    UploadPdf,
    SaveCONQUASDocuments,
    AddEditConquasAcademicQualifications,
    AddEditConquasTrainingWorkshop,
    AddEditConquasTrainingGIPWorkshop,
    AddEditSupervisionExperience,
    GetEducationInformationList,
    GetEducationInformationCONQUASTrainingList,
    GetIndividualEducationInformationCONQUASGIPWorkshopList,
    GetSupervisionExperienceList,
    StoreBase64Image,
    SendPaymentLinkForCONQUASRenewalPayment,
    DeleteConquasAcademicQualifications,
    DeleteConquasTrainingWorkshop,
    DeleteConquasTrainingGIPWorkshop,
    DeleteSupervisionExperience,
    UpdateCONQUAsLOADetails,
    SendRenewalReminderMailForCONQUASMember,
    SendPaymentLinkForConquas,
    UpdateCONQUASPersonalParticularDetail
} from "../ApiEndPoints";
import {
    axiosGet,
    axiosGetAuthorize,
    axiosGetFileAuthorizeblob,
    axiosPost,
    axiosPostAuthorize,
    axiosPostWithoutEncryption,
    

} from "../AxiosRequests";

export default class CONQUASMemberService {

    async SendPaymentLinkForConquas(request) {
        return axiosGet(SendPaymentLinkForConquas, request)
    }
    async getCONQUASMemberList(request) {
        return axiosPost(GetCONQUASMemberList, request);
    }
    async getAllDropDownsForCONQUASMember(request) {
        return axiosGet(GetAllDropDownsForCONQUASMember, request);
    }
    async getCONQUASMemberDetailByMemberId(request) {
        return axiosGet(GetCONQUASMemberDetailByMemberId, request);
    }
    async EditCONQUASMemberDetails(request) {
        return axiosPost(EditCONQUASMemberDetails, request);
    }

    async SentPaymentLinkOfCONQUASMember(request) {
        return axiosGet(SentPaymentLinkOfCONQUASMember, request);
    }

    async acceptAndApprovedCONQUASApplication(request) {
        return axiosGet(AcceptAndApprovedCONQUASApplication, request);
    }
    async SaveConquasMemberClarificationDetails(request) {
        return axiosPost(SaveConquasMemberClarificationDetails, request)
    }
    async rejectCONQUASMemberApplication(request) {
        return axiosPostAuthorize(RejectCONQUASMemberApplication, request)
    }
    async UpdateCONQUASMemberDetails(request) {
        return axiosPost(UpdateCONQUASMemberDetails, request);
    }
    async getCONQUASMemberCertificate(request) {
        return axiosGetFileAuthorizeblob(GetCONQUASMemberCertificate, request)
    }
    async GetCONQUASMemberFiles(request) {
        return axiosGetFileAuthorizeblob(GetCONQUASMemberFiles, request);
    }
    async uploadPDF(request) {
        let formData = new FormData();
        formData.append("file", request.file);
        formData.append("filename", request.filename);
        formData.append("storagePath", request.storagePath);

        return axiosPostWithoutEncryption(UploadPdf, formData, true);
    }
    async saveCONQUASDocuments(request) {
        return axiosPost(SaveCONQUASDocuments, request);
    }
    async AddEditConquasAcademicQualifications(request) {
        return axiosPost(AddEditConquasAcademicQualifications, request);
    }
    async getEducationInformationList(request) {
        return axiosGet(GetEducationInformationList, request);
    }
    async getEducationInformationCONQUASTrainingList(request) {
        return axiosGet(GetEducationInformationCONQUASTrainingList, request);
    }
    async getIndividualEducationInformationCONQUASGIPWorkshopList(request) {
        return axiosGet(GetIndividualEducationInformationCONQUASGIPWorkshopList, request);
    }
    async getSupervisionExperienceList(request) {
        return axiosGet(GetSupervisionExperienceList, request);
    }
    async AddEditConquasTrainingWorkshop(request) {
        return axiosPost(AddEditConquasTrainingWorkshop, request);
    }
    async AddEditConquasTrainingGIPWorkshop(request) {
        return axiosPost(AddEditConquasTrainingGIPWorkshop, request);
    }
    async deleteConquasAcademicQualifications(request) {
        return axiosPostAuthorize(DeleteConquasAcademicQualifications, request)
    }
    async deleteConquasTrainingWorkshop(request) {
        return axiosPostAuthorize(DeleteConquasTrainingWorkshop, request)
    }
    async deleteConquasTrainingGIPWorkshop(request) {
        return axiosPostAuthorize(DeleteConquasTrainingGIPWorkshop, request)
    }
    async AddEditSupervisionExperience(request) {
        return axiosPost(AddEditSupervisionExperience, request);
    }
    async deleteSupervisionExperience(request) {
        return axiosPostAuthorize(DeleteSupervisionExperience, request)
    }
    async storeBase64Image(request) {
        return axiosPostWithoutEncryption(StoreBase64Image, request, false);
    }
    async sendPaymentLinkForCONQUASRenewalPayment(request) {
        return axiosGet(SendPaymentLinkForCONQUASRenewalPayment, request);
    }
    async updateCONQUAsLOADetails(request) {
        return axiosPost(UpdateCONQUAsLOADetails, request)
    }
    async SendRenewalReminderMailForCONQUASMember(request) {
        return axiosGetAuthorize(SendRenewalReminderMailForCONQUASMember, request)
    }
    async updateCONQUASPersonalParticularDetail(request) {
        return axiosPost(UpdateCONQUASPersonalParticularDetail, request);
    }
}