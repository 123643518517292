import { axiosPostFileAuthorizeblob, axiosPostWithoutEncryption,axiosPost } from '../AxiosRequests';
import {  UploadPdf, StoreBase64Image, DownloadMultipleFilesZip ,GetOATIandslotsRecordList,DownloadSheetForOATIandslotsRecordList} from '../ApiEndPoints';

export default class CommonServices {

    // async getAllAreaOfExpertise() {
    //     return axiosGetAuthorize(GetAllAreaOfExpertise);
    // }

    // async getAllBusinessSectors() {
    //     return axiosGetAuthorize(GetAllBusinessSectors);
    // }

    // async getAllChapters() {
    //     return axiosGetAuthorize(GetAllChapters);
    // }

    // async getAllCountries() {
    //     return axiosGetAuthorize(GetAllCountries);
    // }
    // async getAllStatus() {
    //     return axiosGetAuthorize(GetAllStatus);
    // }
    
    async uploadPDF(request) {
        let formData = new FormData();
        formData.append("file", request.file);
        formData.append("filename", request.filename);
        formData.append("storagePath", request.storagePath);
        return axiosPostWithoutEncryption(UploadPdf, formData, true);
    }

    async storeBase64Image(request) {
        return axiosPostWithoutEncryption(StoreBase64Image, request, false);
    }
    async DownloadMultipleFilesZip(request) {
        return axiosPostFileAuthorizeblob(DownloadMultipleFilesZip, request)
    }
    async getOATIandslotsRecordList(request) {
        return axiosPost(GetOATIandslotsRecordList, request);
    }
    async downloadSheetForOATIandslotsRecordList(request) {
        return axiosPostFileAuthorizeblob(DownloadSheetForOATIandslotsRecordList, request);
    }
}