import React, { Component } from "react";
import Fees from "../../components/dashboard/Fees";
import ProspectsCorporateMember from "../../components/dashboard/ProspectsCorporateMember";
import DashboardServices from "../../services/axiosServices/apiServices/DashboardServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { getAuthProps } from "../../utils/AuthenticationLibrary";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.getDashboardServices = new DashboardServices();
    this.swalServices = new SwalServices();
    this.state = {
      isLoading: false,
      route: null,
      entries: "",

      dashboardDetails: {
        businessMatchingPaid: 0,
        businessMatchingPendingPayment: 0,
        businessMatchingTotalAmount: 0,
        closedLost: 0,
        closedSuccessful: 0,
        closedToRevisit: 0,
        eventsPaid: 0,
        eventsPendingPayment: 0,
        eventsTotalAmount: 0,
        expiryWithin30: 0,
        expiryWithin45: 0,
        expiryWithin60: 0,
        inProgress: 0,
        membershipAssociateFee: 0,
        membershipOrdinaryFee: 0,
        membershipTradeFee: 0,
        membershipTotalAmount: 0,
        membershipIndividualFee: 0,
        membershipCONQUASFee: 0,
        membershipSlotFee: 0,

        notStarted: 0,
        renewedlast7: 0,
        // sponsorshipCorporateFee: 0,
        // sponsorshipIndividualFee: 0,
        // sponsorshipTotalAmount: 0,
        totalCorporateMember: 0,
        totalProspects: 0,
      },
      roleId: 0,
    };
  }

  getAllEntries = () => {
    this.setState({ isLoading: true });
    this.getDashboardServices.getAllEntries().then((response) => {

      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ entries: response.responseItem });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  getAdminDashboardDetail = () => {

    this.setState({ isLoading: true });
    this.getDashboardServices.getAdminDashboardDetail().then((response) => {
      console.log("Print Dashboard Response :", response);
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ dashboardDetails: response.responseItem.responseContent });
        console.log("Print Dashboard Details  Response :", this.state.dashboardDetails);
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  componentDidMount() {
    let cookies = getAuthProps();
    if (cookies && cookies.roleId > 0) {
      this.setState({ roleId: cookies.roleId })
    }
    this.getAdminDashboardDetail();
  }



  render() {
    return (
      <div className="main-body-section bg-white h-auto justify-between">
        {/* Prospects Corporate Member Start */}
        <ProspectsCorporateMember
          dashboardDetails={this.state.dashboardDetails}
        />
        {/* Prospects Corporate Member Ends */}

        {/* PendingApproval Start */}
        {/* <PendingApproval
          dashboardDetails={this.state.dashboardDetails}
        /> */}
        {/* PendingApproval End */}

        {/* Events Start */}
        {/* <Events
          dashboardDetails={this.state.dashboardDetails}
        /> */}
        {/* Events End */}

        {/* Fees Start */}
        <Fees
          dashboardDetails={this.state.dashboardDetails}
        />
        {/* Fees End */}
      </div>
    );
  }
}
