import React, { Component } from 'react'
import DropdownSelect from '../../components/dropdown/Dropdown'
import TextInput from '../../components/inputs/TextInput'
import RadioButton from '../../components/membership/membershipTaskList/RadioButton';
import DatePicker from '../../components/datePicker/DatePicker';
import ImageCropperWithUpload from '../../components/ImageUpload/UploadImage';


export default class PreAssessorCpasScoringSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      PassorFail: '',
      croppedImage: null,
    };
  }

  handleInputChange = (event) => {
    this.setState({ inputValue: event.target.value });
  };


  handleRadioButtons = (event) => {
    this.setState({ PassorFail: event.target.value });
  };


  handleSaveImage = (event, file, keyId, imageIdentity, index) => {
    // Handle the saved image here
    console.log("Saved Image Data:", file);
    this.setState({ croppedImage: file.fileStr });
  };

  render() {
    const { croppedImage } = this.state;
    return (
      <div className=''>
        <div className="main-body-section">
          <div className="bg-white px-8 pt-10 pb-12">
            <div className="grid grid-cols-12 gap-6 w-full">
              <div className='2xl:col-span-5 lg:col-span-5 sm:col-span-12 col-span-12'>
                <div className="2xl:col-span-4 lg:col-span-4 sm:col-span-4 col-span-12 mb-3">
                  <h2 className="text-xl text-[#000000] font-bold mb-2">
                    Candidates Name <span className='text-[#E74C3C]'>*</span>
                  </h2>
                  <TextInput
                    name="myInput"
                  // value={this.state.inputValue}
                  // handleChange={this.handleInputChange}
                  // onBlur={this.handleInputBlur}
                  // disabled={false}
                  // autoComplete="off"
                  />
                </div>
                <div className="2xl:col-span-4 lg:col-span-4 sm:col-span-4 col-span-12 mb-3">
                  <h2 className="text-xl text-[#000000] font-bold mb-2">
                    Application Number <span className='text-[#E74C3C]'>*</span>
                  </h2>
                  <TextInput
                  // name="myInput"
                  // value={this.state.inputValue}
                  // handleChange={this.handleInputChange}
                  // onBlur={this.handleInputBlur}
                  // disabled={false}
                  // autoComplete="off"
                  />
                </div>
              </div>
              <div className='2xl:col-span-7 lg:col-span-7 sm:col-span-12 col-span-12 mb-3'>
                <div className="2xl:col-span-7 lg:col-span-7 sm:col-span-7 col-span-12 items-center">
                  <div className='flex items-center justify-end gap-x-4 mt-[25px] '>
                    <h2 className="text-xl text-[#000000] font-bold mb-1">
                      Pre-assessment score:
                    </h2>
                    <div className="2xl:col-span-1 lg:col-span-1 sm:col-span-1 col-span-12">
                      <TextInput
                        className="custom-input"
                      />
                    </div>
                    <div className='flex items-center gap-x-5'>
                      <div className='flex items-center gap-x-3'>
                        <RadioButton
                          name="PassorFail"
                          id="PassorFail"
                          className="custom-radio"
                          value="Pass"
                          handleRadioButtons={this.handleRadioButtons}
                          checked={this.state.PassorFail === 'Pass'}
                          isDisabled={false}
                        />
                        <label htmlFor="male" className='text-xl font-bold'>Pass</label>
                      </div>
                      <div className='flex items-center gap-x-3'>
                        <RadioButton
                          name="PassorFail"
                          id="Fail"
                          className="custom-radio"
                          value="Fail"
                          handleRadioButtons={this.handleRadioButtons}
                          checked={this.state.PassorFail === 'Fail'}
                          isDisabled={false}
                        />
                        <label htmlFor="Fail" className='text-xl font-bold'>Fail</label>
                      </div>
                    </div>
                  </div>

                  <div className='flex items-center justify-end gap-x-4 mt-[40px] '>
                    <h2 className="text-xl text-[#000000] font-bold mb-1">
                      Final Score:
                    </h2>
                    <div className="2xl:col-span-1 lg:col-span-1 sm:col-span-1 col-span-12">
                      <TextInput
                        className="custom-input"
                      />
                    </div>
                    <div className='flex items-center gap-x-5'>
                      <div className='flex items-center gap-x-3'>
                        <RadioButton
                          name="PassorFail"
                        // id="PassorFail"
                        // className="custom-radio"
                        // value="Pass"
                        // handleRadioButtons={this.handleRadioButtons}
                        // checked={this.state.PassorFail === 'Pass'}
                        // isDisabled={false}
                        />
                        <label htmlFor="male" className='text-xl font-bold'>Pass</label>
                      </div>
                      <div className='flex items-center gap-x-3'>
                        <RadioButton
                          name="PassorFail"
                        // id="Fail"
                        // className="custom-radio"
                        // value="Fail"
                        // handleRadioButtons={this.handleRadioButtons}
                        // checked={this.state.PassorFail === 'Fail'}
                        // isDisabled={false}
                        />
                        <label htmlFor="Fail" className='text-xl font-bold'>Fail</label>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

            <div className="grid grid-cols-12 gap-6 w-full mt-5">
              <div className='2xl:col-span-12 lg:col-span-12 sm:col-span-12 col-span-12'>
                <div className='custom-table'>
                  <div className="overflow-x-auto">
                    <table className="w-full bg-white shadow-lg rounded-sm border border-slate-200">
                      <thead className="table-bg text-xs font-semibold capitalize text-[#000000] border-t border-b border-slate-200 ">
                        <tr>
                          <th className="px-2 first:pl-2 last:pr-5 tbl-th whitespace-nowrap text-white font-bold tble-th-txt text-center w-[5%] border-r border-[#ffffff]">
                            Item
                          </th>
                          <th className="px-4 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white font-bold tble-th-txt text-left w-[40%] border-r border-[#ffffff]">
                            CPAS7 Competencies
                          </th>
                          <th className="px-4 first:pl-5 last:pr-5 tbl-th  text-white font-bold tble-th-txt text-left w-[5%] border-r border-[#ffffff] ">
                            Weightage (%)/Marks
                          </th>
                          <th className="px-4 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white font-bold tble-th-txt text-center w-[20%] border-r border-[#ffffff]">
                            Pre-assessment Score
                          </th>
                          <th className="px-4 first:pl-5 last:pr-5 tbl-th text-white font-bold tble-th-txt text-left w-[10%] border-r border-[#ffffff]">
                            Final Score
                          </th>
                          <th className="px-4 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white font-bold tble-th-txt text-left w-[20%] border-r border-[#ffffff]">
                            Assessor&apos;s Remarks
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm custom-tbody">
                        {/* 1 start */}
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 text-center bg-[#E8E8E8] last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] font-bold">
                            1
                          </td>
                          <td className="px-4 first:pl-5 last:pr-5 bg-[#E8E8E8] whitespace-nowrap tble-td-txt text-[#000000]  w-[40%] font-bold">
                            Project planning, scheduling, organisation and execution
                          </td>
                          <td className="px-4 first:pl-5 last:pr-5 bg-[#E8E8E8] whitespace-nowrap tble-td-txt text-[#000000]  w-[5%] font-bold border-r">
                            30%
                          </td>
                          <td colSpan={2} className="px-4 first:pl-5 bg-[#E8E8E8] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000]  w-[30%]   font-bold border-r">
                            <p className='text-center'>Section 1 min passing score 22.5/30</p>
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 bg-[#ffffff] whitespace-nowrap tble-td-txt text-[#000000]  w-[20%]  border-b">
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000]  border-r">
                            1.1
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Identify and set objectives for the project
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={8.2}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%]  border-r">
                          </td>
                          <td rowSpan={4} className="px-4 first:pl-5  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000]   w-[20%] ">
                            <div className='box-style'>
                            </div>
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] border-r">
                            1.2
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Identify and set objectives for the project
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={10.3}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%] border-r">
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] border-r">
                            1.3
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Evaluate project performance against set objectives
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput

                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={5.5}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%]  border-r">
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#E74C3C33] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000]border-r">

                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#E74C3C33] last:pr-5 whitespace-nowrap font-bold tble-td-txt text-[#000000] w-[40%]  border-r">
                            Section 1 Subtotal
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#E74C3C33] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input bg-[#E74C3C33]'>

                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#E74C3C33] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%] border-r">
                            <div className='w-[40%] bg-[#E74C3C33] '>
                              <TextInput
                                value={24.0}
                              />
                            </div>
                          </td>
                          <td className="px-2 first:pl-2 border-b bg-[#E74C3C33] last:pr-2 whitespace-nowrap tble-td-txt text-[#000000] w-[10%]  border-r">
                            <div className='w-[100%] bg-[#E74C3C33] '>
                              <TextInput
                              />
                            </div>
                          </td>
                        </tr>
                        {/* 1 end */}

                        {/* 2 start */}
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 text-center bg-[#E8E8E8] last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] font-bold">
                            2
                          </td>
                          <td className="px-4 first:pl-5 last:pr-5 bg-[#E8E8E8] whitespace-nowrap tble-td-txt text-[#000000]  w-[40%] font-bold">
                            Technical Proficiency and quality management
                          </td>
                          <td className="px-4 first:pl-5 last:pr-5 bg-[#E8E8E8] whitespace-nowrap tble-td-txt text-[#000000]  w-[5%] font-bold border-r">
                            25%
                          </td>
                          <td colSpan={2} className="px-4 first:pl-5 bg-[#E8E8E8] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000]  w-[30%]   font-bold border-r">
                            <p className='text-center'>Section 2  min passing score 19/25</p>
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 bg-[#ffffff]  whitespace-nowrap tble-td-txt text-[#000000]  w-[20%]  border-b border-t">
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000]  border-r">
                            2.1
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Site management, monitoring and execution of project plans
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={13.0}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%]  border-r">
                          </td>
                          <td rowSpan={4} className="px-4 first:pl-5  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000]   w-[20%] ">
                            <div className='box-style'>
                            </div>
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] border-r">
                            2.2
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Understand quality standards and quality assurance
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={13.0}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%] border-r">
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] border-r">
                            2.3
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Knowledge of technology adoption
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput

                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={3.8}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%]  border-r">
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#E74C3C33] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000]border-r">

                          </td>
                          <td className="px-4 first:pl-5 border-b  font-bold bg-[#E74C3C33] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Section 2 Subtotal
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#E74C3C33] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input bg-[#E74C3C33]'>

                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#E74C3C33] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%] border-r">
                            <div className='w-[40%] bg-[#E74C3C33] '>
                              <TextInput
                                value={18.7}
                              />
                            </div>
                          </td>
                          <td className="px-2 first:pl-2 border-b bg-[#E74C3C33] last:pr-2 whitespace-nowrap tble-td-txt text-[#000000] w-[10%]  border-r">
                            <div className='w-[100%] bg-[#E74C3C33] '>
                              <TextInput
                              />
                            </div>
                          </td>
                        </tr>
                        {/* 2 end */}

                        {/* 3 start */}
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 text-center bg-[#E8E8E8] last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] font-bold">
                            3
                          </td>
                          <td className="px-4 first:pl-5 last:pr-5 bg-[#E8E8E8] whitespace-nowrap tble-td-txt text-[#000000]  w-[40%] font-bold">
                            Environmental, health and safety (EHS) management
                          </td>
                          <td className="px-4 first:pl-5 last:pr-5 bg-[#E8E8E8] whitespace-nowrap tble-td-txt text-[#000000]  w-[5%] font-bold border-r">
                            10%
                          </td>
                          <td colSpan={2} className="px-4 first:pl-5 bg-[#E8E8E8] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000]  w-[30%]   font-bold border-r">
                            <p className='text-center'>Section 3  min passing score 7.5/10</p>
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 bg-[#ffffff]  whitespace-nowrap tble-td-txt text-[#000000]  w-[20%]  border-b border-t">
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000]  border-r">
                            3.1
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Knowledge and understanding of EHS practices and regulations
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={6.7}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%]  border-r">
                          </td>
                          <td rowSpan={4} className="px-4 first:pl-5  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000]   w-[20%] ">
                            <div className='box-style'>
                            </div>
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] border-r">
                            3.2
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Managing EHS Risks
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={0.9}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%] border-r">
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] border-r">
                            3.3
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Knowledge of green initiatives and technology
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput

                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={1.5}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%]  border-r">
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#E74C3C33] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000]border-r">

                          </td>
                          <td className="px-4 first:pl-5 border-b font-bold bg-[#E74C3C33] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Section 3 Subtotal
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#E74C3C33] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input bg-[#E74C3C33]'>

                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#E74C3C33] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%] border-r">
                            <div className='w-[40%] bg-[#E74C3C33] '>
                              <TextInput
                                value={9.1}
                              />
                            </div>
                          </td>
                          <td className="px-2 first:pl-2 border-b bg-[#E74C3C33] last:pr-2 whitespace-nowrap tble-td-txt text-[#000000] w-[10%]  border-r">
                            <div className='w-[100%] bg-[#E74C3C33] '>
                              <TextInput
                              />
                            </div>
                          </td>
                        </tr>
                        {/* 3 end */}

                        {/* 4 start */}
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 text-center bg-[#E8E8E8] last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] font-bold">
                            4
                          </td>
                          <td className="px-4 first:pl-5 last:pr-5 bg-[#E8E8E8] whitespace-nowrap tble-td-txt text-[#000000]  w-[40%] font-bold">
                            Problem identification, analysis and resolution
                          </td>
                          <td className="px-4 first:pl-5 last:pr-5 bg-[#E8E8E8] whitespace-nowrap tble-td-txt text-[#000000]  w-[5%] font-bold border-r">
                            10%
                          </td>
                          <td colSpan={2} className="px-4 first:pl-5 bg-[#E8E8E8] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000]  w-[30%]   font-bold border-r">
                            <p className='text-center'>Section 4  min passing score 7.5/10</p>
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 bg-[#ffffff]  whitespace-nowrap tble-td-txt text-[#000000]  w-[20%]  border-b border-t">
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000]  border-r">
                            4.1
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Identify and analyse problems within a construction project
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={5.0}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%]  border-r">
                          </td>
                          <td rowSpan={3} className="px-4 first:pl-5  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000]   w-[20%] ">
                            <div className='box-style'>
                            </div>
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] border-r">
                            4.2
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Design or develop solutions to address problems that occur within a construction project
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={2.5}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%] border-r">
                          </td>
                        </tr>

                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#E74C3C33] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] border-r">
                          </td>
                          <td className="px-4 first:pl-5 border-b font-bold bg-[#E74C3C33] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Section 4 Subtotal
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#E74C3C33] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input bg-[#E74C3C33]'>

                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#E74C3C33] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%] border-r">
                            <div className='w-[40%] bg-[#E74C3C33] '>
                              <TextInput
                                value={9.1}
                              />
                            </div>
                          </td>
                          <td className="px-2 first:pl-2 border-b bg-[#E74C3C33] last:pr-2 whitespace-nowrap tble-td-txt text-[#000000] w-[10%]  border-r">
                            <div className='w-[100%] bg-[#E74C3C33] '>
                              <TextInput
                              />
                            </div>
                          </td>
                        </tr>
                        {/* 4 end */}



                        {/* 5 start */}
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 text-center bg-[#E8E8E8] last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] font-bold">
                            5
                          </td>
                          <td className="px-4 first:pl-5 last:pr-5 bg-[#E8E8E8] whitespace-nowrap tble-td-txt text-[#000000]  w-[40%] font-bold">
                            Contractual, legal and commercial management
                          </td>
                          <td className="px-4 first:pl-5 last:pr-5 bg-[#E8E8E8] whitespace-nowrap tble-td-txt text-[#000000]  w-[5%] font-bold border-r">
                            10%
                          </td>
                          <td colSpan={2} className="px-4 first:pl-5 bg-[#E8E8E8] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000]  w-[30%]   font-bold border-r">
                            <p className='text-center'>Section 5  min passing score 7.5/10</p>
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 bg-[#ffffff]  whitespace-nowrap tble-td-txt text-[#000000]  w-[20%]  border-b border-t">
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000]  border-r">
                            5.1
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Contractual and legal knowledge
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={6.0}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%]  border-r">
                          </td>
                          <td rowSpan={3} className="px-4 first:pl-5  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000]   w-[20%] ">
                            <div className='box-style'>
                            </div>
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] border-r">
                            5.2
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Financial risk management, cost control and procurement practices
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={2.0}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%] border-r">
                          </td>
                        </tr>

                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#E74C3C33] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] border-r">
                          </td>
                          <td className="px-4 first:pl-5 border-b font-bold bg-[#E74C3C33] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Section 5 Subtotal
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#E74C3C33] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input bg-[#E74C3C33]'>

                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#E74C3C33] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%] border-r">
                            <div className='w-[40%] bg-[#E74C3C33] '>
                              <TextInput
                                value={8.1}
                              />
                            </div>
                          </td>
                          <td className="px-2 first:pl-2 border-b bg-[#E74C3C33] last:pr-2 whitespace-nowrap tble-td-txt text-[#000000] w-[10%]  border-r">
                            <div className='w-[100%] bg-[#E74C3C33] '>
                              <TextInput
                              />
                            </div>
                          </td>
                        </tr>
                        {/* 5 end */}




                        {/* 6 start */}
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 text-center bg-[#E8E8E8] last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] font-bold">
                            6
                          </td>
                          <td className="px-4 first:pl-5 last:pr-5 bg-[#E8E8E8] whitespace-nowrap tble-td-txt text-[#000000]  w-[40%] font-bold">
                            People management, interpersonal and leadership skills
                          </td>
                          <td className="px-4 first:pl-5 last:pr-5 bg-[#E8E8E8] whitespace-nowrap tble-td-txt text-[#000000]  w-[5%] font-bold border-r">
                            10%
                          </td>
                          <td colSpan={2} className="px-4 first:pl-5 bg-[#E8E8E8] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000]  w-[30%]   font-bold border-r">
                            <p className='text-center'>Section 5  min passing score 6/8</p>
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 bg-[#ffffff]  whitespace-nowrap tble-td-txt text-[#000000]  w-[20%]  border-b border-t">
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000]  border-r">
                            6.1
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Develop manpower plans to achieve objectives
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={1.0}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%]  border-r">
                          </td>
                          <td rowSpan={6} className="px-4 first:pl-5  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000]   w-[20%] ">
                            <div className='box-style'>
                            </div>
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] border-r">
                            6.2
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Identify recruitment needs
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={2.0}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%] border-r">
                          </td>
                        </tr>

                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] border-r">
                            6.3
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Monitor, evaluate and manage team members under his/her purview
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={1.5}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%] border-r">
                          </td>
                        </tr>


                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] border-r">
                            6.4
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Promote individual performance and develop synergy among team members
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={1.0}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%] border-r">
                          </td>
                        </tr>


                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] border-r">
                            6.5
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5  tble-td-txt text-[#000000] w-[40%]  border-r">
                            Adapt to different situations with different people personalities, capabilities and competencies
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={0.7}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%] border-r">
                          </td>
                        </tr>

                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#E74C3C33] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] border-r">
                          </td>
                          <td className="px-4 first:pl-5 border-b font-bold bg-[#E74C3C33] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Section 6 Subtotal
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#E74C3C33] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input bg-[#E74C3C33]'>

                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#E74C3C33] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%] border-r">
                            <div className='w-[40%] bg-[#E74C3C33] '>
                              <TextInput
                                value={6.1}
                              />
                            </div>
                          </td>
                          <td className="px-2 first:pl-2 border-b bg-[#E74C3C33] last:pr-2 whitespace-nowrap tble-td-txt text-[#000000] w-[10%]  border-r">
                            <div className='w-[100%] bg-[#E74C3C33] '>
                              <TextInput
                              />
                            </div>
                          </td>
                        </tr>
                        {/* 6 end */}

                        {/* 7 start */}
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 text-center bg-[#E8E8E8] last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] font-bold">
                            7
                          </td>
                          <td className="px-4 first:pl-5 last:pr-5 bg-[#E8E8E8] whitespace-nowrap tble-td-txt text-[#000000]  w-[40%] font-bold">
                            Stakeholder engagement and communication
                          </td>
                          <td className="px-4 first:pl-5 last:pr-5 bg-[#E8E8E8] whitespace-nowrap tble-td-txt text-[#000000]  w-[5%] font-bold border-r">
                            10%
                          </td>
                          <td colSpan={2} className="px-4 first:pl-5 bg-[#E8E8E8] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000]  w-[30%]   font-bold border-r">
                            <p className='text-center'>Section 7  min passing score 5.3/7</p>
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 bg-[#ffffff]  whitespace-nowrap tble-td-txt text-[#000000]  w-[20%]  border-b border-t">
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000]  border-r">
                            7.1
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 tble-td-txt text-[#000000] w-[40%]  border-r">
                            <p className='2xl:w-[75%] lg:w-[75%]'>
                              Establish and maintain positive working relationship with various stakeholders such as clients, consultants, senior management, sub-contractor, authorities, general public etc
                            </p>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={5.2}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%]  border-r">
                          </td>
                          <td rowSpan={3} className="px-4 first:pl-5  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000]   w-[20%] ">
                            <div className='box-style'>
                            </div>
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#ffffff] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] border-r">
                            7.2
                          </td>
                          <td className="px-4 first:pl-5 border-b  bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Communicate effectively with stakeholders
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input'>
                              <TextInput
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]  border-r">
                            <div className='w-[40%] '>
                              <TextInput
                                value={1.0}
                              />
                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#ffffff]  last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[10%] border-r">
                          </td>
                        </tr>

                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 border-b  bg-[#E74C3C33] text-center  last:pr-5 whitespace-nowrap tble-td-txt w-[5%] text-[#000000] border-r">
                          </td>
                          <td className="px-4 first:pl-5 border-b font-bold bg-[#E74C3C33] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[40%]  border-r">
                            Section 7 Subtotal
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#E74C3C33] last:pr-5  whitespace-nowrap tble-td-txt text-[#000000] w-[5%]  border-r">
                            <div className='w-[100%] grey-input bg-[#E74C3C33]'>

                            </div>
                          </td>
                          <td className="px-4 first:pl-5 border-b bg-[#E74C3C33] last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%] border-r">
                            <div className='w-[40%] bg-[#E74C3C33] '>
                              <TextInput
                                value={6.1}
                              />
                            </div>
                          </td>
                          <td className="px-2 first:pl-2 border-b bg-[#E74C3C33] last:pr-2 whitespace-nowrap tble-td-txt text-[#000000] w-[10%]  border-r">
                            <div className='w-[100%] bg-[#E74C3C33] '>
                              <TextInput
                              />
                            </div>
                          </td>
                        </tr>
                        {/* 7 end */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gap-6 w-full mt-[30px]">
              <div className='2xl:col-span-12 lg:col-span-12 sm:col-span-12 col-span-12 mb-2'>
                <TextInput
                  name="myInput"
                // value={this.state.inputValue}
                // handleChange={this.handleInputChange}
                // onBlur={this.handleInputBlur}
                // disabled={false}
                // autoComplete="off"
                />
              </div>
              <div className='2xl:col-span-12 lg:col-span-12 sm:col-span-12 col-span-12 mb-3'>
                <div className='relative'>
                  <div className='absolute top-0'>
                    <span className='font-bold'>
                      1.
                    </span>
                  </div>
                  <div className='w-[98%] ml-auto'>
                    <TextInput
                      name="myInput"
                    // value={this.state.inputValue}
                    // handleChange={this.handleInputChange}
                    // onBlur={this.handleInputBlur}
                    // disabled={false}
                    // autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className='2xl:col-span-12 lg:col-span-12 sm:col-span-12 col-span-12 mb-3'>
                <div className='relative'>
                  <div className='absolute top-0'>
                    <span className='font-bold'>
                      2.
                    </span>
                  </div>
                  <div className='w-[98%] ml-auto'>
                    <TextInput
                      name="myInput"
                    // value={this.state.inputValue}
                    // handleChange={this.handleInputChange}
                    // onBlur={this.handleInputBlur}
                    // disabled={false}
                    // autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className='2xl:col-span-12 lg:col-span-12 sm:col-span-12 col-span-12 mb-3'>
                <div className='relative'>
                  <div className='absolute top-0'>
                    <span className='font-bold'>
                      3.
                    </span>
                  </div>
                  <div className='w-[98%] ml-auto'>
                    <TextInput
                      name="myInput"
                    // value={this.state.inputValue}
                    // handleChange={this.handleInputChange}
                    // onBlur={this.handleInputBlur}
                    // disabled={false}
                    // autoComplete="off"
                    />
                  </div>
                </div>
              </div>

              <div className='2xl:col-span-6 lg:col-span-6 sm:col-span-12 col-span-12 mb-3'>
                <div className=''>
                  <div className='w-[96%] ml-auto'>
                    <TextInput
                      name="myInput"
                    // value={this.state.inputValue}
                    // handleChange={this.handleInputChange}
                    // onBlur={this.handleInputBlur}
                    // disabled={false}
                    // autoComplete="off"
                    />
                  </div>
                </div>
              </div>

              <div className='2xl:col-span-6 lg:col-span-6 sm:col-span-12 col-span-12 mb-3'>
                <div className=''>
                  <div className='w-[98%] ml-auto'>
                    <DatePicker />
                  </div>
                </div>
              </div>

              <div className='2xl:col-span-6 lg:col-span-6 sm:col-span-12 col-span-12 mb-3'>
                <div className='upload-sign '>
                  <div className='w-[96%] ml-auto'>
                    <h2 className="text-xl text-[#000000] font-bold mb-2">
                      Upload Siganture <span className='text-[#E74C3C]'>*</span>
                    </h2>
                    <ImageCropperWithUpload
                      keyId="unique_key"
                      height={300}
                      width={400}
                      isCheckValidation={true}
                      MaxFileSize={2000000} // 2MB in bytes
                      MaxFileSizeMessage="2 MB"
                      imageIdentity="example_identity"
                      intitImage="initial_image.jpg"
                      uploadBtn="Upload Image"
                      isCropperSizeFixed={false}
                      // dimensionText="Maximum image size is 400x300"
                      onSaveImage={this.handleSaveImage}
                    />
                    {croppedImage && (
                      <div className='mt-5'>
                        <img src={croppedImage} alt="Cropped" />
                      </div>
                    )}
                  </div>
                </div>

              </div>
            </div>

            <div className="grid grid-cols-12 gap-6 w-full mt-[30px]">
              <div className='2xl:col-span-12 lg:col-span-12 sm:col-span-12 col-span-12 mb-2'>
                <div className="flex items-center justify-between">
                  <a className="btn btn-blue-border text-xl text-white" href="/Dashboard">
                    Save as Draft
                  </a>
                  <button className="text-lg font-bold btn btn-blue text-white">Submit</button>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}
