
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { MultiSelectDropdown, DateInput, NumberInput, CheckboxInput, RadioButton, TextInput } from '../../allTypesInputComponents/AllTypeInputComponents';
import ValidationText from '../../../utils/validation/ValidationText';
import DatePicker from '../../datePicker/DatePicker';
import ImageCropperWithUpload from '../../ImageUpload/UploadImage';
import SectionWithBorder from "../../../components/UIComponent/SectionBorder";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";
import ImageViewer from '../studentMembers/ImageViewer';

export default function StudentApplication(props) {
    const [imageViewer, setImageViewer] = useState(false);
    // useEffect(()=>{
    //     console.log({props});

    // },[props])
    return (
        <>

            <div className='container mx-auto pb-10'>

                <div className="2xl:px-10 2xl:pt-10 lg:px-7 lg:pt-7 pt-5 ">
                    <div className='grid grid-cols-12 gap-4'>
                        <div className='col-span-12'>
                            <div className='md:mt-0 mt-0 bg-gray-box '>
                                <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-0 mb-3">
                                    <div className="col-span-12">
                                        <div className='form-info-title'>
                                            <h4 className='text-[#2d2d2d] form-info-title-txt'>
                                                Personal Particular
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-3 mb-3">
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            First Name
                                            <span className="text-[#C00000]"> *</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                name="firstName"
                                                value={props.studentSignUpData.firstName}
                                                identity="Student_SignUp"
                                                handleChange={(e) => props.handleChange(e, "StudentForm")}
                                            //onBlur={() => props.validateField("firstName")}
                                            />
                                            <ValidationText error={props.validState.error.firstName} />
                                        </div>

                                    </div>
                                    <div className="md:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Last Name
                                            <span className="text-[#C00000]">*</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                name="lastName"
                                                value={props.studentSignUpData.lastName}
                                                identity="Student_SignUp"
                                                handleChange={(e) => props.handleChange(e, "StudentForm")}
                                            //onBlur={() => props.validateField("lastName")}
                                            />
                                            <ValidationText error={props.validState.error.lastName} />
                                        </div>
                                    </div>
                                    <div className="md:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Mailing Address Line 1
                                            <span className="text-[#C00000]">*</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                name="mailingAddressLine1"
                                                value={props.studentSignUpData.mailingAddressLine1}
                                                identity="Student_SignUp"
                                                handleChange={(e) => props.handleChange(e, "StudentForm")}
                                            //onBlur={() => props.validateField("mailingAddressLine1")}
                                            />

                                            <ValidationText error={props.validState.error.mailingAddressLine1} />
                                        </div>
                                    </div>
                                    <div className="md:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Mailing Address Line 2

                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                name="mailingAddressLine2"
                                                value={props.studentSignUpData.mailingAddressLine2}
                                                identity="Student_SignUp"
                                                handleChange={(e) => props.handleChange(e, "StudentForm")}
                                            />
                                        </div>
                                    </div>
                                    <div className="md:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Mailing Address Line 3

                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                placeholder=""
                                                type="text"
                                                name="mailingAddressLine3"
                                                value={props.studentSignUpData.mailingAddressLine3}
                                                identity="Student_SignUp"
                                                handleChange={(e) => props.handleChange(e, "StudentForm")}
                                            />
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Country/Region
                                            <span className="text-[#C00000]">*</span>
                                        </h2>
                                        <div className="dropdown">
                                            <div className="select-dropdown w-full">
                                                <MultiSelectDropdown
                                                    drpIdentity="Selected_Country"
                                                    options={props.countryList}
                                                    // isMulti
                                                    closeMenuOnSelect={true}
                                                    hideSelectedOptions={false}
                                                    onChange={props.handleChangeInDropdown}
                                                    allowSelectAll={false}
                                                    value={props.countryList.filter(country => country.value === props.studentSignUpData.countryId)}
                                                />
                                                <ValidationText error={props.validState.error.countryId} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Postal Code
                                            <span className="text-[#C00000]">*</span>
                                        </h2>
                                        <div className="dropdown">
                                            <div className="select-dropdown w-full">
                                                <NumberInput
                                                    name="postalCode"
                                                    value={props.studentSignUpData.postalCode}
                                                    handleChange={(e) => props.handleChange(e, "StudentForm")}
                                                // onBlur={() => props.validateField("postalCode")}
                                                />
                                                <ValidationText error={props.validState.error.postalCode} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Contact Number
                                            <span className="text-[#C00000]">*</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                name="contactNumber"
                                                value={props.studentSignUpData.contactNumber}
                                                identity="Student_SignUp"
                                                handleChange={(e) => props.handleChange(e, "StudentForm")}
                                            // onBlur={() => props.validateField("contactNumber")}
                                            />
                                            <ValidationText error={props.validState.error.contactNumber} />
                                        </div>
                                    </div>
                                    <div className="md:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Email Address
                                            <span className="text-[#C00000]">*</span>
                                        </h2>
                                        <div className="dropdown">
                                            <TextInput
                                                name="email"
                                                value={props.studentSignUpData.email}
                                                identity="Student_SignUp"
                                                handleChange={(e) => props.handleChange(e, "StudentForm")}
                                            // onBlur={() => props.validateField("email")}
                                            />
                                            <ValidationText error={props.validState.error.email} />
                                        </div>
                                        <em>
                                            <h2 className="input-title mb-3">
                                                Please provide your personal email address preferably instead of your school email address.
                                            </h2>
                                        </em>
                                    </div>

                                    <div className="md:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Nationality
                                            <span className="text-[#C00000]">*</span>
                                        </h2>
                                        <div className="dropdown">
                                            <div className="select-dropdown w-full">
                                                <MultiSelectDropdown
                                                    drpIdentity="Nationality"
                                                    options={props.nationalityList}
                                                    // isMulti
                                                    closeMenuOnSelect={true}
                                                    hideSelectedOptions={false}
                                                    onChange={props.handleChangeInDropdown}
                                                    allowSelectAll={false}
                                                    value={props.nationalityList.filter(nationality => nationality.value === props.studentSignUpData.nationalityTypeId)}
                                                />
                                                <ValidationText error={props.validState.error.nationalityTypeId} />
                                            </div>
                                        </div>
                                    </div>



                                </div>
                                <div className="grid grid-cols-12 gap-4 w-full mt-2 mb-4">
                                    <div className='md:col-span-6 col-span-12 input-sec-texts'>
                                        <div className="col-span-12 input-sec-texts mt-0">
                                            <h2 className="input-title mb-3">
                                                Upload a Scanned Image of your Student ID Card
                                                <span className="text-[#C00000]">*</span>
                                            </h2>

                                            <div className="flex item-center flex-col">
                                                {
                                                    props && !props.studentSignUpData.uploadStudentIDCard ?
                                                        (
                                                            <form className="custom-uploader custom-file-upload-btn flex cursor-pointer">
                                                                <ImageCropperWithUpload
                                                                    key="Main"
                                                                    height={10}
                                                                    width={10}
                                                                    isCheckValidation={true}
                                                                    MaxFileSize={2097152}
                                                                    MaxFileSizeMessage={"2MB"}
                                                                    onSaveImage={props.saveMainImage}
                                                                    imageIdentity="StudentMembers"
                                                                    intitImage={props.studentSignUpData.uploadStudentIDCard}
                                                                    aspactRatio={550 / 550}
                                                                    uploadBtn={"Upload Photo"}
                                                                    className="border btn btn-skyblue"
                                                                />
                                                            </form>
                                                        )
                                                        :
                                                        (
                                                            <button className="2xl:px-2 lg:px-5 py-2 btn btn-blue-border text-xl border"

                                                                onClick={() => setImageViewer(true)}
                                                            >
                                                                <span className="text-xl font-semibold tracking-normal underline">
                                                                    View Photo/Passport Photo
                                                                </span>
                                                            </button>
                                                        )
                                                }

                                                {props && !props.studentSignUpData.uploadStudentIDCard ?
                                                    <ValidationText error={props.validState.error.uploadStudentIDCard} />
                                                    : null}
                                                {
                                                    props && props.studentSignUpData.uploadStudentIDCard ?
                                                        (
                                                            <div className='mt-5 file-uploaded-txt flex items-center'>
                                                                <img src='/check-skyblue.png' className='w-[25px] mr-3' alt=''></img>
                                                                <span className='text-[#000]'>
                                                                    {props.studentSignUpData.uploadStudentIDCard}
                                                                </span>
                                                                <button className='underline text-[#e74c3c] font-[500] ml-3'
                                                                    onClick={() => props.deleteFile()}
                                                                >
                                                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                                                </button>
                                                            </div>
                                                        )
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        {/* <div className="col-span-12 mt-6 mb-6">
                                            <div className='flex justify-start'>
                                                <button className='btn btn-skyblue-border'>
                                                    <span className='fa fa-plus'></span>
                                                    <span className='ml-2'>
                                                        Add Description
                                                    </span>
                                                </button>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="col-span-12 mt-2">
                                        <h2 className="input-title mb-2 ">
                                            In 100 words, Tell us more about yourself
                                            <span className="text-[#C00000]">*</span>
                                        </h2>
                                        <div className="dropdown">
                                            <textarea
                                                name="tellUsMoreAboutYourSelf"
                                                rows="7"
                                                className='w-full custom-textarea'
                                                value={props.studentSignUpData.tellUsMoreAboutYourSelf}
                                                onChange={(e) => props.handleChange(e)}
                                            // onBlur={() => props.validateField("tellUsMoreAboutYourSelf")}
                                            //disabled={props.wordCount.tellUsMoreAboutYourSelf > 100 ? true : false}
                                            />
                                            <ValidationText error={props.validState.error.tellUsMoreAboutYourSelf} />
                                        </div>
                                        {
                                            props.studentSignUpData.tellUsMoreAboutYourSelfwordCount && (props.characterLimit - props.studentSignUpData.tellUsMoreAboutYourSelfwordCount) <= 0 ?
                                                (
                                                    <div className="text-white bg-[#717171] w-[300px] rounded text-center mb-1">
                                                        You reach the character enter limit
                                                    </div>
                                                )
                                                :
                                                props.studentSignUpData.tellUsMoreAboutYourSelfwordCount && props.characterLimit - props.studentSignUpData.tellUsMoreAboutYourSelfwordCount <= 100 ?
                                                    (
                                                        <div className={`mb-1 rounded text-[#ffffff] w-[80px] text-center mt-2 ${props.characterLimit - props.studentSignUpData.tellUsMoreAboutYourSelfwordCount < 50 ? "bg-[#C00000]" : "bg-blue-500"} `}>
                                                            {props.characterLimit - props.studentSignUpData.tellUsMoreAboutYourSelfwordCount}/{props.characterLimit}
                                                        </div>
                                                    )
                                                    :
                                                    null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container mx-auto pb-0'>
                    <div className="2xl:px-10 2xl:pt-10 lg:px-7 lg:pt-7 pt-5">
                        <div className='grid grid-cols-12 gap-4'>
                            <div className='col-span-12'>
                                <div className='md:mt-0 mt-0 bg-gray-box '>
                                    <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-0 mb-3">
                                        <div className="col-span-12">
                                            <div className='form-info-title'>
                                                <h4 className='text-[#2d2d2d] form-info-title-txt text-[14px]'>
                                                    Education Information
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-3 mb-3">
                                        <div className="md:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="input-title mb-3">
                                                Name of Current Institution
                                                <span className="text-[#C00000]">*</span>
                                            </h2>
                                            <div className="dropdown">
                                                <div className="select-dropdown w-full">
                                                    <MultiSelectDropdown
                                                        drpIdentity="NameOfInstituitons"
                                                        options={props.nameOfInstitutionList}
                                                        closeMenuOnSelect={true}
                                                        hideSelectedOptions={false}
                                                        onChange={props.handleChangeInDropdown}
                                                        allowSelectAll={false}
                                                        // value={props.nameOfInstitutionList.filter(
                                                        //     (nameOfInstitution) =>
                                                        //         nameOfInstitution.value ===
                                                        //         props.studentSignUpData.nameOfCurrentInstitution
                                                        // )}
                                                        value={props.nameOfInstitutionList.filter(nameOfInstitution => nameOfInstitution.value === props.studentSignUpData.nameOfCurrentInstitution)}

                                                    />
                                                    <ValidationText
                                                        error={props.validState.error.nameOfCurrentInstitution}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        {
                                            props.studentSignUpData.nameOfCurrentInstitution === 13 ? (
                                                <div className="md:col-span-6 col-span-12 input-sec-texts">
                                                    <h2 className="input-title mb-3">
                                                        Name of Current Institution (Others)
                                                        <span className="text-[#C00000]">*</span>
                                                    </h2>
                                                    <div className="dropdown">
                                                        <TextInput
                                                            name="otherInstitution"
                                                            value={props.studentSignUpData.otherInstitution}
                                                            identity="Student_SignUp"
                                                            handleChange={(e) => props.handleChange(e, "otherInstitution")}
                                                        // onBlur={() => props.validateField("OtherInstitution")}
                                                        />
                                                        {/* <ValidationText
                                                        error={props.validState.error.OtherInstitution}
                                                    /> */}
                                                    </div>
                                                    <em>
                                                        <h2 className="input-title mb-3">
                                                            Please provide your Institution name here.
                                                        </h2>
                                                    </em>
                                                </div>
                                            ) : null
                                        }


                                        <div className="md:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="input-title mb-3">
                                                Course Title
                                                <span className="text-[#C00000]">*</span>
                                            </h2>
                                            <div className="dropdown">
                                                <TextInput
                                                    name="courseTitle"
                                                    value={props.studentSignUpData.courseTitle}
                                                    identity="Student_SignUp"
                                                    handleChange={(e) => props.handleChange(e, "StudentForm")}
                                                // onBlur={() => props.validateField("courseTitle")}
                                                />
                                                <ValidationText error={props.validState.error.courseTitle} />
                                            </div>
                                        </div>
                                        <div className="md:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="input-title mb-3">
                                                Currently in year
                                                <span className="text-[#C00000]">*</span>
                                            </h2>
                                            <div className="dropdown">
                                                <div className="select-dropdown w-full">
                                                    <MultiSelectDropdown
                                                        drpIdentity="Currently_InYear"
                                                        options={props.yearList}
                                                        // isMulti
                                                        closeMenuOnSelect={true}
                                                        hideSelectedOptions={false}
                                                        onChange={props.handleChangeInDropdown}
                                                        allowSelectAll={false}
                                                        value={props.yearList.filter(InYear => InYear.value === props.studentSignUpData.currentlyInYear)}
                                                    />
                                                    <ValidationText error={props.validState.error.currentlyInYear} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="md:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="input-title mb-3">
                                                Expected Date of Graduation
                                                <span className="text-[#C00000]">*</span>
                                            </h2>
                                            <div className="dropdown">
                                                <DateInput
                                                    name="expectedGraduationDate"

                                                    value={moment(props.studentSignUpData.expectedGraduationDate).format("YYYY-MM-DD")}
                                                    identity="Student_SignUp"
                                                    handleChange={(e) => props.handleChange(e, "StudentForm")}
                                                //onBlur={() => props.validateField("expectedGraduationDate")}
                                                />
                                                <ValidationText error={props.validState.error.expectedGraduationDate} />
                                            </div>
                                        </div>

                                        <div className="md:col-span-6 col-span-12 input-sec-texts">
                                            <h2 className="input-title mb-3">
                                                Type of Current/Latest Qualification
                                                <span className="text-[#C00000]">*</span>
                                            </h2>
                                            <div className="dropdown">
                                                <div className="select-dropdown w-full">
                                                    <MultiSelectDropdown
                                                        drpIdentity="CurrentQualificationName"
                                                        options={props.qualificationList}
                                                        // isMulti
                                                        closeMenuOnSelect={true}
                                                        hideSelectedOptions={false}
                                                        onChange={props.handleChangeInDropdown}
                                                        allowSelectAll={false}
                                                        value={props.qualificationList.filter(CurrentQualification => CurrentQualification.value === props.studentSignUpData.typeOfCurrentOrLatestQualification)}
                                                    />

                                                    <ValidationText error={props.validState.error.typeOfCurrentOrLatestQualification} />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container mx-auto'>
                    <div className='grid grid-cols-12 gap-4'>
                        <div className='col-span-12'>
                            <div className='bg-gray-box md:py-10 py-3 md:px-12 px-3 '>

                                <div className="grid grid-cols-12 gap-4 w-full justify-between  mb-3">
                                    <div className="md:col-span-6 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            How do you hear about us?
                                            <span className="text-[#C00000]">*</span>
                                        </h2>
                                        <div className="dropdown">
                                            <div className="select-dropdown w-full">
                                                <MultiSelectDropdown
                                                    drpIdentity="Know_About_Us"
                                                    options={props.knowAboutUsList}
                                                    // isMulti
                                                    closeMenuOnSelect={true}
                                                    hideSelectedOptions={false}
                                                    onChange={props.handleChangeInDropdown}
                                                    allowSelectAll={false}
                                                    value={props.knowAboutUsList.filter(konwAboutUs => konwAboutUs.value === props.studentSignUpData.howDoYouHearAboutUs)}
                                                />

                                                <ValidationText error={props.validState.error.howDoYouHearAboutUs} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-12 gap-4 w-full justify-between  mb-3">
                                    <div className="md:col-span-12 col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            Which activities would you like to see organised by our association to better support your needs? You may select more than one.
                                            <span className="text-[#C00000]">*</span>
                                        </h2>
                                        <div className='flex gap-5 mb-3'>
                                            <div className="register-desc skyblue">
                                                <CheckboxInput
                                                    id="isRequiredIndustry"
                                                    name="isRequiredIndustry"
                                                    value={props.studentSignUpData.isRequiredIndustry}
                                                    checked={props.studentSignUpData.isRequiredIndustry}
                                                    handleChange={(e) => props.handleChangeInCheckbox(e, "StudentForm")}
                                                />
                                            </div>
                                            <div className="">
                                                <p className="check-detail">
                                                    Industry talks by construction professionals
                                                </p>
                                            </div>
                                        </div>
                                        <div className='flex gap-5 mb-3'>
                                            <div className="register-desc skyblue">
                                                <CheckboxInput
                                                    id="isRequiredCareer"
                                                    name="isRequiredCareer"
                                                    value={props.studentSignUpData.isRequiredCareer}
                                                    checked={props.studentSignUpData.isRequiredCareer}
                                                    handleChange={(e) => props.handleChangeInCheckbox(e, "StudentForm")}
                                                />
                                            </div>
                                            <div className="">
                                                <p className="check-detail">
                                                    Career fair
                                                </p>
                                            </div>
                                        </div>
                                        <div className='flex gap-5 mb-3'>
                                            <div className="register-desc skyblue">
                                                <CheckboxInput
                                                    id="isRequiredStudent"
                                                    name="isRequiredStudent"
                                                    value={props.studentSignUpData.isRequiredStudent}
                                                    checked={props.studentSignUpData.isRequiredStudent}
                                                    handleChange={(e) => props.handleChangeInCheckbox(e, "StudentForm")}
                                                />
                                            </div>
                                            <div className="">
                                                <p className="check-detail">
                                                    Student competition
                                                </p>
                                            </div>
                                        </div>
                                        <div className='flex gap-5 mb-3'>
                                            <div className="register-desc skyblue">
                                                <CheckboxInput
                                                    id="isRequiredJourneys"
                                                    name="isRequiredJourneys"
                                                    value={props.studentSignUpData.isRequiredJourneys}
                                                    checked={props.studentSignUpData.isRequiredJourneys}
                                                    handleChange={(e) => props.handleChangeInCheckbox(e, "StudentForm")}
                                                />
                                            </div>
                                            <div className="">
                                                <p className="check-detail">
                                                    Learning journeys such as site visits
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="grid grid-cols-12 gap-4 w-full justify-between  mb-3">
                                    <div className="md:col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            What other activities you would like to be involved in?

                                        </h2>
                                        <div className="dropdown">
                                            <textarea
                                                name="otherActivitiesYouWouldLikeToBeInvolved"
                                                rows="7"
                                                className='w-full custom-textarea'
                                                value={props.studentSignUpData.otherActivitiesYouWouldLikeToBeInvolved}
                                                onChange={(e) => props.handleChange(e, "otherActivitiesYouWouldLikeToBeInvolved")}

                                            />

                                        </div>
                                        {
                                            props.studentSignUpData.otherActivitiesYouWouldLikeToBeInvolvedwordCount && (props.characterLimit - props.studentSignUpData.otherActivitiesYouWouldLikeToBeInvolvedwordCount) <= 0 ?
                                                (
                                                    <div className="text-white bg-[#717171] w-[300px] rounded text-center mb-1">
                                                        You reach the character enter limit
                                                    </div>
                                                )
                                                :
                                                props.studentSignUpData.otherActivitiesYouWouldLikeToBeInvolvedwordCount >= 0 && props.characterLimit - props.studentSignUpData.otherActivitiesYouWouldLikeToBeInvolvedwordCount <= 100 ?
                                                    (
                                                        <div className={`mb-1 rounded text-[#ffffff] w-[80px] text-center mt-2 ${props.characterLimit - props.studentSignUpData.otherActivitiesYouWouldLikeToBeInvolvedwordCount < 50 ? "bg-[#C00000]" : "bg-blue-500"} `}>
                                                            {props.characterLimit - props.studentSignUpData.otherActivitiesYouWouldLikeToBeInvolvedwordCount}/{props.characterLimit}
                                                        </div>
                                                    )
                                                    :
                                                    null
                                        }
                                    </div>
                                </div>
                                {props && props.studentSignUpData.isRequiredIndustry ?

                                    <div className="grid grid-cols-12 gap-4 w-full justify-between  mb-3">
                                        <div className="md:col-span-12 input-sec-texts">
                                            <h2 className="input-title mb-3">
                                                Any specific topics that you think would be beneficial?

                                            </h2>
                                            <div className="dropdown">
                                                <textarea
                                                    name="specifictopicswouldbebeneficial"
                                                    rows="7"
                                                    className='w-full custom-textarea'
                                                    value={props.studentSignUpData.specifictopicswouldbebeneficial}
                                                    onChange={(e) => props.handleChange(e, "Specifictopicswouldbebeneficial")}

                                                />

                                            </div>
                                            {
                                                props.studentSignUpData.specifictopicswouldbebeneficialwordCount && (props.characterLimit - props.studentSignUpData.specifictopicswouldbebeneficialwordCount) <= 0 ?
                                                    (
                                                        <div className="text-white bg-[#717171] w-[300px] rounded text-center mb-1">
                                                            You reach the character enter limit
                                                        </div>
                                                    )
                                                    :
                                                    props.studentSignUpData.specifictopicswouldbebeneficialwordCount >= 0 && props.characterLimit - props.studentSignUpData.specifictopicswouldbebeneficialwordCount <= 100 ?
                                                        (
                                                            <div className={`mb-1 rounded text-[#ffffff] w-[80px] text-center mt-2 ${props.characterLimit - props.studentSignUpData.specifictopicswouldbebeneficialwordCount < 50 ? "bg-[#C00000]" : "bg-blue-500"} `}>
                                                                {props.characterLimit - props.studentSignUpData.specifictopicswouldbebeneficialwordCount}/{props.characterLimit}
                                                            </div>
                                                        )
                                                        :
                                                        null
                                            }
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                <div className="grid grid-cols-12 gap-4 w-full justify-between  mb-3">
                                    <div className="md:col-span-12 input-sec-texts">
                                        <h2 className="input-title mb-3">
                                            What additional information or resources do you think from your school and/or association/regulatory bodies would help you make a more informed decision about pursuing a career in the construction industry?

                                        </h2>
                                        <div className="dropdown">
                                            <textarea
                                                name="additionalInformationOrResources"
                                                rows="7"
                                                className='w-full custom-textarea'
                                                value={props.studentSignUpData.additionalInformationOrResources}
                                                onChange={(e) => props.handleChange(e, "additionalInformationOrResources")}

                                            />
                                        </div>
                                        {
                                            props.studentSignUpData.additionalInformationOrResourceswordCount && (props.characterLimit - props.studentSignUpData.additionalInformationOrResourceswordCount) <= 0 ?
                                                (
                                                    <div className="text-white bg-[#717171] w-[300px] rounded text-center mb-1">
                                                        You reach the character enter limit
                                                    </div>
                                                )
                                                :
                                                props.studentSignUpData.additionalInformationOrResourceswordCount >= 0 && props.characterLimit - props.studentSignUpData.additionalInformationOrResourceswordCount <= 100 ?
                                                    (
                                                        <div className={`mb-1 rounded text-[#ffffff] w-[80px] text-center mt-2 ${props.characterLimit - props.studentSignUpData.additionalInformationOrResourceswordCount < 50 ? "bg-[#C00000]" : "bg-blue-500"} `}>
                                                            {props.characterLimit - props.studentSignUpData.additionalInformationOrResourceswordCount}/{props.characterLimit}
                                                        </div>
                                                    )
                                                    :
                                                    null
                                        }
                                    </div>
                                </div>
                                <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                    <a
                                        className="flex items-center justify-end"

                                    >
                                        <button className={`w-32 px-7 py-3 btn-sm btn-blue text-xl text-white font-semibold ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                                            onClick={() => props.editStudentMemberDetails()}
                                        >
                                            Update
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ImageViewer
                    ImageViewerModal={imageViewer}
                    StudentIdImage={props.studentSignUpData}
                    setOpenModal={() => setImageViewer(false)}
                    onClose={(e) => {
                        e.stopPropagation();
                        setImageViewer(false);
                    }}
                />
            </div>




        </>

    )
}