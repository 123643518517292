import React, { useState } from "react";
import ModalBasic from "../../component/FormModal";
import SectionWithBorder from "../../../components/membership/membershipTaskList/SectionWithBorder";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import moment from "moment";
import ValidationText from "../../../utils/validation/ValidationText";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";
import RadioButton from "../../../components/membership/membershipTaskList/RadioButton";
import QuickLinkDetailModal from "./QuickLinkDetailModal";
import { encryptAES } from "../../../utils/Encryption";


const MembershipTaskListProcess = (props) => {

  const [isQuickLinkDetail, setQuickLinkDetail] = useState(false);

  // let  handleChangeInPriceTable = (value, name, year, tableName) => {
  //   props.handleChangeInPriceTable(value, name, year, tableName);
  // };

  // const handleChangeCheckBox = (e) => {
  //   const { checked } = e.target;
  //   if (checked) {
  //     props.taskDetails.quotationNeeded = true;
  //     setIsQuotationRequired(true);
  //   } else {
  //     props.taskDetails.quotationNeeded = false;
  //     setIsQuotationRequired(false);
  //   }
  // };
  // const [ setIsQuotationRequired] = useState(false);

  return (
    <>
      <ModalBasic
        id="add-edit-modal"
        className="membership-add-edit-modal"
        modalOpen={props.setAddeditModalOpen}
        setModalOpen={props.setModalOpen}
        title="Membership Task List Process"
      >
        {/* Modal content */}
        <div className="2xl:px-14 lg:px-3 pt-4">
          <div className="">
            <SectionWithBorder
              title="Organisation Basic Information"
              quickLink="Organisation Detail"
              //onQuickLink={() => {setQuickLinkDetail(true);props.GetOrganisationBasicInformationById();props.GetCompanyRegisteredWithBCAList()}}
              location={`/CreateCorporateMembers/CorporateMemberId?=${encryptAES(props.taskDetails.corporateMemberId > 0 ? props.taskDetails.corporateMemberId : 0)}`} //`/CreateCorporateMembers/CorporateMemberId?=${encryptAES(props.id > 0 ? props.id : 0)}`
            >
              <div className="grid grid-cols-12 gap-6 w-full items-center pt-7 px-7 pb-7">
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        for="default"
                      >
                        Company Name <span className="text-[#c00000]">*</span>
                      </label>
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#000000]"
                        type="text"
                        name="companyName"
                        value={props.taskDetails.companyName}
                        onChange={(event) => props.handleChange(event)}
                        onBlur={() => props.validateField("companyName")}
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.companyName}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        for="default"
                      >
                        Company Name (In Chinese)
                        {/* <span className="text-[#c00000]">*</span> */}
                      </label>
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#000000]"
                        type="text"
                        name="chineseCompanyName"
                        value={props.taskDetails.chineseCompanyName}
                        onChange={(event) => props.handleChange(event)}
                      // onBlur={() => props.validateField("chineseCompanyName")}
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.chineseCompanyName}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        for="default"
                      >
                        Company UEN <span className="text-[#c00000]">*</span>
                      </label>
                      <input
                        id="default"
                        className="cursor-not-allowed form-input rounded-none w-full shadow-red py-3 text-lg text-[#000000] bg-[#e9e9ea]"
                        type="text"
                        name="companyUEN"
                        value={props.taskDetails.companyUEN}
                        onChange={(event) => props.handleChange(event)}
                        onBlur={() => props.validateField("companyUEN")}
                        disabled={true}
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.companyUEN}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        for="default"
                      >
                        Incorporation Date{" "}
                        <span className="text-[#c00000]">*</span>
                      </label>
                      <input
                        id="default"
                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                        type="date"
                        name="incorporationDate"
                        max={moment(new Date()).format("yyyy-MM-DD")}
                        value={moment(
                          props.taskDetails.incorporationDate
                        ).format("yyyy-MM-DD")}
                        onChange={(event) => props.handleChange(event)}
                        onBlur={() => props.validateField("incorporationDate")}
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.incorporationDate}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        for="default"
                      >
                        Representative First Name{" "}
                        <span className="text-[#c00000]">*</span>
                      </label>
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#000000]"
                        type="text"
                        name="firstName"
                        value={props.taskDetails.firstName}
                        onChange={(event) => props.handleChange(event)}
                        onBlur={() => props.validateField("firstName")}
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.firstName}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        for="default"
                      >
                        Representative Last Name{" "}
                        <span className="text-[#c00000]">*</span>
                      </label>
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#000000]"
                        type="text"
                        name="lastName"
                        value={props.taskDetails.lastName}
                        onChange={(event) => props.handleChange(event)}
                        onBlur={() => props.validateField("lastName")}
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.lastName}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        for="default"
                      >
                        Designation <span className="text-[#c00000]">*</span>
                      </label>                      
                      <Selector
                        drpIdentity="Designation_TaskList"
                        options={props.designationList}
                        isMulti
                        //   options={props.countriesWithPresence}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        // components={{ Option }}
                        onChange={props.handleChangeMultiDropdown}
                        allowSelectAll={true}
                        value={
                          props.taskDetails.selectedDesignation
                        }
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.selectedDesignation}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        for="default"
                      >
                        Business Email <span className="text-[#c00000]">*</span>
                      </label>
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#000000]"
                        type="text"
                        name="email"
                        value={props.taskDetails.businessEmail}
                        onChange={(event) => props.handleChange(event)}
                        onBlur={() => props.validateField("email")}
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.email}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        for="default"
                      >
                        Business Contact Number{" "}
                        <span className="text-[#c00000]">*</span>
                      </label>
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#000000]"
                        type="text"
                        name="contactNo"
                        value={props.taskDetails.contactNo}
                        onChange={(event) => props.handleChange(event)}
                        onBlur={() => props.validateField("contactNo")}
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.contactNo}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SectionWithBorder>

            <SectionWithBorder title="Corporate Membership Subscription">
              <div className="px-7">
                <div className="grid grid-cols-12 gap-6 w-full items-center py-7">
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          for="default"
                        >
                          {" "}
                          Billing Category{" "}
                          <span className="text-[#c00000]">*</span>{" "}
                        </label>
                        <DropdownSelect
                          drpIdentity={"billingCategories"}
                          optionArray={props.billingCategories}
                          setFilterParameters={props.setFilterParameters}
                          value={props.taskDetails.billingCategoryId}
                          disabled={props.disableSubscription}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          for="default"
                        >
                          Updated By<span className="text-[#c00000]">*</span>{" "}
                        </label>
                        <DropdownSelect
                          drpIdentity={"updatedBy"}
                          optionArray={props.users}
                          setFilterParameters={props.setFilterParameters}
                          value={props.taskDetails.updatedBy}
                          disabled={props.disableSubscription}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30 "
                          for="default"
                        >
                          {" "}
                          Subscription Start Date{" "}
                          <span className="text-[#c00000]">*</span>{" "}
                        </label>
                        <div className="grid grid-cols-12 gap-6 items-center w-full">
                          <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full disabled-input">
                            <input
                              id="default"
                              name="subscriptionStartDate"
                              className={`date-pickers form-input rounded-none w-full ${props.disableSubscription
                                ? "bg-[#C4C4C4]"
                                : null
                                } shadow-red py-3 text-lg opacity-50 cursor-not-allowed`}
                              type="date"
                              value={moment(
                                props.taskDetails.subscriptionStartDate
                              ).format("yyyy-MM-DD")}
                              onChange={(event) => props.handleChange(event)}
                              disabled={props.disableSubscription}
                            // onBlur={() => props.validateField("publishDate")}
                            />
                            {/* <div>
                          <ValidationText error={props.validState.error.publishDate} />
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable flex w-full font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          for="default"
                        >
                          <span>Last Updated Date </span>
                          <span className="text-[#c00000]">*</span>{" "}
                        </label>
                        <div className="grid grid-cols-12 gap-6 items-center w-full">
                          <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full disabled-input">
                            <input
                              id="default"
                              name="lastUpdatedDate"
                              className={`date-pickers form-input rounded-none w-full ${props.disableSubscription
                                ? "bg-[#C4C4C4]"
                                : null
                                } shadow-red py-3 text-lg opacity-50 cursor-not-allowed`}
                              type="date"
                              value={moment(
                                props.taskDetails.lastUpdatedDate
                              ).format("yyyy-MM-DD")}
                              onChange={(event) => props.handleChange(event)}
                              disabled={props.disableSubscription}
                            // onBlur={() => props.validateField("publishDate")}
                            />
                            {/* <div>
                          <ValidationText error={props.validState.error.publishDate} />
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts pt-3">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          for="default"
                        >
                          {" "}
                          Subscription End Date{" "}
                          <span className="text-[#c00000]">*</span>{" "}
                        </label>
                        <div className="grid grid-cols-12 gap-6 items-center w-full">
                          <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full disabled-input">
                            <input
                              id="default"
                              name="subscriptionEndDate"
                              className={`date-pickers form-input rounded-none w-full ${props.disableSubscription
                                ? "bg-[#C4C4C4]"
                                : null
                                } shadow-red py-3 text-lg opacity-50 cursor-not-allowed`}
                              type="date"
                              value={moment(
                                props.taskDetails.subscriptionEndDate
                              ).format("yyyy-MM-DD")}
                              onChange={(event) => props.handleChange(event)}
                              disabled={props.disableSubscription}
                            // onBlur={() => props.validateField("publishDate")}
                            />
                            {/* <div>
                          <ValidationText error={props.validState.error.publishDate} />
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </SectionWithBorder>

            <SectionWithBorder title="Pricing Table">
              {/* <div className="px-7">
                <div className="grid grid-cols-12 gap-6 w-full items-center pt-3 pb-7 mt-5">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          for="default"
                        >
                          {" "}
                          Membership Type{" "}
                          <span className="text-[#c00000]">*</span>{" "}
                        </label>
                        <DropdownSelect
                          drpIdentity={"membershipTypes"}
                          optionArray={props.membershipTypes}
                          setFilterParameters={props.setFilterParameters}
                          value={props.taskDetails.membershipTypeId}
                          onBlur={(e) =>
                            props.validateField(
                              "membershipTypeId",
                              props.taskDetails
                            )
                          }
                        />
                        <ValidationText
                          error={
                            props.validStateForInvoice.error.membershipTypeId
                          }
                        />
                      </div>
                      <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          for="default"
                        >
                          {" "}
                          Type of Revenue{" "}
                          <span className="text-[#c00000]">*</span>{" "}
                        </label>
                        <DropdownSelect
                          drpIdentity={"membershipRevenue"}
                          optionArray={props.membershipRevenueList}
                          setFilterParameters={props.setFilterParameters}
                          value={props.taskDetails.membershipRevenueId}
                          onBlur={(e) =>
                            props.validateField(
                              "membershipRevenueId",
                              props.taskDetails
                            )
                          }
                        />
                        <ValidationText
                          error={
                            props.validStateForInvoice.error.membershipRevenueId
                          }
                        />
                      </div>
                      <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          for="default"
                        >
                          {" "}
                          Transaction Type{" "}
                          <span className="text-[#c00000]">*</span>{" "}
                        </label>
                        <DropdownSelect
                          drpIdentity={"transactionTypes"}
                          optionArray={props.transactionTypes}
                          setFilterParameters={props.setFilterParameters}
                          value={props.taskDetails.transactionTypeId}
                          onBlur={(e) =>
                            props.validateField(
                              "transactionTypeId",
                              props.taskDetails
                            )
                          }
                          disabled={true}
                        />
                        <ValidationText
                          error={
                            props.validStateForInvoice.error.transactionTypeId
                          }
                        />
                      </div>
                      <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts disabled-input">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          for="default"
                        >
                          Comments
                        </label>
                        <textarea
                          className="w-full"
                          // placeholder="Comments"
                          id=""
                          rows="6"
                          name="comments"
                          value={props.taskDetails.comments}
                        // onChange={(e) => props.handleChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-6 w-full items-center pt-3 pb-7 my-2">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <div className="flex items-center justify-between">
                          <div className="check-box-sec flex items-center">
                            <input
                              className="form-checkbox"
                              type="checkbox"
                              name="SendQuotation"
                              id="SendQuotation"
                              onChange={(event) => props.handleChangeCheckBox(event)}
                              checked={props.taskDetails.quotationNeeded}
                              value={props.taskDetails.quotationNeeded}
                            />
                            <span className="filter-lable font-bold text-[#000000] pl-4">
                              Send Quotation
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {props.taskDetails.quotationNeeded === true ? (
                  <div className="grid grid-cols-12 gap-6 w-full items-center pt-3 pb-7">
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                          <label
                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                            for="default"
                          >
                            {" "}
                            Membership Tenure{" "}
                            <span className="text-[#c00000]">*</span>{" "}
                          </label>
                          <DropdownSelect
                            drpIdentity={"membershipTenure"}
                            optionArray={props.tenureArr}
                            setFilterParameters={props.setFilterParameters}
                            value={props.taskDetails.membershipTenureId}
                            onBlur={(e) =>
                              props.validateFieldForInvoice(
                                "membershipTenureId",
                                props.taskDetails
                              )
                            }
                          />
                          <ValidationText
                            error={
                              props.validStateForInvoice.error
                                .membershipTenureId
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                          <label
                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                            for="default"
                          >
                            {" "}
                            Number of Chapters{" "}
                            <span className="text-[#c00000]">*</span>{" "}
                          </label>
                          <DropdownSelect
                            drpIdentity={"chapters"}
                            optionArray={props.numberOfChapters}
                            setFilterParameters={props.setFilterParameters}
                            value={props.taskDetails.numberOfChapter + 1}
                            onBlur={(e) =>
                              props.validateFieldForInvoice(
                                "numberOfChapter",
                                props.taskDetails
                              )
                            }
                          />
                          <ValidationText
                            error={
                              props.validStateForInvoice.error.numberOfChapter
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts mt-10">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                          <div className="flex items-center justify-between">
                            <div className="check-box-sec flex items-center">
                              <input
                                className="form-checkbox"
                                type="checkbox"
                                name="name"
                                id="startMembershipOn"
                                onChange={(event) => props.handleChangeMembershipOnCheckBox(event)}
                                checked={props.taskDetails.startMembership}
                                value={props.taskDetails.startMembership}
                              />
                              <span className="filter-lable font-bold text-[#000000] pl-4">
                                Start membership on
                              </span>
                            </div>
                            <span className="disabled-input">
                              <input
                                id="startMembershipDate"
                                name="startMembershipOn"
                                className={`date-pickers form-input rounded-none shadow-red py-3 text-lg ${props && props.taskDetails && props.taskDetails.startMembership && (props.taskDetails.isAppliedForRenewal != true) ? null : "bg-[#f2f2f2]"}`}
                                type="date"
                                value={moment(
                                  props.taskDetails.startMembershipOn
                                ).format("yyyy-MM-DD")}
                                onChange={(event) => props.handleChange(event)}
                                disabled={props && props.taskDetails && props.taskDetails.startMembership && (props.taskDetails.isAppliedForRenewal != true) ? false : true}
                              //onBlur={() => props.validateFieldForInvoice("startMembershipOn")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                ) : null}

              </div> */}
              {/* <div className="grid grid-cols-2 gap-6 pt-10  pb-8 ">
                <div className="border-r border-[#000000] pl-7 padding-r-0 pr-0">
                  <CalculationChart
                    title="Base Pricing/ Market Campaign Pricing"
                    disabled={true}
                    showButtons={false}
                    tableData={props.basePriceTable}
                    handleChangeInPriceTable={(value, name, year, tableName) =>
                      handleChangeInPriceTable(value, name, year, tableName)
                    }
                    showStatus={StatusType.StateFalse}
                    membershipTypeId={props.taskDetails.membershipTypeId}
                  />
                </div>
                <div className="padding-r-0">
                  <div className="pb-8">
                    <CalculationChart

                      title="Allowable Discount"
                      disabled={true}
                      showButtons={false}
                      tableData={props.allowableDiscountTable}
                      handleChangeInPriceTable={(value, name, year, tableName) =>
                        handleChangeInPriceTable(value, name, year, tableName)
                      }
                      showStatus={StatusType.StateFalse}
                      membershipTypeId={props.taskDetails.membershipTypeId}
                    />
                  </div>
                </div>
              </div> */}
              {/* <hr className="custom-hr" /> */}
              {/* <div className="grid grid-cols-2 gap-6 pt-10 pb-4">
                <div className="border-r border-[#000000] px-7 active-inputs padding-r-0 pr-0">
                  <CalculationChart
                    title="Percentage Discount Setting"
                    disabled={false}
                    showButtons={false}
                    tableData={props.percentageDiscountTable}
                    handleChangeInPriceTable={(value, name, year, tableName) =>
                      handleChangeInPriceTable(value, name, year, tableName)
                    }
                    showStatus={StatusType.StatusTrue}
                    membershipTypeId={props.taskDetails.membershipTypeId}
                  />
                </div>
                <div className="padding-r-0 pr-0">
                  <CalculationChart
                    title="System Calculated"
                    disabled={true}
                    showButtons={false}
                    tableData={props.systemCalculatedTable}
                    handleChangeInPriceTable={(value, name, year, tableName) =>
                      handleChangeInPriceTable(value, name, year, tableName)
                    }
                    membershipTypeId={props.taskDetails.membershipTypeId}
                  />
                </div>
              </div> */}
              <div className='table-section'>
                <div className='overflow-x-auto'>
                  <table className='w-full'>
                    <thead className='bg-[#f2f2f2]'>
                      <tr>
                        <th className='text-left py-3 px-5 w-[25%] whitespace-nowrap'>
                          Type
                        </th>
                        <th className='text-left py-3 px-5 w-[25%] whitespace-nowrap'>
                          Item
                        </th>
                        <th className='text-left py-3 px-5 w-[25%] whitespace-nowrap'>
                          Application Fees<br></br>
                          Including GST
                        </th>
                        <th className='text-left py-3 px-5 w-[25%] whitespace-nowrap'>
                          Membership Fees<br></br>
                          Including GST
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='align-top'>
                        <td className='text-left py-2 px-5 pt-3 w-[25%]'>
                          Membership
                        </td>
                        <td className='text-left py-2 px-5 pt-4 w-[25%] whitespace-nowrap'>
                          <div className="flex items-center w-full mt-0">
                            <div className="flex items-center">
                              <RadioButton
                                id="membershipType"
                                identity="membershipType"
                                name="membershipType"
                                value="Ordinary Member"
                                checked={props.taskDetails.membershipTypeId === 1}
                                isDisabled={true}
                                handleRadioButtons={(e) => props.handleRadioButtons(e, "membershipType", props.corporatePricingDetails.ordinaryApplicationFee, props.taskDetails.annualOrdinaryRevenueFee)}
                              />
                              <span className="ml-3">
                                Ordinary Member
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className='text-left py-2 px-5 pt-3 w-[25%] whitespace-nowrap'>
                          <div className='flex items-center w-full mt-1'>
                            <span className='flex items-center'>
                              {`$${parseFloat(props.corporatePricingDetails.ordinaryApplicationFee).toFixed(2)}`}
                            </span>
                          </div>
                        </td>
                        <td className='text-left py-2 px-5 pt-3 w-[25%] whitespace-nowrap'>
                          <div className='flex items-center w-full mt-1'>
                            <span className='flex items-center'>
                              {`$${parseFloat(props.corporatePricingDetails.annualOrdinaryRevenueFee).toFixed(2)}`}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr className='align-top'>
                        <td className='text-left py-2 px-5 pt-3 w-[25%]'>
                          Membership
                        </td>
                        <td className='text-left py-2 px-5 pt-4 w-[25%] whitespace-nowrap'>
                          <div className="flex items-center w-full mt-0">
                            <div className="flex items-center">
                              <RadioButton
                                id="membershipType"
                                identity="membershipType"
                                name="membershipType"
                                value="Associate Member"
                                //checked={props.taskDetails.selectedMembershipType === "Associate Member"}
                                checked={props.taskDetails.membershipTypeId === 2}
                                handleRadioButtons={(e) => props.handleRadioButtons(e, "membershipType", props.corporatePricingDetails.associateApplicationFee, props.corporatePricingDetails.annualAssociateRevenueFee)}
                                isDisabled={true}
                              />
                              <span className="ml-3">
                                Associate Member
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className='text-left py-2 px-5 pt-3 w-[25%] whitespace-nowrap'>
                          <div className='flex items-center w-full mt-1'>
                            <span className='flex items-center'>
                              {`$${parseFloat(props.corporatePricingDetails.associateApplicationFee).toFixed(2)}`}
                            </span>
                          </div>
                        </td>
                        <td className='text-left py-2 px-5 pt-3 w-[25%] whitespace-nowrap'>
                          <div className='flex items-center w-full mt-1'>
                            <span className='flex items-center'>
                              {`$${parseFloat(props.corporatePricingDetails.annualAssociateRevenueFee).toFixed(2)}`}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr className='align-top'>
                        <td className='text-left py-2 px-5 pt-3 w-[25%]'>
                          Membership
                        </td>
                        <td className='text-left py-2 px-5 pt-4 w-[25%] whitespace-nowrap'>
                          <div className="flex items-center w-full mt-0">
                            <div className="flex items-center">
                              <RadioButton
                                id="membershipType"
                                identity="membershipType"
                                name="membershipType"
                                value="Trade Member"
                                //checked={props.taskDetails.selectedMembershipType === "Trade Member"}
                                checked={props.taskDetails.membershipTypeId === 3}
                                handleRadioButtons={(e) => props.handleRadioButtons(e, "membershipType", props.corporatePricingDetails.tradeApplicationFee, props.corporatePricingDetails.annualTradeRevenueFee)}
                                isDisabled={true}
                              />
                              <span className="ml-3">
                                Trade Member
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className='text-left py-2 px-5 pt-3 w-[25%] whitespace-nowrap'>
                          <div className='flex items-center w-full mt-1'>
                            <span className='flex items-center'>
                              {`$${parseFloat(props.corporatePricingDetails.tradeApplicationFee).toFixed(2)}`}
                            </span>
                          </div>
                        </td>
                        <td className='text-left py-2 px-5 pt-3 w-[25%] whitespace-nowrap'>
                          <div className='flex items-center w-full mt-1'>
                            <span className='flex items-center'>
                              {`$${parseFloat(props.corporatePricingDetails.annualTradeRevenueFee).toFixed(2)}`}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-6">
                {/* <div className="col-span-12 gap-6 px-8 py-8 ">
                  <div className="grid grid-cols-12 gap-6 items-center">
                    <div className="2xl:2xl:col-span-3 lg:col-span-3 col-span-12 gap-6 ">
                      <label className="text-lg font-bold text-[#000000]">
                        Additional Promotion
                      </label>
                    </div>
                    <div className="2xl:2xl:col-span-9 lg:col-span-9 col-span-12 gap-6 ">
                      <input
                        //id="default"
                        className="text-lg col-span-5 w-full"
                        type="text"
                        name="additionalPromotion"
                        value={props.taskDetails.additionalPromotion}
                        onChange={(event) => props.handleChange(event)}
                      //onBlur={() => props.validateField("additionalPromotion")}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-12 gap-6 mt-8 items-center">
                    <div className="2xl:2xl:col-span-3 lg:col-span-3 col-span-12 gap-6 ">
                      <label className="text-lg font-bold text-[#000000]">
                        Comments to member
                      </label>
                    </div>
                    <div className="2xl:2xl:col-span-9 lg:col-span-9 col-span-12 gap-6">
                      <input
                        //id="default"
                        className="text-lg col-span-5 w-full"
                        type="text"
                        name="commentsToMember"
                        value={props.taskDetails.commentsToMember}
                        onChange={(event) => props.handleChange(event)}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-12 gap-6  mt-8 items-center">
                    <div className="2xl:2xl:col-span-3 lg:col-span-3 col-span-12 gap-6 ">
                      <label className="text-lg font-bold text-[#000000]">
                        Internal Notes
                      </label>
                    </div>
                    <div className="2xl:2xl:col-span-9 lg:col-span-9 col-span-12 gap-6">
                      <input
                        //id="default"
                        className="text-lg col-span-5 w-full"
                        type="text"
                        name="internalNotes"
                        value={props.taskDetails.internalNotes}
                        onChange={(event) => props.handleChange(event)}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12 gap-6 px-8 pb-10 ">
                  <div className="flex items-center justify-end">
                    {/* <div className="flex items-center justify-between">
                      {props.isLoadingApprove ? (
                        <div className="w-[233px] quatation-send-btn">
                          <ButtonLoader />
                        </div>
                      ) : (
                        <button
                          className={`col-start-1 col-end-3 text-xl font-bold btn-blue-border w-[250px] text-red p-3 ${!props.disablePaymentButton
                            ? "opacity-50 cursor-not-allowed"
                            : null
                            }`}
                          onClick={() => props.addEditMembershipTask()}
                          disabled={!props.disablePaymentButton}
                        >
                          Send for Approval
                        </button>
                      )}
                    </div> */}
                    {/* {props.taskDetails.quotationNeeded === true ? (
                      <div className="flex items-center justify-between">
                        <div className="flex items-center justify-between mr-4">
                          {props.isLoadingQuotation ? (
                            <ButtonLoader />
                          ) : (
                            <button
                              className=" px-0 py-3 btn-blue text-xl border text-white w-[233px]"
                              onClick={(e) => {
                                //e.stopPropagation();
                                //setSendQuotation(true);
                                props.sendQuotationEmailToCorporateMember();
                              }}
                            >
                              Send Quotation
                            </button>
                          )}
                        </div>
                      </div>
                    ) : null} */}
                    {props.taskDetails.statusId === 12 ?
                      <>
                        <button className="col-start-1 col-end-3 text-xl font-bold text-[#005B9C] btn-blue-border w-[250px] text-red p-3 mt-4 mr-5"
                          //disabled = {true}
                          onClick={() => {
                            props.UnrejectMemberApplication('UnReject');
                          }}
                        >
                          Appeal Process
                        </button>
                      </>
                      :
                      <>
                        <div className="flex items-center justify-between mt-3">
                          {
                            (props.taskDetails.companyName === "" ||
                              props.taskDetails.companyUEN === "" ||
                              props.taskDetails.incorporationDate === "" ||
                              props.taskDetails.firstName === "" || props.taskDetails.lastName === "" ||
                              props.taskDetails.email === "" ||
                              props.taskDetails.selectedDesignation?.length === 0 ||
                              props.taskDetails.contactNo === "") ?
                              <span className="text-[#005B9C] px-2">Please fill all mandatory fields</span>
                              :
                              null
                          }
                          <button className="col-start-1 col-end-3 text-xl font-bold text-[#005B9C] btn-blue-border w-[250px] text-red p-3 mt-4 mr-5"
                            onClick={() => {
                              props.rejectMemberApplication('Rejected');
                            }}>
                            Reject Application
                          </button>
                          <button className="col-start-1 col-end-3 text-xl font-bold text-[#005B9C] btn-blue-border w-[250px] text-red p-3 mt-4 mr-5"
                            onClick={() => {
                              props.setSendClarificationsFlag();
                            }}>
                            Send Clarifications
                          </button>
                          {props.isLoadingPayment ? (
                            <div className="w-[233px] quatation-send-btn">
                              <ButtonLoader type='btn btn-blue' />
                            </div>
                          ) : (
                            <button
                              disabled={(props.taskDetails.companyName === "" ||
                                //props.taskDetails.chineseCompanyName === "" ||
                                props.taskDetails.companyUEN === "" ||
                                props.taskDetails.incorporationDate === "" ||
                                props.taskDetails.firstName === "" || props.taskDetails.lastName === "" ||
                                props.taskDetails.email === "" ||
                                //props.taskDetails.selectedDesignation?.length === 0 ||
                                props.taskDetails.contactNo === "") ? true : false}
                              className={
                                props.disablePaymentButton
                                  ? `col-end-9 col-span-2 text-xl font-bold text-white bg-[#d4d4d8] text-red w-[250px] p-3 mt-4`
                                  : `col-start-1 col-end-3 text-xl font-bold text-[#005B9C] btn-blue-border w-[250px] text-red p-3 mt-4 ${(props.taskDetails.companyName === "" ||
                                    //.taskDetails.chineseCompanyName === "" ||
                                    props.taskDetails.companyUEN === "" ||
                                    props.taskDetails.incorporationDate === "" ||
                                    props.taskDetails.firstName === "" || props.taskDetails.lastName === "" ||
                                    props.taskDetails.email === "" ||
                                    //props.taskDetails.selectedDesignation?.length === 0 ||
                                    props.taskDetails.contactNo === "") ? "cursor-not-allowed" : null}`
                              }
                              onClick={() => {
                                props.addEditMembershipTaskAndSendPaymentLink();
                              }}
                            >
                              Send Payment Link
                            </button>
                          )}
                        </div>
                      </>
                    }

                  </div>
                  {/* <div className="flex items-center justify-end">
                    {props.taskDetails.isApplicationFee === false ? <><h6 className="whitespace-nowrap text-[#FF0000] font-bold text-left">Accept Application Fee</h6></> : null}
                  </div> */}
                </div>
              </div>
            </SectionWithBorder>
          </div>
          {/*Footer Button End*/}
          <div className="flex items-center justify-between pb-6">
            <button
              className="text-lg py-2 font-bold btn btn-gray w-auto text-white"
              onClick={() => props.closeModalOnBack()}
            >
              Back
            </button>
            {/* {props.isEscalateLodaing ? (
              <div className="w-full flex justify-center quatation-send-btn pl-[40%] mb-4">
                <ButtonLoader />
              </div>
            ) : (
              <button
                className=" px-7 py-2 btn-sm btn btn-lighblue text-lg text-white font-bold"
                onClick={(e) => { props.sendEscalateMailToMember(); }}
              >
                Escalate this application
              </button>
            )
            } */}
          </div>
          {/*Footer Button End*/}
        </div>

        <QuickLinkDetailModal
          taskDetails={props.taskDetails}
          countryList={props.countryList}
          companyMainActivityList={props.companyMainActivityList}
          typesOfProject={props.typesOfProject}
          publicprojectsForm={props.publicprojectsForm}
          fullStaffEmployeeLocal={props.fullStaffEmployeeLocal}
          fullStaffEmployeeForeginer={props.fullStaffEmployeeForeginer}
          hearAboutUsList={props.hearAboutUsList}
          annualTurnoverList={props.annualTurnoverList}
          setSendInvoiceModal={isQuickLinkDetail}
          setOpenModal={() => setQuickLinkDetail(false)}
          organizationBasicInformation={props.organizationBasicInformation}
          companyActivity={props.companyActivity}
          publicProjectsForms={props.publicProjectsForms}
          mrMrs={props.mrMrs}
          mainGroupList={props.mainGroupList}
          workheadList={props.workheadList}
          gradeList={props.gradeList}	
          typeOfProjects={props.typeOfProjects}
          fulltimeEmployeeLocal={props.fulltimeEmployeeLocal}
          fullTimeEmployeeForeigners={props.fullTimeEmployeeForeigners}
          knowAboutusList={props.knowAboutusList}
          companyRegisteredWithBCAList ={props.companyRegisteredWithBCAList}
          onClose={(e) => {
            e.stopPropagation();
            setQuickLinkDetail(false);
          }}
        />
      </ModalBasic>
    </>
  );
};
export default MembershipTaskListProcess;
