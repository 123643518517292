// Import React Libraries and Components
import React from "react";
import DatePicker from "../../datePicker/DatePicker";
import DropdownSelect from "../../dropdown/Dropdown";
import TextInput from "../../UIComponent/TextInput";
import moment from "moment";
import Validation from "../../../utils/validation/ValidationText";

function AccountInformation(props) {
    return (
        <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
            {/* Account Information Start */}
            <div className="">
                <div className="grid grid-cols-12 gap-6 w-full items-center">
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Account Status <span className="text-[#c00000]">*</span>
                                </h2>
                                <DropdownSelect
                                    drpIdentity={"accountStatus"}
                                    optionArray={props.status}
                                    setFilterParameters={props.setFilterParameters}
                                    value={props.individualAccountInfo.accountStatus}
                                    disabled={true}
                                />
                                <Validation error={props.validState.error.accountStatus} />
                            </div>
                        </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Joined Date <span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.individualAccountInfo.individualSIISAccountId > 0 ? 'disabled-input' : ''}`}>
                                    <DatePicker
                                        name="individualJoinedDate"
                                        handleChange={props.handleChange}
                                        identity="IndividualAccountInforamtion"
                                        value={moment(props.individualAccountInfo.individualJoinedDate).format("yyyy-MM-DD")}
                                        onBlur={(e) => props.validate("individualJoinedDate", props.individualAccountInfo)}
                                        isDisable={props.individualAccountInfo.individualSIISAccountId > 0 ? true : false}
                                    //isCurserAllowed={props.individualAccountInfo.individualJoinedDate === "" ? true : false}
                                    />
                                </div>
                                <Validation error={props.validState.error.individualJoinedDate} />
                            </div>
                        </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                                    Updated By <span className="text-[#c00000]">*</span>
                                </h2>
                                <div className="disabled-input">
                                    <DropdownSelect
                                        drpIdentity={"updatedBy"}
                                        optionArray={props._By}
                                        setFilterParameters={props.setFilterParameters}
                                        value={props.individualAccountInfo.updatedBy}
                                        disabled={true}
                                    />
                                </div>
                                <Validation error={props.validState.error.updatedBy} />
                            </div>
                        </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Updated Date
                                </h2>
                                <div className="disabled-input">
                                    {props.individualAccountInfo.updatedAt === "" || props.individualAccountInfo.updatedAt === null ?
                                        <TextInput
                                            disabled={true}
                                            placeholder=""
                                            type="text"
                                            name="linkedIn"
                                            value="None"
                                            identity="BasicInformation"
                                        />
                                        :
                                        <DatePicker
                                            isDisable={true}
                                            name="updatedAt"
                                            handleChange={props.handleChange}
                                            identity="updatedAt"
                                            value={moment(props.individualAccountInfo.updatedAt).format("yyyy-MM-DD")}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Mark for deleted  */}
                    {/* <div className="xl:col-span-12 lg:col-span-12 col-span-12 mt-3">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <div className="flex items-start register-desc mb-5">
                                    <input
                                        id="markForDeleted"
                                        name="markForDeleted"
                                        type="checkbox"
                                        className="cursor-pointer h-6 w-6 text-[#005B9C] focus:ring-[#005B9C] check-box-custom"
                                        onChange={(event) => props.handleChangeCheckBox(event)}
                                        checked={props.individualAccountInfo.markForDeleted}
                                        value={props.individualAccountInfo.markForDeleted}
                                    />
                                    <label className="communication-label ml-3 block text-[#000000] 2xl:text-[18px]">
                                        Mark for Delete
                                        <span className="text-[#c00000]">*</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* End mark for delete */}

                    {/* Deletion Date Deletion Reason */}

                    {/* {props.individualAccountInfo.markForDeleted ?
                        <>
                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="col-span-12">
                                        <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                                            Deletion Reason
                                        </h2>
                                        <DropdownSelect
                                            drpIdentity={"DeletionReasonId"}
                                            optionArray={props.deletionReasonList}
                                            setFilterParameters={props.setFilterParameters}
                                            value={props.individualAccountInfo.deletionReasonId}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="col-span-12">
                                        <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                            Deletion Date
                                        </h2>
                                        <DatePicker
                                            name="deletionDate"
                                            handleChange={props.handleChange}
                                            identity="DeletionDate"
                                            value={moment(props.individualAccountInfo.deletionDate).format("yyyy-MM-DD")}
                                            onBlur={(e) => props.validate("deletionDate", props.individualAccountInfo)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        null} */}
                </div>
            </div>
            {/* Save Button Section Start*/}
            <div className="grid grid-cols-12 gap-6 pb-10 mt-8">
                <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                    <a
                        className="flex items-center justify-end"
                    //href="/CreateCorporateMembers"
                    >
                        <button
                            className={`w-32 px-7 py-3 btn-sm btn-blue text-xl text-white font-semibold ${props.isDisabledFlag > 0 ? 'cursor-not-allowed' : null}`}
                            onClick={() => props.addEditIndividualAccountInformation()}
                            disabled={props.isDisabledFlag > 0 ? true : false}
                        >
                            Save
                        </button>
                    </a>
                </div>
            </div>
            {/* Save Button Section End*/}
        </div>
    );
}
export default AccountInformation;