import React, { useState } from "react";
import { Link } from "react-router-dom";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import Table from "../../../components/table/Table";

const CPASApplication = () => {
  // State variables managed using useState hook
  const [corporateMemberList, setCorporateMemberList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalRecords: 0,
  });
  const [totalResults, setTotalResults] = useState(0);

  // Function to handle pagination
  const handlePagination = (page) => {
    console.log("Page changed to: ", page);
    // Example: Implement your pagination logic here
    // setPagination({ ...pagination, currentPage: page });
  };

  // Function to handle actions on table data
  const handleActions = (corporateMemberId) => {
    console.log("Actions clicked for Corporate Member ID: ", corporateMemberId);
    // Implement your action logic here
  };

  return (
    <div className="main-body-section">
      <div className="flex">
        <div className="relative flex flex-col flex-1 "></div>
      </div>
      <main className="pb-10">
        <div className="custom-card shadow-lg mb-10">
          <div className="filter-info grid grid-cols-12 gap-6">
            <div className="filter-types 2xl:col-span-3 lg:col-span-4 col-span-12">
              <h2 className="text-xl text-[#000000] font-bold mb-1">
                Search by Name
              </h2>
              <input
                id="default"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
              />
            </div>
            <div className="filter-types 2xl:col-span-3 lg:col-span-4 col-span-12">
              <h2 className="text-xl text-[#000000] font-bold mb-1">
                Search by Email
              </h2>
              <input
                id="default"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
              />
            </div>

            <div className="filter-types 2xl:col-span-3 lg:col-span-4 col-span-12">
              <h2 className="text-xl text-[#000000] font-bold mb-1">
                Type of Application
              </h2>
              <DropdownSelect drpIdentity={"Select_Type"} />
            </div>

            <div className="filter-types 2xl:col-span-3 lg:display-none col-span-12"></div>

            <div className="filter-types 2xl:col-span-3 lg:col-span-4 col-span-12">
              <h2 className="text-xl text-[#000000] font-bold mb-1">
                Submission Date
              </h2>
              <DropdownSelect drpIdentity={"Select_Status"} />
            </div>

            <div className="filter-types 2xl:col-span-3 lg:col-span-4 col-span-12">
              <h2 className="text-xl text-[#000000] font-bold mb-1">
                Status
              </h2>
              <DropdownSelect drpIdentity={"Select_Status"} />
            </div>

            <div className="filter-types 2xl:col-span-3 lg:col-span-4 col-span-12"></div>

            <div className="grid grid-flow-col sm:auto-cols-max justify-end sm:justify-start gap-3 mt-8 ml-0 2xl:ml-16">
              <Link to="/CreateCorporateMembers">
                <button className="btn btn-blue text-white px-14">
                  <span className="text-xl text-white font-semibold tracking-normal">
                    Search
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="relative">
          <div>
            <div className="">
              <Table
                columns={[
                  { name: "name", title: "Name" },
                  { name: "email", title: "Email" },
                  { name: "typeofApplication", title: "Type of Application" },
                  { name: "submissionDate", title: "Submission Date" },
                  { name: "interviewDate", title: "Interview Date" },
                  { name: "status", title: "Status" },
                  { name: "actions", title: "Actions" },
                ]}
                rows={corporateMemberList}
                isLoading={isLoading}
                sortingColumns={["companyName", "statusName"]}
                pagination={pagination}
                totalResults={totalResults}
                totalCount={totalResults}
                setPagination={handlePagination}
                customScope={[
                  {
                    column: "corporateMemberId",
                    renderTableData: handleActions,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CPASApplication;
