import { AddMembershipPricing, 
    GetMembershipPricing ,
    GetEventPaymentReconcilationList, 
    SavePaymentReconcilationDetailsByAdmin,
     AcceptTotalPaidAmountInReconcilation,
     AcceptTotalMembershipPaidAmountInReconciliation,
     AddEditMembershipSlotSPricing,
     GetMembershipSlotSPricing,
     AcceptMembershipAmountInReconciliationOfSlotsMember,
     GetAllDropDownForEntryManagement,
     GetOutstandingInvoicesList,
     AddProofInPaymentProofMapping,
     GetSlotsMemberFiles,
     GetMembershipCONQUASPricing,
     AddEditMembershipCONQUASPricing,
     AcceptMembershipAmountInReconciliationOfCONQUASMember,
     GetCommentsInvoicesList,
     GetCreditNoteFiles,
     addEditStudentMembershipPricing,
     getStudentMembershipPricing,
     AcceptMembershipAmountInReconciliationOfStudentMember
} from "../ApiEndPoints";
import { axiosGet, axiosGetAuthorize, axiosGetFileAuthorizeblob, axiosPostAuthorize } from "../AxiosRequests";

export default class MembershipPricingServices {
    async getStudentMembershipPricing() {
        return axiosGet(getStudentMembershipPricing);
    }
    async addEditStudentMembershipPricing(request) {
        return axiosPostAuthorize(addEditStudentMembershipPricing, request);
    }
    async addEditMembershipPricing(request) {
        return axiosPostAuthorize(AddMembershipPricing, request);
    }
    async getMembershipPricing() {
        return axiosGetAuthorize(GetMembershipPricing);
    }
    async getEventPaymentReconcilationList(request) {
        return axiosPostAuthorize(GetEventPaymentReconcilationList, request)
    }
    async savePaymentReconcilationDetailsByAdmin(request) {
        return axiosPostAuthorize(SavePaymentReconcilationDetailsByAdmin, request);
    }
    async acceptTotalPaidAmountInReconcilation(request) {
        return axiosGetAuthorize(AcceptTotalPaidAmountInReconcilation, request);
    }
    async acceptTotalMembershipPaidAmountInReconciliation(request) {
        return axiosPostAuthorize(AcceptTotalMembershipPaidAmountInReconciliation, request);
    }
    async addEditMembershipSlotSPricing(request) {
        return axiosPostAuthorize(AddEditMembershipSlotSPricing, request);
    }
    async getMembershipSlotSPricing() {
        return axiosGetAuthorize(GetMembershipSlotSPricing);
    }
    async acceptMembershipAmountInReconciliationOfSlotsMember(request) {
        return axiosPostAuthorize(AcceptMembershipAmountInReconciliationOfSlotsMember, request);
    }
    async getAllDropDownForEntryManagement(request) {
        return axiosGetAuthorize(GetAllDropDownForEntryManagement, request);
    }
    
    async getOutstandingInvoicesList(request) {
        return axiosPostAuthorize(GetOutstandingInvoicesList, request)
    }
    async addProofInPaymentProofMapping(request) {
        return axiosPostAuthorize(AddProofInPaymentProofMapping, request);
    }
    async GetSlotsMemberFiles(request) {
        return axiosGetFileAuthorizeblob(GetSlotsMemberFiles, request);
    }
    async addEditMembershipCONQUASPricing(request) {
        return axiosPostAuthorize(AddEditMembershipCONQUASPricing, request);
    }
    async getMembershipCONQUASPricing() {
        return axiosGetAuthorize(GetMembershipCONQUASPricing);
    }
    async acceptMembershipAmountInReconciliationOfCONQUASMember(request) {
        return axiosPostAuthorize(AcceptMembershipAmountInReconciliationOfCONQUASMember, request);
    }
    async getCommentsInvoicesList(request) {
        return axiosPostAuthorize(GetCommentsInvoicesList, request)
    }
    async GetCreditNoteFiles(request) {
        return axiosGetFileAuthorizeblob(GetCreditNoteFiles, request);
    }
    async acceptMembershipAmountInReconciliationOfStudentMember(request) {
        return axiosPostAuthorize(AcceptMembershipAmountInReconciliationOfStudentMember, request);
    }
}