import React from "react";
import ModalBasic from "../../component/ModalBasic";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";
import TinyEceEditor from "../../../components/ckEditor/TinyMCEEditor";
import ValidationText from "../../../utils/validation/ValidationText";
const SendClarifications = (props) => {

    
    return (
        <ModalBasic
            id="send-payment-link-modal"
            modalOpen={props.clarificationModal}
            setModalOpen={props.setOpenModal}
            title="Clarification Detail"
        // noCloseIcon={true}
        >
            <div className="pb-6 px-7 mt-2">
                <div>
                    <TinyEceEditor
                        key={"clarification"}
                        onChange={(e) => props.onTinyEceEditorChange(e, "clarification")}
                        data={props.clarification}
                        // onBlur={() => props.validateField("clarification")}
                    />
                  <ValidationText error={props.validState.error.clarification}/>
                     
                </div>
                <div className="text-center">
                    {
                        props && props.isLoading === true ?
                            <ButtonLoader className="btn btn-orange mt-8" />
                            :
                            <button
                                className="btn btn-orange text-base text-[#fff] font-bold mt-8"
                                onClick={() => props.saveClarificationDetails()}
                            >
                                OK
                            </button>
                    }
                </div>
            </div >
        </ModalBasic>
    );
}
export default SendClarifications;