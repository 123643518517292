import React from "react";
import DatePicker from "../../../datePicker/DatePicker";
import DropdownSelect from "../../../dropdown/Dropdown";
import Validation from "../../../../utils/validation/ValidationText";
import moment from "moment";
import TextInput from "../../../UIComponent/TextInput";
import ButtonLoader from "../../../common/buttonLoader/ButtonLoader";

function CorporateMembershipSubscription(props) {
  return (
    <div>
      <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
        <div className="grid grid-cols-12 gap-6 w-full ">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Membership Type
                  <span className="text-[#c00000]">*</span>
                </h2>
                <DropdownSelect
                  drpIdentity={"MembershipTypesSubscription"}
                  optionArray={props.membershipTypes}
                  setFilterParameters={props.setFilterParameters}
                  value={props.membershipSubscription.membershipTypeId}
                  onBlur={(e) => props.validate("membershipTypeId", props.membershipSubscription)}
                  disabled={props.isDisabledFlag ? true : false}
                />
                <Validation error={props.validState.error.membershipTypeId} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Billing Category
                  <span className="text-[#c00000]">*</span>
                </h2>
                <DropdownSelect
                  drpIdentity={"corporateMemberBillingCategoryId"}
                  optionArray={props.billingCategory}
                  setFilterParameters={props.setFilterParameters}
                  value={props.membershipSubscription.billingCategoryId}
                  onBlur={(e) => props.validate("corporateMemberBillingCategoryId", props.membershipSubscription)}
                  disabled={true}
                />
                <Validation error={props.validState.error.corporateMemberBillingCategoryId} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Subscription Start Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input dropdwn-input-email flex items-center">
                  <DatePicker
                    isDisable={true}
                    name="subscriptionStartDate"
                    handleChange={props.handleChange}
                    identity="MembershipSubscription"
                    value={moment(props.membershipSubscription.subscriptionStartDate).format("yyyy-MM-DD")}
                  // onBlur={(e) => props.validate("corporateJoinedDate", props.membershipSubscription)}
                  />

                  {/* <Validation error={props.validState.error.subscriptionStartDate} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Subscription End Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input dropdwn-input-email flex items-center">
                  <DatePicker
                    isDisable={true}
                    name="subcriptionEndDate"
                    handleChange={props.handleChange}
                    identity="MembershipSubscription"
                    value={moment(props.membershipSubscription.subcriptionEndDate).format("yyyy-MM-DD")}
                  // onBlur={(e) => props.validate("corporateJoinedDate", props.membershipSubscription)}
                  />

                  {/* <Validation error={props.validState.error.subcriptionEndDate} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Updated By
                </h2>
                <DropdownSelect
                  disabled={true}
                  drpIdentity={"updatedById"}
                  optionArray={props._by}
                  setFilterParameters={props.setFilterParameters}
                  value={props.membershipSubscription.updatedBy}
                // onBlur={(e) => props.validate("updatedById", props.membershipSubscription)}
                />
                {/* <Validation error={props.validState.error.updatedById} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Last Updated Date
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className="disabled-input">
                  {props.membershipSubscription.updatedAt === "" || props.membershipSubscription.updatedAt === null ?
                    <TextInput
                      disabled={true}
                      placeholder=""
                      type="text"
                      name="linkedIn"
                      value="None"
                      identity="BasicInformation"
                    />
                    :
                    <DatePicker
                      isDisable={true}
                      name="updatedAt"
                      handleChange={props.handleChange}
                      identity="updatedAt"
                      value={moment(props.membershipSubscription.updatedAt).format("yyyy-MM-DD")}
                    />
                  }
                </div>
                {/* <div className="disabled-input dropdwn-input-email flex items-center">
                  <DatePicker
                    isDisable={true}
                    name="updatedAt"
                    handleChange={props.handleChange}
                    identity="MembershipSubscription"
                    value={moment(props.membershipSubscription.updatedAt).format("yyyy-MM-DD")}
                  onBlur={(e) => props.validate("corporateJoinedDate", props.membershipSubscription)}
                  />

                  <Validation error={props.validState.error.updatedAt} />
                </div> */}
              </div>
            </div>
          </div>
          {props.membershipSubscription.corporateMemberSubscriptionId > 0 ?
            null
            :
            <>
              <div className='col-span-12 mt-0 mb-5 new-button-add-upload'>
                <div className="flex item-center flex-col">
                  {
                    props && props.isPaymentProofLoader ?
                      <ButtonLoader type='btn btn-blue' />
                      :
                      (
                        <form className={`custom-uploader custom-file-upload-btn flex cursor-pointer ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                          <div className="border border-[#f3f3f3] btn btn-blue">
                            <label htmlFor="Upload_Payment_Proof" className={`text-uplod text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>Upload Payment Proof</label>
                            <input className="sr-only" id="Upload_Payment_Proof" type="file"
                              onChange={(event) => props.onFileChange(event, "Upload_Payment_Proof")}
                              disabled={props.isDisabledFlag ? true : false}
                            />
                          </div>
                        </form>
                        // <button class="upload-button px-7 py-3 btn-sm btn-blue text-xl text-white font-semibold">
                        //          <div className="px-7 py-3 btn-sm btn-blue text-xl text-white font-semibold">
                        //     <label htmlFor="Upload_Payment_Proof" className="text-uplod text-white">Upload Payment Proof</label>
                        //     <input className="sr-only" id="Upload_Payment_Proof" type="file"
                        //       onChange={(event) => props.onFileChange(event, "Upload_Payment_Proof")}
                        //     />
                        //   </div>
                        // </button>
                        // <button className="custom-uploader custom-file-upload-btn flex cursor-pointer">
                        // </button>
                      )
                  }
                  <Validation error={props.validState.error.uploadPaymentProof} />
                  {/* <Validation error={props.validState.error.corporateMemberBillingCategoryId} /> */}
                  {
                    props && props.membershipSubscription.uploadPaymentProof ?
                      (
                        <div className='mt-5 file-uploaded-txt  items-center'>
                          <div className="img-and-text-side-by-side">
                            <img src='/check-orange.png' className='w-[25px] mr-3' alt='' />
                            <span className='text-[#000]'>
                              {props.membershipSubscription.uploadPaymentProof}
                            </span>
                          </div>
                          <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                            Comments
                            <span className="text-[#c00000]">*</span>
                          </h2>
                          <textarea
                            className="w-full"
                            id="comments"
                            rows="4"
                            name="comments"
                            value={props.membershipSubscription.comments}
                            onChange={(e) => props.handleChange(e, 'comments')}

                          //   onBlur={() => this.validateField("comments")}
                          />
                          <Validation error={props.validState.error.comments} />
                          {/* <Validation error={props.validMemberSub.error.comments} />  */}
                        </div>

                      )
                      : null
                  }
                </div>
              </div>
            </>
          }
        </div>
      </div>
      {/* Renew Button */}
      <div className="grid grid-cols-12 gap-6 pb-10">
        <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10 new-btn">
          {/* <div className="left-btn">
            <a
              className="flex items-center justify-end"
            //href="/CreateCorporateMembers"
            >
              {
                props && props.isDisable === true ?
                  (
                    <ButtonLoader />
                  )
                  :
                  (
                    <button
                      className="w-32 px-7 py-3 btn-sm btn-blue text-xl text-white font-semibold"
                      onClick={() => props.renewCorporateMember()}
                    >
                      Renew
                    </button>
                  )
              }
            </a>
          </div> */}
          <div className="px-7">
            {
              props && props.isDisabledFlag === true ?
                (
                  <button
                    className="w-32 px-7 py-3 btn-sm btn-blue text-xl text-white font-semibold w-[250px] cursor-not-allowed"
                    disabled={true}
                  >
                    Send Renewal Mail


                  </button>
                )
                :
                (
                  <button
                    className="w-32 px-7 py-3 btn-sm btn-blue text-xl text-white font-semibold w-[250px]"
                    onClick={() => props.sendRenewalReminderMailForCorporateMember()}
                  >
                    Send Renewal Mail
                  </button>
                )
            }
          </div>
          <div className="">
            {/* <a
              className="flex items-center justify-end"
                 href="/CreateCorporateMembers"
            > */}
              {
                props && props.isLoading === true ?
                  (
                    <ButtonLoader type='btn btn-blue' />
                  )
                  :
                  (
                    <button
                      className={`w-32 px-7 py-3 btn-sm btn-blue text-xl text-white font-semibold ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                      onClick={() => props.onSave()}
                      disabled={props.isDisabledFlag ? true : false}
                    >
                      Save
                    </button>
                  )
              }
            {/* </a> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default CorporateMembershipSubscription;