import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "../../../components/table/Table";
import DateInput from "../../../components/inputs/DateInput";

const CPDClaimApproval = () => {
  const [corporateMemberList, setCorporateMemberList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [totalResults, setTotalResults] = useState(0);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [validationError, setValidationError] = useState("");

  // Placeholder function for handling pagination
  const handlePagination = (page, pageSize) => {
    // Implement logic to fetch data for the new page
    console.log("Pagination:", page, pageSize);
    setPagination({ page, pageSize });
  };

  // Example data for corporateMemberList (replace with actual data fetching logic)
  useEffect(() => {
    setIsLoading(true);
    // Simulating fetching data from an API
    setTimeout(() => {
      setCorporateMemberList([
        {
          cpdcertificationnumber: "SCAL123456",
          course: "Finance Management 101",
          cpdpoints: "10",
        },
        {
          cpdcertificationnumber: "SCAL789056",
          course: "Finance Management 101",
          cpdpoints: "10",
        },
        {
          cpdcertificationnumber: "SCAL789057",
          course: "Finance Management 101",
          cpdpoints: "10",
        },
        {
          cpdcertificationnumber: "SCAL789058",
          course: "Finance Management 101",
          cpdpoints: "1",
        },
        {
          cpdcertificationnumber: "SCAL789059",
          course: "Finance Management 101",
          cpdpoints: "5",
        },
        // Add more data as needed
      ]);
      setIsLoading(false);
      setTotalResults(5); // Example total results count
    }, 1000); // Simulating delay for API call
  }, []);

  const handleChange = (value) => {
    setDateOfBirth(value);
    // You can add validation logic here if needed
  };

  const validateField = () => {
    // Example validation logic
    if (!dateOfBirth) {
      setValidationError("Date of Birth is required");
    } else {
      setValidationError("");
    }
  };

  // Function to handle actions (replace with actual action handlers)
  const handleView = (cpdcertificationnumber) => {
    // Redirect to view page or show details for the selected item
    console.log("View action for:", cpdcertificationnumber);
    // Replace with your navigation logic
  };

  return (
    <div className="main-body-section">
      <main className="pb-10">
        <div className="custom-card shadow-lg mb-10">
          <div className="filter-info grid grid-cols-12 gap-6">
            <div className="filter-types 2xl:col-span-4 2xl:order-1 lg:order-1 order-1 lg:col-span-6 col-span-12">
              <h2 className="text-xl text-[#000000] font-bold mb-1">
                Search by Course
              </h2>
              <input
                id="default"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
              />
            </div>

            <div className="filter-types 2xl:col-span-4 2xl:order-2 lg:order-2 order-2 lg:col-span-6 col-span-12">
              <h2 className="text-xl text-[#000000] font-bold mb-1">
                Search by CPD Certification Number
              </h2>
              <input
                id="default"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
              />
            </div>

            <div className="grid grid-flow-col 2xl:order-3 md:col-span-3 lg:order-4 order-3 sm:auto-cols-max justify-end items-center sm:justify-start gap-3 mt-8 ml-0 2xl:ml-16">
              <Link to="/CreateCorporateMembers">
                <button className="btn btn-blue text-white px-14">
                  <span className="text-xl text-white font-semibold tracking-normal">
                    Search
                  </span>
                </button>
              </Link>
            </div>

            <div className="lg:col-span-6 2xl:order-4 2xl:col-span-4 lg:order-3 order-4 col-span-12">
              <div className="bg-white">
                <h2 className="input-title mb-3">
                  Date of Incorporation
                  <span className="text-[#C00000]"> *</span>
                </h2>

                <div className="dropdown">
                  <DateInput
                    name="Date From"
                    value={dateOfBirth}
                    handleChange={handleChange}
                    onBlur={validateField}
                  />
                  {validationError && (
                    <span className="text-red-600">{validationError}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="filter-types 2xl:order-4 2xl:col-span-4 lg:order-6 lg:col-span-0 col-span-12"></div>

            <div className="grid 2xl:order-5 items-center grid-flow-col sm:auto-cols-max lg:order-5 order-5  sm:justify-start gap-3 mt-8 ml-0 2xl:ml-16">
              <Link to="/CreateCorporateMembers">
                <button className="btn btn-blue text-white px-14">
                  <span className="text-xl text-white font-semibold tracking-normal">
                    Create
                  </span>
                </button>
              </Link>
            </div>
            
          </div>
        </div>

        <div className="relative">
          <div>
            <div className="">
              <Table
                columns={[
                  { name: "cpdcertificationnumber", title: "CPD Certification Number" },
                  { name: "course", title: "Course" },
                  { name: "cpdpoints", title: "CPD Points" },
                  {
                    name: "actions",
                    title: "Actions",
                    render: (rowData) => (
                      <Link
                        to={`/view/${rowData.cpdcertificationnumber}`} // Adjust link path as needed
                        className="text-blue-600 underline"
                        onClick={() => handleView(rowData.cpdcertificationnumber)}
                      >
                        View
                      </Link>
                    ),
                  },
                ]}
                rows={corporateMemberList}
                isLoading={isLoading}
                sortingColumns={["companyName", "statusName"]}
                pagination={pagination}
                totalResults={totalResults}
                totalCount={totalResults}
                setPagination={handlePagination}
              />
            </div>
          </div>
        </div>
        
      </main>
    </div>
  );
};

export default CPDClaimApproval;
