// import { axiosGetAuthorize, axiosGetFileAuthorizeblob, axiosGetMultiParams, axiosPostAuthorize } from '../AxiosRequests';
import {
    axiosGetAuthorize, axiosGetFileAuthorizeblob,  axiosPostAuthorize, 
} from "../AxiosRequests";
import {
    GenerateallactiveSLOTsRecords,
    DownloadSheetForSLOTsRecords,
    GetAllForSLOTsRecords

} from '../ApiEndPoints';

export default class ReportForSlotsServices {
    async generateallactiveSLOTsRecords() {
        return axiosGetAuthorize(GenerateallactiveSLOTsRecords);
      }
      async downloadSheetForSLOTsRecords(request) {
        return axiosGetFileAuthorizeblob(DownloadSheetForSLOTsRecords, request);
      } 
      async getAllForSLOTsRecords(request) {
        return axiosPostAuthorize(GetAllForSLOTsRecords, request);
      } 
}