// import { axiosGetAuthorize, axiosGetFileAuthorizeblob, axiosGetMultiParams, axiosPostAuthorize } from '../AxiosRequests';
import {
    axiosGetAuthorize,
    axiosGetFileAuthorizeblob,
    axiosPostAuthorize,
} from "../AxiosRequests";
import {
    DownloadSheetByBatchJobId,
    GenerateMonthlyBatchJob,
    GenerateWeeklyBatchJob,
    GetAllMonthlyBatchJobRecords,
    GetAllWeeklyBatchJobRecords,
    GetAllARAgingReportList,
    GenerateARAgingReport,
    DownloadARAgingSheetByARAgingReportId,
    GetAllGSTReportList,
    GenerateGSTReport,
    DownloadGSTSheetByGSTReportId,
    GetAllWeeklyPaymentRecociliationRecords,
    GenerateWeeklyPaymentReconciliation,
    DownloadSheetByXeroPaymentReconciliationId

} from '../ApiEndPoints';

export default class BatchJobExtractionServices {
    async getAllWeeklyBatchJobRecords(request) {
        return axiosPostAuthorize(GetAllWeeklyBatchJobRecords, request)
    }

    async getAllMonthlyBatchJobRecords(request) {
        return axiosPostAuthorize(GetAllMonthlyBatchJobRecords, request)
    }

    async generateWeeklyBatchJob(request) {
        // return axiosGetAuthorize(GenerateWeeklyBatchJob, request)
        return axiosGetAuthorize(GenerateWeeklyBatchJob, request)

    }

    async generateMonthlyBatchJob(request) {
        return axiosGetAuthorize(GenerateMonthlyBatchJob, request)
    }

    async downloadSheetByBatchJobId(request) {
        return axiosGetFileAuthorizeblob(DownloadSheetByBatchJobId, request);
    }
    // For GST Reports
    async getAllGSTReportList(request) {
        return axiosPostAuthorize(GetAllGSTReportList, request);
    }
    async generateGSTReport(request) {
        return axiosPostAuthorize(GenerateGSTReport, request);
    }
    async downloadGSTSheetByGSTReportId(request) {
        return axiosGetFileAuthorizeblob(DownloadGSTSheetByGSTReportId, request);
    }

    // Charts of Account Services
    // async getAllChartsOfAccountCodeList(request) {
    //     return axiosPostAuthorize(GetAllChartsOfAccountCodeList, request);
    // }
    // async getAllDropdownsForBatchJob(request) {
    //     return axiosGetAuthorize(GetAllDropdownsForBatchJob, request);
    // }
    // async addEditChartsOfAccountCode(request) {
    //     return axiosPostAuthorize(AddEditChartsOfAccountCode, request);
    // }

    // For AR Aging Reports
    async getAllARAgingReportList(request) {
        return axiosPostAuthorize(GetAllARAgingReportList, request);
    }
    async generateARAgingReport(request) {
        return axiosPostAuthorize(GenerateARAgingReport, request);
    }
    async downloadARAgingSheetByARAgingReportId(request) {
        return axiosGetFileAuthorizeblob(DownloadARAgingSheetByARAgingReportId, request);
    }

    async GetAllWeeklyPaymentRecociliationRecords(request) {
        return axiosPostAuthorize(GetAllWeeklyPaymentRecociliationRecords, request)
    }

    async GenerateWeeklyPaymentReconciliation(request) {
        return axiosGetAuthorize(GenerateWeeklyPaymentReconciliation, request)
    }

    
    async DownloadSheetByXeroPaymentReconciliationId(request) {
        return axiosGetFileAuthorizeblob(DownloadSheetByXeroPaymentReconciliationId, request);
    }
}