import { CommonValidationMessages } from "../../../utils/Messages";

export const siisAccountInformationValRules = {
    accountStatus: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "account status"
            ),
        },
    ],
};

export const engagementTrackingValRules = {
    corporateEngagementTypeId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "engagement type"
            ),
        },
    ],
    corporateEngagementRecordedById: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "recorded by"
            ),
        },
    ],

    engagementDate: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "engagement date"
            ),
        },
    ],
    engagementDescription: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "description"
            ),
        },
    ],
};

export const prospectInformationValRules = {
    source1Id: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "source 1"
            ),
        },
    ],

    source2Id: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "source 2"),
        },
    ],

    pic1Id: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "pic 1"
            ),
        },
    ],
};

export const financeCardValRules = {
    creditTermsId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "credit terms"),
        },
    ],
};

// export const financeCardValRules = {
//     renewalNotes: [
//         {
//             type: "require",
//             message: CommonValidationMessages.FieldRequired.replace(
//                 "{0}",
//                 "company name"
//             ),
//         },
//     ],
//     internalFlagId: [
//         {
//             type: "require",
//             message: CommonValidationMessages.FieldRequired.replace(
//                 "{0}",
//                 "company UEN"
//             ),
//         },
//     ],
// };

export const governmentProgrammesValRules = {
    renewalNotes: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company name"
            ),
        },
    ],
    internalFlagId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company UEN"
            ),
        },
    ],
};

export const memberSubscriptionValRules = {
    membershipTypeId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "membership type"
            ),
        },
    ],
    billingCategoryId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "billing category"
            ),
        },
    ],
};

export const chapterInformationValRules = {
    workgroupMappingId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "chapter name"
            ),
        },
    ],
    // renewalNotes: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "company name"
    //         ),
    //     },
    // ],
    // internalFlagId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "company UEN"
    //         ),
    //     },
    // ],
};

export const organizationBasicInformationValRules = {
    companyUEN: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "company UEN"),
        },
        {
            type: "isCompanyUEN",
            message: "Please enter valid company UEN"
        },
    ],
    companyName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company name"
            ),
        },
    ],
    // incorporationDate: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "incorporating date"
    //         ),
    //     },
    // ],
    // jobTitle: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "job title"
    //         ),
    //     },
    // ],
    // mrMrsId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace("{0}", "name prefix"),
    //     },
    // ],
    // firstName: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "representative's first name"
    //         ),
    //     },
    // ],
    // lastName: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "representative's last name"
    //         ),
    //     },
    // ],
    // companyAddress: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "mailing address"
    //         ),
    //     },
    // ],
    // countryId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "country"
    //         ),
    //     },
    // ],
    // email: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "email address"
    //         ),
    //     },
    //     {
    //         type: "email",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
    //     },
    // ],
    // contactNo: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "business contact number"),
    //     },
    //     {
    //         type: "number",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "only digits"),
    //     },
    // ],
    // businessEmail1: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "business email address"
    //         ),
    //     },
    //     {
    //         type: "email",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
    //     },
    // ],
    // companyActivity: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "project type"
    //         ),
    //     },
    // ],
    // projectTypeId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "project type"
    //         ),
    //     },
    // ],
    // fullTimeStaffLocalId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "full time staff(local/PR)"
    //         ),
    //     },
    // ],
    // fullTimeStaffForeignersId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "full time staff(foreginers)"
    //         ),
    //     },
    // ],
    // knowAboutUsId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "how do you hear about us"
    //         ),
    //     },
    // ],
    // postalCode: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "postal code"
    //         ),
    //     },
    //     {
    //         type: "number",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "valid postal code"),
    //     },
    //     {
    //         type: "maxLength",
    //         maxLength: 6,
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "valid postal code"),
    //     },
    //     {
    //         type: "minLength",
    //         minLength: 6,
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "valid postal code"),
    //     },
    // ],
    // companyDescription: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "company description"
    //         ),
    //     },
    // ],
    // membershipRevenueId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "annual turn over"
    //         ),
    //     },
    // ],    
    // aCRACertificatePath: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "ACRA file"
    //         ),
    //     },
    // ],
    // projectTrackRecordReportPath: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "project track record file"
    //         ),
    //     },
    // ],

};

export const organizationBCAScopeValRules = {
    scopeMainGroupId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "main group"
            ),
        },
    ],
    scopeWorkheadId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "workhead"
            ),
        },
    ],
    scopeGradeId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "grade"
            ),
        },
    ],

};

export const organizationProfileValRules = {
    orgGrossTurnoverId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "organisation gross turnover"),
        },
    ],
    selectedSectorsArr: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "business sectors"),
        },
    ],
    selectedExpertiseArr: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "area of expertise"),
        },
    ],
    businessContactNumber: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "business contact number"),
        },
        {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "only digits"),
        },
        {
            type: "maxLength",
            maxLength: 10,
            message: CommonValidationMessages.FieldRequired.replace("{0}", "between 8 and 10 digits"),
        },
        {
            type: "minLength",
            minLength: 8,
            message: CommonValidationMessages.FieldRequired.replace("{0}", "between 8 and 10 digits"),
        },
    ],
    businessEmail: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "business email"),
        },
    ],
    mailingAddress: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "mailing address line 1."),
        },
    ],
    // mailingAddress2: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "mailing address line 2."),
    //     },
    // ],
    // mailingAddress3: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "mailing address line 3."),
    //     },
    // ],
    countryId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "country"),
        },
    ],
    city: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "city"),
        },
    ],
    postalCode: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "postal code"),
        },
        {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid postal code"),
        },
        {
            type: "maxLength",
            maxLength: 6,
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid postal code"),
        },
        {
            type: "minLength",
            minLength: 6,
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid postal code"),
        },
    ],



};

export const organizationBusinessDetailsValRules = {
    renewalNotes: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company name"
            ),
        },
    ],
    internalFlagId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company UEN"
            ),
        },
    ],
};

export const communicationValRules = {
    renewalNotes: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company name"
            ),
        },
    ],
    internalFlagId: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "company UEN"
            ),
        },
    ],
};

export const organizationEmployeesValRules = {
    firstName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "first name"),
        },
    ],
    lastName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "last name"),
        },
    ],
    // emailText: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace("{0}", "email address"),
    //     },
    // ],
    email: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "email address"),
        },
        {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
        },
    ],
    emailString: [
        {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
        },
    ],
    // selectedDesignation: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace("{0}", "designation"),
    //     },
    // ],
    title: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "designation"),
        }
    ],

};

export const engagementvalidationRule = {
    // corporateEngagementTypeId: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "engagement type"
    //         ),
    //     },
    // ],

    // engagementDate: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "engagement date"
    //         ),
    //     },
    // ],
    // engagementDescription: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.FieldRequired.replace(
    //             "{0}",
    //             "description"
    //         ),
    //     },
    // ],
    // corporateEngagementRecordedById: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "recorded by"
    //         ),
    //     },
    // ],
    // corporateEngagementFollowUpById: [
    //     {
    //         type: "require",
    //         message: CommonValidationMessages.SelectRequired.replace(
    //             "{0}",
    //             "follow up by"
    //         ),
    //     },
    // ],
};

export const organisationKeyRolesValRules = {
    mrMrs: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "name prefix"),
        },
    ],
    firstName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "first name"),
        },
    ],
    lastName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "last name"),
        },
    ],
    designation: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "designation"),
        },
    ],
    directOfficeNumber: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "direct office number"),
        },
    ],
    contactNo: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "contact number"),
        },
    ],
    email: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "email address"),
        },
    ],
    organisationRole: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "organisation role"),
        },
    ],

}

export const addEditCreditNoteValRules = {
    cancellationReason: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "cancellation reason"),
        },
    ],
};

export const addEditCreditNoteValRules2 = {
    cancellationReason: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "other cancellation reason"),
        },
    ],
    entranceRefund: [
        {
            type: "maxNumber",
            compareEle: "compareEntranceRefund",
            message: CommonValidationMessages.MaxAmount.replace("{0}", "entrance amount"),
        },
    ],
    annualRefund: [
        {
            type: "maxNumber",
            compareEle: "compareAnnualRefund",
            message: CommonValidationMessages.MaxAmount.replace("{0}", "annual amount"),
        },
    ],
    chapterRefund: [
        {
            type: "maxNumber",
            compareEle: "compareChapterRefund",
            message: CommonValidationMessages.MaxAmount.replace("{0}", "chapter amount"),
        },
    ],
};

