import { React, useRef, useState, useEffect } from "react";
import DropdownSelect from "../../../dropdown/Dropdown";
import TextInput from "../../../UIComponent/TextInput";
import moment from "moment";
import Validation from "../../../../utils/validation/ValidationText";
import ButtonLoader from "../../../common/buttonLoader/ButtonLoader";
import RadioButton from '../../../inputs/CheckboxInput';
import MultiSelectDropdown from '../../../dropdown/MultiSelectDropdown';
import ValidationText from "../../../../utils/validation/ValidationText";
import CheckboxInput from "../../../inputs/CheckboxInput";

function OrganisationBasicInformations(props) {

  //warning // function subtractYears(numOfYears, date = new Date()) {
  //   date.setFullYear(date.getFullYear() - numOfYears);
  //   return date;
  // }
  const textRef = useRef();
  const [value] = useState();
  //warning // const onChnage = (event) => {
  //   setValue(event.target.value);
  // };
  useEffect(() => {
    if (textRef && textRef.current) {
      textRef.current.style.height = "0px";
      const taHeight = textRef.current.scrollHeight;
      textRef.current.style.height = taHeight + "px";
    }
  }, [value]);

  return (
    <div className="w-full organisation-basic-information">
      <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
        <div className="grid grid-cols-12 gap-6 w-full ">

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Company UEN/Business Registration No.{" "}<span className="text-[#c00000]">*</span>
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <input
                  // className={`w-full outline-none appearance-none ${props.disableUenNumber ? "bg-[#f2f2f2]" : null}`}
                  className={`w-full outline-none appearance-none ${props.organizationBasicInformation.organisationInformationId ? 'bg-[#f2f2f2]' : null}`}
                  placeholder=""
                  type="text"
                  name="companyUEN"
                  identity="BasicInformation"
                  value={props.organizationBasicInformation.companyUEN}
                  onChange={(event) => props.handleChange(event, "BasicInformation")}
                  onBlur={(e) => props.validate("companyUEN", props.organizationBasicInformation)}
                  disabled={props.organizationBasicInformation.organisationInformationId ? true : false}
                  autoComplete={"off"}
                />
              </div>
            </div>
            <Validation error={props.validState.error.companyUEN} />
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Company Name <span className="text-[#c00000]">*</span>
                </h2>
                <div className={`dropdwn-input flex items-center ${props.isDisabledFlag ? 'disabled-input' : null}`}>
                  <TextInput
                    //className={`${props.organizationBasicInformation.organisationInformationId ? 'bg-[#f2f2f2]' : null}`}
                    placeholder=""
                    type="text"
                    name="companyName"
                    value={props.organizationBasicInformation.companyName}
                    identity="BasicInformation"
                    handleChange={props.handleChange}
                    onBlur={(e) => props.validate("companyName", props.organizationBasicInformation)}
                    //disabled={props.organizationBasicInformation.organisationInformationId ? true : false}
                    autoComplete={"off"}
                    disabled={props.isDisabledFlag ? true : false}
                  />
                </div>
                <Validation error={props.validState.error.companyName} />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Company Name (In Chinese)
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className={`flex items-center ${props.isDisabledFlag ? 'disabled-input' : null}`}>
                  <TextInput
                    //className={`${props.organizationBasicInformation.organisationInformationId ? 'bg-[#f2f2f2]' : null}`}
                    placeholder=""
                    type="text"
                    name="chinesCompanyName"
                    value={props.organizationBasicInformation.chinesCompanyName}
                    identity="BasicInformation"
                    handleChange={props.handleChange}
                    // onBlur={(e) => props.validate("chinessCompanyName", props.organizationBasicInformation)}
                    //disabled={props.organizationBasicInformation.organisationInformationId ? true : false}
                    autoComplete={"off"}
                    disabled={props.isDisabledFlag ? true : false}
                  />
                </div>
                {/* <Validation error={props.validState.error.chineseCompanyName} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Incorporation Date<span className="text-[#c00000]">*</span>
                </h2>
                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                  <input
                    className="date-pickers w-full custom-input"
                    type="date"
                    name="incorporationDate"
                    identity="BasicInformation"
                    max={moment(new Date()).format("yyyy-MM-DD")}
                    // min={props.membershipSubscription.membershipTypeId === 3 ? subtractYears(4, new Date()) : null}
                    value={moment(props.organizationBasicInformation.incorporationDate).format("yyyy-MM-DD")}
                    onChange={(e) => props.handleChange(e, "BasicInformation")}
                    disabled={props.isDisabledFlag ? true : false}
                    // onBlur={(e) =>
                    //   props.validate(
                    //     "incorporationDate",
                    //     props.organizationBasicInformation
                    //   )
                    // }
                  ></input>
                </div>
                {/* <Validation error={props.validState.error.incorporationDate} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Job Title  <span className="text-[#c00000]">*</span>
                </h2>
                <div className={`flex items-center ${props.isDisabledFlag ? 'disabled-input' : null}`}>
                  <TextInput
                    //className={`${props.organizationBasicInformation.organisationInformationId ? 'bg-[#f2f2f2]' : null}`}
                    placeholder=""
                    type="text"
                    name="jobTitle"
                    value={props.organizationBasicInformation.jobTitle}
                    identity="BasicInformation"
                    handleChange={props.handleChange}
                    // onBlur={(e) => props.validate("jobTitle", props.organizationBasicInformation)}
                    //disabled={props.organizationBasicInformation.organisationInformationId ? true : false}
                    autoComplete={"off"}
                    disabled={props.isDisabledFlag ? true : false}
                  />
                </div>
                {/* <Validation error={props.validState.error.jobTitle} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Representative's First Name <span className="text-[#c00000]">*</span>
                </h2>
                <div className={`flex items-center left-border-none ${props.isDisabledFlag ? 'disabled-input' : null}`}>
                  <div className="w-[130px]">
                    <DropdownSelect
                      drpIdentity={"NamePrefix"}
                      optionArray={props.mrMrs}
                      setFilterParameters={props.setFilterParameters}
                      value={props.organizationBasicInformation.mrMrsId}
                      disabled={props.isDisabledFlag ? true : false}
                    />
                  </div>
                  <TextInput
                    placeholder=""
                    type="text"
                    name="firstName"
                    value={props.organizationBasicInformation.firstName}
                    identity="BasicInformation"
                    handleChange={props.handleChange}
                    disabled={props.isDisabledFlag ? true : false}
                    // onBlur={(e) =>
                    //   props.validate(
                    //     "firstName",
                    //     props.organizationBasicInformation
                    //   )
                    // }
                  />
                </div>
                <div>
                  {/* <Validation error={props.validState.error.mrMrsId} />
                  <Validation error={props.validState.error.firstName} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl theme-color font-bold 2xl:mb-1">
                  Representative's Last Name{" "}
                  <span className="text-[#c00000]">*</span>
                </h2>
                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                  <TextInput
                    placeholder=""
                    type="text"
                    name="lastName"
                    value={props.organizationBasicInformation.lastName}
                    identity="BasicInformation"
                    handleChange={props.handleChange}
                    disabled={props.isDisabledFlag ? true : false}
                    // onBlur={(e) =>
                    //   props.validate(
                    //     "lastName",
                    //     props.organizationBasicInformation
                    //   )
                    // }
                  />
                </div>
              </div>
            </div>
            {/* <Validation error={props.validState.error.lastName} /> */}
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Business/Mailing Address Line 1 <span className="text-[#c00000]">*</span>
                </h2>
                <div className={`dropdwn-input flex items-center ${props.isDisabledFlag ? 'disabled-input' : null}`}>
                  <TextInput
                    //className={`${props.organizationBasicInformation.organisationInformationId ? 'bg-[#f2f2f2]' : null}`}
                    placeholder=""
                    type="text"
                    name="companyAddress"
                    value={props.organizationBasicInformation.companyAddress}
                    identity="BasicInformation"
                    handleChange={props.handleChange}
                    disabled={props.isDisabledFlag ? true : false}
                    // onBlur={(e) => props.validate("companyAddress", props.organizationBasicInformation)}
                    //disabled={props.organizationBasicInformation.organisationInformationId ? true : false}
                    autoComplete={"off"}
                  />
                </div>
                {/* <Validation error={props.validState.error.companyAddress} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Business/Mailing Address Line 2
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className={`dropdwn-input flex items-center ${props.isDisabledFlag ? 'disabled-input' : null}`}>
                  <TextInput
                    //className={`${props.organizationBasicInformation.organisationInformationId ? 'bg-[#f2f2f2]' : null}`}
                    placeholder=""
                    type="text"
                    name="companyAddress2"
                    value={props.organizationBasicInformation.companyAddress2}
                    identity="BasicInformation"
                    disabled={props.isDisabledFlag ? true : false}
                    handleChange={props.handleChange}
                    //onBlur={(e) => props.validate("companyAddress2", props.organizationBasicInformation)}
                    //disabled={props.organizationBasicInformation.organisationInformationId ? true : false}
                    autoComplete={"off"}
                  />
                </div>

              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Business/Mailing Address Line 3
                  {/* <span className="text-[#c00000]">*</span> */}
                </h2>
                <div className={`dropdwn-input flex items-center ${props.isDisabledFlag ? 'disabled-input' : null}`}>
                  <TextInput
                    //className={`${props.organizationBasicInformation.organisationInformationId ? 'bg-[#f2f2f2]' : null}`}
                    placeholder=""
                    type="text"
                    name="companyAddress3"
                    value={props.organizationBasicInformation.companyAddress3}
                    identity="BasicInformation"
                    handleChange={props.handleChange}
                    disabled={props.isDisabledFlag ? true : false}
                    //onBlur={(e) => props.validate("companyAddress3", props.organizationBasicInformation)}
                    //disabled={props.organizationBasicInformation.organisationInformationId ? true : false}
                    autoComplete={"off"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Postal Code{" "}
                  <span className="text-[#c00000]">*</span>
                </h2>
                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                  <TextInput
                    placeholder=""
                    type="number"
                    name="postalCode"
                    maxLength={6}
                    identity="BasicInformation"
                    value={props.organizationBasicInformation.postalCode}
                    handleChange={props.handleChange}
                    // onBlur={(e) => props.validate("postalCode", props.organizationBasicInformation)}
                    disabled={props.isDisabledFlag ? true : false}
                  // autoComplete={"off"}
                  />
                </div>
              </div>
            </div>
            {/* <Validation error={props.validState.error.postalCode} /> */}
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Country/Region <span className="text-[#c00000]">*</span>
                </h2>
                <MultiSelectDropdown
                  drpIdentity="Country"
                  options={props.countryList}
                  //isMulti
                  // isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  // components={{ Option }}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={false}
                  value={props.countryList.filter(country => country.value === props.organizationBasicInformation.countryId)}
                  isDisabled={props.isDisabledFlag ? true : false}
                />
              </div>
            </div>
            {/* <Validation error={props.validState.error.countryId} /> */}
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Email Address <span className="text-[#c00000]">*</span>
                </h2>
                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                  <TextInput
                    placeholder=""
                    type="email"
                    name="email"
                    value={props.organizationBasicInformation.email}
                    identity="BasicInformation"
                    handleChange={props.handleChange}
                    // onBlur={(e) => props.validate("email", props.organizationBasicInformation)}
                    disabled={props.isDisabledFlag ? true : false}
                  />
                </div>
              </div>
            </div>
            {/* <Validation error={props.validState.error.email} /> */}
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Business Contact Number{" "}
                  <span className="text-[#c00000]">*</span>
                </h2>
                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                  <TextInput
                    placeholder=""
                    type="number"
                    name="contactNo"
                    maxLength={10}
                    value={props.organizationBasicInformation.contactNo}
                    identity="BasicInformation"
                    handleChange={props.handleChange}
                    // onBlur={(e) => props.validate("contactNo", props.organizationBasicInformation)}
                    autoComplete={"off"}
                    disabled={props.isDisabledFlag ? true : false}
                  />
                </div>
              </div>
            </div>
            {/* <Validation error={props.validState.error.contactNo} /> */}
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Business Email Address <span className="text-[#c00000]">*</span>
                </h2>
                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                  <TextInput
                    placeholder=""
                    type="email"
                    name="businessEmail1"
                    value={props.organizationBasicInformation.businessEmail1}
                    identity="BasicInformation"
                    handleChange={props.handleChange}
                    disabled={props.isDisabledFlag ? true : false}
                    // onBlur={(e) => props.validate("businessEmail1", props.organizationBasicInformation)}
                  />
                </div>
              </div>
            </div>
            {/* <Validation error={props.validState.error.businessEmail1} /> */}
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Main Activities of Company <span className="text-[#c00000]">*</span>
                </h2>
                <MultiSelectDropdown
                  drpIdentity="Company_Activity"
                  options={props.companyActivity}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  // components={{ Option }}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={true}
                  isDisabled={props.isDisabledFlag ? true : false}
                  value={props.organizationBasicInformation.companyActivity}
                />
              </div>
            </div>
            {/* <Validation error={props.validState.error.companyActivity} /> */}
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Type of projects <span className="text-[#c00000]">*</span>
                </h2>
                <MultiSelectDropdown
                  drpIdentity="Project_Type"
                  options={props.typeOfProjects}
                  // isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  // components={{ Option }}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={false}
                  isDisabled={props.isDisabledFlag ? true : false}
                  value={props.typeOfProjects.find(type => type.value === props.organizationBasicInformation.projectTypeId)}
                />
              </div>
            </div>
            {/* <Validation error={props.validState.error.projectTypeId} /> */}
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Public projects from <span className="text-[#c00000]">*</span>
                </h2>
                <div className="col-span-12">
                  <div className='grid grid-cols-12 gap-4'>
                    {props.publicProjectsForms.map(
                      (item, index) =>
                        item.label !== 'Others (please specify)' ?
                          (<div className='md:col-span-4  col-span-12'>
                            <div className="flex logo-joint items-start">
                              <div className={`flex items-center register-desc pt-1 ${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                <CheckboxInput
                                  id="isPublicProjectsForm"
                                  name={item.label}
                                  value={item.value}
                                  checked={item.isChecked}
                                  disabled={props.isDisabledFlag ? true : false}
                                  handleChange={(e) => props.handleChangeInCheckbox(e, "Public_Project_Forms", index)}
                                />
                              </div>
                              <div className="pl-3 checkbox-text-part">
                                <p className="check-detail">
                                  {item.label}
                                </p>
                              </div>
                            </div>
                          </div>
                          )
                          :
                          (
                            <div className="col-span-12 mt-4">
                              <div className="flex logo-joint items-start">
                                <div className={`flex items-center register-desc pt-1 ${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                  <CheckboxInput
                                    id="isPublicProjectsForm"
                                    name={item.label}
                                    value={item?.isOthers}
                                    checked={item?.isOthers}
                                    disabled={props.isDisabledFlag ? true : false}
                                    handleChange={(e) => props.handleChangeInCheckbox(e, "Public_Project_Forms", index)}
                                  />
                                </div>
                                <div className="pl-3 chekbox-text-part">
                                  <p className="check-detail">
                                    {item.label}
                                  </p>
                                </div>
                              </div>
                              {
                                item.isOthers ?

                                  (
                                    <div className={`dropdown mt-4 ${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                      <TextInput
                                        placeholder=""
                                        type="text"
                                        name="otherProjectForm"
                                        value={props.organizationBasicInformation.otherProjectForm}
                                        identity="BasicInformation"
                                        handleChange={props.handleChange}
                                        disabled={props.isDisabledFlag ? true : false}
                                        //     handleChange={(e) => props.handleChangeInCheckbox(e, "Public_Project_Forms",index)}
                                        // onBlur={(e) => props.validate("jobTitle", props.organizationBasicInformation)}
                                        // disabled={props.organizationBasicInformation.organisationInformationId ? true : false}
                                        autoComplete={"off"}
                                      />
                                    </div>
                                  )
                                  : null
                              }
                            </div>
                          )
                    )}
                    {/* <div className='md:col-span-4  col-span-12'>
                      <div className="flex logo-joint items-start">
                        <div className="flex items-center register-desc pt-1">
                          <CheckboxInput
                            id="isPublicProjectsForm"
                            name="isLTA"
                            value={props.publicProjectsForms?.isLTA}
                            checked={props.publicProjectsForms?.isLTA}
                            handleChange={(e) => props.handleChangeInCheckbox(e, "Public_Project_Forms")}
                          />
                        </div>
                        <div className="pl-3 checkbox-text-part">
                          <p className="check-detail">
                            LTA
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='md:col-span-4  col-span-12'>
                      <div className="flex logo-joint items-start">
                        <div className="flex items-center register-desc pt-1">
                          <CheckboxInput
                            id="isPublicProjectsForm"
                            name="isPUB"
                            value={props.publicProjectsForms?.isPUB}
                            checked={props.publicProjectsForms?.isPUB}
                            handleChange={(e) => props.handleChangeInCheckbox(e, "Public_Project_Forms")}
                          />
                        </div>
                        <div className="pl-3 chekbox-text-part">
                          <p className="check-detail">
                            PUB
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='md:col-span-4  col-span-12'>
                      <div className="flex logo-joint items-start">
                        <div className="flex items-center register-desc pt-1">
                          <CheckboxInput
                            id="isPublicProjectsForm"
                            name="isJTC"
                            value={props.publicProjectsForms?.isJTC}
                            checked={props.publicProjectsForms?.isJTC}
                            handleChange={(e) => props.handleChangeInCheckbox(e, "Public_Project_Forms")}
                          />
                        </div>
                        <div className="pl-3 chekbox-text-part">
                          <p className="check-detail">
                            JTC
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='md:col-span-4  col-span-12'>
                      <div className="flex logo-joint items-start">
                        <div className="flex items-center register-desc pt-1">
                          <CheckboxInput
                            id="isPublicProjectsForm"
                            name="isMOH"
                            value={props.publicProjectsForms?.isMOH}
                            checked={props.publicProjectsForms?.isMOH}
                            handleChange={(e) => props.handleChangeInCheckbox(e, "Public_Project_Forms")}
                          />
                        </div>
                        <div className="pl-3 chekbox-text-part">
                          <p className="check-detail">
                            MOH
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='md:col-span-4  col-span-12'>
                      <div className="flex logo-joint items-start">
                        <div className="flex items-center register-desc pt-1">
                          <CheckboxInput
                            id="isPublicProjectsForm"
                            name="isMOE"
                            value={props.publicProjectsForms?.isMOE}
                            checked={props.publicProjectsForms?.isMOE}
                            handleChange={(e) => props.handleChangeInCheckbox(e, "Public_Project_Forms")}
                          />
                        </div>
                        <div className="pl-3 chekbox-text-part">
                          <p className="check-detail">
                            MOE
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-span-12 mt-4">
              <div className="flex logo-joint items-start">
                <div className="flex items-center register-desc pt-1">
                  <CheckboxInput
                    id="isPublicProjectsForm"
                    name="isOthers"
                    value={props.publicProjectsForms?.isOthers}
                    checked={props.publicProjectsForms?.isOthers}
                    handleChange={(e) => props.handleChangeInCheckbox(e, "Public_Project_Forms")}
                  />
                </div>
                <div className="pl-3 chekbox-text-part">
                  <p className="check-detail">
                    Others (please specify)
                  </p>
                </div>
              </div>
              {
                props.publicProjectsForms?.isOthers ?

                  (
                    <div className="dropdown mt-4">
                      <TextInput
                        placeholder=""
                        type="text"
                        name="otherProjectForm"
                        value={props.publicProjectsForms.otherProjectForm}
                        identity="BasicInformation"
                        handleChange={props.handleChange}
                        // onBlur={(e) => props.validate("jobTitle", props.organizationBasicInformation)}
                        // disabled={props.organizationBasicInformation.organisationInformationId ? true : false}
                        autoComplete={"off"}
                      />
                    </div>
                  )
                  : null
              }

            </div> */}
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  No. of full time staff employed (Local/PR) <span className="text-[#c00000]">*</span>
                </h2>
                <MultiSelectDropdown
                  drpIdentity="Employee_Local"
                  options={props.fulltimeEmployeeLocal}
                  // isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  // components={{ Option }}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={false}
                  isDisabled={props.isDisabledFlag ? true : false}
                  value={props.fulltimeEmployeeLocal.find(empLocal => empLocal.value === props.organizationBasicInformation.fullTimeStaffLocalId)}
                />
              </div>
            </div>
            {/* <Validation error={props.validState.error.fullTimeStaffLocalId} /> */}
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  No. of full time staff employed (Foreigners) <span className="text-[#c00000]">*</span>
                </h2>
                <MultiSelectDropdown
                  drpIdentity="Employee_Foreginrs"
                  options={props.fullTimeEmployeeForeigners}
                  // isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  // components={{ Option }}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={false}
                  isDisabled={props.isDisabledFlag ? true : false}
                  value={props.fullTimeEmployeeForeigners.find(empForeginers => empForeginers.value === props.organizationBasicInformation.fullTimeStaffForeignersId)}
                />
              </div>
            </div>
            {/* <Validation error={props.validState.error.fullTimeStaffForeignersId} /> */}
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  How do you hear about us? <span className="text-[#c00000]">*</span>
                </h2>
                <MultiSelectDropdown
                  drpIdentity="Know_About_Us"
                  options={props.knowAboutusList}
                  // isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  // components={{ Option }}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={false}
                  isDisabled={props.isDisabledFlag ? true : false}
                  value={props.knowAboutusList.find(aboutUs => aboutUs.value === props.organizationBasicInformation.knowAboutUsId)}
                />
              </div>
            </div>
            {/* <Validation error={props.validState.error.knowAboutUsId} /> */}

            {
              props && props.organizationBasicInformation.knowAboutUsId === 5 ?

                (
                  <div className={`dropdown mt-4 ${props.isDisabledFlag ? 'disabled-input' : null}`}>
                    <TextInput
                      name="hearAboutOther"
                      value={props.organizationBasicInformation.hearAboutOther}
                      identity="BasicInformation"
                      handleChange={props.handleChange}
                      disabled={props.isDisabledFlag ? true : false}
                    // onBlur={(e) => props.validate("companyName", props.organisationDetails)}
                    />
                  </div>
                )
                : null
            }
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Company Description{" "}
                  <span className="text-[#c00000]">*</span>
                </h2>
                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>

                  <textarea
                    name="companyDescription"
                    className='w-full'
                    maxLength={300}
                    placeholder=""

                    onChange={(e) => { props.handleChange(e, "BasicInformation") }}
                    value={props.organizationBasicInformation.companyDescription}
                    disabled={props.isDisabledFlag ? true : false}
                    onBlur={() => props.validate("companyDescription", props.organizationBasicInformation)}>
                  </textarea>


                </div>
                {/* <div>
                  <textarea name="companyDescription" rows={8} maxLength={300} value={props.organizationBasicInformation.companyDescription} className="w-full textareanew" ref={textRef} onChange={(e) => { onChnage(e); props.handleChange(e, "BasicInformation") }} onBlur={() => props.validate("companyDescription", props.organizationBasicInformation)}>
                    {value}
                  </textarea>
                </div> */}
                <div>

                </div>
              </div>
            </div>
            {/* <Validation error={props.validState.error.companyDescription} /> */}
            {
              (props.characterLimit - props.organizationBasicInformation.companyDescription?.length) <= 0 ?
                (
                  <div className="text-white bg-[#717171] w-[300px] rounded text-center mb-1">
                    You reach the character enter limit
                  </div>
                )
                :
                props.characterLimit - props.organizationBasicInformation.companyDescription?.length <= 250 ?
                  (
                    <div className={`mb-1 rounded text-[#ffffff] w-[80px] text-center mt-2 ${props.characterLimit - props.organizationBasicInformation.companyDescription?.length < 50 ?
                      "bg-[#C00000]"
                      : props.characterLimit -
                        props.organizationBasicInformation.companyDescription?.length <
                        100 ?
                        "bg-[#ffc107]"
                        : "bg-blue-500"}`}>
                      {props.characterLimit - props.organizationBasicInformation.companyDescription?.length}/{props.characterLimit}
                    </div>
                  )
                  :
                  null
            }
          </div>

          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Annual Turn Over{" "}
                  <span className="text-[#c00000]">*</span>
                </h2>
                <MultiSelectDropdown
                  drpIdentity="Annual_Turnover"
                  options={props.annualTurnover}
                  // isMulti
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  // components={{ Option }}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={false}
                  isDisabled={props.isDisabledFlag ? true : false}
                  value={props.annualTurnover.find(turnOver => turnOver.value === props.organizationBasicInformation.membershipRevenueId)}
                />
              </div>
            </div>
            {/* <Validation error={props.validState.error.membershipRevenueId} /> */}
          </div>

          {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Direct Office Number{" "}
                  <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="number"
                  name="directOfficeNumber"
                  maxLength={10}
                  value={props.organizationBasicInformation.directOfficeNumber}
                  identity="directOfficeNumber"
                  handleChange={props.handleChange}
                  // onBlur={(e) => props.validate("directOfficeNumber", props.organizationBasicInformation)}
                  autoComplete={"off"}
                />
              </div>
            </div>
            <Validation error={props.validState.error.directOfficeNumber} />
          </div> */}

          {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                  Mobile Number{" "}
                  <span className="text-[#c00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="number"
                  name="mobileNumber"
                  maxLength={10}
                  value={props.organizationBasicInformation.mobileNumber}
                  identity="mobileNumber"
                  handleChange={props.handleChange}
                  // onBlur={(e) => props.validate("mobileNumber", props.organizationBasicInformation)}
                  autoComplete={"off"}
                />
              </div>
            </div>
            <Validation error={props.validState.error.mobileNumber} />
          </div> */}

        </div>
        <div className='mt-5 bg-gray-box md:py-7 py-4 md:px-10 px-2 bg-[#fafafa]'>
          <div className="grid grid-cols-12 md:gap-4 gap-3 w-full justify-between mt-2">
            <div className="lg:col-span-6 col-span-12">
              <h2 className="input-title">
                Is the company registered with BCA?
                <span className="text-[#C00000]">*</span>
              </h2>
              {/* <Validation error={props.validState.error.companyScopeList} /> */}
              <div className={`flex items-center w-full ${props.isDisabledFlag ? 'disabled-input' : null} mt-3`}>
                <div className="flex items-center">
                  <RadioButton
                    id="isRegisterd"
                    name="isCompanyRegisterWithBCA"
                    value={props.organizationBasicInformation.isCompanyRegisterWithBCA}
                    checked={props.organizationBasicInformation.isCompanyRegisterWithBCA === true}
                    handleChange={(e) => props.handleRadioButtons(e, "isRegisterd")}
                    disabled={props.isDisabledFlag ? true : false}
                  />
                  <span className="ml-3">
                    Yes
                  </span>
                </div>
                <div className="flex items-center ml-10">
                  <RadioButton
                    id="isRegisterd"
                    name="isCompanyRegisterWithBCA"
                    value={props.organizationBasicInformation.isCompanyRegisterWithBCA}
                    checked={props.organizationBasicInformation.isCompanyRegisterWithBCA === false}
                    handleChange={(e) => props.handleRadioButtons(e, "isRegisterd")}
                    disabled={props.isDisabledFlag ? true : false}
                  />
                  <span className="ml-3">
                    No
                  </span>
                </div>
              </div>
            </div>
            {/* Table Section Start */}
            {
              props && props.organizationBasicInformation.isCompanyRegisterWithBCA === true ?
                (
                  <div className='col-span-12'>
                    <div className='table-section'>
                      <div className='overflow-x-auto'>
                        <table className='w-full'>
                          <thead className='bg-[#0b3382] text-white'>
                            <tr>
                              <th className='whitespace-nowrap text-left py-3 px-5'>
                                Selected Scope
                              </th>
                              <th className='whitespace-nowrap text-left py-3 px-5'>
                                Grade
                              </th>
                              <th className='whitespace-nowrap text-left py-3 px-5'>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* {props && props.organizationBasicInformation.CompanyScopeList?.map((scope, key) => {
                              return (
                                <tr className='bg-[#eeeeee] text-black border-t-4 border-[#ffffff]' key={key}>
                                  <td className='whitespace-nowrap text-left py-2 px-5'>
                                    {`${scope.mainGroup} - ${scope.workhead} `}
                                  </td>
                                  <td className='whitespace-nowrap text-left py-2 px-5'>
                                    {scope.grade}
                                  </td>
                                  <td className='whitespace-nowrap text-center py-2 px-5'>
                                    <button
                                      className='action-btn'
                                      onClick={() => props.handleRemoveScopeAndRoles(key, "Remove_Scope")}
                                    >
                                      <img className='h-[30px] ' src='../delete-icon.svg' alt='' />
                                    </button>
                                  </td>
                                </tr>
                              )
                            })} */}
                            {props && props.organizationBasicInformation.companyScopeList?.map((scope, key) => {
                              return (
                                <tr className='bg-[#eeeeee] text-black border-t-4 border-[#ffffff]'
                                  key={key}
                                >
                                  <td className='whitespace-nowrap text-left py-2 px-5'>
                                    {`${scope.mainGroup} - ${scope.workhead} `}
                                  </td>
                                  <td className='whitespace-nowrap text-left py-2 px-5'>
                                    {scope.grade}
                                  </td>
                                  <td className='whitespace-nowrap text-center py-2 px-5'>
                                    <button
                                      className={`action-btn ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                                      onClick={() => props.handleRemoveScopeAndRoles(key, "Remove_Scope")}
                                      disabled={props.isDisabledFlag ? true : false}
                                    >
                                      <img className='h-[30px] ' src='../delete-icon.svg' alt='' />
                                    </button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )
                :
                null
            }
            {/* Table Section End */}
          </div>
          {
            props && props.organizationBasicInformation.isCompanyRegisterWithBCA === true ?
              (
                <>
                  <div className="grid grid-cols-12 md:gap-10 md:gap-4 gap-3 w-full justify-between mt-10">
                    <div className="md:col-span-4 col-span-12">
                      <h2 className="input-title mb-3">
                        Main Group
                        <span className="text-[#C00000]">*</span>
                      </h2>
                      <div className="dropdown">
                        <div className="select-dropdown w-full">
                          <MultiSelectDropdown
                            drpIdentity="Main_Group"
                            options={props.mainGroupList}
                            // isMulti
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            onChange={props.handleChangeMultiDropdown}
                            allowSelectAll={false}
                            value={props.mainGroupList.filter(mainGroup => mainGroup.value === props.selectedScope.scopeMainGroupId)}
                            isDisabled={props.isDisabledFlag ? true : false}
                          />
                          <ValidationText error={props.validStateBCAScope.error.scopeMainGroupId} />
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <h2 className="input-title mb-3">
                        Workhead
                        <span className="text-[#C00000]">*</span>
                      </h2>
                      <div className="dropdown">
                        <div className="select-dropdown w-full">
                          <MultiSelectDropdown
                            drpIdentity="Workhead"
                            options={props.workheadList}
                            // isMulti
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            onChange={props.handleChangeMultiDropdown}
                            allowSelectAll={false}
                            value={props.workheadList.filter(workhead => workhead.value === props.selectedScope.scopeWorkheadId)}
                            isDisabled={props.selectedScope.scopeMainGroupId > 0 ? false : true}
                          />
                          <ValidationText error={props.validStateBCAScope.error.scopeWorkheadId} />
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <h2 className="input-title mb-3">
                        Grade
                        <span className="text-[#C00000]">*</span>
                      </h2>
                      <div className="dropdown">
                        <div className="select-dropdown w-full">
                          <MultiSelectDropdown
                            drpIdentity="Grade"
                            options={props.gradeList}
                            // isMulti
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            onChange={props.handleChangeMultiDropdown}
                            allowSelectAll={false}
                            value={props.gradeList.filter(grade => grade.value === props.selectedScope.scopeGradeId)}
                            isDisabled={props.selectedScope.scopeWorkheadId > 0 ? false : true}
                          />
                          <ValidationText error={props.validStateBCAScope.error.scopeGradeId} />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className='flex justify-end'>
                        <button
                          className={`btn btn-blue-border ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                          onClick={() => props.handleAddScopeAndRoles("Add_Scope")}
                          disabled={props.isDisabledFlag ? true : false}
                        >
                          <span className='fa fa-plus'></span>
                          <span className='ml-2'>
                            Add Scope
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <div className='grid grid-cols-12 md:gap-10 md:gap-4 gap-3 w-full justify-between mt-10'>
                    <div className="col-span-12">
                      <h2 className="input-title mb-3">
                        Project Track Record (year of completion, project name, client, project value, ongoing/completed)
                        <span className="text-[#C00000]">*</span>
                      </h2>
                      <div className="flex item-center flex-col">
                        <form className="custom-uploader custom-file-upload-btn flex">
                          <div className='border border-[#f3f3f3] btn btn-blue cursor-pointer'>
                            <label
                              htmlFor={`Upload_Document`}
                              className="text-uplod text-white cursor-pointer"
                            >
                              Upload File
                            </label>
                            <input
                              className="sr-only"
                              id={`Upload_Document`}
                              type="file"
                              onChange={(event) => props.onFileChange(event, "Upload_Document")}
                            />
                          </div>
                        </form>
                        {props.organizationBasicInformation.isValidateProjectTrackRecordReportPath ?
                          ""
                          :
                          <ValidationText error={"Please upload file"} />}
                        {
                          props && props.organizationBasicInformation.projectTrackRecordReportPath ?
                            (
                              <div className='mt-5 file-uploaded-txt flex items-center'>
                                <img src='/check.png' className='w-[25px] mr-3' alt=''></img>
                                <span className='text-[#000]'>
                                  {props.organizationBasicInformation.projectTrackRecordReportPath}
                                </span>
                              </div>
                            )
                            : null
                        }
                      </div>
                    </div>
                  </div> */}
                </>
              )
              :
              null
          }
        </div>
      </div>
      {/* Save Button */}
      <div className="grid grid-cols-12 gap-6 pb-10 mt-2">
        <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10">
          {/* <div className="right-status-label absolute 2xl:top-[9px] 2xl:right-[-20px] xl:top-[10px] xl:right-[-20px]  lg:top-[10px] lg:right-[-20px]">
              <span className={`text-xl text-white font-semibold  2xl:px-2 xl:px-2 lg:px-3 md:px-6 sm:px-4 py-3 ${value.paymentStatus === "Success" ? "bg-[#7a9763]" : "bg-[#ffae00]"}`}>
                {value.paymentStatus === "Success" ? "Paid" : value.paymentStatus}
              </span>
            </div> */}
          <div className="right-btn w-full">
            <div className="flex items-center justify-end space-x-4">
              {props.organizationBasicInformation.aCRACertificatePath && props.organizationBasicInformation.aCRACertificatePath !== "" ?
                <>
                  <button className={`2xl:px-7 lg:px-5 py-2 btn btn-blue-border text-xl border text-[#005B9C] btn-width-fix ${props.isDisabledFlag ? 'disabled-input' : null}`}
                    /// onClick={() => props.GetCorporateMemberFiles(true)}
                    onClick={() => props.GetCorporateMemberFilesDownload(true, props.organizationBasicInformation.aCRACertificatePath)} disabled={props.organizationBasicInformation.organisationInformationId ? false : true}

                  >
                    <span className="text-xl text-[#005B9C] font-semibold tracking-normal underline">
                      View ACRA File
                    </span>
                  </button>
                </>
                :
                <>
                  <div className={`ml-5 2xl:px-7 lg:px-5 py-2 btn btn-blue-border text-white btn-width-fix ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                    <form className="custom-uploader custom-file-upload-btn flex">
                      {/* <div className='border border-[#f3f3f3] cursor-pointer '> */}
                      <label
                        htmlFor={`Upload_File`} c
                        className={`${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                      // className="btn btn-blue-border text-white cursor-pointer"
                      >
                        Upload ACRA File
                      </label>
                      <input
                        className="sr-only"
                        // key={`FileQuestion${key}`}
                        id={`Upload_File`}
                        type="file"
                        onChange={(event) => props.onFileChange(event, "Upload_Certificate")}
                        disabled={props.isDisabledFlag ? true : false}
                      />
                      {/* </div> */}
                    </form>
                  </div>
                </>
              }

              {props.organizationBasicInformation.projectTrackRecordReportPath && props.organizationBasicInformation.projectTrackRecordReportPath !== "" ?
                <>
                  <button className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn-blue-border text-xl border text-[#005B9C] btn-width-fix"
                    // onClick={() => props.GetCorporateMemberFiles(false)} disabled={props.organizationBasicInformation.organisationInformationId ? false : true}>
                    onClick={() => props.GetCorporateMemberFilesDownload(false, props.organizationBasicInformation.projectTrackRecordReportPath)} disabled={props.organizationBasicInformation.organisationInformationId ? false : true}>
                    <span className="text-xl text-[#005B9C] font-semibold tracking-normal underline">
                      View Project Track Record File
                    </span>
                  </button>
                </>
                :
                <>
                  <div className={`ml-5 2xl:px-7 lg:px-5 py-2 btn btn-blue-border text-white btn-width-fix ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                    <form className="custom-uploader custom-file-upload-btn flex ">
                      {/* <div className='border border-[#f3f3f3] cursor-pointer'> */}
                      <label
                        htmlFor={`Upload_Document`}
                        className={`${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                      // className="btn btn-blue-border text-white cursor-pointer "
                      >
                        Upload Project Track Record File
                      </label>
                      <input
                        className="sr-only"
                        // key={`FileQuestion${key}`}
                        id={`Upload_Document`}
                        type="file"
                        disabled={props.isDisabledFlag ? true : false}
                        onChange={(event) => props.onFileChange(event, "Upload_Document")}
                      />
                      {/* </div> */}
                    </form>

                  </div>
                </>
              }
            </div>
            <div className="flex justify-end mb-4 space-x-28">
              <div className={`ml-5 2xl:px-7 lg:px-8`}>
                {/* <Validation error={props.validState.error.aCRACertificatePath} /> */}
              </div>
              <div className={`ml-5 2xl:px-7 lg:px-8`}>
                {/* <Validation error={props.validState.error.projectTrackRecordReportPath} /> */}
              </div>
            </div>
          </div>

          <div className="right-btn w-full">
            <div className="flex items-center justify-end space-x-4">
              {
                props && props.organizationBasicInformation.aCRACertificatePath !== null ?

                  <div className={`ml-5 2xl:px-7 lg:px-5 py-2 btn btn-blue-border text-white btn-width-fix ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                    <form className="custom-uploader custom-file-upload-btn flex">
                      {/* <div className='border border-[#f3f3f3] cursor-not-allowed'> */}
                      <label
                        htmlFor={`Upload_File`}
                        className={`${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                      // className="btn btn-blue-border text-white cursor-pointer "
                      >
                        Update ACRA File
                      </label>
                      <input
                        className="sr-only"
                        // key={`FileQuestion${key}`}
                        id={`Upload_File`}
                        type="file"
                        onChange={(event) => props.onFileChange(event, "Upload_Certificate")}
                        disabled={props.isDisabledFlag ? true : false}
                      />
                      {/* </div> */}
                    </form>
                  </div>
                  : null
              }
              {
                props && props.organizationBasicInformation.projectTrackRecordReportPath !== null ?

                  <div className={`ml-5 2xl:px-7 lg:px-5 py-2 btn btn-blue-border text-white btn-width-fix ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                    <form className="custom-uploader custom-file-upload-btn flex">
                      {/* <div className='border border-[#f3f3f3] cursor-pointer'> */}
                      <label
                        htmlFor={`Upload_Document`}
                        className={`${props.isDisabledFlag ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                      >
                        Update Project Track Record File
                      </label>
                      <input
                        className="sr-only"
                        // key={`FileQuestion${key}`}
                        id={`Upload_Document`}
                        type="file"
                        disabled={props.isDisabledFlag ? true : false}
                        onChange={(event) => props.onFileChange(event, "Upload_Document")}
                      />
                      {/* </div> */}
                    </form>
                  </div>
                  : null
              }
            </div>
            <div className="flex justify-end mb-1 space-x-28">
              {props && props.organizationBasicInformation.aCRACertificatePath !== null ?
                <div className={`ml-5 2xl:px-7 lg:px-8`}>
                  {/* <Validation error={props.validState.error.aCRACertificatePath} /> */}
                </div>
                : null
              }
              {
                props && props.organizationBasicInformation.projectTrackRecordReportPath !== null ?
                  <div className={`ml-5 2xl:px-7 lg:px-8`}>
                    {/* <Validation error={props.validState.error.projectTrackRecordReportPath} /> */}
                  </div>
                  : null
              }
            </div>
          </div>
        </div>
        <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10 ">
          {/* <a
            className="flex items-center justify-end"
          href="/CreateCorporateMembers"
          > */}
          <div className="flex items-center justify-end">
            {
              props && props.isLoading === true ?
                (
                  <ButtonLoader className="mt-4 btn btn-blue mr-5 " />
                )
                :
                (
                  <button
                    className={`w-32 px-7 py-3 btn-sm btn-blue text-xl text-white font-semibold ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                    onClick={() => props.onSave()}
                    disabled={props.isDisabledFlag && props.isDisabledFlag === true ? true : false}
                  >
                    Save
                  </button>
                )
            }
          </div>
          {/* </a> */}
        </div>
      </div>
    </div>
  );
}
export default OrganisationBasicInformations;