import {  } from '../ApiEndPoints';

export default class CommonServices {

    // async addMembershipBatchJobRecordsAfterPayment() {
    //     return axiosPostAuthorize(AddMembershipBatchJobRecordsAfterPayment);
    // }

    // async getAllBusinessSectors() {
    //     return axiosGetAuthorize(GetAllBusinessSectors);
    // }

    // async getAllChapters() {
    //     return axiosGetAuthorize(GetAllChapters);
    // }

    // async getAllCountries() {
    //     return axiosGetAuthorize(GetAllCountries);
    // }
    // async getAllStatus() {
    //     return axiosGetAuthorize(GetAllStatus);
    // }
    // async uploadPDF(request) {
    //     let formData = new FormData();
    //     formData.append("file", request.file);
    //     formData.append("filename", request.filename);
    //     formData.append("storagePath", request.storagePath);
    //     return axiosPostWithoutEncryption(uploadPDF, formData, true);
    // }

    // async storeBase64Image(request) {
    //     return axiosPostWithoutEncryption(StoreBase64Image, request, false);
    // }

}