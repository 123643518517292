import React, { Component } from "react";
import SectionWithBorder from "../../../components/UIComponent/SectionBorder";
import Row3Input from "../../../components/membership/membershipPricing/Row3Input";
import MembershipPricingServices from "../../../services/axiosServices/apiServices/MembershipPricingServices";
import { NavLink } from "react-router-dom";
import SwalServices from "../../../services/swalServices/SwalServices";
import {
    isValidForm,
    validate,
} from "../../../utils/validation/CommonValidator";
import { CommonValidationMessages } from "../../../utils/Messages";
// import { getAuthProps } from "../../../utils/AuthenticationLibrary";

export default class StudentPricing extends Component {
    constructor(props) {
        super(props);
        this.membershipPricingServices = new MembershipPricingServices();
        this.swalServices = new SwalServices();

        this.state = {
            studentPricingTable: {
                MembershipStudentPricingId: 0,
                studentMembershipFee: 0,
            },

            validationRule: {
                studentMembershipFee: [
                    {
                        type: "number",
                        message: CommonValidationMessages.FieldRequired.replace(
                            "{0}",
                            "only numeric values"
                        ),
                    },
                ],
            },
            validState: {
                isValid: true,
                error: {},
            },
        };
    }
    handleChange(event) {
        const value = event.target.value;
        let num = parseFloat(value).toFixed(0);
        const name = event.target.name;
        let detail = this.state.studentPricingTable;
        detail[name] = num;
        this.setState({ studentPricingTable: { ...detail } });
    }

    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.studentPricingTable,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        console.log("isValidateAllFields");
        const newValidState = isValidForm(
            this.state.studentPricingTable,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };

    addEditStudentMembershipPricing = () => {
      
        let isValid = this.isValidateAllFields();
        if (isValid) {
            let request = this.state.studentPricingTable
            this.membershipPricingServices
                .addEditStudentMembershipPricing(request)
                .then((response) => {
                    if (response.statusCode === 200 && response.responseItem != null) {
                        this.swalServices.Success(
                            "Membership Student Pricing Updated Successfully."
                        );
                    } else {
                        this.swalServices.Error(response.message);
                    }
                });
        }
    };
    getStudentMembershipPricing = () => {
        this.membershipPricingServices.getStudentMembershipPricing().then((response) => {
            if ((response.statusCode === 200 && response.responseItem !== null)) {
                // this.swalServices.Success("Success");
                if (response.responseItem.responseContent !== null) {
                    this.setState({
                        studentPricingTable: response.responseItem.responseContent,
                    });
                }

            } else {
                this.swalServices.Error(response.message);
            }
        });
    };
    componentDidMount() {
        this.getStudentMembershipPricing();
    }

    render() {
        return (
            <>
                <div className="main-body-section">
                    <div className="bg-white">
                        <div className="heading-part pl-7 py-4 border-b">
                            <div className="flex items-center justify-between">
                                <h4 className="small-title font-bold theme-color">
                                    Student Pricing Table
                                </h4>
                            </div>
                        </div>
                        <div className="custom-card shadow-lg mb-10">
                            <div className="">
                                <SectionWithBorder
                                    totalColumn={2}
                                    column1="Fees"
                                    column2="Student Member"
                                >
                                    <div className="sign-icon-input px-8">
                                        <div className="pr-24">
                                            <Row3Input
                                                totalColumn={2}
                                                className="text-base text-[#000000]"
                                                caption="Student Membership Fee (One-Time)"
                                                col1InputType="Money"
                                                col1FieldName="studentMembershipFee"

                                                col1value={this.state.studentPricingTable.studentMembershipFee}
                                                handleChange={this.handleChange.bind(this)}
                                                validateField={this.validateField.bind(this)}
                                                validState={this.state.validState}
                                                error1={this.state.validState.error.studentMembershipFee}
                                            />
                                        </div>
                                    </div>
                                </SectionWithBorder>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between">
                        <NavLink to="/Dashboard" className="btn btn-gray text-xl text-white">
                            {" "}
                            Back
                        </NavLink>
                        <button
                            className="text-lg font-bold btn btn-blue text-white"
                            onClick={() => this.addEditStudentMembershipPricing()}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </>
        );
    }
}