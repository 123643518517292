import React, { Component } from "react";
import Row3Input from "../../../components/membership/membershipPricing/Row3Input";
import MembershipPricingServices from "../../../services/axiosServices/apiServices/MembershipPricingServices";
import { NavLink } from "react-router-dom";
import SwalServices from "../../../services/swalServices/SwalServices";
import {
  isValidForm,
  validate,
} from "../../../utils/validation/CommonValidator";
import { CommonValidationMessages } from "../../../utils/Messages";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";

export default class MembershipPricing extends Component {
  constructor(props) {
    super(props);
    this.membershipPricingServices = new MembershipPricingServices();
    this.swalServices = new SwalServices();

    this.state = {
      membershipPricing: {
        membershipPricingId: 0,
        ordinaryApplicationFee: 0,
        associateApplicationFee: 0,
        tradeApplicationFee: 0,
        individualApplicationFee: 0,
        studentApplicationFee: 0,
        annualOrdinaryRevenueFee: 0,
        annualAssociateRevenueFee: 0,
        annualTradeRevenueFee: 0,
        annualIndividualRevenueFee: 0,
        annualStudentRevenueFee: 0,
      },
      validationRule: {
        ordinaryApplicationFee: [
          {
            type: "floatNumber",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        associateApplicationFee: [
          {
            type: "floatNumber",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        tradeApplicationFee: [
          {
            type: "floatNumber",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        individualApplicationFee: [
          {
            type: "floatNumber",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        studentApplicationFee: [
          {
            type: "floatNumber",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualOrdinaryRevenueFee: [
          {
            type: "floatNumber",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualAssociateRevenueFee: [
          {
            type: "floatNumber",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualTradeRevenueFee: [
          {
            type: "floatNumber",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualIndividualRevenueFee: [
          {
            type: "floatNumber",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
        annualStudentRevenueFee: [
          {
            type: "floatNumber",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "only numeric values"
            ),
          },
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
    };
  }
  handleChange(event) {
    const value = event.target.value;
    // var num = parseFloat(value).toFixed(2);
    const name = event.target.name;
    const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/)
    if (validated) {
      let detail = this.state.membershipPricing;
      detail[name] = parseFloat(value);
      this.setState({ membershipPricing: { ...detail } });
    }
  }

  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.membershipPricing,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateAllFields = () => {
    console.log("isValidateAllFields");
    const newValidState = isValidForm(
      this.state.membershipPricing,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  addMembershipPricing = () => {
  
    let isValid = this.isValidateAllFields();
    if (isValid) {
      this.membershipPricingServices
        .addEditMembershipPricing(this.state.membershipPricing)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.swalServices.Success(
              "Membership pricing updated successfully."
            );
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };
  getMembershipPricing = () => {
    this.membershipPricingServices.getMembershipPricing().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        // this.swalServices.Success("Success");
        this.setState({
          membershipPricing: response.responseItem.responseContent,
        });
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };
  componentDidMount() {
    // For Set Created by based on admin login
    let cookie = getAuthProps();
    const adminUserId = (cookie && cookie.adminUserId) ? Number(cookie.adminUserId) : 0;
    if (adminUserId > 0) {
      this.setState({ createdBy: adminUserId });
    }
    // END
    this.getMembershipPricing();
  }

  render() {
    return (
      <>
        <div className="main-body-section">
          <div className="bg-white">
            <div className="heading-part pl-7 py-4 border-b">
              <div className="flex items-center justify-between">
                <h4 className="small-title font-bold theme-color">
                  Membership Pricing Table
                </h4>
              </div>
            </div>
            <div className="custom-card shadow-lg mb-10">
              <div className="">
                <div className="border pb-3 border-[#e0e0e0] border-solid border-section-main">
                  {/* <SectionWithBorder
                    totalColumn={4}
                    column1="Fees"
                    column2="Ordinary Membership (OM)"
                    column3="Associate Member (AM)"
                    column4="Trade Member (TM)"
                  > */}
                  <div className={`grid grid-cols-12 gap-6 h-[50px] bg-zinc-100 w-full items-center xl:pr-24 pr-8 pl-4 responsive-member-price-table`}>
                    <div className="col-span-3">
                      <h2 className="text-[#000000]">Fees</h2>
                    </div>
                    <div className="col-span-3">
                      <h2 className="text-[#000000]">Ordinary Membership (OM)</h2>
                    </div>
                    <div className="col-span-3">
                      <h2 className="text-[#000000]">Associate Member (AM)</h2>
                    </div>
                    <div className="col-span-3">
                      <h2 className="text-[#000000]">Trade Member (TM)</h2>
                    </div>
                  </div>
                  <div className="sign-icon-input pl-4 xl:pr-24 pr-8">
                    <div className="">
                      <Row3Input
                        totalColumn={4}
                        className="text-base text-[#000000]"
                        caption="Application Fee (One-Time)"
                        col1InputType="Money"
                        col2InputType="Money"
                        col3InputType="Money"
                        col1FieldName="ordinaryApplicationFee"
                        col2FieldName="associateApplicationFee"
                        col3FieldName="tradeApplicationFee"
                        col1value={this.state.membershipPricing.ordinaryApplicationFee}
                        col2value={this.state.membershipPricing.associateApplicationFee}
                        col3value={this.state.membershipPricing.tradeApplicationFee}
                        handleChange={this.handleChange.bind(this)}
                        validateField={this.validateField.bind(this)}
                        validState={this.state.validState}
                        error1={this.state.validState.error.ordinaryApplicationFee}
                        error2={this.state.validState.error.associateApplicationFee}
                        error3={this.state.validState.error.tradeApplicationFee}
                      />
                    </div>
                  </div>
                  {/* </SectionWithBorder> */}
                </div>

                <div className="annual-member-fee">
                  <div className="border pb-3 border-[#e0e0e0] border-solid border-section-main">
                    {/* <SectionWithBorder
                    totalColumn={3}
                    column1="Annual Membership Fee"
                  > */}
                    <div className={`grid grid-cols-12 gap-6 h-[50px] bg-zinc-100 w-full items-center xl:pr-24 pr-8 pl-4 responsive-member-price-table`}>
                      <div className="col-span-12">
                        <h2 className="text-[#000000]">Annual Membership Fee</h2>
                      </div>
                    </div>
                    <h2 className="text-base pl-4 mt-10 text-zinc-500">
                      Tier
                    </h2>
                    <div className="pl-4 xl:pr-24 pr-8">
                      <div className="">
                        <Row3Input
                          totalColumn={4}
                          className="text-base text-[#000000]"
                          caption="Revenue"
                          col1InputType="Money"
                          col2InputType="Money"
                          col3InputType="Money"
                          col1FieldName="annualOrdinaryRevenueFee"
                          col2FieldName="annualAssociateRevenueFee"
                          col3FieldName="annualTradeRevenueFee"
                          col1value={this.state.membershipPricing.annualOrdinaryRevenueFee}
                          col2value={this.state.membershipPricing.annualAssociateRevenueFee}
                          col3value={this.state.membershipPricing.annualTradeRevenueFee}
                          handleChange={this.handleChange.bind(this)}
                          validateField={this.validateField.bind(this)}
                          validState={this.state.validState}
                          error1={this.state.validState.error.annualOrdinaryRevenueFee}
                          error2={this.state.validState.error.annualAssociateRevenueFee}
                          error3={this.state.validState.error.annualTradeRevenueFee}
                        />

                        {/* <Row3Input
                          totalColumn={4}
                          className="text-base text-[#000000]"
                          caption="Revenue from $1M to $10M"
                          col1InputType="Money"
                          col2InputType="Money"
                          col3InputType="Money"
                          col1FieldName="annualOrdinaryFee10M"
                          col2FieldName="annualAssociateFee10M"
                          col3FieldName="annualAssociateFee10M"
                          col1value={this.state.membershipPricing.annualOrdinaryFee10M}
                          col2value={this.state.membershipPricing.annualAssociateFee10M}
                          col3value={this.state.membershipPricing.annualAssociateFee10M}
                          handleChange={this.handleChange.bind(this)}
                          validateField={this.validateField.bind(this)}
                          validState={this.state.validState}
                        // error1={this.state.validState.error.annualOrdinaryFee10M}
                        // error2={this.state.validState.error.annualAssociateFee10M}
                        /> */}

                        {/* <Row3Input
                          totalColumn={4}
                          className="text-base text-[#000000]"
                          caption="Revenue <$10M to $30M"
                          col1InputType="Money"
                          col2InputType="Money"
                          col3InputType="Money"
                          col1FieldName="annualOrdinaryFee30M"
                          col2FieldName="annualAssociateFee30M"
                          col3FieldName="annualAssociateFee30M"
                          col1value={this.state.membershipPricing.annualOrdinaryFee30M}
                          col2value={this.state.membershipPricing.annualAssociateFee30M}
                          col3value={this.state.membershipPricing.annualAssociateFee30M}
                          handleChange={this.handleChange.bind(this)}
                          validateField={this.validateField.bind(this)}
                          validState={this.state.validState}
                        // error1={this.state.validState.error.annualOrdinaryFee30M}
                        // error2={this.state.validState.error.annualAssociateFee30M}
                        /> */}
                        {/* <Row3Input
                          totalColumn={4}
                          className="text-base text-[#000000]"
                          caption="Revenue <$30M to $50M"
                          col1InputType="Money"
                          col2InputType="Money"
                          col3InputType="Money"
                          col1FieldName="annualOrdinaryFee50M"
                          col2FieldName="annualAssociateFee50M"
                          col3FieldName="annualAssociateFee50M"
                          col1value={this.state.membershipPricing.annualOrdinaryFee50M}
                          col2value={this.state.membershipPricing.annualAssociateFee50M}
                          col3value={this.state.membershipPricing.annualAssociateFee30M}
                          handleChange={this.handleChange.bind(this)}
                          validateField={this.validateField.bind(this)}
                          validState={this.state.validState}
                        // error1={this.state.validState.error.annualOrdinaryFee50M}
                        // error2={this.state.validSate.error.annualAssociateFee50M}
                        /> */}
                        {/* <Row3Input
                          totalColumn={4}
                          className="text-base text-[#000000]"
                          caption="Revenue >$50M"
                          col1InputType="Money"
                          col2InputType="Money"
                          col3InputType="Money"
                          col1FieldName="annualOrdinaryFeeMax"
                          col2FieldName="annualAssociateFeeMax"
                          col3FieldName="annualAssociateFeeMax"
                          col1value={this.state.membershipPricing.annualOrdinaryFeeMax}
                          col2value={this.state.membershipPricing.annualAssociateFeeMax}
                          col3value={this.state.membershipPricing.annualAssociateFeeMax}
                          handleChange={this.handleChange.bind(this)}
                          validateField={this.validateField.bind(this)}
                          validState={this.state.validState}
                        // error1={this.state.validState.error.annualOrdinaryFeeMax}
                        // error2={this.state.validState.error.annualAssociateFeeMax}
                        /> */}
                      </div>
                    </div>
                    {/* </SectionWithBorder> */}
                  </div>
                </div>
                <div className="">
                </div>
              </div>

              <div className="">
                <div className="border pb-3 border-[#e0e0e0] border-solid border-section-main">
                  {/* <SectionWithBorder
                  totalColumn={3}
                  column1="Fees"
                  column2="Individual Member (IM)"
                  column3="Student"
                > */}
                  <div className={`grid grid-cols-12 gap-6 h-[50px] bg-zinc-100 w-full items-center xl:pr-24 pr-8 pl-4 responsive-member-price-table`}>
                    <div className="col-span-4">
                      <h2 className="text-[#000000]">Fees</h2>
                    </div>
                    <div className="col-span-4">
                      <h2 className="text-[#000000]">Individual Member (IM)</h2>
                    </div>
                    <div className="col-span-4">
                      <h2 className="text-[#000000]">Student</h2>
                    </div>
                  </div>
                  <div className="sign-icon-input pl-4 xl:pr-24 pr-8">
                    <div className="">
                      <Row3Input
                        totalColumn={3}
                        className="text-base text-[#000000]"
                        caption="Application Fee (One-Time)"
                        col1InputType="Money"
                        col2InputType="Money"
                        col1FieldName="individualApplicationFee"
                        col2FieldName="studentApplicationFee"
                        col1value={this.state.membershipPricing.individualApplicationFee}
                        col2value={this.state.membershipPricing.studentApplicationFee}
                        handleChange={this.handleChange.bind(this)}
                        validateField={this.validateField.bind(this)}
                        validState={this.state.validState}
                        error1={this.state.validState.error.individualApplicationFee}
                        error2={this.state.validState.error.studentApplicationFee}
                      />
                    </div>
                  </div>
                  {/* </SectionWithBorder> */}
                </div>
                <div className="annual-member-fee">
                  <div className="border pb-3 border-[#e0e0e0] border-solid border-section-main">
                    {/* <SectionWithBorder
                    totalColumn={3}
                    column1="Annual Membership Fee"
                  > */}

                    <div className={`grid grid-cols-12 gap-6 h-[50px] bg-zinc-100 w-full items-center xl:pr-24 pr-8 pl-4 responsive-member-price-table`}>
                      <div className="col-span-12">
                        <h2 className="text-[#000000]">Annual Membership Fee</h2>
                      </div>
                    </div>
                    <h2 className="text-base pl-4 mt-10 text-zinc-500">
                      Tier
                    </h2>
                    <div className="pl-4">
                      <div className="xl:pr-24 pr-8">
                        <Row3Input
                          totalColumn={3}
                          className="text-base text-[#000000]"
                          caption="Revenue"
                          col1InputType="Money"
                          col2InputType="Money"
                          col1FieldName="annualIndividualRevenueFee"
                          col2FieldName="annualStudentRevenueFee"
                          col1value={this.state.membershipPricing.annualIndividualRevenueFee}
                          col2value={this.state.membershipPricing.annualStudentRevenueFee}
                          handleChange={this.handleChange.bind(this)}
                          validateField={this.validateField.bind(this)}
                          validState={this.state.validState}
                          error1={this.state.validState.error.annualIndividualRevenueFee}
                          error2={this.state.validState.error.annualStudentRevenueFee}
                        />

                        {/* <Row3Input
                          totalColumn={3}
                          className="text-base text-[#000000]"
                          caption="Revenue from $1M to $10M"
                          col1InputType="Money"
                          col2InputType="Money"
                          col1FieldName="annualIndividualFee10M"
                          col2FieldName="annualStudentFee10M"
                          col1value={this.state.membershipPricing.annualIndividualFee10M}
                          col2value={this.state.membershipPricing.annualStudentFee10M}
                          handleChange={this.handleChange.bind(this)}
                          validateField={this.validateField.bind(this)}
                          validState={this.state.validState}
                        // error1={this.state.validState.error.annualIndividualFee10M}
                        // error2={this.state.validState.error.annualStudentFee10M}
                        /> */}

                        {/* <Row3Input
                          totalColumn={3}
                          className="text-base text-[#000000]"
                          caption="Revenue <$10M to $30M"
                          col1InputType="Money"
                          col2InputType="Money"
                          col1FieldName="annualIndividualFee30M"
                          col2FieldName="annualStudentFee30M"
                          col1value={this.state.membershipPricing.annualIndividualFee30M}
                          col2value={this.state.membershipPricing.annualStudentFee30M}
                          handleChange={this.handleChange.bind(this)}
                          validateField={this.validateField.bind(this)}
                          validState={this.state.validState}
                        // error1={this.state.validState.error.annualIndividualFee30M}
                        // error2={this.state.validState.error.annualStudentFee30M}
                        /> */}

                        {/* <Row3Input
                          totalColumn={3}
                          className="text-base text-[#000000]"
                          caption="Revenue <$30M to $50M"
                          col1InputType="Money"
                          col2InputType="Money"
                          col1FieldName="annualIndividualFee50M"
                          col2FieldName="annualStudentFee50M"
                          col1value={this.state.membershipPricing.annualIndividualFee50M}
                          col2value={this.state.membershipPricing.annualStudentFee50M}
                          handleChange={this.handleChange.bind(this)}
                          validateField={this.validateField.bind(this)}
                          validState={this.state.validState}
                        // error1={this.state.validState.error.annualIndividualFee50M}
                        // error2={this.state.validSate.error.annualStudentFee50M}
                        /> */}

                        {/* <Row3Input
                          totalColumn={3}
                          className="text-base text-[#000000]"
                          caption="Revenue >$50M"
                          col1InputType="Money"
                          col2InputType="Money"
                          col1FieldName="annualIndividualFeeMax"
                          col2FieldName="annualStudentFeeMax"
                          col1value={this.state.membershipPricing.annualIndividualFeeMax}
                          col2value={this.state.membershipPricing.annualStudentFeeMax}
                          handleChange={this.handleChange.bind(this)}
                          validateField={this.validateField.bind(this)}
                          validState={this.state.validState}
                        // error1={this.state.validState.error.annualIndividualFeeMax}
                        // error2={this.state.validState.error.annualStudentFeeMax}
                        /> */}
                      </div>
                    </div>
                    {/* </SectionWithBorder> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <NavLink to="/Dashboard" className="btn btn-gray text-xl text-white">
              {" "}
              Back
            </NavLink>
            <button
              className="text-lg font-bold btn btn-blue text-white"
              onClick={() => this.addMembershipPricing()}
            >
              Save
            </button>
          </div>
        </div>
      </>
    );
  }
}