import React from "react";
import ModalBasic from "../../component/ModalBasic";

const SendPaymentLink = (props) => {
    return (
        <ModalBasic
            id="send-payment-link-modal"
            modalOpen={props.paymentLinkModal}
            setModalOpen={props.setOpenModal}
            title=""
            noCloseIcon={true}
        >
            <div className="text-center pb-2">
                <h1 className="text-4xl text-[#005B9C] font-bold leading-loose py-4">
                    Payment Link Sent
                </h1>
                <h1 className="text-xl text-[#000000] font-normal leading-loose p-8 py-4">
                    {/* Email has been sent to applicant's company business email {props.email}. */}
                    Payment Link has been sent successfully.
                </h1>
                <div className="text-center">
                    <button
                        className="btn btn-blue text-base text-[#fff] font-bold mt-8"
                        onClick={props.onClose}
                    >
                        OK
                    </button>
                </div>
            </div >
        </ModalBasic>
    );
}
export default SendPaymentLink;