import React from "react";
const RadioButton = (props) => {
    return (
        <div className={`signup-radio`}>
            <input
                type="radio"
                name={props.name}
                id={props.id}
                className={`form-radio ${props.className ? props.className : "form-radio-blue"} ${props.isDisabled ? 'bg-[#e9e9ea]' : '' }`}
                value={props.value}
                onChange={(event) => props.handleRadioButtons(event)}
                checked={props.checked}
                disabled={props.isDisabled ? true : false}
            />
        </div>
    );
};
export default RadioButton;