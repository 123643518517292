export const multiselectDropdownCustomStyles = {
    control: (base, isFocused, isSelected) => ({
        ...base,
        // match with the menu
        borderRadius: "0",
        // Removes weird border around container
        boxShadow: null,
    }),
    menu: (base, isSelected, isFocused) => ({
        ...base,
        // override border radius to match the box
        borderRadius: 0,
        // kill the gap
        marginTop: 0,
    }),
    option: (styles, { isFocused, isSelected, isUnchecked }) => ({
        ...styles,
        background: isUnchecked ? 'rgba(255, 255, 255, 1)' : isSelected ? 'rgba(255, 255, 255, 1)' : undefined,
        color: isSelected ? 'rgba(0, 0, 0, 1)' : undefined,
        zIndex: 1
    }),
    menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0,
    })
}