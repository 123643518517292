import { CommonValidationMessages } from "../../../utils/Messages";

// CONQUAS Member Validation Rules
export const CONQUASMemberValidationRules = {

    conquasLevelId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "Level"
            ),
        },
    ],
    nameOnCertificate: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "nameOnCertificate"
            ),
        },
    ],
    firstName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "First Name"
            ),
        },
    ],
    lastName: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Last Name"
            ),
        },
    ],
    dateOfBirth: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "date of birth"),
        },
        {
            type: "isMaxDate",
            message: " date of birth must be earlier than today's date",
        },
        {
            type: "isMinDate",
            message: "Please check your date of birth ",
        },
    ],
    identificationNo: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Identification No"
            ),
        },
    ],
    isSexMale: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "Sex"
            ),
        },
    ],
    homeAddressLine1: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Home Address Line 1"
            ),
        },
    ],
    postalCode: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Postal Code"
            ),
        },
    ],
    countryId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "Country"
            ),
        },
    ],
    contactNumberHome: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Contact Number (Home)"
            ),
        },
    ],
    contactNumberOffice: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Contact Number (Office)"
            ),
        },
    ],
    mobileNumber: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Mobile Number"
            ),
        },
    ],
    email: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Email Address"
            ),
        },
        {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
        },
    ],
    countryOfBirth: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "Country of Birth"
            ),
        },
    ],
    conquasNationalityId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "Nationality"
            ),
        },
    ],
    fullFacePhoto: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "Upload Full Face Photo/Passport Photo"
            ),
        },
    ],
    nameOfCurrentEmployer: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Name of Current Employer"
            ),
        },
    ],
    officeAddress: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Office Address"
            ),
        },
    ],
   
    designation: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Designation"
            ),
        },
    ],
    dateJoinedCompany: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "Date Joined Company"
            ),
        },
        {
            type: "isMaxDate",
            message: " date of birth must be earlier than today's date",
        },
        {
            type: "isMinDate",
            message: "Please check your date of birth ",
        },
    ],
}

export const CONQUASAcademicQualificationsValidationRules = {
    nameOfInstitution: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Name of Institution"
            ),
        },
    ],
    academicProfessionalQualificationsId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "Academic/Professional Qualifications"
            ),
        },
    ],
    durationOfCourseStartDate: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "Start Date"
            ),
        },
        {
            type: "isMaximumDate",
            message: "course start date must be earlier than 31 Dec 9999",
        },
        {
            type: "isMinDate",
            message: "Please check your course start date",
        },
    ],
    durationOfCourseEndDate: [ 
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "End Date"
            ),
        },
        {
            type: "isMaximumDate",
            message: "course end date must be earlier than 31 Dec 9999",
        },
        {
            type: "isMinDate",
            message: "Please check your course end date",
        },
    ],
    academicQualificationsAndCourseCertificate: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "Academic Qualifications and Course Certificates"
            ),
        },
    ],
}

export const CONQUASTrainingWorkshopValidationRules = {
    nameOfInstitution: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Name of Institution"
            ),
        },
    ],
 
    durationOfCourseStartDate: [
        {
            type: "require",
            message:CommonValidationMessages.SelectRequired.replace("{0}", "course start date"),
        },
        {
            type: "isMaximumDate",
            message: "course start date must be earlier than 31 Dec 9999",
        },
        {
            type: "isMinDate",
            message: "Please check your course start date",
        },
    ],
    durationOfcourseEndDate: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "course end date"),
        },
        {
            type: "isMaximumDate",
            message: "course end date must be earlier than 31 Dec 9999",
        },
        {
            type: "isMinDate",
            message: "Please check your course end date",
        },
    ],
    conquasTrainingWorkshopForId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "CONQUAS Training Workshop"
            ),
        },
    ],
    academicQualificationAndCourseCertificates: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "Upload Academic Qualifications and Course Certificates"
            ),
        },
    ],
}

export const CONQUASGIPWorkshopValidationRules = {
    nameOfInstitution: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Name of Institution"
            ),
        },
    ],
   
    durationOfCourseStartDate: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "course start date"),
        },
        {
            type: "isMaximumDate",
            message: "course start date must be earlier than 31 Dec 9999",
        },
        {
            type: "isMinDate",
            message: "Please check your course start date",
        },
    ],
    durationOfcourseEndDate: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "course end date"),
        },
        {
            type: "isMaximumDate",
            message: "course end date must be earlier than 31 Dec 9999",
        },
        {
            type: "isMinDate",
            message: "Please check your course end date",
        },
    ],
    conquasgipWorkshopForId: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "CONQUAS GIP Workshop"
            ),
        },
    ],
    academicQualificationsAndCourseCertificate: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "Upload Academic Qualifications and Course Certificates"
            ),
        },
    ],
};

export const CONQUASSupervisionExperienceValidationRules = {
    nameOfProject: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Name of Project"
            ),
        },
    ],
    costOfProject: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Cost of Project"
            ),
        },
    ],
    titleOfPosition: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Title of Position"
            ),
        },
    ],
    dateFrom: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "Date From"
            ),
        },
        {
            type: "isMaximumDate",
            message: "from date must be earlier than 31 Dec 9999",
        },
        {
            type: "isMinDate",
            message: "Please check your  from date",
        },
    ],
    dateTo: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "Date To"
            ),
        },
        {
            type: "isMaximumDate",
            message: "from date must be earlier than 31 Dec 9999",
        },
        {
            type: "isMinDate",
            message: "Please check your course to date",
        },
    ],
    nameOfEmployer: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Name of Employer"
            ),
        },
    ],
    addressOfEmployer: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Address of Employer"
            ),
        },
    ],
    conquasScoreOfProject: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "CONQUAS Score of Project"
            ),
        },
    ],
    academicQualificationsAndCourseCertificates: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "Upload Academic Qualifications and Course Certificates"
            ),
        },
    ],
    lettersOfEmployment: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}", 
                "letter of employment"
                ),
        },
    ],
    descriptionOfProject: [
        {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
                "{0}",
                "Description of Project"
            ),
        },
    ],
    isQMProject: [
        {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
                "{0}",
                "QM Project? (Y/N)"
            ),
        },
    ],
};