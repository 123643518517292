import React, { Component } from 'react'
import TextInput from '../../components/inputs/TextInput'
import RadioButton from '../../components/membership/membershipTaskList/RadioButton'
import DatePicker from '../../components/datePicker/DatePicker'
import ImageCropperWithUpload from '../../components/ImageUpload/UploadImage'

export default class CpasInterview extends Component {
  render() {
    return (
      <div>
        <div className="main-body-section">
          <div className="bg-white px-8 pt-10 pb-12">
            <div className="grid grid-cols-12 gap-6 w-full">
              <div className='2xl:col-span-12 lg:col-span-12 sm:col-span-12 col-span-12'>
                <div className=''>
                  <span className='2xl:text-[21px] lg:text-[21px] md:text-[21px] sm:text-[21px] text-[18px] font-bold'>
                    Batch: 1230
                  </span>
                  <p className='2xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[14px] text-[14px]'>We are currently assigning the interview for the following applicants.</p>
                </div>
              </div>

            </div>

            <div className="grid grid-cols-12 gap-6 w-full mt-5">
              <div className='2xl:col-span-12 lg:col-span-12 sm:col-span-12 col-span-12'>
                <div className='send-interview-package'>
                  <div className="overflow-x-auto">
                    <table className="w-full bg-white shadow-lg rounded-sm  border-slate-200">
                      <thead className="table-bg text-xs font-semibold capitalize text-[#000000] border-t border-b border-slate-200 ">
                        <tr>
                          <th className="px-4 first:pl-5 last:pr-5 tbl-th whitespace-nowrap  font-bold tble-th-txt text-left  w-[15%] border-r border-[#ffffff]  bg-[#D9D9D9] text-[#2D2D2D]">
                            Name
                          </th>
                          <th className="px-4 first:pl-5 last:pr-5 tbl-th whitespace-nowrap  font-bold tble-th-txt text-left w-[20%] border-r border-[#ffffff]  bg-[#D9D9D9] text-[#2D2D2D]">
                            Email
                          </th>
                          <th className="px-4 first:pl-5 last:pr-5 tbl-th whitespace-nowrap  font-bold tble-th-txt text-left w-[30%] border-r border-[#ffffff]  bg-[#D9D9D9] text-[#2D2D2D]">
                            Tier
                          </th>
                          <th className="px-4 first:pl-5 last:pr-5 tbl-th whitespace-nowrap font-bold tble-th-txt text-center w-[10%] border-r border-[#ffffff]  bg-[#D9D9D9] text-[#2D2D2D]">
                            Submission Date
                          </th>
                          <th className="px-4 first:pl-5 last:pr-5 tbl-th whitespace-nowrap font-bold tble-th-txt text-center w-[10%] border-r border-[#ffffff]  bg-[#D9D9D9] text-[#2D2D2D]">
                            Interview Date
                          </th>
                          <th className="px-4 first:pl-5 last:pr-5 tbl-th whitespace-nowra font-bold tble-th-txt text-left w-[15%] border-r border-[#ffffff]  bg-[#D9D9D9] text-[#2D2D2D]">
                            Interview Time
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm custom-tbody">
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[15%] tble-td-txt text-[#000000] ">
                            John Lim
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[20%] tble-td-txt text-[#000000] ">
                            john@gmail.com
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[30%] tble-td-txt text-[#000000] ">
                            Accredited Construction Professional A-Star
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[10%] tble-td-txt text-[#000000] ">
                            10 Dec 2023
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[10%] tble-td-txt text-[#000000] ">
                            3 Jan 2024
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[15%] tble-td-txt text-[#000000] ">
                            2.30pm
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[15%] tble-td-txt text-[#000000] ">
                            Alex Tan
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[20%] tble-td-txt text-[#000000] ">
                            alex@gmail.com
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[30%] tble-td-txt text-[#000000] ">
                            Accredited Construction Professional A-Star
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[10%] tble-td-txt text-[#000000] ">
                            10 Dec 2023
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[10%] tble-td-txt text-[#000000] ">
                            3 Jan 2024
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[15%] tble-td-txt text-[#000000] ">
                            3.00pm
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[15%] tble-td-txt text-[#000000] ">
                            Marcus Yeo
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[20%] tble-td-txt text-[#000000] ">
                            marcus@gmail.com
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[30%] tble-td-txt text-[#000000] ">
                            Accredited Construction Professional A-Star
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[10%] tble-td-txt text-[#000000] ">
                            10 Dec 2023
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[10%] tble-td-txt text-[#000000] ">
                            3 Jan 2024
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[15%] tble-td-txt text-[#000000] ">
                            3.30pm
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className='2xl:col-span-12 lg:col-span-12 sm:col-span-12 col-span-12'>
                <div className='send-interview-package lg:pt-7'>
                  <div className="overflow-x-auto">
                    <table className="w-full bg-white shadow-lg rounded-sm  border-slate-200">
                      <thead className="table-bg text-xs font-semibold capitalize text-[#000000] border-t border-b border-slate-200 ">
                        <tr>
                          <th className="px-4 first:pl-5 last:pr-5 tbl-th whitespace-nowrap  font-bold tble-th-txt text-left  w-[15%] border-r border-[#ffffff]  bg-[#D9D9D9] text-[#2D2D2D]">
                            Role
                          </th>
                          <th className="px-4 first:pl-5 last:pr-5 tbl-th whitespace-nowrap  font-bold tble-th-txt text-left w-[15%] border-r border-[#ffffff]  bg-[#D9D9D9] text-[#2D2D2D]">
                            Name
                          </th>
                          <th className="px-4 first:pl-5 last:pr-5 tbl-th whitespace-nowrap  font-bold tble-th-txt text-left w-[25%] border-r border-[#ffffff]  bg-[#D9D9D9] text-[#2D2D2D]">
                            Email Address
                          </th>
                          <th className="px-4 first:pl-5 last:pr-5 tbl-th whitespace-nowrap font-bold tble-th-txt text-center w-[10%] border-r border-[#ffffff]  bg-[#D9D9D9] text-[#2D2D2D]">
                            NC
                            Signed On
                          </th>
                          <th className="px-4 first:pl-5 last:pr-5 tbl-th whitespace-nowrap font-bold tble-th-txt text-center w-[10%] border-r border-[#ffffff]  bg-[#D9D9D9] text-[#2D2D2D]">
                            Package Signed On
                          </th>
                          <th className="px-4 first:pl-5 last:pr-5 tbl-th whitespace-nowra font-bold tble-th-txt text-left w-[20%] border-r border-[#ffffff]  bg-[#D9D9D9] text-[#2D2D2D]">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm custom-tbody">
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[15%] tble-td-txt text-[#000000] ">
                            Head Assessor
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[15%] tble-td-txt text-[#000000] ">
                            <TextInput
                              value={'John'}
                            />
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[25%] tble-td-txt text-[#000000] ">
                            <TextInput
                              value={'John@gmail.com'}
                            />
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[10%] tble-td-txt text-[#000000] ">
                            5 Jan 2024
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[10%] tble-td-txt text-[#000000] ">
                            5 Jan 2024
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[20%] tble-td-txt text-[#000000] ">
                            <div className=''>
                              <a href='' className='2xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[14px] text-[14px] underline  text-[#003087]' >Send NC</a>
                              <button className="2xl:px-5 lg:px-2 py-2 btn btn-blue-border text-xl border text-[#005B9C] ml-10">
                                <span className="text-xl text-[#005B9C] font-semibold tracking-normal ">
                                  Send Package
                                </span>
                              </button>

                            </div>
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[15%] tble-td-txt text-[#000000] ">
                            Assessor 1
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[15%] tble-td-txt text-[#000000] ">
                            <TextInput
                              value={'Leia'}
                            />
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[25%] tble-td-txt text-[#000000] ">
                            <TextInput
                              value={'Leia@gmail.com'}
                            />
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[10%] tble-td-txt text-[#000000] ">
                            6 Jan 2024
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[10%] tble-td-txt text-[#000000] ">
                            6 Jan 2024
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[20%] tble-td-txt text-[#000000] ">
                            <div className=''>
                              <a href='' className='2xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[14px] text-[14px] underline  text-[#003087]' >Send NC</a>
                              <button className="2xl:px-5 lg:px-2 py-2 btn btn-blue-border text-xl border text-[#005B9C] ml-10">
                                <span className="text-xl text-[#005B9C] font-semibold tracking-normal ">
                                  Send Package
                                </span>
                              </button>

                            </div>
                          </td>
                        </tr>
                        <tr className="custom-tbl-td">
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[15%] tble-td-txt text-[#000000] ">
                            Assessor 2
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[15%] tble-td-txt text-[#000000] ">
                            <TextInput
                              value={'Betty'}
                            />
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[25%] tble-td-txt text-[#000000] ">
                            <TextInput
                              value={'Betty@gmail.com'}
                            />
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[10%] tble-td-txt text-[#000000] ">
                            7 Jan 2024
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[10%] tble-td-txt text-[#000000] ">
                            7 Jan 2024
                          </td>
                          <td className="px-2 first:pl-5 text-center border-[#ffffff] last:pr-5 whitespace-nowrap  w-[20%] tble-td-txt text-[#000000] ">
                            <div className=''>
                              <a href='' className='2xl:text-[16px] lg:text-[16px] md:text-[16px] sm:text-[14px] text-[14px] underline  text-[#003087]' >Send NC</a>
                              <button className="2xl:px-5 lg:px-2 py-2 btn btn-blue-border text-xl border text-[#005B9C] ml-10">
                                <span className="text-xl text-[#005B9C] font-semibold tracking-normal ">
                                  Send Package
                                </span>
                              </button>

                            </div>
                          </td>
                        </tr>


                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gap-6 w-full mt-[30px]">
              <div className='2xl:col-span-12 lg:col-span-12 sm:col-span-12 col-span-12 mb-2'>
                <div className="flex items-center justify-end">
                  <button className="text-lg font-bold btn btn-blue text-white">Submit</button>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}
